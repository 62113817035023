import React, { useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import moment from 'moment'

import Button from './button'
import StyledDatePicker from './date-picker'
import { FormField, FormLabel, FormRow } from './form'
import Input from './input'
import MultiList from './multi-list'
import { SelectBoxSimple } from './select-box'
import { currentUserDetails } from '../api/apollo/variables'
import { updateAccountDescription } from '../api/graphql/company-client'
import {
  createEnterpriseCompany,
  updateEnterpriseCompany,
} from '../api/graphql/support-client'
import { isNonWhitelabelSupportUser } from '../helpers'
import styles from '../styles/settings-enterprise-org-form.module.scss'

export const defaultEnterpriseFields: EnterpriseBillingDetails = {
  whitelabel: '',
  companyName: '',
  businessDivision: '',
  autoRenew: true,
  sponsorList: [],
  accountManagerList: [],
  contractStartDate: '',
  contractEndDate: '',
  revenuePerMonth: 0,
  totalContractValue: 0,
  breakClauseDate: null,
  domainLimit: 999,
  userLimit: 0,
  domains: '',
  legalEntityName: '',
  customTandC: false,
  companyNotes: '',
  origDataSource: 'GA4_PROP',
  trackAvailable: true,
  auditAvailable: true,
  reportAvailable: true,
  explainAvailable: false,
}

const formShape = {
  whitelabel: {
    title: 'Whitelabel domain',
    type: 'select',
    // These options are hardcoded for now
    options: [
      {
        name: 'None (Regular Uplifter client)',
        value: 'support',
      },
      {
        name: 'Uptimal',
        value: 'uptimal',
      },
    ],
  },
  companyName: {
    title: 'Client',
    type: 'input',
  },
  businessDivision: {
    optional: true,
    title: 'Business unit',
    type: 'input',
  },
  sponsorList: {
    title: 'Sponsors',
    type: 'multi-list',
    placeholder: 'User name and surname',
    placeholderTwo: 'User email',
  },
  legalEntityName: {
    title: 'Clients legal name',
    type: 'input',
  },
  contractStartDate: {
    title: 'Contract start date',
    type: 'date',
  },
  breakClauseDate: {
    optional: true,
    title: 'Break clause date',
    type: 'date',
  },
  contractEndDate: {
    title: 'Contract end date',
    type: 'date',
  },
  totalContractValue: {
    title: 'Total contract revenue',
    type: 'currency',
  },
  revenuePerMonth: {
    title: 'Monthly revenue',
    type: 'currency',
  },
  customTandC: {
    title: 'Custom Terms & Conditions?',
    type: 'toggle',
    label: ' ',
  },
  autoRenew: {
    title: 'Automatic contract renewal?',
    label: ' ',
    type: 'toggle',
  },
  domains: {
    optional: true,
    title: 'Website domains',
    type: 'textarea',
  },
  domainLimit: {
    title: 'Paid domain limit',
    type: 'number',
  },
  userLimit: {
    title: 'Paid users limit',
    type: 'number',
  },
  origDataSource: {
    title: 'Data source',
    type: 'select',
    options: [
      {
        name: 'Google Analytics',
        value: 'GA4_PROP',
      },
      {
        name: 'Adobe Analytics',
        value: 'AA_REPORT',
      },
    ],
  },
  trackModules: {
    type: 'group',
    title: 'Paid modules',
    group: {
      trackAvailable: {
        title: 'Track',
        label: 'Module enabled',
        type: 'toggle',
      },
      reportAvailable: {
        title: 'Report',
        label: 'Module enabled',
        type: 'toggle',
      },
    },
  },
  companyNotes: {
    optional: true,
    title: 'Notes',
    type: 'textarea',
  },
}

interface EnterpriseOrgFormProps {
  fields: EnterpriseBillingDetails
  includeWhitelabelField?: boolean
  onCreateNew?: () => void
}

export const EnterpriseOrgForm = ({
  fields: intialFields,
  includeWhitelabelField,
  onCreateNew,
}: EnterpriseOrgFormProps) => {
  const {
    companyID,
    workspaceID,
    userPermission,
    whiteLabelAdminDomain,
  } = useReactiveVar(currentUserDetails)

  const [addEnterpriseAccount] = useMutation(createEnterpriseCompany)
  const [updateCompanyDetails, { loading }] = useMutation(
    updateEnterpriseCompany,
  )
  /** Used for Alex to change the Track>Learn copy without needing to connect */
  const [updateTrackLearnCopy] = useMutation(updateAccountDescription)

  const [fields, setFields] = useState(intialFields)
  const [updated, setUpdated] = useState(false)

  const currencySign = '£'

  const updateFields = (key: string, value: any) => {
    setUpdated(true)
    setFields((curr) => ({ ...curr, [key]: value }))
  }

  return (
    <form
      id="enterpriseOrgForm"
      className={styles.newAccountForm}
      autoComplete="off"
      onSubmit={async (e) => {
        e.preventDefault()

        if (onCreateNew) {
          await addEnterpriseAccount({
            variables: {
              ...fields,
              dataSourceType: fields.origDataSource as string,
              // Whitelabel support users can only create new orgs on their whitelabel domain
              whitelabel: isNonWhitelabelSupportUser(userPermission)
                ? fields.whitelabel || undefined
                : whiteLabelAdminDomain,
              revenuePerMonthPreBreakClause: 0,
              revenuePerMonthPostBreakClause: 0,
            },
          })

          onCreateNew()
        } else {
          await updateCompanyDetails({
            variables: {
              companyID,
              ...fields,
              dataSourceType: fields.origDataSource as string,
            },
          })

          await updateTrackLearnCopy({
            variables: {
              accountID: workspaceID,
              trackLearnCopy: fields.origDataSource,
            },
          })
        }
      }}
    >
      {Object.keys(formShape).map((key) => {
        const shapeItem = formShape[key]
        const { optional, type } = shapeItem

        const value = fields[key]

        if (key === 'whitelabel' && !includeWhitelabelField) {
          return null
        }

        switch (shapeItem.type) {
          case 'textarea':
            return (
              <FormRow key={key} includePaddingBottom>
                <FormLabel
                  id={key}
                  optional={optional ? '(if applicable)' : ''}
                >
                  {shapeItem.title}
                </FormLabel>
                <FormField>
                  <Input
                    required={!shapeItem.optional}
                    id={key}
                    name={key}
                    type="textArea"
                    onChange={(val: string) => {
                      updateFields(key, val)
                    }}
                    placeholder="Click here and start typing..."
                    value={value}
                  />
                </FormField>
              </FormRow>
            )
          case 'toggle':
          case 'group':
            return (
              <FormRow key={key} includePaddingBottom>
                <FormLabel
                  id={key}
                  optional={optional ? '(if applicable)' : ''}
                >
                  {shapeItem.title}
                </FormLabel>
                <FormField>
                  {type === 'group' ? (
                    <span className={styles.groupFields}>
                      {Object.keys(shapeItem.group).map((groupKey) => {
                        const groupShapeItem = shapeItem.group[groupKey]
                        return (
                          <span key={groupKey} className={styles.row}>
                            <Input
                              type="checkbox"
                              id={groupKey}
                              name={groupKey}
                              label={groupShapeItem.title}
                              checked={fields[groupKey]}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                const { checked } = e.target as HTMLInputElement
                                updateFields(groupKey, checked)
                              }}
                            />
                          </span>
                        )
                      })}
                    </span>
                  ) : (
                    <Input
                      type="checkbox"
                      id={key}
                      name={key}
                      label={shapeItem.label}
                      checked={value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { checked } = e.target as HTMLInputElement
                        updateFields(key, checked)
                      }}
                    />
                  )}
                </FormField>
              </FormRow>
            )
          case 'currency':
            return (
              <FormRow key={key} includePaddingBottom>
                <FormLabel
                  id={key}
                  optional={optional ? '(if applicable)' : ''}
                >
                  {shapeItem.title} ({currencySign})
                </FormLabel>
                <FormField>
                  <Input
                    key={key}
                    required={!shapeItem.optional}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      const { value: val } = e.target as HTMLInputElement
                      const parsedVal = val ? parseInt(val, 10) : 0
                      updateFields(
                        key,
                        !Number.isNaN(parsedVal) ? parsedVal : 0,
                      )
                    }}
                    label={shapeItem.title}
                    type="text"
                    name={key}
                    id={key}
                    value={value}
                  />
                </FormField>
              </FormRow>
            )
          case 'number':
          case 'input':
            return (
              <FormRow key={key} includePaddingBottom>
                <FormLabel
                  id={key}
                  optional={optional ? '(if applicable)' : ''}
                >
                  <span>{shapeItem.title}</span>
                </FormLabel>
                <FormField>
                  <Input
                    required={!shapeItem.optional}
                    autoComplete="off"
                    key={key}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      const { value: val } = e.target as HTMLInputElement

                      if (shapeItem.type === 'number') {
                        const parsedVal = val ? parseInt(val, 10) : 0
                        updateFields(
                          key,
                          !Number.isNaN(parsedVal) ? parsedVal : 0,
                        )
                      } else {
                        updateFields(key, val)
                      }
                    }}
                    label={shapeItem.title}
                    type="text"
                    name={key}
                    id={key}
                    value={value}
                  />
                </FormField>
              </FormRow>
            )
          case 'date': {
            let dateValue: null | Date = null
            if (value !== null && value !== '') {
              const dateFormatted = moment(value, 'YYYYMMDD').format(
                'YYYY-MM-DD',
              )
              dateValue = new Date(Date.parse(dateFormatted))
              // check if date invalid
              if (Number.isNaN(dateValue.getTime())) {
                dateValue = null
              }
            }
            return (
              <FormRow key={key} includePaddingBottom>
                <FormLabel
                  id={key}
                  optional={optional ? '(if applicable)' : ''}
                >
                  {shapeItem.title}
                </FormLabel>
                <FormField>
                  <StyledDatePicker
                    dateFormat="dd/MM/y"
                    placeholderText="dd/MM/y"
                    key={key}
                    className="date-picker"
                    selected={dateValue}
                    onChange={(date) => {
                      if (date !== null) {
                        const dateF = moment(date.toString()).format('YYYYMMDD')
                        updateFields(key, dateF)
                      } else {
                        updateFields(key, date)
                      }
                    }}
                  />
                </FormField>
              </FormRow>
            )
          }
          case 'multi-list': {
            return (
              <FormRow key={key} includePaddingBottom>
                <FormLabel
                  id={key}
                  optional={optional ? '(if applicable)' : ''}
                >
                  {shapeItem.title}
                </FormLabel>
                <FormField>
                  <MultiList
                    id={key}
                    ItemOneLabel="Full Name"
                    ItemTwoLabel="Email"
                    list={value}
                    onChange={(val) =>
                      updateFields(
                        key,
                        val.map((v) => ({
                          name: v.col1Value,
                          email: v.col2Value,
                        })),
                      )
                    }
                  />
                </FormField>
              </FormRow>
            )
          }
          case 'select': {
            return (
              <FormRow key={key} includePaddingBottom>
                <FormLabel
                  id={key}
                  optional={optional ? '(if applicable)' : ''}
                >
                  {shapeItem.title}
                </FormLabel>
                <FormField>
                  <SelectBoxSimple
                    name={key}
                    key={key}
                    value={value}
                    disabled={!onCreateNew && ['whitelabel'].includes(key)}
                    onChange={(val) => {
                      updateFields(key, val)
                    }}
                  >
                    {shapeItem.options.map((i) => (
                      <option key={`${key}${i.value}`} value={i.value}>
                        {i.name}
                      </option>
                    ))}
                  </SelectBoxSimple>
                </FormField>
              </FormRow>
            )
          }
          default:
            return null
        }
      })}
      {!onCreateNew && (
        <FormRow bottomBorder={false}>
          <FormLabel />
          <FormField>
            <Button
              className={styles.saveButton}
              loading={loading}
              isDisabled={!updated}
              form="enterpriseOrgForm"
            >
              Save
            </Button>
          </FormField>
        </FormRow>
      )}
    </form>
  )
}
