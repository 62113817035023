import React, { useMemo } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { currentUserDetails, dataSourceReactive } from '../api/apollo/variables'
import { updateAccountDescription } from '../api/graphql/company-client'
import { getAccountDataSource } from '../api/graphql/workspace-client'
import adobeAnalytics from '../assets/logos/adobe-analytics.svg'
import googleAnalytics from '../assets/logos/google-analytics-icon.svg'
import SiteWrapper from '../components/site-wrapper'
import Layout from '../components/layout'
import Intro from '../components/intro'
import { NavigateButton } from '../components/button'
import CampaignIntroContent from '../components/campaign-intro-content'
import { Toggle } from '../components/input-v2'
import TrackLearnAccordion from '../components/track-learn-accordion'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/track-learn-accordion.module.scss'

export default function TrackLearn() {
  const { workspaceID } = useReactiveVar(currentUserDetails)
  const dataSource = useReactiveVar(dataSourceReactive)

  const logAction = useLogAction()

  const history = useHistory()

  const { data: dataSourceData } = useQuery(getAccountDataSource)

  const [updateTrackLearnCopy] = useMutation(updateAccountDescription)

  const dataSourceCopyToShow = useMemo(() => {
    if (!!dataSource && dataSource.connectionSource !== 'not-connected') {
      return dataSource.connectionSource === 'adobe'
        ? 'adobe'
        : 'googleAnalytics'
    }

    return dataSourceData?.currentAccount.trackLearnCopy === 'AA_REPORT'
      ? 'adobe'
      : 'googleAnalytics'
  }, [dataSourceData])

  return (
    <SiteWrapper>
      <Layout width={1200}>
        <Intro title="Learn how to track campaigns">
          <div className={styles.introContainer}>
            <CampaignIntroContent>
              <p>
                Click on a topic to start learning and take our test to get a
                LinkedIn certificate.
              </p>
            </CampaignIntroContent>
            {/* User should only be able to toggle if not connected to a data source */}
            {(!dataSource ||
              dataSource.connectionSource === 'not-connected') && (
              <div className={styles.switchContainer}>
                <p className={styles.toggleLabel}>
                  Showing{' '}
                  {dataSourceCopyToShow === 'adobe'
                    ? 'Adobe Analytics'
                    : 'Google Analytics'}
                </p>
                <Toggle
                  checked={dataSourceCopyToShow === 'adobe'}
                  onColor="#cbd5e0"
                  offColor="#cbd5e0"
                  checkedIcon={
                    <img
                      src={adobeAnalytics}
                      className={styles.toggleIcon}
                      alt="Adobe Analytics"
                    />
                  }
                  uncheckedIcon={
                    <img
                      src={googleAnalytics}
                      className={styles.toggleIcon}
                      alt="Google Analytics"
                    />
                  }
                  onChange={async (isAdobe) => {
                    await updateTrackLearnCopy({
                      variables: {
                        accountID: workspaceID,
                        trackLearnCopy: isAdobe ? 'AA_REPORT' : 'GA4_PROP',
                      },
                    })

                    logAction({
                      variables: {
                        action: 'track-learn-switch-data-source',
                        pagePath: '/track/learn',
                        functionName: 'dataSourceToggle',
                        websiteSection: 'track',
                        extra: isAdobe ? 'AA_REPORT' : 'GA4_PROP',
                      },
                    })
                  }}
                />
              </div>
            )}
          </div>
        </Intro>

        <TrackLearnAccordion adobe={dataSourceCopyToShow === 'adobe'} />

        <NavigateButton onPress={() => history.push('/track/create-links')}>
          Create campaign links
        </NavigateButton>
      </Layout>
    </SiteWrapper>
  )
}
