import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import { nanoid } from 'nanoid'

import useCustomLinks from './useCustomLinks'
import useLogAction from './useLogAction'
import { currentUserDetails, dataSourceReactive } from '../api/apollo/variables'
import {
  createNewLink,
  getUplifterIDCurrentTotal,
  updateCurrentSequentialCodeID,
} from '../api/graphql/track-create-client'
import { listAppGroups } from '../api/graphql/track-edit-client'
import { getMinCodesByAccount } from '../api/graphql/track-view-client'
import { loopApiCall } from '../helpers'
import {
  EmailForm,
  SavedFormType,
  WebLinkForm,
  formIsWebLinkForm,
  getExistingQueryString,
  makeLinkSecure,
} from '../helpers/track-create'
import {
  defaultValidationChecksValues,
  getAnchorFromString,
  getCustomDomainID,
  getDomain,
} from '../helpers/track-module'
import useOnboarding from './useOnboarding'
import {
  AddCode,
  DeepLinkInput,
  GetCampaignCodeGeneratorQuery,
} from '../__gql-types__/graphql'

export interface BuildLinksResult extends AddCode {
  urlWithHash: string
  selected: boolean
  urlLength: number
  queryLength: number
}

export interface SoftWarningModalType {
  type: 'no-url' | 'no-url-shortlink' | 'has-anchor'
  fullLinks: BuildLinksResult[]
}

export interface HardWarningModalType {
  type: 'invalid-query-length' | 'invalid-landing-page-length'
  fullLinks: BuildLinksResult[]
  characterLimit: number
  charactersOverLimit: number
}

export interface CustomLinkDomainDetails {
  shortLinkDomainID: string
  appLinkDomainID: string
  appLinkDomainLabel: string
}

interface UseTrackCreateFormSubmitProps {
  formType: SavedFormType
  formSubmissionState: {
    softDisable: boolean
    fieldsWithErrors: string[]
    showErrorMessages: boolean
  }
  setFormSubmissionState: React.Dispatch<
    React.SetStateAction<{
      softDisable: boolean
      fieldsWithErrors: string[]
      showErrorMessages: boolean
    }>
  >
  generatedStructure:
    | GetCampaignCodeGeneratorQuery['campaignCodeGenerator']
    | null
  useAppLinks?: boolean
}

/** Submission logic and error state for Track>Create form */
const useTrackCreateFormSubmit = ({
  formType,
  formSubmissionState,
  setFormSubmissionState,
  generatedStructure,
  useAppLinks,
}: UseTrackCreateFormSubmitProps) => {
  const { userEmail, workspaceID } = useReactiveVar(currentUserDetails)
  const dataSource = useReactiveVar(dataSourceReactive)

  const logAction = useLogAction()

  const {
    fullOnboardingSections: { user: userOnboardingSections },
    updateOnboardingSection,
  } = useOnboarding()

  const {
    canUseCustomLinks,
    availableDomains,
    availableAliases,
    fetchingAliases,
    useAliases,
  } = useCustomLinks(useAppLinks ? 'appLink' : 'shortLink')

  const [getUserLinks] = useLazyQuery(getMinCodesByAccount)
  const [
    getUplifterID,
    { data: uplifterIdData, error: uplifterIdError },
  ] = useLazyQuery(getUplifterIDCurrentTotal, { fetchPolicy: 'network-only' })
  const [fetchAppGroupDetails] = useLazyQuery(listAppGroups)

  const [checkUplifterID, { loading: checkingUplifterIDs }] = useMutation(
    updateCurrentSequentialCodeID,
    {
      notifyOnNetworkStatusChange: true,
    },
  )
  const [addCode, { loading: addingCodes }] = useMutation(createNewLink)

  const [linkType, setLinkType] = useState<'short' | 'full' | null>(null)

  const [customLinkKey, setCustomLinkKey] = useState(nanoid())
  const [customLinkDomainDetails, setCustomLinkDomainDetails] = useState<
    CustomLinkDomainDetails
  >({
    shortLinkDomainID: '',
    appLinkDomainID: '',
    appLinkDomainLabel: '',
  })
  const [customLinkAlias, setCustomLinkAlias] = useState(
    availableAliases ? availableAliases.individual[0] : '',
  )
  const [customLinkError, setCustomLinkError] = useState(false)

  const [createLinkWarning, setCreateLinkWarning] = useState<
    SoftWarningModalType | HardWarningModalType | null
  >(null)
  const [createLinkError, setCreateLinkError] = useState(false)

  // Adobe accounts don't use UplifterID - don't fetch until data source is known
  useEffect(() => {
    if (
      !!workspaceID &&
      (!dataSource || dataSource.connectionSource !== 'adobe')
    ) {
      getUplifterID()
    }
  }, [workspaceID, dataSource])

  const useUplifterID = useMemo(() => {
    if (!uplifterIdData || dataSource?.connectionSource === 'adobe')
      return false

    return !!uplifterIdData.track.currentSequentialCodeID.isEnabled
  }, [uplifterIdData, dataSource])

  /** Builds correct shape ready for addCodes mutation, without calling it */
  // TODO: Make this work for multi-journey
  const buildFullLinks = useCallback(
    (formVals: WebLinkForm | EmailForm): BuildLinksResult[] => {
      if (!generatedStructure) return []

      const {
        paramDefs,
        masterPrefix,
        paramSeparator,
        validationChecks,
        existingParametersAddedToStart,
      } = generatedStructure

      /** Rule to include empty parameter values in built link */
      const includeEmptyValuesRule = validationChecks.find(
        (check) => check.name === 'INCLUDE_EMPTY_VALUES',
      )

      const includeEmptyValues = !!(
        includeEmptyValuesRule && includeEmptyValuesRule.enabled
      )

      const copyFromIncludePrefixRule = validationChecks.find(
        (check) => check.name === 'INCLUDE_PREFIX_WITH_COPY_FROM',
      )

      /** If field is copied from others, should the copied field's prefix be part of the value? */
      const copyFromIncludePrefix =
        copyFromIncludePrefixRule && copyFromIncludePrefixRule.enabled

      /** If field is copied from other fields, how should multiple values be separated? */
      const copyFromSeparator =
        validationChecks.find((check) => check.name === 'COPY_FROM_SEPARATOR')
          ?.value || '|'

      const codeList: BuildLinksResult[] = []

      if (formIsWebLinkForm(formVals)) {
        const urlsToUse = [...formVals.url]

        if (urlsToUse.length === 0) urlsToUse.push('')

        urlsToUse.forEach((link) => {
          const codeToAdd: BuildLinksResult = {
            fC: '',
            pDfs: [],
            lP: '',
            tC: '',
            // TODO: This won't work for multi-journey
            shortLinkID: customLinkAlias,
            urlWithHash: '',
            selected: true,
            urlLength: 0,
            queryLength: 0,
          }

          let queryString = ''

          paramDefs.forEach((param) => {
            const {
              fieldID,
              fieldType,
              selectFields,
              metaParameter,
              prefix,
              parameterDependsOn,
              copyFromField,
              fixedValue,
            } = param

            let codeName = ''
            let codeValue = ''

            const fieldVals = formVals.generatorParameterValues[fieldID]

            const isCopyFromField = copyFromField && copyFromField.length > 0

            if (isCopyFromField) {
              let addCopyValues = true

              if (parameterDependsOn) {
                const { parentFieldID, parentOptionIDs } = parameterDependsOn

                if (parentFieldID === 'account') {
                  addCopyValues = parentOptionIDs.includes(workspaceID)
                } else if (
                  !formVals.generatorParameterValues[parentFieldID] ||
                  !parentOptionIDs.includes(
                    // TODO: Needs to work for all array elements of fieldVals
                    formVals.generatorParameterValues[parentFieldID][0],
                  )
                ) {
                  // Do not add values for copyFrom fields if its dependencies are not met
                  addCopyValues = false
                }
              }

              if (addCopyValues) {
                // Fetch values of copied fields in the form
                // TODO: Needs to work for all array elements of fieldVals
                const copyFromValues = copyFromField.reduce(
                  (acc, { copyFromID }) => {
                    return {
                      ...acc,
                      [copyFromID]: formVals.generatorParameterValues[
                        copyFromID
                      ]
                        ? formVals.generatorParameterValues[copyFromID][0] || ''
                        : '',
                    }
                  },
                  {},
                )

                let copiedName = ''
                let copiedValue = ''

                Object.entries(copyFromValues).forEach(
                  ([copiedFieldID, copiedFieldValue], copyFieldIndex) => {
                    // Get full copied field
                    const copiedField = paramDefs.find(
                      ({ fieldID: fieldToCheck }) =>
                        fieldToCheck === copiedFieldID,
                    )

                    let valueToCopy = copiedFieldValue

                    if (copiedField?.fieldType === 'select') {
                      const copiedSelectField = copiedField.selectFields?.find(
                        (field) => field.optionID === copiedFieldValue,
                      )

                      if (copiedSelectField) {
                        valueToCopy = copiedSelectField.optionValue
                      }
                    }

                    // Do not include empty values
                    if (valueToCopy === '') return

                    // First param should not be preceded with a separator
                    // * Special case: do not use copyFrom separator if the copied param does not have a prefix
                    // * The copied values might combine to a single value for the generator, so should not use copyFrom separator
                    if (copyFieldIndex > 0 && copiedField?.prefix !== '') {
                      copiedName += copyFromSeparator
                      copiedValue += copyFromSeparator
                    }

                    // Add copiedField prefix if required
                    if (copyFromIncludePrefix) {
                      copiedName += copiedField?.prefix || ''
                      copiedValue += copiedField?.prefix || ''
                    }

                    copiedName += valueToCopy
                    copiedValue += valueToCopy
                  },
                )

                codeName = copiedName
                codeValue = copiedValue
              }
            } else if (fieldType === 'fixed') {
              // Only add fixed parameter if its dependencies are met
              let addFixedValue = true

              if (parameterDependsOn) {
                const { parentFieldID, parentOptionIDs } = parameterDependsOn

                if (parentFieldID === 'account') {
                  addFixedValue = parentOptionIDs.includes(workspaceID)
                } else if (
                  !formVals.generatorParameterValues[parentFieldID] ||
                  !parentOptionIDs.includes(
                    // TODO: Needs to work for all array elements of fieldVals
                    formVals.generatorParameterValues[parentFieldID][0],
                  )
                ) {
                  // Do not add values for copyFrom fields if its dependencies are not met
                  addFixedValue = false
                }
              }

              if (addFixedValue) {
                codeName = fixedValue || ''
                codeValue = fixedValue || ''
              }
            } else if (fieldVals) {
              // TODO: Needs to work for all array elements of fieldVals
              const fieldVal = fieldVals[0]

              // TODO: Add parameterDependsOn logic
              // It only applies in the multi journey
              // if (parameterDependsOn) {
              //   // Fields can only depend on other 'select' fields
              //   console.log('Blocking for:', fieldID)

              //   return
              // }

              if (
                fieldType === 'select' &&
                selectFields &&
                selectFields.length > 0
              ) {
                const selectField = selectFields.find(
                  (field) => field.optionID === fieldVal,
                )

                if (selectField) {
                  codeName = selectField.optionName
                  codeValue = selectField.optionValue
                }
              } else {
                codeName = fieldVal || ''
                codeValue = fieldVal || ''
              }
            }

            codeToAdd.pDfs?.push([codeName, codeValue])

            if (!metaParameter && (includeEmptyValues || codeName !== '')) {
              queryString += `${paramSeparator}${prefix}${codeValue}`
            }
          })

          // Remove leading paramSeparator
          queryString = queryString
            .slice(paramSeparator.length)
            .replaceAll(' ', '%20')

          const secureLink = makeLinkSecure(link)

          // Add negative lookahead to accomodate hash based routing
          // https://example.com/#/# should not have the first hash removed
          const linkAnchor = getAnchorFromString(secureLink)
          const anchorReplaceRegex = new RegExp(
            `#(?!\/)${linkAnchor.replace('#', '')}`,
            'i',
          )

          const linkWithoutHash = secureLink.replace(anchorReplaceRegex, '')

          let fullCode: string

          const linkHasQueryString = linkWithoutHash.indexOf('?') !== -1

          // Move existing parameters to the end
          if (linkHasQueryString && !existingParametersAddedToStart) {
            const existingQueryString = getExistingQueryString(linkWithoutHash)

            const linkWithoutExistingParams = linkWithoutHash
              .replace(existingQueryString, '')
              .replace('?', '')

            fullCode = `${linkWithoutExistingParams}${masterPrefix}${queryString}${
              existingQueryString ? `&${existingQueryString}` : ''
            }${linkAnchor}`
          } else {
            const isPrefixLastCharacter =
              linkHasQueryString &&
              linkWithoutHash.indexOf('?') === linkWithoutHash.length - 1

            const prefixToReplace = isPrefixLastCharacter ? '' : '&'

            fullCode = `${linkWithoutHash}${
              linkHasQueryString
                ? masterPrefix.replace('?', prefixToReplace)
                : masterPrefix
            }${queryString}${linkAnchor}`
          }

          codeToAdd.tC = queryString
          codeToAdd.fC = fullCode
          codeToAdd.lP = linkWithoutHash
          codeToAdd.urlWithHash = secureLink
          codeToAdd.urlLength = codeToAdd.fC.length
          codeToAdd.queryLength = codeToAdd.fC.replace(
            linkWithoutHash,
            '',
          ).length

          codeList.push(codeToAdd)
        })
      } else {
        // TODO: Email version
      }

      return codeList
    },
    [generatedStructure, customLinkAlias],
  )

  /** Adds sequential Uplifter IDs to built links in place */
  const addUplifterIDsToLinks = useCallback(
    async (fullLinks: BuildLinksResult[]) => {
      if (!uplifterIdData || !useUplifterID) return fullLinks

      try {
        const {
          track: {
            currentSequentialCodeID: { currentTotal, etag, prefix, acctPrefix },
          },
        } = uplifterIdData

        // Rerun Uplifter ID check until sent etag and currentTotal match new values
        // If checked > 50 times, exit
        const {
          currentTotal: newCurrentTotal,
          updatedTotal,
        } = await loopApiCall(
          async (variables) => {
            const { data: newData } = await checkUplifterID({
              variables,
            })

            return (
              newData?.track.updateCurrentSequentialCodeID || {
                currentTotal: currentTotal || '0',
                etag: '',
                updatedTotal: null,
              }
            )
          },
          {
            // If updatedTotal is null, check failed and must be rerun
            successCheck: (res) =>
              !!(
                res.etag &&
                res.updatedTotal !== undefined &&
                res.updatedTotal !== null
              ),
            currCallbackVars: {
              currentTotal: currentTotal || '0',
              etag,
              newTotal: (
                parseInt(currentTotal || '0', 16) + fullLinks.length
              ).toString(16),
            },
            varsTransformer: (res) => ({
              currentTotal: res.currentTotal,
              etag: res.etag,
              newTotal: (
                parseInt(res.currentTotal, 16) + fullLinks.length
              ).toString(16),
            }),
          },
          50,
        )

        if (updatedTotal === null) {
          throw new Error('Reached limit of attempts to get valid Uplifter IDs')
        }

        // Add Uplifter ID (before hash) for all codes
        fullLinks.forEach((fullLink, fullLinkIndex) => {
          let fullUplifterID = `${prefix}${acctPrefix}`

          if (fullLinkIndex === 0) {
            fullUplifterID += (parseInt(newCurrentTotal, 16) + 1).toString(16)
          } else {
            fullUplifterID += (
              parseInt(newCurrentTotal, 16) +
              fullLinkIndex +
              1
            ).toString(16)
          }

          // Add negative lookahead to accomodate hash based routing
          // https://example.com/#/# should not have the first hash removed
          const linkAnchor = getAnchorFromString(fullLink.fC)
          const anchorReplaceRegex = new RegExp(
            `#(?!\/)${linkAnchor.replace('#', '')}`,
            'i',
          )

          const fullLinkWithoutAnchor = fullLink.fC.replace(
            anchorReplaceRegex,
            '',
          )
          const fullParamsWithoutAnchor = fullLink.tC.replace(
            anchorReplaceRegex,
            '',
          )

          fullLinks.splice(fullLinkIndex, 1, {
            ...fullLink,
            fC: `${fullLinkWithoutAnchor}${fullUplifterID}${linkAnchor}`,
            tC: `${fullParamsWithoutAnchor}${fullUplifterID}${linkAnchor}`,
          })
        })

        return fullLinks
      } catch {
        throw new Error('Issue with Uplifter IDs')
      }
    },
    [uplifterIdData, useUplifterID],
  )

  /** Calls addCodes mutation and uses shortLink aliases ans UplifterIDs */
  const createLinks = useCallback(
    async (
      fullLinks: BuildLinksResult[],
      appLink?: {
        appGroupID: string
        redirectContext: string[]
        fallbackUrl?: string
      },
    ) => {
      try {
        const codeList = fullLinks.map(({ tC, lP, fC, pDfs, shortLinkID }) => ({
          fC,
          lP,
          tC,
          pDfs,
          shortLinkID: useAppLinks || linkType === 'short' ? shortLinkID : '',
        }))

        let deepLinkConfig: DeepLinkInput | null = null

        if (appLink) {
          const { data: appGroupData } = await fetchAppGroupDetails()

          if (!appGroupData) {
            throw new Error('App group not found')
          }

          const fullAppGroupDetails = appGroupData.track.deepLinkQueries.listAppGroups.find(
            (appGroup) => appGroup.appGroupID === appLink.appGroupID,
          )

          if (!fullAppGroupDetails) {
            throw new Error('App group not found')
          }

          const {
            appGroupID,
            deepLinkServiceID,
            redirectWeb,
          } = fullAppGroupDetails

          deepLinkConfig = {
            appGroupID,
            deepLinkServiceID,
            redirectContext: appLink.redirectContext
              ? appLink.redirectContext
              : undefined,
            fallBackURL: appLink.fallbackUrl || redirectWeb,
          }
        }

        // TODO: Bulk start
        await addCode({
          variables: {
            customDomainID: useAppLinks
              ? undefined
              : getCustomDomainID(customLinkDomainDetails.shortLinkDomainID),
            codeList,
            deepLinkConfig: deepLinkConfig || undefined,
          },
        })

        // TODO: Need to use ALL aliases in the multi journey
        // * See onYes function in previewModal for campaign-code-generator-form.tsx
        if (useAppLinks || linkType === 'short') {
          useAliases([customLinkAlias])

          // Re-render shortLink component so custom alias is replaced with existing generated one
          setCustomLinkKey(nanoid())
        }

        // Refetch totals
        // TODO (Stretch): Update the cached object's value for currentTotal instead of refetching
        if (
          (!dataSource || dataSource.connectionSource !== 'adobe') &&
          useUplifterID
        ) {
          await getUplifterID()
        }

        // GA Tracking
        // @ts-ignore
        if (window.dataLayer && window.dataLayer.push) {
          // @ts-ignore
          window.dataLayer.push({
            event: 'create_campaign_link',
            link_creation_type: `add-code${
              linkType === 'short' ? '-short-link' : ''
            }`,
            link_count: fullLinks.filter(({ selected }) => selected).length,
          })
        }

        logAction({
          variables: {
            action: `add-code${linkType === 'short' ? '-short-link' : ''}`,
            functionName: 'addCodes',
            pagePath: '/track/create-links',
            extra: fullLinks
              .filter(({ selected }) => selected)
              .length.toString(),
            websiteSection: 'track',
          },
        })

        // Check if onboarding step `createCampaignLink` is completed
        if (
          !userOnboardingSections.find(
            (section) => section.onboardingSectionID === 'createCampaignLink',
          )?.sectionCompleted
        ) {
          // Updates the backend cache for onboarding state
          updateOnboardingSection('createCampaignLink', 'user')
        }

        return {
          fullLinks,
          customLinkDomain: availableDomains.find((availableDomain) => {
            if (useAppLinks) {
              return (
                availableDomain.optionValue ===
                customLinkDomainDetails.appLinkDomainID
              )
            }

            return (
              availableDomain.optionValue ===
              customLinkDomainDetails.shortLinkDomainID
            )
          })?.optionName,
        }
      } catch {
        throw new Error('Issue with creating links')
      }
    },
    [
      useAppLinks,
      linkType,
      availableDomains,
      customLinkAlias,
      customLinkDomainDetails,
      userOnboardingSections,
    ],
  )

  const submitForm = useCallback(
    async (
      formVals: WebLinkForm | EmailForm,
      /** Used to ignore errors (e.g. missing URL, anchor present). Should only be set to true in warning modals */
      forceBuild?: boolean,
    ): Promise<{
      success: boolean
      fullLinks: BuildLinksResult[]
      customLinkDomain?: string
    }> => {
      if (!generatedStructure) return { success: false, fullLinks: [] }

      setCreateLinkError(false)

      const { softDisable, fieldsWithErrors } = formSubmissionState

      // Do not generate link if form has errors
      if (softDisable) {
        setFormSubmissionState((curr) => ({
          ...curr,
          showErrorMessages: true,
        }))

        logAction({
          variables: {
            action: 'failed-add-code',
            functionName: 'addCodes',
            pagePath: '/track/create-links',
            extra: JSON.stringify(formVals),
            websiteSection: 'track',
          },
        })

        if (fieldsWithErrors.includes('landingPage')) {
          logAction({
            variables: {
              action: 'track-error-missing-landing-page',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
            },
          })
        }

        const paramErrors = fieldsWithErrors.filter(
          (error) => error !== 'landingPage',
        )

        if (paramErrors.length > 0) {
          logAction({
            variables: {
              action: 'track-error-missing-required-field',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
              extra: JSON.stringify(paramErrors),
            },
          })
        }

        return { success: false, fullLinks: [] }
      }

      let fullLinks: BuildLinksResult[] = []

      try {
        setFormSubmissionState({
          softDisable: false,
          fieldsWithErrors: [],
          showErrorMessages: false,
        })

        setCustomLinkError(false)

        let deepLinkConfig: {
          appGroupID: string
          redirectContext?: string[]
          fallbackUrl: string
        }

        if (formIsWebLinkForm(formVals)) {
          const { validationChecks } = generatedStructure

          const showLandingPageRule = validationChecks.find(
            (check) => check.name === 'SHOW_LANDING_PAGE',
          )

          const showLanding = !!(
            showLandingPageRule && showLandingPageRule.enabled
          )

          // Check recently created links to confirm alias has not already been used
          if (useAppLinks || linkType === 'short') {
            // Shortlink being created but no URL present - don't create link
            if (linkType === 'short' && !showLanding && !forceBuild) {
              // ! Throw on error/warning?
              setCreateLinkWarning({ type: 'no-url-shortlink', fullLinks: [] })

              logAction({
                variables: {
                  action: 'track-error-missing-landing-page',
                  functionName: 'addCodes',
                  pagePath: '/track/create-links',
                  websiteSection: 'track',
                },
              })

              return { success: false, fullLinks: [] }
            }

            // Check if aliases have already been used by the user
            const { data } = await getUserLinks({
              variables: {
                dimensionFilter: {
                  dimensionName: userEmail,
                  dimensionParameterID: 'createdBy',
                  dimensionOptions: [],
                },
                limit: 1000,
              },
              fetchPolicy: 'cache-only',
            })

            if (data?.minCodesByAccount) {
              const usedAliases = (
                data.minCodesByAccount.shortLink || []
              ).filter((sL) => sL !== '')

              // TODO: Make this work for multi-journey
              const aliasExists = usedAliases.find(
                (sL) => sL.indexOf(customLinkAlias) > -1,
              )

              // Don't create new link - it already exists
              if (aliasExists) {
                setCustomLinkError(true)
                return { success: false, fullLinks: [] }
              }
            }
          }

          const allUrlsValid =
            formVals.url.length === 0
              ? false
              : formVals.url.every((url) => !!getDomain(url))

          if (showLanding && !allUrlsValid && !forceBuild) {
            // ! Throw on error/warning?
            setCreateLinkWarning({ type: 'no-url', fullLinks: [] })

            logAction({
              variables: {
                action: 'track-error-missing-landing-page',
                functionName: 'addCodes',
                pagePath: '/track/create-links',
                websiteSection: 'track',
              },
            })

            return { success: false, fullLinks: [] }
          }

          // Show warning modal for anchors in URLs
          const urlsContainAnchors: boolean[] = []
          formVals.url.forEach((url) => {
            const anchorPresent = url.match(/#/g)

            // Need to ensure `#` character is not part of URL routing
            // `#` is not always an anchor
            urlsContainAnchors.push(
              !!(
                anchorPresent &&
                !(anchorPresent.length === 1 && url.indexOf('/#/') !== -1)
              ),
            )
          })

          if (!forceBuild && urlsContainAnchors.find((url) => !!url)) {
            // ! Throw on error/warning?
            setCreateLinkWarning({ type: 'has-anchor', fullLinks: [] })

            logAction({
              variables: {
                action: 'track-error-anchor-warning',
                functionName: 'addCodes',
                pagePath: '/track/create-links',
                websiteSection: 'track',
              },
            })

            return { success: false, fullLinks: [] }
          }

          fullLinks = buildFullLinks(formVals)

          await addUplifterIDsToLinks(fullLinks)

          const maxQueryLengthRule = validationChecks.find(
            (item) => item.name === 'LIMIT_QUERY_LENGTH',
          )
          const maxQueryLength =
            maxQueryLengthRule &&
            maxQueryLengthRule.enabled &&
            maxQueryLengthRule.value
              ? parseInt(maxQueryLengthRule.value || '', 10)
              : parseInt(
                  defaultValidationChecksValues.LIMIT_QUERY_LENGTH || '',
                  10,
                )

          const queryLengths = fullLinks.map(({ queryLength }) => queryLength)

          const longestQuery = Math.max(...queryLengths)

          // Check query is shorter than max length rule
          if (longestQuery > maxQueryLength) {
            setCreateLinkWarning({
              type: 'invalid-query-length',
              fullLinks,
              characterLimit: maxQueryLength,
              charactersOverLimit: longestQuery - maxQueryLength,
            })

            logAction({
              variables: {
                action: 'track-error-landing-query-length',
                functionName: 'addCodes',
                pagePath: '/track/create-links',
                websiteSection: 'track',
                extra: JSON.stringify({
                  queryOverLimitBy: longestQuery - maxQueryLength,
                }),
              },
            })

            return { success: false, fullLinks: [] }
          }

          if (showLanding) {
            const maxUrlLengthRule = validationChecks.find(
              (item) => item.name === 'LIMIT_URL_LENGTH',
            )

            const maxUrlLength =
              maxUrlLengthRule &&
              maxUrlLengthRule.enabled &&
              maxUrlLengthRule.value
                ? parseInt(maxUrlLengthRule.value || '', 10)
                : parseInt(
                    defaultValidationChecksValues.LIMIT_URL_LENGTH || '',
                    10,
                  )

            const urlLengths = fullLinks.map(({ urlLength }) => urlLength)

            const longestUrl = Math.max(...urlLengths)

            // Check URL is shorter than max length rule
            if (longestUrl > maxUrlLength) {
              setCreateLinkWarning({
                type: 'invalid-landing-page-length',
                fullLinks,
                characterLimit: maxUrlLength,
                charactersOverLimit: longestUrl - maxUrlLength,
              })

              logAction({
                variables: {
                  action: 'track-error-landing-page-length',
                  functionName: 'addCodes',
                  pagePath: '/track/create-links',
                  websiteSection: 'track',
                  extra: JSON.stringify({
                    linkOverLimitBy: longestUrl - maxUrlLength,
                  }),
                },
              })

              return { success: false, fullLinks: [] }
            }
          }

          if (formVals.appValues) {
            const { appGroupID, appScreen } = formVals.appValues

            deepLinkConfig = {
              appGroupID,
              redirectContext: [appScreen],
              fallbackUrl: formVals.url[0],
            }
          }
        } else {
          // TODO: Email form
        }

        // Only trigger 'CreateNewLink' mutation if on single version of form
        if (fullLinks.length === 1) {
          const { customLinkDomain } = await createLinks(
            fullLinks,
            // @ts-ignore
            deepLinkConfig,
          )

          return { success: true, fullLinks, customLinkDomain }
        }

        // TODO: Use response in preview modals (multi/email forms)
        return { success: true, fullLinks }
      } catch {
        setCreateLinkError(true)

        return { success: false, fullLinks }
      }
    },
    [
      formType,
      linkType,
      formSubmissionState,
      buildFullLinks,
      addUplifterIDsToLinks,
      createLinks,
      generatedStructure,
      customLinkAlias,
    ],
  )

  return {
    linkType,
    setLinkType,
    canUseCustomLinks,
    customLinkAlias,
    setCustomLinkAlias,
    fetchingAliases,
    customLinkKey,
    customLinkDomainDetails,
    setCustomLinkDomainDetails,
    customLinkError,
    submitForm,
    createLinkWarning,
    setCreateLinkWarning,
    createLinkLoading: checkingUplifterIDs || addingCodes,
    uplifterIdLoading:
      dataSource &&
      dataSource.connectionSource !== 'adobe' &&
      !uplifterIdData &&
      !uplifterIdError,
    uplifterIdError,
    createLinkError,
  }
}

export default useTrackCreateFormSubmit
