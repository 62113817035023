import { gql } from '../../__gql-types__/gql'

export const getAccountDataSource = gql(`
	query GetAccountDataSource {
		currentAccount {
			accountID
			blankAccount
			dataSource {
				adobeReportSuiteName
				adobeSegmentName
				connected
				gaAccountName
				gaPropertyName
				gaViewName
				ga4AccountName
				ga4PropertyName
				kind
			}
			trackLearnCopy
		}
	}
`)

export const getCompanyWorkspacesDataSources = gql(`
	query GetCompanyWorkspacesDataSources {
		currentCompany {
			companyID
			accountList {
				accountID
				accountName
				homepage
				blankAccount
				dataSource {
					gaPropertyID
					ga4PropertyID
					gaAccountName
					gaAccountID
					ga4PropertyName
					ga4AccountName
					connected
					adobeSegmentName
					adobeReportSuiteName
					adobeReportID
					ga4AccountID
					gaPropertyName
					gaViewID
					gaViewName
					kind
				}
			}
		}
	}
`)

export const getAccountUsers = gql(`
	query GetAccountUsers ($accountID: String!) {
		account(accountID: $accountID) {
			accountID
			userAccountProfiles {
				accountID
				accountName
				created
				expired
				inviteOpen
				lastLogin
				userEmail
				userID
				userPermission
			}
		}
	}
`)

export const updateUserPermissions = gql(`
	mutation UpdateUserPermissions(
		$accountID: String!
		$userID: String!
		$permissionLevel: String!
	) {
		userAccountSettings {
			updateUserPermissionLevel(
				accountID: $accountID
				permissionLevel: $permissionLevel
				userID: $userID
			) {
				email
				userID
			}
		}
	}
`)

export const removeExistingUser = gql(`
	mutation RemoveUser(
		$accountID: String!,
		$userID: String!
	) {
		userAccountSettings {
			removeUser(
				accountID: $accountID,
				userID: $userID
			) {
				accountID
				userAccountProfiles {
					accountID
					accountName
					created
					expired
					inviteOpen
					lastLogin
					userEmail
					userID
					userPermission
				}
			}
		}
	}
`)

export const revokeUserInvite = gql(`
	mutation RevokeUserInvite($userID: String!) {
		userAccountSettings {
			revokeUserInvite(userID: $userID)
		}
	}
`)

export const toggleModuleAvailability = gql(`
	mutation ToggleModuleAvailability (
		$accountID: String!,
		$companyID: String!,
		$explainModule: Boolean,
		$reportModule: Boolean,
		$trackModule: Boolean
	) {
		userAccountSettings {
			toggleModuleAvailability (
				applyToCompany: false
				accountID: $accountID
				companyID: $companyID
				explainModule: $explainModule
				reportModule: $reportModule
				trackModule: $trackModule
			) {
				... on Account {
					__typename
					accountID
					explainAvailable
					reportAvailable
					trackAvailable
				}
			}
		}
	}
`)

export const deleteWorkspace = gql(`
	mutation DeleteWorkspace (
		$workspaceID: String!
	) {
		userAccountSettings {
			deleteAccount(accountID: $workspaceID) {
				newToken
				success
				wasUserCurrentAccount
			}
		}
	}
`)
