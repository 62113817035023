import React, { useCallback, useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'

import Button from './button'
import Input from './input'
import Link from './link'
import { currentUserDetails } from '../api/apollo/variables'
import { updateGeneratorParameterSelectAddOption } from '../api/graphql/track-edit-client'
import { GeneratorSelectFields, ValidationChecks } from '../api/types'
import { prepareInput } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import styles from '../styles/track-edit-add-new-dropdown-row.module.scss'
import { GetCampaignCodeGeneratorQuery } from '../__gql-types__/graphql'

export interface AddNewSelectFieldProps {
  field: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs'][0]
  fields: { optionName: string; optionValue: string }[]
  validation?: ValidationChecks[] | null
  restrictDropdowns: boolean
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  shownValues?: any[]
}

const newFieldTemplate = {
  hide: false,
  optionName: '',
  optionValue: '',
  optionID: '',
}

export default function AddNewSelectField({
  field,
  fields,
  validation,
  restrictDropdowns,
  setSearchTerm,
  shownValues,
}: AddNewSelectFieldProps) {
  const { fieldID, fieldName, forceLowerCase, lengthLimit = 45 } = field

  const logAction = useLogAction()

  const { workspaceID } = useReactiveVar(currentUserDetails)

  const { fullOnboardingSections, updateOnboardingSection } = useOnboarding()

  const [addSelectOption] = useMutation(updateGeneratorParameterSelectAddOption)

  const [newField, setNewField] = useState<GeneratorSelectFields>({
    ...newFieldTemplate,
  })
  const [fieldValueEdited, setFieldValueEdited] = useState(false)
  const [fieldNameError, setFieldNameError] = useState(false)
  const [fieldValueError, setFieldValueError] = useState(false)
  const [valueTooLong, setValueTooLong] = useState(false)

  const completeOnboardingSection = useCallback(() => {
    const editTaxonomy = fullOnboardingSections.account.find(
      (section) => section.onboardingSectionID === 'editTaxonomy',
    )

    if (editTaxonomy && !editTaxonomy.sectionCompleted) {
      updateOnboardingSection('editTaxonomy', 'account')
    }
  }, [fullOnboardingSections])

  return (
    <tr className={styles.addNewOptionRow}>
      <td>
        <Input
          name={`${fieldID}-new-option-name`}
          className={styles.newOptionInput}
          placeholder="Add new dropdown name"
          value={newField.optionName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target as HTMLInputElement

            setSearchTerm(value)

            const found = fields.find((f) => f.optionName === value)

            setFieldNameError(!!found)

            const newFieldToSet = {
              ...newField,
              optionName: value,
            }

            if (!fieldValueEdited) {
              let optionValue = prepareInput(value, validation)

              if (forceLowerCase) {
                optionValue = optionValue.toLowerCase()
              }

              setValueTooLong(optionValue.length > (lengthLimit as number))

              const foundValue = fields.find(
                (f) => f.optionValue === optionValue,
              )

              setFieldValueError(!!foundValue)

              newFieldToSet.optionValue = optionValue
            }

            setNewField(newFieldToSet)
          }}
          onKeyUp={(e: React.KeyboardEvent) => {
            if (
              e.key === 'Enter' &&
              !(newField.optionName === '' || fieldNameError || fieldValueError)
            ) {
              if (fieldID) {
                addSelectOption({
                  variables: {
                    fieldID,
                    optionName: newField.optionName,
                    optionValue: newField.optionValue,
                    hide: false,
                  },
                })

                logAction({
                  variables: {
                    action: 'update-generator-parameter-add-select-option',
                    extra: JSON.stringify({
                      fieldID,
                      newOption: {
                        ...newField,
                      },
                      accountID: workspaceID,
                    }),
                    websiteSection: 'track',
                    functionName: 'updateGeneratorParameterSelectAddOption',
                    pagePath: '/track/edit-dropdowns',
                  },
                })

                completeOnboardingSection()

                setNewField({ ...newFieldTemplate })
              }
            }
          }}
        />
        {newField.optionName &&
          !fieldNameError &&
          shownValues &&
          shownValues.length > 0 && (
            <p className={styles.footNote}>
              Similar names found, could you use:
            </p>
          )}
        {fieldNameError && (
          <p className={styles.footNoteError}>
            This name is already being used.
          </p>
        )}
      </td>
      <td>
        <Input
          name={`${fieldName}-new-option-value`}
          className={styles.newOptionInput}
          value={newField.optionValue}
          placeholder="Add new dropdown value"
          beforeChange={(value: string) => prepareInput(value, validation)}
          onValueChange={(value) => {
            setValueTooLong(value.length > (lengthLimit as number))

            setSearchTerm(value)
            setFieldValueEdited(true)

            const found = fields.find((f) => f.optionValue === value)

            setFieldValueError(!!found)

            setNewField({
              ...newField,
              optionValue: value,
            })
          }}
          onKeyUp={(e: React.KeyboardEvent) => {
            if (
              e.key === 'Enter' &&
              !(newField.optionName === '' || fieldNameError || fieldValueError)
            ) {
              if (fieldID) {
                addSelectOption({
                  variables: {
                    fieldID,
                    optionName: newField.optionName,
                    optionValue: newField.optionValue,
                    hide: false,
                  },
                })

                logAction({
                  variables: {
                    action: 'update-generator-parameter-add-select-option',
                    extra: JSON.stringify({
                      fieldID,
                      newOption: {
                        ...newField,
                      },
                      accountID: workspaceID,
                    }),
                    websiteSection: 'track',
                    functionName: 'updateGeneratorParameterSelectAddOption',
                    pagePath: '/track/edit-dropdowns',
                  },
                })

                completeOnboardingSection()
              }

              setNewField({ ...newFieldTemplate })
            }
          }}
        />
        {valueTooLong ? (
          <p className={styles.footNoteError}>
            {newField.optionValue.length - (lengthLimit as number)} characters
            over the limit. Reduce it or edit the limit{' '}
            <Link href="/track/edit-parameters-and-rules">here</Link>.
          </p>
        ) : (
          <>
            {newField.optionValue &&
              !fieldValueError &&
              shownValues &&
              shownValues.length > 0 && (
                <p className={styles.footNote}>
                  Similar values found, could you use:
                </p>
              )}
            {fieldValueError && (
              <p className={styles.footNoteError}>
                This code is already being used.
              </p>
            )}
          </>
        )}
      </td>
      {restrictDropdowns && <td />}
      <td>
        <Button
          isDisabled={
            newField.optionName === '' ||
            fieldNameError ||
            fieldValueError ||
            valueTooLong
          }
          onPress={() => {
            if (fieldID) {
              addSelectOption({
                variables: {
                  fieldID,
                  optionName: newField.optionName,
                  optionValue: newField.optionValue,
                  hide: false,
                },
              })

              logAction({
                variables: {
                  action: 'update-generator-parameter-add-select-option',
                  extra: JSON.stringify({
                    fieldID,
                    newOption: {
                      ...newField,
                    },
                    accountID: workspaceID,
                  }),
                  websiteSection: 'track',
                  functionName: 'updateGeneratorParameterSelectAddOption',
                  pagePath: '/track/edit-dropdowns',
                },
              })

              setNewField({ ...newFieldTemplate })
              setSearchTerm('')

              completeOnboardingSection()
            }
          }}
        >
          Confirm
        </Button>
      </td>
    </tr>
  )
}
