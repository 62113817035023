/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

/** Returns accounts available for given AA credentials */
export type AaAccount = {
  __typename?: 'AAAccount'
  /** Adobe company name */
  adobeCompanyName: Scalars['String']
  /** Adobe global company ID */
  adobeGlobalCompanyId: Scalars['String']
  /** Adobe IMS org ID */
  adobeImsOrgId: Scalars['String']
  /** List of properties available for the given GA credentials */
  reportSuites: Array<AaReportSuite>
}

/** Returns accounts available for given AA credentials */
export type AaAccountSummary = {
  __typename?: 'AAAccountSummary'
  /** List of link report IDs per account */
  aaAccountList: Array<AaAccount>
}

/** Used for creating a new AA account */
export type AaDescInput = {
  /** Adobe company name */
  adobeCompanyName: Scalars['String']
  /** Adobe global company ID */
  adobeGlobalCompanyId: Scalars['String']
  /** Adobe IMS organization ID */
  adobeImsOrgId: Scalars['String']
  /** Adobe report suite ID */
  adobeReportSuiteId: Scalars['String']
  /** Adobe report suite name */
  adobeReportSuiteName: Scalars['String']
  /** Data source type */
  dataSourceType?: InputMaybe<Scalars['String']>
}

/** Returns an encrypted version of the AA  credentials */
export type AaOauthCreds = {
  __typename?: 'AAOauthCreds'
  /** Encrypted version of the AA credentials */
  creds: Scalars['String']
  /** State of the oauth flow */
  state: Scalars['String']
}

/** Returns report suites available for given AA account */
export type AaReportSuite = {
  __typename?: 'AAReportSuite'
  /** Report suite ID */
  adobeReportSuiteID: Scalars['String']
  /** Report suite name */
  adobeReportSuiteName: Scalars['String']
}

/** A logical grouping of track,report,audit,explain modules belonging to an company. */
export type Account = {
  __typename?: 'Account'
  /** A unique id of an account within uplifter */
  accountID: Scalars['String']
  /** The name of the account */
  accountName: Scalars['String']
  /** A flag whether audit is enabled on an account level. */
  auditAvailable: Scalars['Boolean']
  /** A flag showing that the account has not been connected to a datasource */
  blankAccount: Scalars['Boolean']
  /** The company id that owns this account */
  companyID: Scalars['String']
  /** The name of the company that owns this account. */
  companyName: Scalars['String']
  /** ISO formatted date of the account creation */
  created: Scalars['String']
  /** The data source associated with this account */
  dataSource?: Maybe<DataSource>
  /** A flag whether explain is enabled on the account level */
  explainAvailable: Scalars['Boolean']
  /** The homepage of the account that is being represented- eg. www.uplifter.ai */
  homepage: Scalars['String']
  /** Whether this account should be available to uplifterAdmin users as demo account. */
  isDemo: Scalars['Boolean']
  /** A provided link to the logo of the account */
  logoLink: Scalars['String']
  /** List of qr code images that can be used with this account */
  qrCodeLogoList: Array<Scalars['String']>
  /** Returns a base64 encoded source for an image */
  qrCodeLogoSrc: Scalars['String']
  /** QR code settings that have been set for the account */
  qrSettings?: Maybe<Array<GenericSetting>>
  /** A flag whether report is available on an account level */
  reportAvailable: Scalars['Boolean']
  /** A flag whether track is enabled on an account level. */
  trackAvailable: Scalars['Boolean']
  /** Copy that should be used in the track learn module */
  trackLearnCopy?: Maybe<Scalars['String']>
  /** Which module should be used as a homepage for this account. */
  uplifterAccountLandingPage: Scalars['String']
  /** The list of users that have access to the account, with their preferences. */
  userAccountProfiles: Array<UserAccountProfile>
}

/** A logical grouping of track,report,audit,explain modules belonging to an company. */
export type AccountQrCodeLogoSrcArgs = {
  blobName: Scalars['String']
}

/** Onboarding steps specific to the account */
export type AccountOnboarding = {
  __typename?: 'AccountOnboarding'
  /** List of onboarding step progress */
  accountOnboardingProgressList: Array<OnboardingStep>
}

/** Root account settings queries */
export type AccountSettingsQueries = {
  __typename?: 'AccountSettingsQueries'
  /** Returns the details of the Adobe FTP account */
  adobeFTPDetails: AdobeFtpAccount
  /** All queries for Azure Marketplace integration */
  azureMarketplaceQueries: AzureMarketplaceQueries
  /** All queries for data sources */
  dataSourceQueries: DataSourceQueries
  /** retrieves the enterprise billing detail. will fail if not an enterprise */
  enterpriseBillingDetail: EnterpriseBillingDetail
  /** All queries for Monday.com integration */
  mondayQueries: MondayQueries
}

/** Returns totals for key account usage data and full data for graph display */
export type AccountUsageGraphData = {
  __typename?: 'AccountUsageGraphData'
  /** YYYY-MM-DD based on weekly, monthly, daily granularity */
  dateRange: Array<Scalars['String']>
  /** Totals per dimension on each granularity slice */
  dateTotalRows: Array<Scalars['Float']>
  /** List of traces to apply to chart. */
  graphData: Array<AccountUsageGraphItem>
  /** YYYY-MM-DD end date of entire period */
  queryEndDate: Scalars['String']
  /** YYYY-MM-DD start date of entire period */
  queryStartDate: Scalars['String']
  /** Total number of logins over the requested data range */
  total: Scalars['Int']
}

/** A list of traces to be applied of the metric over months. */
export type AccountUsageGraphItem = {
  __typename?: 'AccountUsageGraphItem'
  /** Name of the dimension break down value */
  dimensionName: Scalars['String']
  /** Values of stacked dimension group */
  dimensionValues: Array<Scalars['Float']>
}

/** Code to add to uplifter */
export type AddCode = {
  /** Full code (landing page + generated code) */
  fC: Scalars['String']
  /** landing page to add to uplifter */
  lP: Scalars['String']
  /** Parameter definitions to add to uplifter */
  pDfs: Array<Array<Scalars['String']>>
  /** Short link ID returned from shortLink candidates */
  shortLinkID?: InputMaybe<Scalars['String']>
  /** Generated code (no landing page) or prefix */
  tC: Scalars['String']
}

/** Applied to add additional metrics to the campaign link dashboard/track view table */
export type AdditionalMetric = {
  /** The three values in order required to calculate the metric */
  calculation?: InputMaybe<Array<Scalars['String']>>
  /** Help text that can be shown to the user */
  description: Scalars['String']
  /** name of the metric & how it is presented to the user */
  displayName: Scalars['String']
  /** Flag to determine whether an increase in this metric has a positive sentiment. */
  increasePositive: Scalars['Boolean']
  /** Id of metric in the datasource */
  metricID: Scalars['String']
  /** How data should be displayed float|session|duration */
  units: Scalars['String']
}

/** Google Analytics mutations */
export type AdobeAnalyticsMutations = {
  __typename?: 'AdobeAnalyticsMutations'
  /** Handle oauth2 callback response from Adobe Analytics */
  handleAAAuthCallback: AaOauthCreds
  /** Refresh Adobe Analytics access token for an account */
  reconnectAAAccount: Scalars['Boolean']
}

/** Google Analytics mutations */
export type AdobeAnalyticsMutationsHandleAaAuthCallbackArgs = {
  code: Scalars['String']
  state: Scalars['String']
}

/** Google Analytics mutations */
export type AdobeAnalyticsMutationsReconnectAaAccountArgs = {
  accountID: Scalars['String']
  credentials: Scalars['String']
}

/** Adobe settings */
export type AdobeFtpAccount = {
  __typename?: 'AdobeFTPAccount'
  /** The hostname of the adobe ftp server */
  adobeFTPHost?: Maybe<Scalars['String']>
  /** The login for the adobe ftp server */
  adobeFTPLogin?: Maybe<Scalars['String']>
  /** The relation id for the adobe account id that is used in SAINT) */
  relationID?: Maybe<Scalars['String']>
  /** The report id for the adobe accountr */
  reportID?: Maybe<Scalars['String']>
}

/** Context for linking into apps */
export type AppContext = {
  __typename?: 'AppContext'
  /** Workspace ID */
  accountID: Scalars['String']
  /** ID of the user that added the option */
  addedBy: Scalars['String']
  /** ID of the app group */
  appGroupID: Scalars['String']
  /** Company ID */
  companyID: Scalars['String']
  /** Date the option was added */
  dateAdded: Scalars['String']
  /** Date the option was last updated */
  dateLastUpdated: Scalars['String']
  /** ID of the user that last updated the option */
  lastUpdatedBy: Scalars['String']
  /** Name of the option */
  optionDisplayName: Scalars['String']
  /** ID of the option in uplifter */
  optionID: Scalars['String']
  /** Key of the option */
  optionKey: Scalars['String']
  /** Value of the option */
  optionValue: Scalars['String']
  /** Order of the option */
  order: Scalars['Int']
}

/** Context for linking into apps, used for inserting into uplifter */
export type AppContextInsertInput = {
  /** Name of the option */
  optionDisplayName: Scalars['String']
  /** Key of the option, the json key sent to the app */
  optionKey: Scalars['String']
  /** Value of the option, the json value sent to the app */
  optionValue: Scalars['String']
}

/** Context for linking into apps, used for updating in uplifter */
export type AppContextUpdateInput = {
  /** Name of the option, clicked on in uplifter */
  optionDisplayName?: InputMaybe<Scalars['String']>
  /** ID of the option in uplifter */
  optionID: Scalars['String']
  /** Key of the option, the json key sent to the app */
  optionKey?: InputMaybe<Scalars['String']>
  /** Value of the option, the json value sent to the app */
  optionValue?: InputMaybe<Scalars['String']>
}

export type AuditRes = {
  __typename?: 'AuditRes'
  numTestsRun: Scalars['Int']
  percentageComplete: Scalars['Int']
  status: Scalars['String']
  testResult: Array<ResultItem>
  totalTests: Scalars['Int']
}

/** A dimension available for querying */
export type AvailableDimension = {
  __typename?: 'AvailableDimension'
  /** Format to display dates in */
  dateFormat?: Maybe<Scalars['String']>
  /** The name of the dimension- from the generator */
  dimensionName: Scalars['String']
  /** The values this dimension can take */
  dimensionOptions?: Maybe<Array<Scalars['String']>>
  /** The id of the parameter within the generator */
  dimensionParameterID: Scalars['String']
  /** The help text for this dimension from the generator */
  helpText?: Maybe<Scalars['String']>
  /** date|input|select */
  parameterType: Scalars['String']
}

/** A success metric available for querying */
export type AvailableMetric = {
  __typename?: 'AvailableMetric'
  /** Calculation required for metric */
  calculation?: Maybe<Array<Scalars['String']>>
  /** A human understandable description of the metric */
  description: Scalars['String']
  /** The name of the metric to display to the user */
  displayName: Scalars['String']
  /** If an increase in this metric is a positive sentiment. */
  increasePositive: Scalars['Boolean']
  /** The metric comes as standard with the datasource (uneditable) */
  isStandard: Scalars['Boolean']
  /** Flag that shows whether it is a metric from uplifter */
  isUplifterClickMetric?: Maybe<Scalars['Boolean']>
  /** The name of the metric to use with the api */
  metricID: Scalars['String']
  /** Flag to show that the metric is available in the DB. */
  setupComplete: Scalars['Boolean']
  /** How the metric is measured - eg, integer, seconds, float... */
  units: Scalars['String']
}

/** Azure marketplace billing terms */
export type AzureMarketplaceBillingTerm = {
  __typename?: 'AzureMarketplaceBillingTerm'
  /** currency of the billing term */
  currency: Scalars['String']
  /** price of the billing term */
  price: Scalars['Float']
  /** description of the billing term */
  termDescription: Scalars['String']
  /** unit of the billing term (like per month, per year) */
  termUnit: Scalars['String']
}

/** Azure marketplace subscription */
export type AzureMarketplacePlan = {
  __typename?: 'AzureMarketplacePlan'
  /** list of billing terms for the plan */
  billingTerms: Array<AzureMarketplaceBillingTerm>
  /** plan description that is shown to the user and in the azure portal */
  description: Scalars['String']
  /** plan display name that is shown to the user and in the azure portal */
  displayName: Scalars['String']
  /** whether the plan has free trials */
  hasFreeTrials: Scalars['Boolean']
  /** whether the plan is priced per seat */
  isPricePerSeat: Scalars['Boolean']
  /** whether the plan is stop sell */
  isStopSell: Scalars['Boolean']
  /** maximum quantity that can be purchased */
  maxQuantity?: Maybe<Scalars['Int']>
  /** minimum quantity that can be purchased */
  minQuantity?: Maybe<Scalars['Int']>
  /** plan id on azure (what becomes the subscriptionLevel) */
  planId: Scalars['String']
}

/** Queries for Azure Marketplace integration */
export type AzureMarketplaceQueries = {
  __typename?: 'AzureMarketplaceQueries'
  /** Returns a list of available plans for the Azure Marketplace integration */
  listPlans: Array<AzureMarketplacePlan>
}

/** Billing mutations */
export type BillingMutations = {
  __typename?: 'BillingMutations'
  /** Cancel a Microsoft Marketplace subscription. The subscription will be cancelled at the end of the current billing period. */
  cancelMicrosoftMarketplaceSubscription: Company
  /** Change the Microsoft Marketplace plan for a company */
  changeMicrosoftMarketplacePlan: Company
  /** Update the number of seats for a Microsoft Marketplace plan */
  updateMicrosoftMarketplaceSeats: Company
  /** Update the quantity of a Paddle subscription */
  updatePaddleQuantity: Company
}

/** Billing mutations */
export type BillingMutationsCancelMicrosoftMarketplaceSubscriptionArgs = {
  companyID: Scalars['String']
}

/** Billing mutations */
export type BillingMutationsChangeMicrosoftMarketplacePlanArgs = {
  companyID: Scalars['String']
  newPlanID: Scalars['String']
}

/** Billing mutations */
export type BillingMutationsUpdateMicrosoftMarketplaceSeatsArgs = {
  companyID: Scalars['String']
  totalSeats: Scalars['Int']
}

/** Billing mutations */
export type BillingMutationsUpdatePaddleQuantityArgs = {
  companyID: Scalars['String']
  paddleEnv?: Scalars['String']
  updatedQuantity: Scalars['Int']
}

/** A representation of the code as stored in big query.  Usually a lot, uses minified keys. */
export type CampaignCode = {
  __typename?: 'CampaignCode'
  /** landing page + campaign code */
  c: Scalars['String']
  /** List of defintions of the code as it was entered at the time. */
  cDef: Array<CodeDef>
  /** code ID (as stored in uplifter) */
  cID: Scalars['String']
  /** Deep link information for the code */
  dL?: Maybe<DeepLink>
  /** email address of user that first added */
  e: Scalars['String']
  /** email address of last editor */
  edB?: Maybe<Scalars['String']>
  /** datetime of last edit */
  edT?: Maybe<Scalars['String']>
  /** Short link ID */
  sL: Scalars['String']
  /** datetime code first added */
  t: Scalars['String']
  /** version number (number of edits to code) */
  vN?: Maybe<Scalars['Int']>
}

/** List of parameters, and global rules for the code */
export type CampaignCodeGenerator = {
  __typename?: 'CampaignCodeGenerator'
  accountID: Scalars['String']
  /** Default pardot business unit to use for this account */
  defaultPardotBusinessUnit?: Maybe<Scalars['String']>
  /** Whether existing parameters are added to the start of the code */
  existingParametersAddedToStart: Scalars['Boolean']
  /** Whether the generator can be edited or not */
  isLocked: Scalars['Boolean']
  /** The value that immediately follows the landing page.  Usually just ? */
  masterPrefix: Scalars['String']
  /** List of parameters/fields used to generate code */
  paramDefs: Array<ParamDef>
  /** Value used to separate parameters - usually & */
  paramSeparator: Scalars['String']
  /** Validation checks to apply before code can be added. */
  validationChecks: Array<ValidationCheck>
}

/** Meta information about the campaign link dashboard */
export type CampaignLinkDashboard = {
  __typename?: 'CampaignLinkDashboard'
  accountID: Scalars['String']
  /** returns which click data metrics are available for this account */
  availableClickMetrics?: Maybe<Array<AvailableMetric>>
  /** returns which dimensions are available for the account */
  availableDimensions?: Maybe<Array<AvailableDimension>>
  /** returns which metrics currently contain data */
  availableMetrics?: Maybe<Array<AvailableMetric>>
  /** Set dashboard title for the account. */
  dashboardTitle: Scalars['String']
  /** source of the data from google/adobe */
  dataSourceDescription: Scalars['String']
  /** Segment (if applied) to the data source from google/adobe */
  dataSourceSegment?: Maybe<DataSourceSegment>
  /** earliest date that can be queried by the dashboard */
  firstDate: Scalars['String']
  /** List of default settings for the link dashboard */
  onloadDefaultOptions?: Maybe<Array<GenericSetting>>
}

/** Returns an array of traces to display on a stacked bar chart/ line chart */
export type CampaignLinkDashboardGraph = {
  __typename?: 'CampaignLinkDashboardGraph'
  /** YYYY-MM-DD based on weekly, monthly, daily granularity */
  dateRange: Array<Scalars['String']>
  /** Grand total/average for each date */
  dateTotalRows: Array<Scalars['Float']>
  /** List of traces to apply to chart. */
  graphData: Array<CampaignLinkDashboardGraphItem>
  /** Whether the graph should be shown as a line or stacked bar chart */
  graphType: Scalars['String']
  /** Boolean flag to show whether an increase is a good thing */
  increasePostive: Scalars['Boolean']
}

/** A list of traces to be applied of the metric over months. */
export type CampaignLinkDashboardGraphItem = {
  __typename?: 'CampaignLinkDashboardGraphItem'
  /** Name of the dimension break down value */
  dimensionName: Scalars['String']
  /** Values of stacked dimension group.  */
  dimensionValues: Array<Scalars['Float']>
}

/** Set of aggregated metrics to be shown to the user */
export type CampaignLinkDashboardMetricData = {
  __typename?: 'CampaignLinkDashboardMetricData'
  /** Boolean flag to show whether an increase is a good thing */
  increasePositive: Scalars['Boolean']
  /** Name of last complete period (using granularity arg) */
  lastPeriodName: Scalars['String']
  /** YYYY-MM-DD of last complete period */
  lastPeriodStartDate: Scalars['String']
  /** YYYY-MM-DD of last complete period of the previous year */
  lastYearStartDate: Scalars['String']
  /** Total value of metric after filters in last complete period time granularity */
  metricLastPeriod: ComparisonMetric
  /** % increase of metric between last complete period & preceding period time granularity */
  metricLastPeriodComparison: ComparisonMetric
  /** % increase between last complete period and that period the previous year */
  metricPeriodOnPeriodComparison: ComparisonMetric
  /** % increase between last complete period and average period metric. */
  periodComparisonVsPeriodAverage: ComparisonMetric
  /** Name of preceeding period to last complete period (using granularity arg) */
  prevPeriodName: Scalars['String']
  /** YYYY-MM-DD of period preceeding last complete period */
  prevPeriodStartDate: Scalars['String']
  /** YYYY-MM-DD end date of entire period */
  queryEndDate: Scalars['String']
  /** YYYY-MM-DD start date of entire period */
  queryStartDate: Scalars['String']
  /** Total value of metric over selected time period */
  totalMetric: ComparisonMetric
}

/** A list of campaign links and metric values.  sorted by selected metric. can be filtered. */
export type CampaignLinkDashboardTable = {
  __typename?: 'CampaignLinkDashboardTable'
  /** The campaign link + campaign code */
  campaignLinks: Array<Scalars['String']>
  /** List of campaign links and metric value */
  tableData: Array<CampaignLinkDashboardTableItem>
}

/** Row item to display in table */
export type CampaignLinkDashboardTableItem = {
  __typename?: 'CampaignLinkDashboardTableItem'
  /** Whether each campaign code is using partial matches in the calc */
  containsPartial: Array<Scalars['Boolean']>
  /** Sum of each row */
  grandTotal: Scalars['Float']
  /** Hover text to explain metric */
  helpText: Scalars['String']
  /** id of metric in uplifter */
  metricID: Scalars['String']
  /** displayName of metric */
  metricName: Scalars['String']
  /** Selected metric values */
  metricValue: Array<Scalars['Float']>
  /** How data should be displayed float|session|duration */
  units: Scalars['String']
}

/** Field that is being requested to add values to. */
export type ChangeRequest = {
  __typename?: 'ChangeRequest'
  /** Unique id of the field within the generator */
  fieldID: Scalars['String']
  /** Options being requested to be added */
  newOptions?: Maybe<Array<ChangeRequestNewOption>>
}

/** Field that is being requested to add values to. */
export type ChangeRequestInput = {
  /** Unique id of the field within the generator */
  fieldID: Scalars['String']
  /** Name of the field within the generator */
  fieldName: Scalars['String']
  /** Options being requested to be added */
  newOptions?: InputMaybe<Array<NewOptionInput>>
}

/** New option request */
export type ChangeRequestNewOption = {
  __typename?: 'ChangeRequestNewOption'
  /** whether this has been approved and added by an admin user. */
  approved?: Maybe<Scalars['Boolean']>
  /** message sent to user when it was approved/rejected. */
  message?: Maybe<Scalars['String']>
  /** Name of new option to display in select field */
  optionName: Scalars['String']
  /** ID of the option request */
  optionRequestID?: Maybe<Scalars['String']>
  /** automatically formatted option value to insert into code */
  optionValue: Scalars['String']
  /** Whether this option has been rejected by an admin. */
  rejected?: Maybe<Scalars['Boolean']>
}

/** Field that is being requested to add values to. */
export type ChangeRequestResponseInput = {
  /** Unique id of the field within the generator */
  fieldID: Scalars['String']
  /** Name of the field within the generator */
  fieldName: Scalars['String']
  /** Options being requested to be added */
  newOptions?: InputMaybe<Array<NewOptionResponseInput>>
}

/** Defintion of the code as it was stored in big query at the time. */
export type CodeDef = {
  __typename?: 'CodeDef'
  /** field/parameter ID */
  fID: Scalars['String']
  /** name of option (select) whats entered (input) */
  n?: Maybe<Scalars['String']>
  /** name of option value (select) whats entered (input) */
  v?: Maybe<Scalars['String']>
}

/** List of campaign codes for the account */
export type CodeList = {
  __typename?: 'CodeList'
  /** List of campaign codes for the account */
  codeList: Array<CampaignCode>
  /** count of codes returned */
  totalCodes: Scalars['Int']
}

/** Metric data for campaign codes by the associated data source */
export type CodeMetricData = {
  __typename?: 'CodeMetricData'
  accountID: Scalars['String']
  /** List of campaign codes that contain data */
  codeIDs: Array<Scalars['String']>
  /** Earliest date of click data available */
  earliestClickDate?: Maybe<Scalars['String']>
  /** Earliest date of deep link data available */
  earliestDeepLinkDate?: Maybe<Scalars['String']>
  /** Earliest date of metric data available */
  earliestMetricDate?: Maybe<Scalars['String']>
  /** List of metrics with values */
  metricValues: Array<CodeMetricDataValues>
  /** A human readable status of this code: live|ended|unused */
  status?: Maybe<Array<Scalars['String']>>
  /** count of codes returned */
  totalCodes: Scalars['Int']
}

/** Values for metric data for campaign codes by the associated data source */
export type CodeMetricDataValues = {
  __typename?: 'CodeMetricDataValues'
  /** A human readable status of this code: live|ended|unused */
  displayName: Scalars['String']
  /** Human readable description of metric from data source */
  helpText: Scalars['String']
  /** metric ID from datasource */
  metricID: Scalars['String']
  /** metrics from 30 days ago */
  mf30?: Maybe<Array<Scalars['Float']>>
  /** Boolean flag of whether the metric from 30 days contains partial data */
  mf30Partial?: Maybe<Array<Scalars['Boolean']>>
  /** metrics from 365 days ago */
  mf365?: Maybe<Array<Scalars['Float']>>
  /** Boolean flag of whether the code total contains partial data */
  mf365Partial?: Maybe<Array<Scalars['Boolean']>>
  /** Total metric values for the code, from when we started getting data */
  totalMetricValues: Array<Scalars['Float']>
  /** units to display metric value using */
  units: Scalars['String']
}

/** Version History for a given campaign code */
export type CodeVersionHistory = {
  __typename?: 'CodeVersionHistory'
  /** Email address of user that first added */
  author: Scalars['String']
  /** List of booleans showing which fields were changed at each version goes landing page then each parameter */
  changeMap: Array<Array<Scalars['Boolean']>>
  /** ID of campaign code */
  codeID: Scalars['String']
  /** Time code was created */
  createdTime: Scalars['String']
  /** List of users that edited the code at each version */
  editedBy: Array<Scalars['String']>
  /** List of times the code was edited at each version */
  editedTime: Array<Scalars['String']>
  /** Description of code at each version */
  versionHistory: MinimalCodeList
}

/** A company is a logical grouping of accounts.  Also contains the billing info. */
export type Company = {
  __typename?: 'Company'
  /** Total accounts owned by the company */
  accountCount: Scalars['Int']
  /** List of accounts that owned by the company */
  accountList: Array<Account>
  /** Enterprise: List of mezzo people that support the account */
  accountManagerList?: Maybe<Scalars['String']>
  /** Whether the billing is active or not */
  activeState: Scalars['String']
  /** whether audit is available at an account level */
  auditAvailable: Scalars['Boolean']
  /** Enterprise: Whether the enterprise subscription auto renews */
  autoRenew?: Maybe<Scalars['Boolean']>
  /** List of short links that are accessible to the company */
  availableShortLinkCustomDomains: Array<ShortLinkCustomDomain>
  /** Enterprise: associated billing notes field */
  billingNotes?: Maybe<Scalars['String']>
  /** Enterprise: list of account owners */
  billingUserList?: Maybe<Scalars['String']>
  /** Enterprise: description of the break clause */
  breakClause?: Maybe<Scalars['String']>
  /** Enterprise: date the break clause no longer applies */
  breakClauseDate?: Maybe<Scalars['String']>
  /** Enterprise: flag for if there is a break clause */
  breakClauseExists?: Maybe<Scalars['Boolean']>
  /** Enterprise: sub division of enterprise owning the company */
  businessDivision?: Maybe<Scalars['String']>
  /** A unique company id within uplifter */
  companyID: Scalars['String']
  /** Name of company */
  companyName: Scalars['String']
  /** Enterprise: Notes field only shown to uplifterAdmin users. */
  companyNotes?: Maybe<Scalars['String']>
  /** Enterprise: end date of contract ISO format */
  contractEndDate?: Maybe<Scalars['String']>
  /** Enterprise: link to the contract in sharepoint */
  contractLink?: Maybe<Scalars['String']>
  /** Enterprise: start date of contract ISO format */
  contractStartDate?: Maybe<Scalars['String']>
  /** ISO formatted date that company was created */
  created: Scalars['String']
  /** Enterprise: whether this company uses custom terms and conditions */
  customTandC?: Maybe<Scalars['Boolean']>
  /** Enterprise: account manager shown to the client */
  displayAccountManager?: Maybe<Scalars['String']>
  /** Enterprise: whether a domain limit applies to this account */
  domainLimit?: Maybe<Scalars['Int']>
  /** Enterprise: a list of domains associated with this company */
  domains?: Maybe<Scalars['String']>
  /** Oracle Eloqua Integration details for the company */
  eloquaIntegrationData?: Maybe<EloquaIntegrationData>
  /** Oracle Eloqua Integration details for the company */
  eloquaIntegrationStatus: Scalars['String']
  /** whether explain is available at an account level */
  explainAvailable: Scalars['Boolean']
  /** Company industry */
  industry?: Maybe<Scalars['String']>
  /** Enterprise: internal contact email */
  internalContactEmail?: Maybe<Scalars['String']>
  /** Enterprise: internal contact name */
  internalContactName?: Maybe<Scalars['String']>
  /** Enterprise: account manager shown internally */
  mezzoAccountManager?: Maybe<Scalars['String']>
  /** Number of licences bought by the company */
  microsoftQuantity?: Maybe<Scalars['Int']>
  /** Microsoft subscription details for the company */
  microsoftSubscriptionData?: Maybe<MicrosoftSubscriptionData>
  /** Id of subscription bought in microsoft */
  microsoftSubscriptionId?: Maybe<Scalars['String']>
  /** Monday.com Integration details for the company */
  mondayIntegrationStatus: Scalars['String']
  /** Okta Integration details for the company */
  oktaDomainList: OktaDomainList
  /** Original data source for the company */
  origDataSource?: Maybe<Scalars['String']>
  /** A url sent to the user that set up billing to cancel on paddle */
  paddleCancelUrl?: Maybe<Scalars['String']>
  /** Paddle checkout id of the subscription that was used to create the subscription */
  paddleSubscriptionCheckoutId?: Maybe<Scalars['String']>
  /** Currency of user that paid according to paddle */
  paddleSubscriptionCurrency?: Maybe<Scalars['String']>
  /** Email of user that paid according to paddle */
  paddleSubscriptionEmail?: Maybe<Scalars['String']>
  /** Id of company within paddle */
  paddleSubscriptionId?: Maybe<Scalars['String']>
  /** Id of the last alert received from paddle */
  paddleSubscriptionLastAlertId?: Maybe<Scalars['String']>
  /** Name of the last alert received from paddle, subscription_created etc */
  paddleSubscriptionLastAlertName?: Maybe<Scalars['String']>
  /** A json dumps of the last webhook received by paddle for this client */
  paddleSubscriptionLastCustomData?: Maybe<Scalars['String']>
  /** Detailed date time that the last event happened (payment etc) */
  paddleSubscriptionLastEventTime?: Maybe<Scalars['String']>
  /** Paddle subscription ids that are linked to this subscription */
  paddleSubscriptionLinkedSubscriptions?: Maybe<Scalars['String']>
  /** Whether the user has given marketing consent */
  paddleSubscriptionMarketingConsent?: Maybe<Scalars['String']>
  /** next date the client can expect to be billed */
  paddleSubscriptionNextBillDate?: Maybe<Scalars['String']>
  /** Id of company within paddle */
  paddleSubscriptionPlanId?: Maybe<Scalars['String']>
  /** Number of licences bought by the company */
  paddleSubscriptionQuantity?: Maybe<Scalars['String']>
  /** Paddle source of the subscription, checkout etc. */
  paddleSubscriptionSource?: Maybe<Scalars['String']>
  /** Paddle description of the status of the subscription */
  paddleSubscriptionStatus?: Maybe<Scalars['String']>
  /** Price per licence */
  paddleSubscriptionUnitPrice?: Maybe<Scalars['String']>
  /** Paddle user id of billing user within paddle */
  paddleSubscriptionUserId?: Maybe<Scalars['String']>
  /** The user's paddle url where payment can be updated */
  paddleUpdateUrl?: Maybe<Scalars['String']>
  /** Salesforce pardot Integration details for the company */
  pardotIntegrationStatus: Scalars['String']
  /** uplifter|paddle|microsoft */
  paymentProcessor?: Maybe<Scalars['String']>
  /** Public api key for the company */
  publicApiKey?: Maybe<Scalars['String']>
  /** whether report is available at an account level */
  reportAvailable: Scalars['Boolean']
  /** Enterprise: MRR value associated with the company */
  revenuePerMonth?: Maybe<Scalars['Float']>
  /** Enterprise: MRR after break clause */
  revenuePerMonthPostBreakClause?: Maybe<Scalars['Float']>
  /** Enterprise: MRR before break clause */
  revenuePerMonthPreBreakClause?: Maybe<Scalars['Float']>
  /** Flag to show if marketing cloud is connected */
  salesforceMcIntegrationList: Array<SalesforceMcapiKey>
  /** Flag to show if marketing cloud is connected */
  salesforceMcIntegrationStatus: Scalars['String']
  /** Enterprise: List of senior stakeholders */
  sponsorList?: Maybe<Scalars['String']>
  /** Pricing plan of company */
  subscriptionLevel: Scalars['String']
  /** level of support available in relation to pricing plan */
  supportEnabled: Scalars['String']
  /** Enterprise: total value over length of the contract */
  totalContractValue?: Maybe<Scalars['Float']>
  /** whether track is available at an account level */
  trackAvailable: Scalars['Boolean']
  /** Total users (not including uplifterAdmin) with access to accounts of this company */
  userCount: Scalars['Int']
  /** Enterprise: limit of users in company */
  userLimit?: Maybe<Scalars['Int']>
}

/** A company is a logical grouping of accounts.  Also contains the billing info. */
export type CompanyEloquaIntegrationDataArgs = {
  eloquaOrgID: Scalars['String']
}

export type CompanyAccount = Account | Company

/** Aggregated metrics after filters have been applied */
export type ComparisonMetric = {
  __typename?: 'ComparisonMetric'
  /** String value of metric formatted by api */
  metricDisplay: Scalars['String']
  /** Value of last complete month */
  metricLastCompleteValue: Scalars['Float']
  /** Value previous to last complete month */
  metricPrevValue?: Maybe<Scalars['Float']>
  /** Title of comparison metric to be shown to the user */
  metricTitle: Scalars['String']
  /** Actual value of metric */
  metricValue?: Maybe<Scalars['Float']>
}

/** An option available in a select field dropdown */
export type CopyFromField = {
  __typename?: 'CopyFromField'
  /** Field ID that this field copies from */
  copyFromID: Scalars['String']
}

/** Input type for copy from ids */
export type CopyFromFieldInput = {
  copyFromID: Scalars['String']
}

/** Creative library item */
export type CreativeLibraryItem = {
  __typename?: 'CreativeLibraryItem'
  /** account ID */
  accountID: Scalars['String']
  /** blob storage location of the creative if an upload */
  blobURL?: Maybe<Scalars['String']>
  /** user id that created the creative */
  createdBy: Scalars['String']
  /** time the creative was created */
  createdTime: Scalars['String']
  /** creative ID */
  creativeID: Scalars['String']
  /** link to the creative */
  creativeLink?: Maybe<Scalars['String']>
  /** type of creative upload|link */
  creativeType: Scalars['String']
  /** option ID from the generator */
  optionID?: Maybe<Scalars['String']>
  /** option name from the generator */
  optionName?: Maybe<Scalars['String']>
  /** parameter ID from the generator */
  parameterID: Scalars['String']
}

/** The current user is the user that logged in, represnted in the token. */
export type CurrentUser = {
  __typename?: 'CurrentUser'
  /** A flag showing whether the user accepted receiving marketing */
  acceptedMarketing: Scalars['String']
  /** Whether the user accepted TandC during registration */
  acceptedTandC: Scalars['Boolean']
  /** ISO formatted date when the user first created their account */
  created: Scalars['String']
  /** Last account ID the user logged into */
  curAccountID: Scalars['String']
  /** retrieves the current users current account permission */
  currentAccountPermission: Scalars['String']
  /** User's email address, used as a ref and a login */
  email: Scalars['String']
  /** User's first name */
  firstName: Scalars['String']
  /** A concatenation of first and lastname */
  fullName: Scalars['String']
  /** A flag showing whether the user has completed registration */
  inviteOpen: Scalars['Boolean']
  /** how the user last logged in - SSO/password */
  lastMethod: Scalars['String']
  /** User's last name */
  lastName: Scalars['String']
  /** URL slug to take the user to when they log in */
  preferredHomepage: Scalars['String']
  /** A location in azure of a publically available image of the user */
  profileImgLink: Scalars['String']
  /** A job title provided for public use */
  publicJobTitle: Scalars['String']
  /** User's telephone number, if provided */
  telNum: Scalars['String']
  /** A flag showing whether user is internal user */
  uplifterAdmin: Scalars['Boolean']
  /** List of user account profiles belonging to this user */
  userAccountProfiles: Array<UserAccountProfile>
  /** Unique user ID within uplifter */
  userID: Scalars['String']
  /** A user name provided for public user */
  userName: Scalars['String']
  /** A flag of which whitelabel the admin user might be. null if not an admin */
  whitelabelAdmin?: Maybe<Scalars['String']>
}

/** Connected data sources to the account */
export type DataSource = {
  __typename?: 'DataSource'
  /** The id of the adobe report suite */
  adobeReportID?: Maybe<Scalars['String']>
  /** The name of the adobe report suite */
  adobeReportSuiteName?: Maybe<Scalars['String']>
  /** The name of the adobe segment */
  adobeSegmentName?: Maybe<Scalars['String']>
  /** Whether the data source is currently connected */
  connected: Scalars['Boolean']
  /** The id of the GA4 account */
  ga4AccountID?: Maybe<Scalars['String']>
  /** The name of the GA4 account */
  ga4AccountName?: Maybe<Scalars['String']>
  /** The id of the GA4 property */
  ga4PropertyID?: Maybe<Scalars['String']>
  /** The name of the GA4 property */
  ga4PropertyName?: Maybe<Scalars['String']>
  /** The id of the GA account */
  gaAccountID?: Maybe<Scalars['String']>
  /** The name of the GA account */
  gaAccountName?: Maybe<Scalars['String']>
  /** The id of the GA property */
  gaPropertyID?: Maybe<Scalars['String']>
  /** The id of the GA4 property */
  gaPropertyName?: Maybe<Scalars['String']>
  /** The id of the GA view */
  gaViewID?: Maybe<Scalars['String']>
  /** The name of the GA view */
  gaViewName?: Maybe<Scalars['String']>
  /** Type of data source, eg. GA_VIEW|GA4_PROP|AA_REPORT */
  kind: Scalars['String']
}

/** Metric definition from datasource */
export type DataSourceMetric = {
  __typename?: 'DataSourceMetric'
  /** List of metrics required to calculate metric */
  calculation?: Maybe<Array<Scalars['String']>>
  /** helpt text shown to user to describe metric */
  description: Scalars['String']
  /** Name of metric to display to user */
  displayName: Scalars['String']
  /** If an increase in this metric is a positive sentiment */
  increasePositive?: Maybe<Scalars['Boolean']>
  /** Metric comes standard with data source */
  isStandard: Scalars['Boolean']
  /** ID of metric to use with datasource */
  metricID: Scalars['String']
  /** Metrics required to calculate metric */
  requiredMetrics?: Maybe<Array<Scalars['String']>>
  /** One of percent|float|duration */
  units: Scalars['String']
}

/** List of metrics available in datasource */
export type DataSourceMetricList = {
  __typename?: 'DataSourceMetricList'
  dataSourceMetricList: Array<DataSourceMetric>
}

/** Queries for datasources settings */
export type DataSourceQueries = {
  __typename?: 'DataSourceQueries'
  /** Returns a link to request Adobe Analytics permissions */
  adobeAuthLink: Scalars['String']
  /** Returns a list of available Adobe Analytics accounts */
  availableAAAccounts: AaAccountSummary
  /** Returns a list of available Google Analytics accounts */
  availableGAAccounts: GaAccountSummary
  /** Returns a list of available metrics for a given datasource */
  datasourceAvailableMetrics: DataSourceMetricList
  /** Returns a link to request Google Analytics permissions */
  gaAuthLink: Scalars['String']
  /** Generate an oauth2 link to request salesforce MC permissions */
  genMCOauthLink: Oauth2Link
  /** Generate an oauth2 link to request salesforce permissions */
  genSalesforceOauthLink: Oauth2Link
}

/** Queries for datasources settings */
export type DataSourceQueriesAdobeAuthLinkArgs = {
  state: Scalars['String']
}

/** Queries for datasources settings */
export type DataSourceQueriesAvailableAaAccountsArgs = {
  creds: Scalars['String']
}

/** Queries for datasources settings */
export type DataSourceQueriesAvailableGaAccountsArgs = {
  creds: Scalars['String']
}

/** Queries for datasources settings */
export type DataSourceQueriesGaAuthLinkArgs = {
  state: Scalars['String']
}

/** Queries for datasources settings */
export type DataSourceQueriesGenMcOauthLinkArgs = {
  state: Scalars['String']
}

/** Queries for datasources settings */
export type DataSourceQueriesGenSalesforceOauthLinkArgs = {
  state: Scalars['String']
}

/** Segment definitions for data sources */
export type DataSourceSegment = {
  __typename?: 'DataSourceSegment'
  /** ID of segment */
  segmentID: Scalars['String']
  /** Name of segment */
  segmentName: Scalars['String']
}

/** A representation of the deep link stored in table storage */
export type DeepLink = {
  __typename?: 'DeepLink'
  /** Workspace ID */
  accountID: Scalars['String']
  /** query string that is generated by uplifter */
  analyticsContext?: Maybe<Scalars['String']>
  /** ID of the app group */
  appGroupID: Scalars['String']
  /** ID of the campaign code */
  campaignCodeID: Scalars['String']
  /** Company ID */
  companyID: Scalars['String']
  /** ID of the deep link service | default */
  deepLinkServiceID: Scalars['String']
  /** deep link short link */
  deepLinkShortLink?: Maybe<Scalars['String']>
  /** web URL if redirection breaks */
  fallBackURL?: Maybe<Scalars['String']>
  /** Open graph description */
  ogDescription?: Maybe<Scalars['String']>
  /** Open graph image */
  ogImage?: Maybe<Scalars['String']>
  /** Open graph image alt */
  ogImageAlt?: Maybe<Scalars['String']>
  /** Open graph image height */
  ogImageHeight?: Maybe<Scalars['String']>
  /** Open graph image width */
  ogImageWidth?: Maybe<Scalars['String']>
  /** Open graph title */
  ogTitle?: Maybe<Scalars['String']>
  /** The redirect context as received by the app */
  redirectContext?: Maybe<Scalars['String']>
}

/** Description of android app used for deep linking */
export type DeepLinkAndroidApp = {
  __typename?: 'DeepLinkAndroidApp'
  /** Name of the android app */
  androidAppName?: Maybe<Scalars['String']>
  /** Package name of the android app */
  androidPackage?: Maybe<Scalars['String']>
  /** Redirect android download */
  androidPlayStoreLink?: Maybe<Scalars['String']>
  /** URL to open on android */
  androidUrl?: Maybe<Scalars['String']>
  /** For grouping apps together */
  appGroupID: Scalars['String']
  /** ID of the android app in Uplifter */
  appID: Scalars['String']
  /** Wellknown JSON for android */
  assetLinksAndroid?: Maybe<Scalars['String']>
  /** Company ID */
  companyID: Scalars['String']
  /** ID of the deep link service */
  deepLinkServiceID: Scalars['String']
  /** web URL if redirection breaks */
  fallBackURL?: Maybe<Scalars['String']>
  /** Redirect android preferred */
  redirectAndroidPreferred?: Maybe<Scalars['String']>
  /** Redirect android uri */
  redirectAndroidUri?: Maybe<Scalars['String']>
}

export type DeepLinkAndroidAppDeepLinkIosApp =
  | DeepLinkAndroidApp
  | DeepLinkIosApp

/** A group of related apps for deep linking */
export type DeepLinkAppGroup = {
  __typename?: 'DeepLinkAppGroup'
  /** Workspace ID */
  accountID: Scalars['String']
  /** Description of the app group */
  appGroupDescription?: Maybe<Scalars['String']>
  /** ID of the app group */
  appGroupID: Scalars['String']
  /** Name of the app group */
  appGroupName: Scalars['String']
  /** For the case where multiple apps go to the same domain, this is used to differentiate between them */
  appGroupPath?: Maybe<Scalars['String']>
  /** List of apps in the app group */
  appList: Array<DeepLinkAndroidAppDeepLinkIosApp>
  /** Company ID */
  companyID: Scalars['String']
  /** Date the app group was added */
  dateAdded: Scalars['String']
  /** ID of the deep link service */
  deepLinkServiceID: Scalars['String']
  /** Whether the app group is a test environment */
  isUAT: Scalars['Boolean']
  /** Default web URL to redirect to if no app is found */
  redirectWeb: Scalars['String']
}

/** Input for creating a deep link */
export type DeepLinkInput = {
  /** query string that will represent the analytics context */
  analyticsContext?: InputMaybe<Scalars['String']>
  /** ID of the app group */
  appGroupID: Scalars['String']
  /** ID of the deep link service | default */
  deepLinkServiceID: Scalars['String']
  /** Short link to apply to deep link */
  deepLinkShortLink?: InputMaybe<Scalars['String']>
  /** web URL if redirection breaks */
  fallBackURL?: InputMaybe<Scalars['String']>
  /** Open graph description */
  ogDescription?: InputMaybe<Scalars['String']>
  /** Open graph image */
  ogImage?: InputMaybe<Scalars['String']>
  /** Open graph image alt */
  ogImageAlt?: InputMaybe<Scalars['String']>
  /** Open graph image height */
  ogImageHeight?: InputMaybe<Scalars['String']>
  /** Open graph image width */
  ogImageWidth?: InputMaybe<Scalars['String']>
  /** Open graph title */
  ogTitle?: InputMaybe<Scalars['String']>
  /** Redirect context- app context ids */
  redirectContext?: InputMaybe<Array<Scalars['String']>>
}

/** Description of ios app used for deep linking */
export type DeepLinkIosApp = {
  __typename?: 'DeepLinkIosApp'
  /** For grouping apps together */
  appGroupID: Scalars['String']
  /** ID of the ios app in Uplifter */
  appID: Scalars['String']
  /** Company ID */
  companyID: Scalars['String']
  /** ID of the deep link service */
  deepLinkServiceID: Scalars['String']
  /** web URL if redirection breaks */
  fallBackURL?: Maybe<Scalars['String']>
  /** Name of the ios app */
  iosAppName?: Maybe<Scalars['String']>
  /** App store ID of the ios app */
  iosAppStoreID?: Maybe<Scalars['String']>
  /** Bundle ID of the ios app */
  iosBundleID?: Maybe<Scalars['String']>
  /** Team ID of the ios app */
  iosTeamID?: Maybe<Scalars['String']>
  /** Redirect ios download */
  redirectIosDownload?: Maybe<Scalars['String']>
  /** Redirect ios preferred */
  redirectIosPreferred?: Maybe<Scalars['String']>
  /** Redirect ios uri */
  redirectIosUri?: Maybe<Scalars['String']>
}

/** Deep linking queries */
export type DeepLinkQueries = {
  __typename?: 'DeepLinkQueries'
  /** Returns the details of a deep link */
  getDeeplinkDetails: DeepLink
  /** Returns a list of app context options for the current workspace for deep linking */
  listAppContextOptions: Array<AppContext>
  /** Returns a list of app registration groups for the workspace */
  listAppGroups: Array<DeepLinkAppGroup>
  /** Returns a list of app registrations for the workspace for deep linking */
  listAppRegistrations: Array<DeepLinkAndroidAppDeepLinkIosApp>
  /** Returns a list of available deep linking services for the company */
  listDeepLinkServices: Array<DeepLinkService>
}

/** Deep linking queries */
export type DeepLinkQueriesGetDeeplinkDetailsArgs = {
  codeID: Scalars['String']
  deepLinkServiceID: Scalars['String']
}

/** Deep linking queries */
export type DeepLinkQueriesListAppContextOptionsArgs = {
  appGroupID: Scalars['String']
}

/** Description of the deep link service */
export type DeepLinkService = {
  __typename?: 'DeepLinkService'
  /** Company ID */
  companyID: Scalars['String']
  /** ID of the deep link service */
  deepLinkServiceID: Scalars['String']
  /** Name of the deep link service */
  deepLinkServiceName: Scalars['String']
  /** Subdomain of the deep link service */
  deepLinkServiceSubdomain: Scalars['String']
}

/** Default email parameter setting */
export type DefaultEmailParameter = {
  __typename?: 'DefaultEmailParameter'
  /** ID of the parameter option in uplifter */
  optionID?: Maybe<Scalars['String']>
  /** Name of the parameter option in uplifter */
  optionName?: Maybe<Scalars['String']>
  /** Value of the parameter that should always be used */
  optionValue?: Maybe<Scalars['String']>
  /** Parameter ID */
  parameterID: Scalars['String']
}

/** If there are parameters that are consistently used, they can be stored here */
export type DefaultEmailParameterInput = {
  /** ID of the parameter option in uplifter */
  optionID?: InputMaybe<Scalars['String']>
  /** Name of the parameter option in uplifter - for non-select fields */
  optionName?: InputMaybe<Scalars['String']>
  /** Value of the parameter that should always be used - for non-select fields */
  optionValue: Scalars['String']
  /** ID of the parameter in uplifter */
  parameterID: Scalars['String']
}

/** Applied to limit data returned in the table.  Names are treated as AND, options as OR. */
export type DimensionFilter = {
  /** Name of dimension - treated as AND */
  dimensionName: Scalars['String']
  /** Which values are acceptable.  Treated as OR. */
  dimensionOptions: Array<Scalars['String']>
  /** Id of the dimension to filter on */
  dimensionParameterID: Scalars['String']
}

/** List of dimension filters */
export type DimensionFilterList = {
  /** List of dimension filters */
  dimensionFilters: Array<DimensionFilter>
}

/** filter that can be applied */
export type DimensionFilterOutput = {
  __typename?: 'DimensionFilterOutput'
  /** Name of dimension to filter on */
  dimensionName: Scalars['String']
  /** List of options to apply to this filter */
  dimensionOptions: Array<Scalars['String']>
  /** ID of the dimension parameter */
  dimensionParameterID: Scalars['String']
}

/** Representation of an eloqua email group for filtering */
export type EloquaEmailGroup = {
  __typename?: 'EloquaEmailGroup'
  /** ID of the eloqua email template */
  groupID: Scalars['String']
  /** Name of the eloqua email template */
  name: Scalars['String']
}

/** Returns email templates from eloqua */
export type EloquaEmailGroupList = {
  __typename?: 'EloquaEmailGroupList'
  /** List of email campaigns from eloqua */
  eloquaEmailGroupList: Array<EloquaEmailGroup>
}

/** Description for the Oracle Eloqua integration data. */
export type EloquaIntegrationData = {
  __typename?: 'EloquaIntegrationData'
  /** List of parameter IDs that are being synced to eloqua picklists */
  parameterIDList: Array<Scalars['String']>
}

/** Representation of an eloqua email link */
export type EloquaLink = {
  __typename?: 'EloquaLink'
  /** ID of the link */
  linkID: Scalars['String']
  /** Name of the link */
  linkName: Scalars['String']
  /** Type of link */
  linkType: Scalars['String']
  /** URL of the link */
  linkURL: Scalars['String']
}

/** Representation of an eloqua account */
export type EloquaOrg = {
  __typename?: 'EloquaOrg'
  /** Display name of the eloqua account */
  eloquaOrgDisplayName: Scalars['String']
  /** ID of the eloqua account */
  eloquaOrgID: Scalars['String']
  /** Name of the eloqua account */
  eloquaOrgName: Scalars['String']
}

/** List of connected eloqua accounts */
export type EloquaOrgList = {
  __typename?: 'EloquaOrgList'
  /** List of connected eloqua accounts */
  eloquaOrgList: Array<EloquaOrg>
}

/** Representation of an eloqua picklist */
export type EloquaPicklist = {
  __typename?: 'EloquaPicklist'
  /** ID of the eloqua picklist */
  eloquaID: Scalars['String']
  /** List of options in the eloqua picklist */
  eloquaPicklistOptions: Array<EloquaPicklistOption>
  /** Name of the eloqua picklist */
  name: Scalars['String']
}

/** Representation of an eloqua picklist option */
export type EloquaPicklistOption = {
  __typename?: 'EloquaPicklistOption'
  /** Name of the eloqua picklist option */
  name: Scalars['String']
  /** Value of the eloqua picklist option */
  value?: Maybe<Scalars['String']>
}

/** Root eloqua queries */
export type EloquaQueries = {
  __typename?: 'EloquaQueries'
  /** Returns a list of available Eloqua templates */
  getAvailableEloquaEmailGroups: EloquaEmailGroupList
  /** Returns a list of available Eloqua templates */
  getAvailableEloquaTemplateList: EloquaTemplateList
  /** Returns a list of connected Eloqua orgs */
  getConnectedEloquaOrgs: EloquaOrgList
  /** Returns the html and links for a given Eloqua email template */
  getEloquaEmailTemplateDetail: EloquaTemplate
  /** Returns a list of available Eloqua picklists */
  getEloquaPicklistDetail: EloquaPicklist
  /** Returns a list of available Eloqua picklists */
  getEloquaPicklists: Array<EloquaPicklist>
}

/** Root eloqua queries */
export type EloquaQueriesGetAvailableEloquaEmailGroupsArgs = {
  eloquaOrgID: Scalars['String']
}

/** Root eloqua queries */
export type EloquaQueriesGetAvailableEloquaTemplateListArgs = {
  eloquaOrgID: Scalars['String']
  groupID?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}

/** Root eloqua queries */
export type EloquaQueriesGetEloquaEmailTemplateDetailArgs = {
  eloquaOrgID: Scalars['String']
  templateID: Scalars['String']
}

/** Root eloqua queries */
export type EloquaQueriesGetEloquaPicklistDetailArgs = {
  eloquaOrgID: Scalars['String']
  picklistID: Scalars['String']
}

/** Root eloqua queries */
export type EloquaQueriesGetEloquaPicklistsArgs = {
  eloquaOrgID: Scalars['String']
}

/** Representation of an eloqua email template */
export type EloquaTemplate = {
  __typename?: 'EloquaTemplate'
  /** ID of the eloqua email template */
  eloquaID: Scalars['String']
  /** List of links in the eloqua email template */
  eloquaLinkList?: Maybe<Array<EloquaLink>>
  /** HTML of the eloqua email template */
  emailHtml?: Maybe<Scalars['String']>
  /** Name of the eloqua email template */
  name: Scalars['String']
}

/** Returns email templates from eloqua */
export type EloquaTemplateList = {
  __typename?: 'EloquaTemplateList'
  /** List of email templates from eloqua */
  eloquaTemplateList: Array<EloquaTemplate>
}

/** Settings for track to follow for emails */
export type EmailTrackSettings = {
  __typename?: 'EmailTrackSettings'
  /** List of domains that should have the links replaced for */
  domainList: Array<Scalars['String']>
}

/** Billing details taken from sharepoint list */
export type EnterpriseBillingDetail = {
  __typename?: 'EnterpriseBillingDetail'
  /** List of account managers within uplifter for this client */
  accountManagerList: Array<EnterpriseBillingUser>
  /** Flag for whether the audit module is accessible in any account in this client */
  auditAvailable: Scalars['Boolean']
  autoRenew: Scalars['Boolean']
  /** List of users that own the account */
  billingUserList: Scalars['String']
  /** Part of the business that this company belongs to */
  businessDivision: Scalars['String']
  /** Name of company */
  companyName: Scalars['String']
  /** End date of contract */
  contractEndDate: Scalars['String']
  /** Start date of contract */
  contractStartDate: Scalars['String']
  /** flag for whether the company has special t and c separate to default */
  customTandC: Scalars['Boolean']
  /** If set, total number of accounts allowed */
  domainLimit: Scalars['Int']
  /** List of allowed domains */
  domains: Scalars['String']
  /** Flag for whether the explain module is accessible in any account in this client */
  explainAvailable: Scalars['Boolean']
  /** Name of company on the paperwork */
  legalEntityName: Scalars['String']
  /** Original data source for this company */
  origDataSource?: Maybe<Scalars['String']>
  /** Flag for whether the report module is accessible in any account in this client */
  reportAvailable: Scalars['Boolean']
  /** List of sponsors within the company */
  sponsorList: Array<EnterpriseBillingUser>
  /** Total price the customer pays over the term of the contract */
  totalContractValue: Scalars['String']
  /** Flag for whether the track module is accessible in any account in this client */
  trackAvailable: Scalars['Boolean']
  /** If set, total number of users */
  userLimit: Scalars['Int']
}

/** sponsor/uplifter admin details */
export type EnterpriseBillingUser = {
  __typename?: 'EnterpriseBillingUser'
  /** email of sponsor/uplifter admin */
  email: Scalars['String']
  /** name of sponsor/uplifter admin */
  name: Scalars['String']
}

/** Feedback from user */
export type FeedbackContext = {
  /** What question was the user asked to prompt feedback, if any? */
  feedbackPrompt: Scalars['String']
  /** The response given which triggered the feedback form */
  feedbackResponse: Scalars['String']
}

/** Marketing funnel lane metric */
export type FunnelMetric = {
  __typename?: 'FunnelMetric'
  /** format of the metric */
  metricFormat?: Maybe<Scalars['String']>
  /** name of the metric */
  metricName: Scalars['String']
  /** value of the metric across the cards */
  metricValue?: Maybe<Scalars['Float']>
}

/** Input for adding additional funnel metrics */
export type FunnelMetricInput = {
  /** format of the metric */
  metricFormat: Scalars['String']
  /** name of the metric */
  metricName: Scalars['String']
  /** value of the metric */
  metricValue: Scalars['Float']
}

/** Used for creating a new GA4 account */
export type Ga4DescInput = {
  /** Data source type */
  dataSourceType?: InputMaybe<Scalars['String']>
  /** GA account ID */
  gaAccountID: Scalars['String']
  /** GA account name */
  gaAccountName: Scalars['String']
  /** GA property ID */
  gaPropertyID: Scalars['String']
  /** GA property name */
  gaPropertyName: Scalars['String']
}

/** Returns accounts available for given GA credentials */
export type GaAccount = {
  __typename?: 'GAAccount'
  /** Account display name in case account name is not unique */
  accountDisplayName: Scalars['String']
  /** Account ID in the GA system */
  accountID: Scalars['String']
  /** Account name */
  accountName: Scalars['String']
  /** List of properties available for the given GA credentials */
  properties: Array<GaProperty>
}

/** Returns accounts available for given GA credentials */
export type GaAccountSummary = {
  __typename?: 'GAAccountSummary'
  /** List of link performance report IDs per user */
  ga4AccountList: Array<GaAccount>
  /** List of link performance report IDs per user */
  uaAccountList: Array<GaAccount>
}

/** Used for creating a UA new account */
export type GaDescInput = {
  /** Data source type */
  dataSourceType?: InputMaybe<Scalars['String']>
  /** GA account ID */
  gaAccountID: Scalars['String']
  /** GA account name */
  gaAccountName: Scalars['String']
  /** GA property ID */
  gaPropertyID: Scalars['String']
  /** GA property name */
  gaPropertyName: Scalars['String']
  /** GA view ID */
  gaViewID: Scalars['String']
  /** GA view name */
  gaViewName: Scalars['String']
}

/** Returns an encrypted version of the GA credentials */
export type GaOauthCreds = {
  __typename?: 'GAOauthCreds'
  /** Encrypted version of the GA credentials */
  creds: Scalars['String']
  /** State of the oauth flow */
  state: Scalars['String']
}

/** Returns properties available for given GA credentials */
export type GaProperty = {
  __typename?: 'GAProperty'
  /** Property display name in case property name is not unique */
  propertyDisplayName: Scalars['String']
  /** ID of the property in GA */
  propertyID: Scalars['String']
  /** Name of the property in GA */
  propertyName: Scalars['String']
  /** List of properties available for the given GA credentials */
  views?: Maybe<Array<GaView>>
}

/** Returns properties available for given GA credentials */
export type GaView = {
  __typename?: 'GAView'
  /** View display name in case view name is not unique */
  viewDisplayName: Scalars['String']
  /** ID of the view in GA */
  viewID: Scalars['String']
  /** Name of the view in GA */
  viewName: Scalars['String']
}

/** Generic name/email input */
export type GenericEmailInput = {
  /** Email of user */
  email: Scalars['String']
  /** Name of user */
  name: Scalars['String']
}

/** generic setting set by the frontend */
export type GenericSetting = {
  __typename?: 'GenericSetting'
  /** Whether to apply this valdiation setting */
  enabled?: Maybe<Scalars['Boolean']>
  /** Name of validation setting to apply */
  name: Scalars['String']
  /** Where a value should be replaced, this is the value to replace with. */
  value?: Maybe<Scalars['String']>
}

/** Default qr code settings for an account */
export type GenericSettingInput = {
  /** Whether to apply this valdiation setting */
  enabled?: InputMaybe<Scalars['Boolean']>
  /** Name of validation setting to apply */
  name: Scalars['String']
  /** Where a value should be replaced, this is the value to replace with. */
  value?: InputMaybe<Scalars['String']>
}

/** Google Analytics mutations */
export type GoogleAnalyticsMutations = {
  __typename?: 'GoogleAnalyticsMutations'
  /** Handle oauth2 callback response from Google Analytics */
  handleGAAuthCallback: GaOauthCreds
  /** Refresh Google Analytics access token for an account */
  reconnectGAAccount: Scalars['Boolean']
}

/** Google Analytics mutations */
export type GoogleAnalyticsMutationsHandleGaAuthCallbackArgs = {
  code: Scalars['String']
  state: Scalars['String']
}

/** Google Analytics mutations */
export type GoogleAnalyticsMutationsReconnectGaAccountArgs = {
  accountID: Scalars['String']
  credentials: Scalars['String']
}

/** Results obtained from the bots visit to the site. Timesout at 20s */
export type IntensiveUrlValidation = {
  __typename?: 'IntensiveUrlValidation'
  /** Whether the bot was able to visit the page */
  badUrl: Scalars['Boolean']
  /** whether the bot clicked the cookie consent */
  clickedCookieConsent: Scalars['Boolean']
  /** whether an analytics tag was found on the page */
  noAnalyticsTag: Scalars['Boolean']
  /** Whether the page resulted in a redirect */
  redirectedLandingPage: Scalars['Boolean']
  /** Whether the landing page took more than 4 seconds to paint */
  slowLandingPage: Scalars['Boolean']
  /** status code */
  statusCode: Scalars['Int']
  /** Url to test */
  testUrl: Scalars['String']
  /** Whether the validator errored or not */
  validatorReturned: Scalars['Boolean']
}

/** Quick validation result list */
export type IntensiveUrlValidationResults = {
  __typename?: 'IntensiveUrlValidationResults'
  /** List of intensive url validation results */
  validationResults: Array<IntensiveUrlValidation>
}

/** Returns the filters available to filter by */
export type LinkAuditFilter = {
  __typename?: 'LinkAuditFilter'
  /** ID of the parameter to filter on */
  parameterID: Scalars['String']
  /** Name of the parameter to filter on */
  parameterName: Scalars['String']
  /** List of options to filter on */
  parameterOptions: Array<Scalars['String']>
}

/** Returns the filters available to filter by */
export type LinkAuditFilters = {
  __typename?: 'LinkAuditFilters'
  /** List of parameters to filter on */
  parameterFilters: Array<LinkAuditFilter>
}

/** Link audit queries */
export type LinkAuditQueries = {
  __typename?: 'LinkAuditQueries'
  accountID: Scalars['String']
  /** Returns a list of filters for the link audit table */
  linkAuditFilters: LinkAuditFilters
  /** Returns the graph data for the link audit table */
  linkAuditGraph: AccountUsageGraphData
  /** Returns a table of links that have not been created in uplifter */
  linkAuditTable: LinkAuditTable
}

/** Link audit queries */
export type LinkAuditQueriesLinkAuditFiltersArgs = {
  tempTable?: InputMaybe<Scalars['String']>
}

/** Link audit queries */
export type LinkAuditQueriesLinkAuditGraphArgs = {
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity?: InputMaybe<Scalars['String']>
  searchFilter?: InputMaybe<SearchFilter>
  selectedMetric?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
}

/** Link audit queries */
export type LinkAuditQueriesLinkAuditTableArgs = {
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  groupByLandingPage?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  searchFilter?: InputMaybe<SearchFilter>
  selectedMetric?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  tempTable?: InputMaybe<Scalars['String']>
}

/** Table of campaign links not created in uplifter */
export type LinkAuditTable = {
  __typename?: 'LinkAuditTable'
  /** List of dates the landing page was first observed in the datasource */
  firstObserved: Array<Scalars['String']>
  /** List of landing pages that have been clicked on */
  landingPageList: Array<Scalars['String']>
  /** List of formats for each metric */
  metricFormat: Array<Scalars['String']>
  /** List of metrics available in the links */
  metricHeadings: Array<Scalars['String']>
  /** List of metric values for each landing page */
  metricValues: Array<Array<Scalars['Float']>>
  /** Paging data for the table */
  pageData?: Maybe<PagingData>
  /** List of parameters available in the links */
  parameterHeadings: Array<Scalars['String']>
  /** List of parameter values for each landing page */
  parameterValues: Array<Array<Scalars['String']>>
}

/** A representation of the action that has been taken by a user. */
export type LogAction = {
  __typename?: 'LogAction'
  /** The account ID of the user who took the action. */
  acctID: Scalars['String']
  /** The action that was taken. */
  action: Scalars['String']
  /** The section of the API the action was taken in. */
  apiSection: Scalars['String']
  /** Any extra information that was provided with the action. */
  extra: Scalars['String']
  /** The function name of the action. */
  functionName: Scalars['String']
  /** The GET parameters of the action. */
  getParams: Scalars['String']
  /** The body of the action. */
  requestBody: Scalars['String']
  /** The page path of the action. */
  requestPath: Scalars['String']
  /** The time at which the action was taken. */
  requestTime: Scalars['String']
  /** The user ID of the user who took the action. */
  userID: Scalars['String']
}

/** Represents a new token that should be used in the auth header */
export type LoginToken = {
  __typename?: 'LoginToken'
  /** The new token to use in the auth header */
  token: Scalars['String']
}

/** Salesforce Block/slot map for template */
export type McBlock = {
  __typename?: 'MCBlock'
  /** block id on marketing cloud */
  blockID: Scalars['String']
  /** content to replace hrefs in */
  content: Scalars['String']
}

/** Salesforce Block/slot map for making changes to template based emails */
export type McBlockInput = {
  /** block id on marketing cloud */
  blockID: Scalars['String']
  /** content to replace hrefs in */
  content: Scalars['String']
}

/** Salesforce slot map for template */
export type McSlotMap = {
  __typename?: 'MCSlotMap'
  /** list of blocks from a template that need updating */
  blocks: Array<McBlock>
  /** content to replace hrefs in */
  content: Scalars['String']
  /** slot id on marketing cloud */
  slotID: Scalars['String']
}

/** Salesforce slot map for making changes to  template based emails */
export type McSlotMapInput = {
  /** list of blocks from a template that need updating */
  blocks: Array<McBlockInput>
  /** content to replace hrefs in */
  content: Scalars['String']
  /** slot id on marketing cloud */
  slotID: Scalars['String']
}

/** Salesforce marketing cloud email template */
export type MarketingCloudEmailTemplate = {
  __typename?: 'MarketingCloudEmailTemplate'
  /** date the template was last updated on marketing cloud */
  dateLastUpdated: Scalars['String']
  /** list of blocks from a template that need updating: only for templates */
  slotMap?: Maybe<Array<McSlotMap>>
  /** template content on marketing cloud */
  templateContent?: Maybe<Scalars['String']>
  /** template id on marketing cloud */
  templateID: Scalars['String']
  /** template name on marketing cloud */
  templateName: Scalars['String']
  /** template type on marketing cloud */
  templateType: Scalars['String']
  /** text content of the email for templatebasedemail */
  textContent: Scalars['String']
}

/** Root marketing cloud queries */
export type MarketingCloudQueries = {
  __typename?: 'MarketingCloudQueries'
  /** Returns the html and links for a given MC email template */
  getTemplateDetail: MarketingCloudEmailTemplate
  /** Returns a list of available Eloqua templates */
  getTemplateList: Array<MarketingCloudEmailTemplate>
}

/** Root marketing cloud queries */
export type MarketingCloudQueriesGetTemplateDetailArgs = {
  mid: Scalars['String']
  templateID: Scalars['String']
}

/** Root marketing cloud queries */
export type MarketingCloudQueriesGetTemplateListArgs = {
  mid: Scalars['String']
}

/** Marketing funnel board definition */
export type MarketingFunnelBoardDefinition = {
  __typename?: 'MarketingFunnelBoardDefinition'
  /** awareness filter for the board */
  awarenessFilter?: Maybe<Scalars['String']>
  /** id of the board */
  boardID: Scalars['String']
  /** title of the board */
  boardTitle: Scalars['String']
  /** end date of the board absolute YYYY-MM-DD */
  endDate?: Maybe<Scalars['String']>
  /** list of other filters for the board */
  filterList?: Maybe<Array<DimensionFilterOutput>>
  /** main filter for the board */
  mainFilter?: Maybe<Array<DimensionFilterOutput>>
  /** start date of the board relative or absolute */
  startDate: Scalars['String']
}

/** Marketing funnel board description - no lane data */
export type MarketingFunnelBoardDescription = {
  __typename?: 'MarketingFunnelBoardDescription'
  /** company|account|user */
  availableTo: Scalars['String']
  /** description of the board */
  boardDescription: Scalars['String']
  /** whether the user has set the board as hidden */
  boardHidden: Scalars['Boolean']
  /** id of the board */
  boardID?: Maybe<Scalars['String']>
  /** title of the board */
  boardTitle: Scalars['String']
  /** url of the board */
  boardURL?: Maybe<Scalars['String']>
}

/** Marketing funnel card */
export type MarketingFunnelCard = {
  __typename?: 'MarketingFunnelCard'
  /** additional connections for the card */
  additionalConnections?: Maybe<Array<MarketingFunnelCardConnection>>
  /** Blob urls of images uploaded to the card */
  additionalImages?: Maybe<Array<Scalars['String']>>
  /** additional metrics for the card */
  additionalMetrics?: Maybe<Array<FunnelMetric>>
  /** annotations for the card */
  cardAnnotation?: Maybe<Scalars['String']>
  /** connections for the card */
  cardConnections?: Maybe<Array<MarketingFunnelCardConnection>>
  /** id of the card */
  cardID: Scalars['String']
  /** list of metrics for the card */
  cardMetrics: Array<FunnelMetric>
  /** where the card appears in the lane, top to bottom */
  cardOrder: Scalars['Int']
  /** title of the card */
  cardTitle: Scalars['String']
  /** Whether its a metric or an annotation */
  cardType: Scalars['String']
  /** whether the card is hidden */
  hideCard?: Maybe<Scalars['Boolean']>
  /** ID of the user that last edited the card */
  lastEditedBy?: Maybe<Scalars['String']>
  /** Date that the card was last edited */
  lastEditedDate?: Maybe<Scalars['String']>
  /** updated title of the card */
  updatedTitle?: Maybe<Scalars['String']>
}

/** Marketing funnel card connection */
export type MarketingFunnelCardConnection = {
  __typename?: 'MarketingFunnelCardConnection'
  /** id of the card the connection is from */
  connectedFrom: Scalars['String']
  /** id of the card the connection is to */
  connectedTo: Scalars['String']
  /** metric for the connection */
  connectionMetricName: Scalars['String']
  /** value of the metric for the connection */
  connectionMetricValue: Scalars['Float']
  /** whether it is a metric or an annotation */
  connectionType: Scalars['String']
  /** whether the connection is hidden */
  hideConnection?: Maybe<Scalars['Boolean']>
  /** updated metric for the connection */
  updatedConnectionMetricName?: Maybe<Scalars['String']>
  /** updated value of the metric for the connection */
  updatedConnectionMetricValue?: Maybe<Scalars['Float']>
}

/** Marketing funnel card connection input */
export type MarketingFunnelCardConnectionInput = {
  /** id of the card the connection is from */
  connectedFrom: Scalars['String']
  /** id of the card the connection is to */
  connectedTo: Scalars['String']
  /** metric for the connection */
  connectionMetricName: Scalars['String']
  /** value of the metric for the connection */
  connectionMetricValue: Scalars['Float']
  /** whether it is a metric or an annotation */
  connectionType: Scalars['String']
  /** whether the connection is hidden */
  hideConnection?: InputMaybe<Scalars['Boolean']>
  /** updated metric for the connection */
  updatedConnectionMetricName?: InputMaybe<Scalars['String']>
  /** updated value of the metric for the connection */
  updatedConnectionMetricValue?: InputMaybe<Scalars['Float']>
}

/** Marketing funnel lane */
export type MarketingFunnelLane = {
  __typename?: 'MarketingFunnelLane'
  /** list of cards in the lane */
  cardList: Array<MarketingFunnelCard>
  /** whether the lane has been reordered */
  hasBeenReordered?: Maybe<Scalars['Boolean']>
  /** id of the lane */
  laneID: Scalars['String']
  /** list of total metrics for the each card in the lane */
  laneMetrics: Array<FunnelMetric>
  /** where the lane appears in the board, left to right */
  laneOrder: Scalars['Int']
  /** title of the lane */
  laneTitle: Scalars['String']
  /** primary colour of the lane */
  primaryColour?: Maybe<Scalars['String']>
  /** secondary colour of the lane */
  secondaryColour?: Maybe<Scalars['String']>
  /** list of metrics by id to display in the lane */
  selectedMetricList: Array<Scalars['String']>
}

/** Marketing funnel queries */
export type MarketingFunnelQueries = {
  __typename?: 'MarketingFunnelQueries'
  /** Returns the marketing funnel board list in order */
  getMarketingFunnelBoardList: Array<MarketingFunnelBoardDescription>
  /** Returns the marketing funnel report */
  getMarketingFunnelReport: MarketingFunnelReport
}

/** Marketing funnel queries */
export type MarketingFunnelQueriesGetMarketingFunnelReportArgs = {
  awarenessFilter?: InputMaybe<Scalars['String']>
  boardID?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<Array<DimensionFilter>>
  forceCacheUpdate?: InputMaybe<Scalars['Boolean']>
  mainFilter?: InputMaybe<Array<DimensionFilter>>
  startDate?: InputMaybe<Scalars['String']>
}

/** Marketing funnel report */
export type MarketingFunnelReport = {
  __typename?: 'MarketingFunnelReport'
  /** input parameters used to generate the metric data for the board */
  boardDefinition: MarketingFunnelBoardDefinition
  /** id of the board */
  boardID?: Maybe<Scalars['String']>
  /** title of the board */
  boardTitle: Scalars['String']
  /** url of the board */
  boardURL?: Maybe<Scalars['String']>
  /** list of lanes on the board */
  laneList: Array<MarketingFunnelLane>
}

/** Microsoft subscription details for the company */
export type MicrosoftSubscriptionData = {
  __typename?: 'MicrosoftSubscriptionData'
  /** Currency of subscription */
  currency: Scalars['String']
  /** Period end date of subscription */
  periodEndDate: Scalars['String']
  /** Period start date of subscription */
  periodStartDate: Scalars['String']
  /** Plan ID of subscription */
  planId: Scalars['String']
  /** Plan name of subscription */
  planName: Scalars['String']
  /** Price of subscription */
  price: Scalars['String']
  /** Quantity of licenses */
  quantity: Scalars['Int']
  /** Status of subscription */
  status: Scalars['String']
  /** Subscription ID */
  subscriptionID: Scalars['String']
  /** Term description of subscription */
  termDescription: Scalars['String']
  /** Term unit of subscription */
  termUnit: Scalars['String']
}

/** Minimal representation of a parameter definition */
export type MinParamDef = {
  __typename?: 'MinParamDef'
  /** Field ID of parameter within generator */
  paramID: Scalars['String']
  /** Current name of field within generator */
  paramName: Scalars['String']
}

/** Size reduced list of codes per account */
export type MinimalCodeList = {
  __typename?: 'MinimalCodeList'
  accountID: Scalars['String']
  /** List of campaign code ids for each code generated by the account */
  author: Array<Scalars['String']>
  /** Field names entered by the user, as according to the current generator.  blank string if missing. */
  codeDef: Array<Array<Scalars['String']>>
  /** List of campaign code ids for each code generated by the account */
  codeID: Array<Scalars['String']>
  /** Time code was created */
  createdTime: Array<Scalars['String']>
  /** Landing page + campaign code */
  fullLink: Array<Scalars['String']>
  linkID?: Maybe<Scalars['String']>
  /** Concise version of the current generator */
  minGenDef: Array<MinParamDef>
  /** List of short links to apply to codes */
  shortLink?: Maybe<Array<Scalars['String']>>
  /** count of codes returned */
  totalCodes: Scalars['Int']
  /** Version number of the code */
  versionNumber: Array<Scalars['Int']>
}

/** Returns IDs used for monday.com integration */
export type MondayIDs = {
  __typename?: 'MondayIDs'
  /** Board ID */
  mondayBoardId: Scalars['String']
  /** Board Name */
  mondayBoardName: Scalars['String']
  /** Group ID */
  mondayGroupId: Scalars['String']
  /** Group Name */
  mondayGroupName: Scalars['String']
  /** URL slug for the account */
  mondayUrlSlug: Scalars['String']
  /** Workspace ID */
  mondayWorkspaceId: Scalars['String']
  /** Workspace Name */
  mondayWorkspaceName: Scalars['String']
}

/** Save oauth token against Uplifter company ID */
export type MondayMutations = {
  __typename?: 'MondayMutations'
  /** Creates a board on the provided Monday workspace ID */
  mondayCreateBoard: MondayIDs
  /** Deletes rows from monday integration tables */
  mondayRemoveIntegration: Scalars['Boolean']
  /** Save oauth token against Uplifter company ID */
  saveMondayAuth: MondayWorkspaces
}

/** Save oauth token against Uplifter company ID */
export type MondayMutationsMondayCreateBoardArgs = {
  workspaceID: Scalars['String']
  workspaceName: Scalars['String']
}

/** Save oauth token against Uplifter company ID */
export type MondayMutationsSaveMondayAuthArgs = {
  code: Scalars['String']
  state?: Scalars['String']
}

/** Queries for Monday.com integration */
export type MondayQueries = {
  __typename?: 'MondayQueries'
  /** Generate an oauth2 link to request monday permissions */
  genMondayoauth2Link: Oauth2Link
  /** Fetch workspace, board and group IDs for where Uplifter requests are sent in the user's account */
  getMondayIds: MondayIDs
  /** Returns all column values for the given puleID provided by Monday.com */
  getMondayItem: MondayIDs
  /** Fetch details of workspaces in the user's Monday.com account */
  getWorkspaces: MondayWorkspaces
}

/** Queries for Monday.com integration */
export type MondayQueriesGenMondayoauth2LinkArgs = {
  state: Scalars['String']
}

/** Queries for Monday.com integration */
export type MondayQueriesGetMondayItemArgs = {
  pulseID: Scalars['String']
}

/** sponsor/uplifter admin details */
export type MondayWorkspace = {
  __typename?: 'MondayWorkspace'
  /** Description of workspace */
  mondayWorkspaceDescription?: Maybe<Scalars['String']>
  /** ID of workspace */
  mondayWorkspaceId: Scalars['String']
  /** Name of workspace */
  mondayWorkspaceName: Scalars['String']
}

/** Returns workspace details for company account */
export type MondayWorkspaces = {
  __typename?: 'MondayWorkspaces'
  /** List of workspace IDs for the given account */
  mondayWorkspaces: Array<MondayWorkspace>
}

/** Root mutations */
export type Mutation = {
  __typename?: 'Mutation'
  /** Add codes to the generator */
  addCodes: MinimalCodeList
  /** Add a user to an individual account */
  addUserAccount: User
  /** Log an action for an authenticated user to the big query log */
  authenticatedLogAction: LogAction
  /** Used to delete a code from uplifter - removes short link. */
  deleteCode: CampaignCode
  /** Remove a qr code from the library of qr codes for this account */
  deleteQrCodeImage: Account
  /** Force onboarding cache refresh */
  forceCacheRefresh: Scalars['Boolean']
  /** report mutations list */
  report: ReportMutations
  /** Send a feature request to the support team */
  sendFeatureRequest: Scalars['Boolean']
  /** Updates the performance dashboard settings */
  setLinkPerformanceDashboardAccountSettings: CampaignLinkDashboard
  /** Track mutations list */
  track: TrackMutations
  /** Update a single existing code */
  updateCode: CampaignCode
  /** Updates the qr settings */
  updateQrSettings: Account
  /** Change the page the user sees when they log in */
  updateUserHomepage: CurrentUser
  /** Account settings mutation list */
  userAccountSettings: UserAccountSettings
}

/** Root mutations */
export type MutationAddCodesArgs = {
  bulkStart?: InputMaybe<Scalars['String']>
  codeList: Array<AddCode>
  customDomainID?: InputMaybe<Scalars['String']>
  deepLinkConfig?: InputMaybe<DeepLinkInput>
}

/** Root mutations */
export type MutationAddUserAccountArgs = {
  accountID: Scalars['String']
  email: Scalars['String']
  permissionLevel: Scalars['String']
}

/** Root mutations */
export type MutationAuthenticatedLogActionArgs = {
  action: Scalars['String']
  extra?: InputMaybe<Scalars['String']>
  functionName: Scalars['String']
  getParams?: InputMaybe<Scalars['String']>
  pagePath: Scalars['String']
  requestBody?: InputMaybe<Scalars['String']>
  websiteSection: Scalars['String']
}

/** Root mutations */
export type MutationDeleteCodeArgs = {
  codeID: Scalars['String']
  customDomainID?: InputMaybe<Scalars['String']>
  deepLinkServiceID?: InputMaybe<Scalars['String']>
}

/** Root mutations */
export type MutationDeleteQrCodeImageArgs = {
  blobName: Scalars['String']
}

/** Root mutations */
export type MutationForceCacheRefreshArgs = {
  accountID?: InputMaybe<Scalars['String']>
  applyToAccount?: InputMaybe<Scalars['Boolean']>
  task: Scalars['String']
}

/** Root mutations */
export type MutationSendFeatureRequestArgs = {
  message: Scalars['String']
  page: Scalars['String']
}

/** Root mutations */
export type MutationSetLinkPerformanceDashboardAccountSettingsArgs = {
  additionalMetrics?: InputMaybe<Array<AdditionalMetric>>
  dashboardTitle?: InputMaybe<Scalars['String']>
  onloadDefaultOptions?: InputMaybe<Array<GenericSettingInput>>
}

/** Root mutations */
export type MutationUpdateCodeArgs = {
  codeID: Scalars['String']
  customDomainID?: InputMaybe<Scalars['String']>
  deepLinkConfig?: InputMaybe<DeepLinkInput>
  fullCode?: InputMaybe<Scalars['String']>
  paramDefs?: InputMaybe<Array<ParamDefInput>>
}

/** Root mutations */
export type MutationUpdateQrSettingsArgs = {
  qrSettings: Array<GenericSettingInput>
}

/** Root mutations */
export type MutationUpdateUserHomepageArgs = {
  preferredHomepage: Scalars['String']
}

/** Field that is being requested to add values to. */
export type NewOptionInput = {
  /** Name of new option to display in select field */
  optionName: Scalars['String']
  /** automatically formatted option value to insert into code */
  optionValue: Scalars['String']
}

/** Field that is being requested to add values to. */
export type NewOptionResponseInput = {
  /** whether this has been approved and added by an admin user. */
  approved: Scalars['Boolean']
  /** message sent to user when it was approved/rejected. */
  message: Scalars['String']
  /** Name of new option to display in select field */
  optionName: Scalars['String']
  /** automatically formatted option value to insert into code */
  optionValue: Scalars['String']
}

/** Link used to give uplifter access to an oauth2 provider */
export type Oauth2Link = {
  __typename?: 'Oauth2Link'
  /** link to redirect the user to to complete oauth2 process */
  oauth2Link: Scalars['String']
}

/** Okta tenant description */
export type OktaDomain = {
  __typename?: 'OktaDomain'
  /** Okta client ID for the company */
  clientID: Scalars['String']
  /** Date the okta domain was created */
  dateCreated: Scalars['String']
  /** Email domain for the company */
  emailDomain: Scalars['String']
  /** Okta login link for the company */
  loginLink: Scalars['String']
  /** Okta tenant domain for the company */
  tenantDomain: Scalars['String']
  /** Okta tenant name for the company */
  tenantName: Scalars['String']
}

/** List of okta domains for the company */
export type OktaDomainList = {
  __typename?: 'OktaDomainList'
  /** List of okta domains */
  oktaDomains: Array<OktaDomain>
}

/** Onboarding steps specific to the account */
export type OnboardingStep = {
  __typename?: 'OnboardingStep'
  /** Section ID of the onboarding step */
  onboardingSectionID: Scalars['String']
  /** Whether this step has been completed */
  sectionCompleted: Scalars['Boolean']
  /** Whether this step has been skipped */
  sectionSkipped: Scalars['Boolean']
  /** Whether this step has ever been skipped or un-completed */
  sectionSkippedBefore: Scalars['Boolean']
}

/** Used for enabling parent child relationships */
export type OptionFilter = {
  __typename?: 'OptionFilter'
  /** The parameter ID/field ID of the parent */
  parentFieldID: Scalars['String']
  /** Option id of parent which triggers this option to be shown. */
  parentOptionIDs: Array<Scalars['String']>
}

/** Used for specifying parent child relationships in a generator */
export type OptionFilterInput = {
  /** Parent select field ID */
  parentFieldID: Scalars['String']
  /** List of parent option IDs from the parent select fields required to select this option */
  parentOptionIDs: Array<Scalars['String']>
}

/** Oracle Eloqua mutations */
export type OracleEloquaMutations = {
  __typename?: 'OracleEloquaMutations'
  /** Save the basic key for Eloqua. separate endpoint to come for oauth2 */
  saveEloquaBasicKey: Company
  /** Update an eloqua org name */
  updateEloquaOrgName: EloquaOrg
}

/** Oracle Eloqua mutations */
export type OracleEloquaMutationsSaveEloquaBasicKeyArgs = {
  companyName: Scalars['String']
  eloquaPassword: Scalars['String']
  userName: Scalars['String']
}

/** Oracle Eloqua mutations */
export type OracleEloquaMutationsUpdateEloquaOrgNameArgs = {
  eloquaDisplayName: Scalars['String']
  eloquaOrgID: Scalars['String']
}

/** Page screenshot */
export type PageScreenshot = {
  __typename?: 'PageScreenshot'
  /** list of urls requested */
  landingPage: Scalars['String']
  /** screenshot of the page in base64 */
  screenshotBase64?: Maybe<Scalars['String']>
  /** blob storage location of the screenshot */
  screenshotLocation?: Maybe<Scalars['String']>
  /** type of screenshot blob|base64|none */
  screenshotType: Scalars['String']
}

/** Returns the page data for a large response */
export type PagingData = {
  __typename?: 'PagingData'
  /** Bigquery temp table name */
  destination: Scalars['String']
  /** Current limit */
  limit: Scalars['Int']
  /** Current offset */
  offset: Scalars['Int']
  /** Total number of rows */
  total: Scalars['Int']
  /** Total number of pages, according to current limit, offset and total */
  totalPages: Scalars['Int']
}

/** A description of a generator parameter/field */
export type ParamDef = {
  __typename?: 'ParamDef'
  /** For fields that duplicate a value from another, the field ID to copy from */
  copyFromField?: Maybe<Array<CopyFromField>>
  /** For date fields, format to save in code. YYYY-MM-DD etc. */
  dateFormat?: Maybe<Scalars['String']>
  /** For email fields, default value to append to code */
  emailDefault?: Maybe<DefaultEmailParameter>
  /** Whether this parameter should be shown to the user */
  fieldAvailable: Scalars['Boolean']
  /** A unique parameter ID within the generator */
  fieldID: Scalars['String']
  /** The name of the parameter to show to the user */
  fieldName: Scalars['String']
  /** Type of input to show to user - input|select|dateFormat|fixed */
  fieldType: Scalars['String']
  /** For fields that have a fixed value, the value to use */
  fixedValue?: Maybe<Scalars['String']>
  /** This parameter should be forced to lower case */
  forceLowerCase?: Maybe<Scalars['Boolean']>
  /** Hover text to explain the parameter to user */
  helpText: Scalars['String']
  /** Used in reporting - a flag to show whether this field denotes a campaign */
  isCampaignField?: Maybe<Scalars['Boolean']>
  /** Used in reporting - enables creative screenshots to be saved to blob storage for options in this field */
  isCreativeField?: Maybe<Scalars['Boolean']>
  /** Maximum number of characters allowed in this field, defaults to 40 */
  lengthLimit?: Maybe<Scalars['Int']>
  /** Whether provided parameter should be used in code */
  metaParameter?: Maybe<Scalars['Boolean']>
  /** Parent child relationships - when options are selected, parameter is shown. */
  parameterDependsOn?: Maybe<OptionFilter>
  /** prefix to the parameter - like utm_medium= */
  prefix: Scalars['String']
  /** Whether it is necessary a parameter is provided */
  required: Scalars['Boolean']
  /** For select fields, list of applicable options */
  selectFields?: Maybe<Array<SelectField>>
}

/** Used to update individual codes definitions */
export type ParamDefInput = {
  /** Field ID within a generator */
  fieldID: Scalars['String']
  /** What is shown to the user (and filtered in link performance) */
  optionName?: InputMaybe<Scalars['String']>
}

/** Returns accounts available for given pardot credentials */
export type PardotAccount = {
  __typename?: 'PardotAccount'
  /** Display name of the pardot account */
  pardotDisplayName: Scalars['String']
  /** ID of the pardot account */
  pardotID: Scalars['String']
  /** Name of the pardot account */
  pardotName: Scalars['String']
}

/** Returns accounts available for given pardot credentials */
export type PardotAccountInput = {
  /** Display name of the pardot account */
  pardotDisplayName: Scalars['String']
  /** ID of the pardot account */
  pardotID: Scalars['String']
  /** Name of the pardot account */
  pardotName: Scalars['String']
}

/** Pardot Link definitions for tying a pardot account to a link */
export type PardotLinkInput = {
  /** ID of the campaign code */
  campaignCodeID: Scalars['String']
  /** ID of the pardot email template */
  emailID: Scalars['String']
  /** ID of the pardot campaign */
  pardotCampaignID: Scalars['String']
  /** ID of the pardot account */
  pardotID: Scalars['String']
}

/** Root queries */
export type Query = {
  __typename?: 'Query'
  /** Data about provided account id. */
  account: Account
  /** User onboarding progress */
  accountOnboardingProgress: AccountOnboarding
  /** root acccount settings queries */
  accountSettings: AccountSettingsQueries
  /** Representation of the campaign code generator as it is stored in database */
  campaignCodeGenerator: CampaignCodeGenerator
  /** A graph of campaign link engagement over time */
  campaignLinkDashboardGraph: CampaignLinkDashboardGraph
  /** A graph of campaign link engagement over time */
  campaignLinkDashboardGraphClickData: CampaignLinkDashboardGraph
  /** Shows performance of campaign links, and updates daily.  This class is used for meta information. */
  campaignLinkDashboardMeta: CampaignLinkDashboard
  /** A set of aggregated metrics that describe the campaign link dashboard */
  campaignLinkDashboardMetricData: CampaignLinkDashboardMetricData
  /** A set of aggregated metrics for short link click data */
  campaignLinkDashboardMetricDataClickData: CampaignLinkDashboardMetricData
  /** A sorted table of campaign links by an engagement metric */
  campaignLinkDashboardTable: CampaignLinkDashboardTable
  /**
   * List of codes created by the current account
   * @deprecated Use get min codes instead
   */
  codesByAccount: CodeList
  /**
   * List of codes created by user including definition
   * @deprecated Use get min codes instead
   */
  codesByUser: CodeList
  /** Data about a provided companyID. */
  company: Company
  /** Current account data, inferred from token. */
  currentAccount: Account
  /** Current company information, inferred from the token. */
  currentCompany: Company
  /** Data about the current user, inferred from the token.  More sensitive than User. */
  currentUser: CurrentUser
  /** Returns a list of metrics available in the datasource for the account */
  dataSourceMetricList: DataSourceMetricList
  /** Get optimised possible code list for large accounts */
  minCodesByAccount: MinimalCodeList
  /** Get fast code list minimal format */
  minCodesQuick: MinimalCodeList
  /** root report queries */
  report: ReportQueries
  /** Returns a list of short link IDs that won't clash in the database */
  shortLinkCandidates: ShortLinkCandidatesShortLinkBatchCandidates
  /** List of codes with engagement data from data source attached */
  storedCodeStats: CodeMetricData
  /** Set of possible track queries */
  track: TrackQueries
  /** Data about a user.  Requires permissions.  Less sensitive than CurrentUser */
  user: User
  /** User onboarding progress */
  userOnboardingProgress: UserOnboarding
}

/** Root queries */
export type QueryAccountArgs = {
  accountID: Scalars['String']
}

/** Root queries */
export type QueryCampaignLinkDashboardGraphArgs = {
  campaignCodeID?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity?: InputMaybe<Scalars['String']>
  matchType: Scalars['String']
  nBuckets?: Scalars['Int']
  selectedMetric: Scalars['String']
  stackDimension?: InputMaybe<Scalars['String']>
  startDate: Scalars['String']
}

/** Root queries */
export type QueryCampaignLinkDashboardGraphClickDataArgs = {
  campaignCodeID?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity?: InputMaybe<Scalars['String']>
  matchType: Scalars['String']
  nBuckets?: Scalars['Int']
  selectedMetric: Scalars['String']
  stackDimension?: InputMaybe<Scalars['String']>
  startDate: Scalars['String']
}

/** Root queries */
export type QueryCampaignLinkDashboardMetricDataArgs = {
  campaignCodeID?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity: Scalars['String']
  matchType: Scalars['String']
  selectedMetric: Scalars['String']
  startDate: Scalars['String']
}

/** Root queries */
export type QueryCampaignLinkDashboardMetricDataClickDataArgs = {
  campaignCodeID?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity: Scalars['String']
  matchType: Scalars['String']
  selectedMetric: Scalars['String']
  startDate: Scalars['String']
}

/** Root queries */
export type QueryCampaignLinkDashboardTableArgs = {
  campaignCodeID?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  matchType: Scalars['String']
  selectedMetric: Scalars['String']
  startDate: Scalars['String']
}

/** Root queries */
export type QueryCompanyArgs = {
  companyID: Scalars['String']
}

/** Root queries */
export type QueryMinCodesByAccountArgs = {
  codeIDList?: InputMaybe<Array<Scalars['String']>>
  dimensionFilter?: InputMaybe<DimensionFilter>
  endDate?: InputMaybe<Scalars['String']>
  filterByCurrentUser?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  noReturn?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<SortFilter>
  refreshCache?: InputMaybe<Scalars['Boolean']>
  startDate?: InputMaybe<Scalars['String']>
}

/** Root queries */
export type QueryMinCodesQuickArgs = {
  codeIDList?: InputMaybe<Array<Scalars['String']>>
  dimensionFilter?: InputMaybe<DimensionFilter>
  endDate?: InputMaybe<Scalars['String']>
  filterByCurrentUser?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<SortFilter>
  startDate?: InputMaybe<Scalars['String']>
}

/** Root queries */
export type QueryShortLinkCandidatesArgs = {
  customDomainID?: InputMaybe<Scalars['String']>
  deepLinkServiceID?: InputMaybe<Scalars['String']>
  nLinks: Scalars['Int']
}

/** Root queries */
export type QueryStoredCodeStatsArgs = {
  codeIDList?: InputMaybe<Array<Scalars['String']>>
  dimensionFilter?: InputMaybe<DimensionFilter>
  filterByCurrentUser?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  noReturn?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<SortFilter>
  refreshCache?: InputMaybe<Scalars['Boolean']>
}

/** Root queries */
export type QueryUserArgs = {
  userID: Scalars['String']
}

/** Quick validation result - whether page exists */
export type QuickUrlValidation = {
  __typename?: 'QuickUrlValidation'
  /** Returned status code of url */
  statusCode: Scalars['Int']
  /** URL to test */
  testUrl: Scalars['String']
}

/** Quick validation result list */
export type QuickUrlValidationResults = {
  __typename?: 'QuickUrlValidationResults'
  /** List of quick url validation results */
  validationResults: Array<QuickUrlValidation>
}

/** A remote dashboard embeddable within uplifter */
export type RemoteDashboard = {
  __typename?: 'RemoteDashboard'
  /** Description of the remote dashboard */
  dashboardDescription: Scalars['String']
  /** Unique ID of the remote dashboard */
  dashboardID: Scalars['String']
  /** Name of the remote dashboard */
  dashboardName: Scalars['String']
  /** Source of the remote dashboard */
  dashboardSource: Scalars['String']
  /** Thumbnail of the remote dashboard */
  dashboardThumbnail: Scalars['String']
  /** Date the remote dashboard was added */
  dateAdded: Scalars['String']
  /** Link to embed the remote dashboard */
  embedLink: Scalars['String']
  /** Date the remote dashboard was last accessed */
  lastAccessed: Scalars['String']
  /** ID of the user that added the remote dashboard */
  userAdded: Scalars['String']
}

/** Returns the list of remote dashboards available to a user in an account */
export type RemoteDashboardList = {
  __typename?: 'RemoteDashboardList'
  /** List of remote dashboards */
  remoteDashboards: Array<RemoteDashboard>
}

/** Result of deleting an account */
export type RemoveAccountResult = {
  __typename?: 'RemoveAccountResult'
  /** New token to use if the account was the current account for the user */
  newToken?: Maybe<Scalars['String']>
  /** Whether the account was successfully removed */
  success: Scalars['Boolean']
  /** Whether the account was the current account for the user - needs a new token and refresh */
  wasUserCurrentAccount: Scalars['Boolean']
}

/** report mutation operations */
export type ReportMutations = {
  __typename?: 'ReportMutations'
  /** Add a new custom embedded dashboard */
  addCustomEmbeddedDashboard: RemoteDashboard
  /** Add a marketing funnel card. only userAnnotations can be added */
  addMarketingFunnelCard: MarketingFunnelReport
  /** Delete a custom embedded dashboard */
  deleteCustomEmbeddedDashboard: RemoteDashboard
  /** Delete a marketing funnel board */
  deleteMarketingFunnelBoard: Array<MarketingFunnelBoardDescription>
  /** Delete a marketing funnel card */
  deleteMarketingFunnelCard: MarketingFunnelReport
  /** Delete a marketing funnel lane */
  deleteMarketingFunnelLane: MarketingFunnelReport
  /** Remove saved performance reports- also drops it from user preferences */
  removeSavedLinkAuditReport: SavedLinkPerformanceReportList
  /** Remove saved performance reports- also drops it from user preferences */
  removeSavedLinkPerformanceReport: SavedLinkPerformanceReportList
  /** Save a new link performance report settings */
  saveLinkAuditReport: SavedLinkPerformanceReport
  /** Save a new link performance report settings */
  saveLinkPerformanceReport: SavedLinkPerformanceReport
  /** Save the initial marketing funnel board */
  saveMarketingFunnelBoardInitial: MarketingFunnelReport
  /** Update a custom embedded dashboard */
  updateCustomEmbeddedDashboard: RemoteDashboard
  /** Update the order of custom embedded dashboards for an account */
  updateCustomEmbeddedDashboardOrder: RemoteDashboardList
  /** Used for reordering/hiding reports */
  updateLinkAuditUserPrefs: SavedLinkPerformanceReportList
  /** Used for reordering/hiding reports */
  updateLinkPerformanceUserPrefs: SavedLinkPerformanceReportList
  /** Update the marketing funnel board */
  updateMarketingFunnelBoard: MarketingFunnelReport
  /** Update the order of marketing funnel boards for an account */
  updateMarketingFunnelBoardOrder: Array<MarketingFunnelBoardDescription>
  /** Update a marketing funnel card */
  updateMarketingFunnelCard: MarketingFunnelReport
  /** Update a marketing funnel lane */
  updateMarketingFunnelLane: MarketingFunnelReport
  /** Update the order of marketing funnel lanes */
  updateMarketingFunnelLaneOrder: MarketingFunnelReport
  /** for updating whats been saved on a performance report */
  updateSavedLinkAuditReport: SavedLinkPerformanceReport
  /** for updating whats been saved on a performance report */
  updateSavedLinkPerformanceReport: SavedLinkPerformanceReport
}

/** report mutation operations */
export type ReportMutationsAddCustomEmbeddedDashboardArgs = {
  dashboardName: Scalars['String']
  embedLink: Scalars['String']
}

/** report mutation operations */
export type ReportMutationsAddMarketingFunnelCardArgs = {
  boardID: Scalars['String']
  cardTitle: Scalars['String']
  curCardOrder: Array<Scalars['String']>
  laneID: Scalars['String']
}

/** report mutation operations */
export type ReportMutationsDeleteCustomEmbeddedDashboardArgs = {
  dashboardID: Scalars['String']
}

/** report mutation operations */
export type ReportMutationsDeleteMarketingFunnelBoardArgs = {
  boardID: Scalars['String']
}

/** report mutation operations */
export type ReportMutationsDeleteMarketingFunnelCardArgs = {
  boardID: Scalars['String']
  cardID: Scalars['String']
  laneID: Scalars['String']
}

/** report mutation operations */
export type ReportMutationsDeleteMarketingFunnelLaneArgs = {
  boardID: Scalars['String']
  laneID: Scalars['String']
}

/** report mutation operations */
export type ReportMutationsRemoveSavedLinkAuditReportArgs = {
  savedReportID: Scalars['String']
}

/** report mutation operations */
export type ReportMutationsRemoveSavedLinkPerformanceReportArgs = {
  savedReportID: Scalars['String']
}

/** report mutation operations */
export type ReportMutationsSaveLinkAuditReportArgs = {
  availableTo: Scalars['String']
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<Array<DimensionFilter>>
  granularity: Scalars['String']
  reportName: Scalars['String']
  reportTitle: Scalars['String']
  selectedMetric: Scalars['String']
  startDate: Scalars['String']
}

/** report mutation operations */
export type ReportMutationsSaveLinkPerformanceReportArgs = {
  availableTo: Scalars['String']
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<Array<DimensionFilter>>
  granularity?: InputMaybe<Scalars['String']>
  matchType: Scalars['String']
  reportName: Scalars['String']
  reportTitle: Scalars['String']
  selectedMetric: Scalars['String']
  stackDimension: Scalars['String']
  startDate: Scalars['String']
}

/** report mutation operations */
export type ReportMutationsSaveMarketingFunnelBoardInitialArgs = {
  availableTo: Scalars['String']
  awarenessFilter?: InputMaybe<Scalars['String']>
  boardDescription?: InputMaybe<Scalars['String']>
  boardID: Scalars['String']
  boardTitle: Scalars['String']
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<Array<DimensionFilter>>
  mainFilter?: InputMaybe<Array<DimensionFilter>>
  startDate?: InputMaybe<Scalars['String']>
}

/** report mutation operations */
export type ReportMutationsUpdateCustomEmbeddedDashboardArgs = {
  dashboardDescription?: InputMaybe<Scalars['String']>
  dashboardID: Scalars['String']
  dashboardName?: InputMaybe<Scalars['String']>
  embedLink?: InputMaybe<Scalars['String']>
}

/** report mutation operations */
export type ReportMutationsUpdateCustomEmbeddedDashboardOrderArgs = {
  newOrder: Array<Scalars['String']>
}

/** report mutation operations */
export type ReportMutationsUpdateLinkAuditUserPrefsArgs = {
  hideReports?: InputMaybe<Array<Scalars['String']>>
  newOrder?: InputMaybe<Array<Scalars['String']>>
}

/** report mutation operations */
export type ReportMutationsUpdateLinkPerformanceUserPrefsArgs = {
  hideReports?: InputMaybe<Array<Scalars['String']>>
  newOrder?: InputMaybe<Array<Scalars['String']>>
}

/** report mutation operations */
export type ReportMutationsUpdateMarketingFunnelBoardArgs = {
  availableTo?: InputMaybe<Scalars['String']>
  awarenessFilter?: InputMaybe<Scalars['String']>
  boardDescription?: InputMaybe<Scalars['String']>
  boardID: Scalars['String']
  boardTitle?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<Array<DimensionFilter>>
  mainFilter?: InputMaybe<Array<DimensionFilter>>
  startDate?: InputMaybe<Scalars['String']>
}

/** report mutation operations */
export type ReportMutationsUpdateMarketingFunnelBoardOrderArgs = {
  hideReports?: InputMaybe<Array<Scalars['String']>>
  newOrder: Array<Scalars['String']>
}

/** report mutation operations */
export type ReportMutationsUpdateMarketingFunnelCardArgs = {
  additionalConnections?: InputMaybe<Array<MarketingFunnelCardConnectionInput>>
  additionalImages?: InputMaybe<Array<Scalars['String']>>
  additionalMetrics?: InputMaybe<Array<FunnelMetricInput>>
  boardID: Scalars['String']
  cardID: Scalars['String']
  cardTitle?: InputMaybe<Scalars['String']>
  hideCard?: InputMaybe<Scalars['Boolean']>
  laneID: Scalars['String']
}

/** report mutation operations */
export type ReportMutationsUpdateMarketingFunnelLaneArgs = {
  boardID: Scalars['String']
  cardOrder?: InputMaybe<Array<Scalars['String']>>
  laneID: Scalars['String']
  laneTitle?: InputMaybe<Scalars['String']>
  primaryColour?: InputMaybe<Scalars['String']>
  secondaryColour?: InputMaybe<Scalars['String']>
  selectedMetricList?: InputMaybe<Array<Scalars['String']>>
}

/** report mutation operations */
export type ReportMutationsUpdateMarketingFunnelLaneOrderArgs = {
  boardID: Scalars['String']
  newOrder: Array<Scalars['String']>
}

/** report mutation operations */
export type ReportMutationsUpdateSavedLinkAuditReportArgs = {
  availableTo?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<Array<DimensionFilter>>
  granularity?: InputMaybe<Scalars['String']>
  groupByLandingPage?: InputMaybe<Scalars['Boolean']>
  hideColumnIndexList?: InputMaybe<Array<Scalars['Int']>>
  renamedColumnNameList?: InputMaybe<Array<Scalars['String']>>
  reportName?: InputMaybe<Scalars['String']>
  reportTitle?: InputMaybe<Scalars['String']>
  savedReportID: Scalars['String']
  selectedMetric?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  updatedTableColumnIndexOrderList?: InputMaybe<Array<Scalars['Int']>>
}

/** report mutation operations */
export type ReportMutationsUpdateSavedLinkPerformanceReportArgs = {
  availableTo?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<Array<DimensionFilter>>
  granularity?: InputMaybe<Scalars['String']>
  groupByLandingPage?: InputMaybe<Scalars['Boolean']>
  hideColumnIndexList?: InputMaybe<Array<Scalars['Int']>>
  matchType?: InputMaybe<Scalars['String']>
  renamedColumnNameList?: InputMaybe<Array<Scalars['String']>>
  reportName?: InputMaybe<Scalars['String']>
  reportTitle?: InputMaybe<Scalars['String']>
  savedReportID: Scalars['String']
  selectedMetric?: InputMaybe<Scalars['String']>
  stackDimension?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  updatedTableColumnIndexOrderList?: InputMaybe<Array<Scalars['Int']>>
}

/** Report account settings queries */
export type ReportQueries = {
  __typename?: 'ReportQueries'
  accountID: Scalars['String']
  /** Returns active users per time period */
  activeUsers: AccountUsageGraphData
  /** Returns totals for erroneous codes blocked */
  badCodesBlocked: AccountUsageGraphData
  /** Returns totals for codes created and creation methods */
  codesCreated: AccountUsageGraphData
  /** Returns a list of creative library items for a given parameter */
  getCreativeList: Array<CreativeLibraryItem>
  /** Returns totals for invalid codes blocked */
  invalidCodesBreakdown: AccountUsageGraphData
  /** Queries for the link audit report */
  linkAudit: LinkAuditQueries
  /** Returns list of remote dashboards for an account */
  listRemoteDashboards: RemoteDashboardList
  /** Returns list of saved reports for an account */
  listSavedLinkAuditReports: SavedLinkPerformanceReportList
  /** Returns list of saved reports for an account */
  listSavedLinkPerformanceReports: SavedLinkPerformanceReportList
  /** Returns account usage data per user */
  logins: AccountUsageGraphData
  /** Queries for the marketing funnel report */
  marketingFunnel: MarketingFunnelQueries
  /** Returns a list of page screenshots */
  pagePreview: Array<PageScreenshot>
  /** Returns active users per time period */
  totalUsers: AccountUsageGraphData
}

/** Report account settings queries */
export type ReportQueriesActiveUsersArgs = {
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity?: InputMaybe<Scalars['String']>
  startDate: Scalars['String']
}

/** Report account settings queries */
export type ReportQueriesBadCodesBlockedArgs = {
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity?: InputMaybe<Scalars['String']>
  startDate: Scalars['String']
}

/** Report account settings queries */
export type ReportQueriesCodesCreatedArgs = {
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity?: InputMaybe<Scalars['String']>
  startDate: Scalars['String']
}

/** Report account settings queries */
export type ReportQueriesGetCreativeListArgs = {
  parameterID: Scalars['String']
}

/** Report account settings queries */
export type ReportQueriesInvalidCodesBreakdownArgs = {
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity?: InputMaybe<Scalars['String']>
  startDate: Scalars['String']
}

/** Report account settings queries */
export type ReportQueriesLoginsArgs = {
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity?: InputMaybe<Scalars['String']>
  startDate: Scalars['String']
}

/** Report account settings queries */
export type ReportQueriesPagePreviewArgs = {
  urlList: Array<Scalars['String']>
}

/** Report account settings queries */
export type ReportQueriesTotalUsersArgs = {
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity?: InputMaybe<Scalars['String']>
  startDate: Scalars['String']
}

export type Result = {
  __typename?: 'Result'
  category: Scalars['String']
  data?: Maybe<Scalars['String']>
  definition: Scalars['String']
  gaLink: Scalars['String']
  importance: Scalars['String']
  result: Scalars['String']
  resultMarkdown: Scalars['String']
  solution: Scalars['String']
  testDataReturnType: Scalars['String']
  testDescription: Scalars['String']
  testPass: Scalars['Boolean']
  testid: Scalars['String']
}

export type ResultItem = {
  __typename?: 'ResultItem'
  result: Result
  testItem: Scalars['String']
}

/** A salesforce marketing cloud api key */
export type SalesforceMcapiKey = {
  __typename?: 'SalesforceMCAPIKey'
  /** Name of the business unit */
  businessUnitName: Scalars['String']
  /** Marketing cloud mid */
  mid: Scalars['String']
}

/** Salesforce mc mutations */
export type SalesforceMcMutations = {
  __typename?: 'SalesforceMCMutations'
  /** Handle oauth2 callback response from Salesforce mc */
  handleSalesforceMCCallback: Company
}

/** Salesforce mc mutations */
export type SalesforceMcMutationsHandleSalesforceMcCallbackArgs = {
  businessUnitName: Scalars['String']
  code: Scalars['String']
  encryptedState: Scalars['String']
  mid: Scalars['String']
  tssd?: InputMaybe<Scalars['String']>
}

/** Returns pardot accounts available for given pardot credentials */
export type SalesforcePardotAccountSummary = {
  __typename?: 'SalesforcePardotAccountSummary'
  /** List of link performance report IDs per user */
  pardotList: Array<PardotAccount>
}

/** Representation of a pardot email template */
export type SalesforcePardotEmailTemplateDef = {
  __typename?: 'SalesforcePardotEmailTemplateDef'
  /** Date the pardot email template was added */
  dateAdded: Scalars['String']
  /** Date the pardot email template was last updated */
  dateUpdated: Scalars['String']
  /** Direct link to the pardot email template */
  directLink: Scalars['String']
  /** HTML of the pardot email template */
  emailHTML?: Maybe<Scalars['String']>
  /** ID of the pardot email template */
  emailID: Scalars['String']
  /** List of links in the pardot email template */
  emailLinks?: Maybe<Array<Scalars['String']>>
  /** Name of the pardot email template */
  emailName: Scalars['String']
  /** Subject of the pardot email template */
  emailSubject: Scalars['String']
  /** Whether the pardot email template is a classic list email.  These are read only and cannot be edited by the API, but can be edited in the pardot UI */
  isClassicListEmail: Scalars['Boolean']
  /** ID of the pardot campaign */
  pardotCampaignID?: Maybe<Scalars['String']>
  /** ID of the pardot account (only in classic) */
  pardotID?: Maybe<Scalars['String']>
  /** Name of the pardot account (only in classic) */
  pardotName?: Maybe<Scalars['String']>
  /** classic|lightning|aloha */
  uiType: Scalars['String']
}

/** Pardot templates need to be requested individually */
export type SalesforcePardotEmailTemplateInput = {
  /** ID of the pardot email template */
  emailID: Scalars['String']
  /** Whether the pardot email template is a classic list email.  These are read only and cannot be edited by the API, but can be edited in the pardot UI */
  isClassicListEmail: Scalars['Boolean']
  /** ID of the pardot account */
  pardotID: Scalars['String']
  /** Name of the pardot account */
  pardotName: Scalars['String']
  /** classic|lightning|aloha */
  uiType: Scalars['String']
}

/** List of email templates available within a pardot subscription */
export type SalesforcePardotEmailTemplates = {
  __typename?: 'SalesforcePardotEmailTemplates'
  /** List of classic builder email templates */
  classicBuilder: Array<SalesforcePardotEmailTemplateDef>
  /** List of lightning builder email templates */
  lightningBuilder: Array<SalesforcePardotEmailTemplateDef>
}

/** Salesforce pardot mutations */
export type SalesforcePardotMutations = {
  __typename?: 'SalesforcePardotMutations'
  /** Handle oauth2 callback response from Salesforce pardot */
  handleSalesforcePardotCallback: SalesforcePardotAccountSummary
  /** Save pardot business unit list */
  savePardotAccountList: SalesforcePardotAccountSummary
}

/** Salesforce pardot mutations */
export type SalesforcePardotMutationsHandleSalesforcePardotCallbackArgs = {
  code: Scalars['String']
  encryptedState: Scalars['String']
}

/** Salesforce pardot mutations */
export type SalesforcePardotMutationsSavePardotAccountListArgs = {
  pardotAcctList: Array<PardotAccountInput>
}

/** Returns the per user per account settings for a link performance report */
export type SavedLinkPerformanceReport = {
  __typename?: 'SavedLinkPerformanceReport'
  /** List of link performance report settings */
  applyFilters?: Maybe<Array<DimensionFilterOutput>>
  /** One of company|account|user - who the report is accessible to.  company is for shared accounts */
  availableTo: Scalars['String']
  /** email address of the user that created the report */
  createdBy: Scalars['String']
  /** User ID of the user that created the report */
  createdByID: Scalars['String']
  /** Date the report was saved */
  dateCreated: Scalars['String']
  /** end date to filter the report by */
  endDate: Scalars['String']
  /** How the graph is grouped by - daily/weekly/monthly/quarterly */
  granularity?: Maybe<Scalars['String']>
  /** Whether to group by landing page */
  groupByLandingPage?: Maybe<Scalars['Boolean']>
  /** column indexes that should not be displayed in the table */
  hideColumnIndexList?: Maybe<Array<Scalars['Int']>>
  /** email address of the user that last edited the report settings */
  lastEditedBy: Scalars['String']
  /** User id of the user that last edited the report */
  lastEditedByID: Scalars['String']
  /** Date that the report was last edited */
  lastEditedDate: Scalars['String']
  /** How the campaign link is grouped by - link/code/campaign parameter */
  matchType?: Maybe<Scalars['String']>
  /** New names for columns in the table. if in use, all columns must be included */
  renamedColumnNameList?: Maybe<Array<Scalars['String']>>
  /** what goes in the tab */
  reportName: Scalars['String']
  /** Title of report to display */
  reportTitle: Scalars['String']
  /** Unique ID of this report within this account */
  savedReportID: Scalars['String']
  /** Metric that appears in the graph and the table */
  selectedMetric: Scalars['String']
  /** Dimension that is used to stack the table by */
  stackDimension?: Maybe<Scalars['String']>
  /** Start date to filter the report by */
  startDate: Scalars['String']
  /** New order to display columns in the table */
  updatedTableColumnIndexOrderList?: Maybe<Array<Scalars['Int']>>
}

/** Returns the list of saved performance report settings available to a user in an account */
export type SavedLinkPerformanceReportList = {
  __typename?: 'SavedLinkPerformanceReportList'
  /** List of link performance reports the user does not want to see. */
  hiddenReportList: Array<Scalars['String']>
  /** List of saved link performance reports */
  savedReports: Array<SavedLinkPerformanceReport>
  /** List of most recent user preferred order of saved report ids. */
  userPreferredOrder: Array<Scalars['String']>
}

/** Applied to limit data returned as a search result */
export type SearchFilter = {
  /** Field to search| ALL to search all fields */
  searchField: Scalars['String']
  /** Value to search for */
  searchValue: Scalars['String']
}

/** An option available in a select field dropdown */
export type SelectField = {
  __typename?: 'SelectField'
  /** A flag determining whether this option should be shown to the user. */
  hide: Scalars['Boolean']
  /** Parent child relationships - when option is shown. */
  optionFilter?: Maybe<Array<OptionFilter>>
  /** A unique option id for this option */
  optionID: Scalars['String']
  /** The value displayed to the user */
  optionName: Scalars['String']
  /** The option inserted into the code */
  optionValue: Scalars['String']
}

/** Used for adding bulk options to a select field */
export type SelectOptionInput = {
  /** Whether or not to hide the parameter */
  hide: Scalars['Boolean']
  /** Parent child specification */
  optionFilter?: InputMaybe<Array<OptionFilterInput>>
  /** Name of the option */
  optionName: Scalars['String']
  /** Value of the option */
  optionValue: Scalars['String']
}

/** Flag to determine whether a short link is available/reserve it */
export type ShortLinkAvailable = {
  __typename?: 'ShortLinkAvailable'
  /** Datetime of when the short link reservation will be released back into the system if not used */
  availableUntil?: Maybe<Scalars['String']>
  /** Flag to show whether the short link is available/has been reserved */
  isAvailable: Scalars['Boolean']
  /** Provided link id */
  shortLinkID: Scalars['String']
}

/** List of short link ids that will not clash */
export type ShortLinkBatchCandidates = {
  __typename?: 'ShortLinkBatchCandidates'
  /** Start of the batch - abcdef-0x1 -> its the short link ID and then the link number in hexadecimal */
  bulkStart: Scalars['String']
  /** Number of links in the batch */
  nLinks: Scalars['Int']
}

/** List of short link ids that will not clash */
export type ShortLinkCandidates = {
  __typename?: 'ShortLinkCandidates'
  /** List of short link IDs that will not clash */
  availableLinkIDs: Array<Scalars['String']>
}

export type ShortLinkCandidatesShortLinkBatchCandidates =
  | ShortLinkBatchCandidates
  | ShortLinkCandidates

/** A short link custom domain is an azure function redirect service. */
export type ShortLinkCustomDomain = {
  __typename?: 'ShortLinkCustomDomain'
  /** A unique ID for a custom domain for a company within uplifter */
  customDomainID: Scalars['String']
  /** Domain of custom domain */
  customDomainName: Scalars['String']
}

/** Sort filter for the track view table */
export type SortFilter = {
  /** Sort direction: ASC or DESC */
  sortDirection: Scalars['String']
  /** Field to sort by */
  sortField: Scalars['String']
}

export type Subscription = {
  __typename?: 'Subscription'
  auditRun: AuditRes
}

export type SubscriptionAuditRunArgs = {
  browserTimeZone: Scalars['String']
  curAccount: Scalars['String']
  endDate: Scalars['String']
  ref: Scalars['String']
  startDate: Scalars['String']
  uid: Scalars['String']
}

/** track deep linking mutation operations */
export type TrackDeeplinkingMutations = {
  __typename?: 'TrackDeeplinkingMutations'
  /** Add a new deepLink service */
  addDeepLinkService: DeepLinkService
  /** Bulk add options into the app context */
  bulkAddAppContext: Array<AppContext>
  /** Bulk delete options from the app context */
  bulkDeleteAppContext: Array<AppContext>
  /** Bulk update options in the app context */
  bulkUpdateAppContext: Array<AppContext>
  /** Create a new app group, a container for apps */
  createAppGroup: DeepLinkAppGroup
  /** Delete an app for deep linking */
  deleteApp: Scalars['Boolean']
  /** Delete an app group, update the applinks file */
  deleteAppGroup: Scalars['Boolean']
  /** Register a new app for deep linking */
  registerApp: DeepLinkAndroidAppDeepLinkIosApp
  /** Reorder options in the app context */
  reorderAppContext: Array<AppContext>
  /** Update an existing app for deep linking */
  updateApp: DeepLinkAndroidAppDeepLinkIosApp
  /** Update an app group, name description etc */
  updateAppGroup: DeepLinkAppGroup
}

/** track deep linking mutation operations */
export type TrackDeeplinkingMutationsAddDeepLinkServiceArgs = {
  deepLinkServiceName: Scalars['String']
  deepLinkServiceSubdomain: Scalars['String']
  storageAccountName: Scalars['String']
}

/** track deep linking mutation operations */
export type TrackDeeplinkingMutationsBulkAddAppContextArgs = {
  appGroupID: Scalars['String']
  insertContextList: Array<AppContextInsertInput>
}

/** track deep linking mutation operations */
export type TrackDeeplinkingMutationsBulkDeleteAppContextArgs = {
  appGroupID: Scalars['String']
  deleteContextList: Array<Scalars['String']>
}

/** track deep linking mutation operations */
export type TrackDeeplinkingMutationsBulkUpdateAppContextArgs = {
  appGroupID: Scalars['String']
  updateContextList: Array<AppContextUpdateInput>
}

/** track deep linking mutation operations */
export type TrackDeeplinkingMutationsCreateAppGroupArgs = {
  appGroupDescription?: InputMaybe<Scalars['String']>
  appGroupName: Scalars['String']
  appGroupPath?: InputMaybe<Scalars['String']>
  deepLinkServiceID: Scalars['String']
  isUAT?: InputMaybe<Scalars['Boolean']>
  redirectWeb: Scalars['String']
}

/** track deep linking mutation operations */
export type TrackDeeplinkingMutationsDeleteAppArgs = {
  appID: Scalars['String']
}

/** track deep linking mutation operations */
export type TrackDeeplinkingMutationsDeleteAppGroupArgs = {
  appGroupID: Scalars['String']
}

/** track deep linking mutation operations */
export type TrackDeeplinkingMutationsRegisterAppArgs = {
  androidAppName?: InputMaybe<Scalars['String']>
  androidPackage?: InputMaybe<Scalars['String']>
  androidPlayStoreLink?: InputMaybe<Scalars['String']>
  androidURI?: InputMaybe<Scalars['String']>
  appGroupID: Scalars['String']
  assetLinksAndroid?: InputMaybe<Scalars['String']>
  deepLinkServiceID: Scalars['String']
  device: Scalars['String']
  fallBackURL?: InputMaybe<Scalars['String']>
  iosAppName?: InputMaybe<Scalars['String']>
  iosAppStoreID?: InputMaybe<Scalars['String']>
  iosBundleID?: InputMaybe<Scalars['String']>
  iosTeamID?: InputMaybe<Scalars['String']>
  iosURI?: InputMaybe<Scalars['String']>
  redirectMethod: Scalars['String']
}

/** track deep linking mutation operations */
export type TrackDeeplinkingMutationsReorderAppContextArgs = {
  appGroupID: Scalars['String']
  reorderContextList: Array<Scalars['String']>
}

/** track deep linking mutation operations */
export type TrackDeeplinkingMutationsUpdateAppArgs = {
  androidAppName?: InputMaybe<Scalars['String']>
  androidPackage?: InputMaybe<Scalars['String']>
  androidPlayStoreLink?: InputMaybe<Scalars['String']>
  androidURI?: InputMaybe<Scalars['String']>
  appGroupID?: InputMaybe<Scalars['String']>
  appID: Scalars['String']
  assetLinksAndroid?: InputMaybe<Scalars['String']>
  deepLinkServiceID: Scalars['String']
  device: Scalars['String']
  fallBackURL?: InputMaybe<Scalars['String']>
  iosAppName?: InputMaybe<Scalars['String']>
  iosAppStoreID?: InputMaybe<Scalars['String']>
  iosBundleID?: InputMaybe<Scalars['String']>
  iosTeamID?: InputMaybe<Scalars['String']>
  iosURI?: InputMaybe<Scalars['String']>
}

/** track deep linking mutation operations */
export type TrackDeeplinkingMutationsUpdateAppGroupArgs = {
  appGroupDescription?: InputMaybe<Scalars['String']>
  appGroupID: Scalars['String']
  appGroupName?: InputMaybe<Scalars['String']>
  appGroupPath?: InputMaybe<Scalars['String']>
  isUAT?: InputMaybe<Scalars['Boolean']>
  redirectWeb?: InputMaybe<Scalars['String']>
}

/** Track direct download */
export type TrackDirectDownloadShare = {
  __typename?: 'TrackDirectDownloadShare'
  /** account id of codes shared */
  account: Scalars['String']
  /** Company id of shared codes */
  cid: Scalars['String']
  /** JSON string of original request */
  codeShareData: Scalars['String']
  /** User id of user that shared codes */
  requester: Scalars['String']
  /** datetime of when codes were shared */
  sent: Scalars['String']
  /** Short ID for downloading shared codes */
  shortID: Scalars['String']
}

/** track mutation operations */
export type TrackMutations = {
  __typename?: 'TrackMutations'
  /** Add a creative link to the creative library */
  addCreativeLink: CreativeLibraryItem
  /** Add a parameter to a campaign code generator */
  addGeneratorParameter: ParamDef
  /** Add a short link to an existing campaign code */
  addShortLinkExistingCode: CampaignCode
  /** Approve a user's update request */
  approveUpdateRequest: UpdateRequest
  /** Delete a list of campaign codes. returns a list of the deleted codeIDs. */
  deleteCodes: Array<Scalars['String']>
  /** Delete a creative link from the creative library */
  deleteCreativeLink: Scalars['Boolean']
  /** Delete a parameter from a campaign code generator */
  deleteGeneratorParameter: CampaignCodeGenerator
  /** Delete a validation check from a campaign code generator */
  deleteValidationCheck: CampaignCodeGenerator
  /** Mutations for Oracle Eloqua integration */
  eloquaMutations: TrackOracleEloquaMutations
  /** Reject a user's update request */
  rejectUpdateRequest: UpdateRequest
  /** Mutations for Salesforce mc integration */
  salesforceMCMutations: TrackSalesforceMcMutations
  /** Apply links to a Pardot email template */
  sendPardotUpdate: SalesforcePardotEmailTemplateDef
  /** Send a file to the adobe FTP server */
  sendSaintFile: AdobeFtpAccount
  /** Send an update request to the applicable users */
  sendUpdateRequest: UpdateRequest
  /** Send a response to a user's update request */
  sendUpdateRequestResponse: UpdateRequest
  /** Set the default email settings for a track */
  setDefaultTrackEmailSettings: EmailTrackSettings
  /** Email and generate a sharing link for a list of campaign codes */
  shareCodes: TrackDirectDownloadShare
  /** Toggle the landing page validation for a campaign code generator */
  toggleLandingPageValidation: CampaignCodeGenerator
  /** Mutations for deep linking */
  trackDeeplinkingMutations: TrackDeeplinkingMutations
  /** Update a creative link in the creative library */
  updateCreativeLink: CreativeLibraryItem
  /** Update the current sequential code ID for a track */
  updateCurrentSequentialCodeID: UplifterIdSequentialTotal
  /** Update the prefix for current sequential code ID for the generator */
  updateCurrentSequentialCodeIDObject: UplifterIdSequentialTotal
  /** Update the master settings for a campaign code generator */
  updateGeneratorMasterSettings: CampaignCodeGenerator
  /** Update a parameter for a campaign code generator */
  updateGeneratorParameter: ParamDef
  /** Update the parameter order for a campaign code generator */
  updateGeneratorParameterOrder: CampaignCodeGenerator
  /** Add multiple options to a select parameter for a campaign code generator */
  updateGeneratorParameterSelectAddMultipleOptions: CampaignCodeGenerator
  /** Add an option to a select parameter for a campaign code generator */
  updateGeneratorParameterSelectAddOption: CampaignCodeGenerator
  /** Delete an option to a select parameter for a campaign code generator */
  updateGeneratorParameterSelectDeleteOption: CampaignCodeGenerator
  /** Drop parent/child relationship for a select parameter for a campaign code generator */
  updateGeneratorParameterSelectDropParentChild: CampaignCodeGenerator
  /** Reorder options to a select parameter for a campaign code generator */
  updateGeneratorParameterSelectReorderOptions: CampaignCodeGenerator
  /** Update an option to a select parameter for a campaign code generator */
  updateGeneratorParameterSelectUpdateOption: CampaignCodeGenerator
}

/** track mutation operations */
export type TrackMutationsAddCreativeLinkArgs = {
  creativeLink: Scalars['String']
  optionID?: InputMaybe<Scalars['String']>
  optionName?: InputMaybe<Scalars['String']>
  parameterID: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsAddGeneratorParameterArgs = {
  dateFormat?: InputMaybe<Scalars['String']>
  fieldAvailable: Scalars['Boolean']
  fieldName: Scalars['String']
  fieldType: Scalars['String']
  fixedValue?: InputMaybe<Scalars['String']>
  forceLowerCase?: InputMaybe<Scalars['Boolean']>
  helpText: Scalars['String']
  isCampaignField?: InputMaybe<Scalars['Boolean']>
  isCreativeField?: InputMaybe<Scalars['Boolean']>
  lengthLimit?: InputMaybe<Scalars['Int']>
  metaParameter?: Scalars['Boolean']
  prefix: Scalars['String']
  required: Scalars['Boolean']
}

/** track mutation operations */
export type TrackMutationsAddShortLinkExistingCodeArgs = {
  codeID: Scalars['String']
  customDomainID?: InputMaybe<Scalars['String']>
  deepLinkConfig?: InputMaybe<DeepLinkInput>
  newShortLinkID: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsApproveUpdateRequestArgs = {
  accountIDList?: InputMaybe<Array<Scalars['String']>>
  fieldID: Scalars['String']
  fieldName: Scalars['String']
  message?: InputMaybe<Scalars['String']>
  newName: Scalars['String']
  newValue: Scalars['String']
  optionRequestID?: InputMaybe<Scalars['String']>
  optionRequestIndex?: InputMaybe<Scalars['Int']>
  requestID: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsDeleteCodesArgs = {
  codeIDList: Array<Scalars['String']>
  customDomainID?: InputMaybe<Scalars['String']>
  deepLinkServiceID?: InputMaybe<Scalars['String']>
}

/** track mutation operations */
export type TrackMutationsDeleteCreativeLinkArgs = {
  creativeID: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsDeleteGeneratorParameterArgs = {
  removeFieldID: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsDeleteValidationCheckArgs = {
  validationCheckName: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsRejectUpdateRequestArgs = {
  fieldID: Scalars['String']
  fieldName: Scalars['String']
  message?: InputMaybe<Scalars['String']>
  optionRequestID?: InputMaybe<Scalars['String']>
  optionRequestIndex?: InputMaybe<Scalars['Int']>
  requestID: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsSendPardotUpdateArgs = {
  emailID: Scalars['String']
  generatedPardotLinks: Array<PardotLinkInput>
  pardotID: Scalars['String']
  pardotName: Scalars['String']
  uiType: Scalars['String']
  updatedHTMLMessage: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsSendUpdateRequestArgs = {
  requestNote: Scalars['String']
  updateRequestList: Array<ChangeRequestInput>
}

/** track mutation operations */
export type TrackMutationsSendUpdateRequestResponseArgs = {
  approved: Scalars['Boolean']
  changeRequests?: InputMaybe<Array<ChangeRequestResponseInput>>
  message: Scalars['String']
  requestID: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsSetDefaultTrackEmailSettingsArgs = {
  domainList?: InputMaybe<Array<Scalars['String']>>
}

/** track mutation operations */
export type TrackMutationsShareCodesArgs = {
  codeList: Array<Scalars['String']>
  emailList: Array<Scalars['String']>
  note: Scalars['String']
  subject: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsToggleLandingPageValidationArgs = {
  validateLandingPage: Scalars['Boolean']
}

/** track mutation operations */
export type TrackMutationsUpdateCreativeLinkArgs = {
  creativeID: Scalars['String']
  creativeLink?: InputMaybe<Scalars['String']>
  optionID?: InputMaybe<Scalars['String']>
  optionName?: InputMaybe<Scalars['String']>
  parameterID?: InputMaybe<Scalars['String']>
}

/** track mutation operations */
export type TrackMutationsUpdateCurrentSequentialCodeIdArgs = {
  currentTotal: Scalars['String']
  etag: Scalars['String']
  newTotal: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsUpdateCurrentSequentialCodeIdObjectArgs = {
  isEnabled?: InputMaybe<Scalars['Boolean']>
  updatedPrefix?: InputMaybe<Scalars['String']>
}

/** track mutation operations */
export type TrackMutationsUpdateGeneratorMasterSettingsArgs = {
  defaultPardotBusinessUnit?: InputMaybe<Scalars['String']>
  existingParametersAddedToStart?: InputMaybe<Scalars['Boolean']>
  isLocked?: InputMaybe<Scalars['Boolean']>
  masterPrefix?: InputMaybe<Scalars['String']>
  paramSeparator?: InputMaybe<Scalars['String']>
  validationChecks?: InputMaybe<Array<GenericSettingInput>>
}

/** track mutation operations */
export type TrackMutationsUpdateGeneratorParameterArgs = {
  copyFromField?: InputMaybe<Array<CopyFromFieldInput>>
  dateFormat?: InputMaybe<Scalars['String']>
  emailDefault?: InputMaybe<DefaultEmailParameterInput>
  fieldAvailable?: InputMaybe<Scalars['Boolean']>
  fieldName?: InputMaybe<Scalars['String']>
  fieldType?: InputMaybe<Scalars['String']>
  fixedValue?: InputMaybe<Scalars['String']>
  forceLowerCase?: InputMaybe<Scalars['Boolean']>
  helpText?: InputMaybe<Scalars['String']>
  isCampaignField?: InputMaybe<Scalars['Boolean']>
  isCreativeField?: InputMaybe<Scalars['Boolean']>
  lengthLimit?: InputMaybe<Scalars['Int']>
  metaParameter?: InputMaybe<Scalars['Boolean']>
  parameterDependsOn?: InputMaybe<OptionFilterInput>
  prefix?: InputMaybe<Scalars['String']>
  required?: InputMaybe<Scalars['Boolean']>
  updateFieldID: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsUpdateGeneratorParameterOrderArgs = {
  newOrder: Array<Scalars['String']>
}

/** track mutation operations */
export type TrackMutationsUpdateGeneratorParameterSelectAddMultipleOptionsArgs = {
  fieldID: Scalars['String']
  optionList: Array<SelectOptionInput>
}

/** track mutation operations */
export type TrackMutationsUpdateGeneratorParameterSelectAddOptionArgs = {
  fieldID: Scalars['String']
  hide: Scalars['Boolean']
  optionFilter?: InputMaybe<Array<OptionFilterInput>>
  optionName: Scalars['String']
  optionValue: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsUpdateGeneratorParameterSelectDeleteOptionArgs = {
  fieldID: Scalars['String']
  optionID: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsUpdateGeneratorParameterSelectDropParentChildArgs = {
  fieldID: Scalars['String']
}

/** track mutation operations */
export type TrackMutationsUpdateGeneratorParameterSelectReorderOptionsArgs = {
  fieldID: Scalars['String']
  newOrder: Array<Scalars['String']>
}

/** track mutation operations */
export type TrackMutationsUpdateGeneratorParameterSelectUpdateOptionArgs = {
  fieldID: Scalars['String']
  hide?: InputMaybe<Scalars['Boolean']>
  optionFilter?: InputMaybe<Array<OptionFilterInput>>
  optionID: Scalars['String']
  optionName?: InputMaybe<Scalars['String']>
  optionValue?: InputMaybe<Scalars['String']>
}

/** Oracle eloqua mutation operations */
export type TrackOracleEloquaMutations = {
  __typename?: 'TrackOracleEloquaMutations'
  /** Syncs uplifter dropdowns to eloqua picklists */
  syncEloquaTrack: Company
  /** Unsync uplifter dropdowns to eloqua picklists */
  unsyncEloquaTrack: Company
  /** Update an eloqua template */
  updateTemplate: EloquaTemplate
}

/** Oracle eloqua mutation operations */
export type TrackOracleEloquaMutationsSyncEloquaTrackArgs = {
  eloquaOrgID: Scalars['String']
  parameterIDList: Array<Scalars['String']>
}

/** Oracle eloqua mutation operations */
export type TrackOracleEloquaMutationsUnsyncEloquaTrackArgs = {
  eloquaOrgID: Scalars['String']
}

/** Oracle eloqua mutation operations */
export type TrackOracleEloquaMutationsUpdateTemplateArgs = {
  eloquaOrgID: Scalars['String']
  templateID: Scalars['String']
  updatedHTMLMessage: Scalars['String']
}

/** Root track queries */
export type TrackQueries = {
  __typename?: 'TrackQueries'
  accountID: Scalars['String']
  /** Returns a list of available Salesforce Pardot email templates */
  availableSalesforcePardotEmailTemplates: SalesforcePardotEmailTemplates
  /** Returns the campaign code generator for a given account */
  campaignCodeGeneratorAccount: CampaignCodeGenerator
  /** List of code versions for a given code */
  codeVersionHistory: CodeVersionHistory
  /** Returns the current sequential code ID for current account */
  currentSequentialCodeID: UplifterIdSequentialTotal
  /** All queries for deep linking */
  deepLinkQueries: DeepLinkQueries
  /** Returns the default settings for Pardot */
  defaultTrackEmailSettings: EmailTrackSettings
  /** All queries for Eloqua integration */
  eloquaQueries: EloquaQueries
  /** Returns a list of available Salesforce Pardot accounts from pardot */
  getAvailableSalesforcePardotAccounts: SalesforcePardotAccountSummary
  /** Returns a list of available Salesforce Pardot accounts saved to uplifter */
  getSavedPardotAcctList: SalesforcePardotAccountSummary
  /** Returns a list of validation results for a list of landing pages */
  getTrackValidationResultsByLandingPage: Array<Maybe<TrackValidationResult>>
  /** Returns an intensive validation of a landing page url - whether page exists */
  intensiveUrlValidation: IntensiveUrlValidationResults
  /** Returns a quick validation of a landing page url - whether page exists */
  quickUrlValidation: QuickUrlValidationResults
  /** All queries for Salesforce Marketing Cloud integration */
  salesforceMarketingCloudQueries: MarketingCloudQueries
  /** Returns the html and links for a series of given Salesforce Pardot email template */
  salesforcePardotEmailHTML: SalesforcePardotEmailTemplates
  /** Returns a list of validation results for a given track code */
  trackValidationResults: Array<Maybe<TrackValidationResult>>
  /** Returns a table of data for a given view */
  trackViewTable: ViewTable
  /** List of update requests made to update the generator & their status */
  updateRequestList: UpdateRequestList
  /** Tests/reserves a short link candidate */
  validateShortLinkCandidate: ShortLinkAvailable
}

/** Root track queries */
export type TrackQueriesAvailableSalesforcePardotEmailTemplatesArgs = {
  pardotID?: InputMaybe<Scalars['String']>
  templateLocation?: Scalars['String']
}

/** Root track queries */
export type TrackQueriesCampaignCodeGeneratorAccountArgs = {
  accountID: Scalars['String']
}

/** Root track queries */
export type TrackQueriesCodeVersionHistoryArgs = {
  codeID: Scalars['String']
}

/** Root track queries */
export type TrackQueriesGetTrackValidationResultsByLandingPageArgs = {
  includeQueryPars?: InputMaybe<Scalars['Boolean']>
  urlList: Array<Scalars['String']>
}

/** Root track queries */
export type TrackQueriesIntensiveUrlValidationArgs = {
  urlList: Array<Scalars['String']>
}

/** Root track queries */
export type TrackQueriesQuickUrlValidationArgs = {
  urlList: Array<Scalars['String']>
}

/** Root track queries */
export type TrackQueriesSalesforcePardotEmailHtmlArgs = {
  classicPardotTemplateList?: InputMaybe<
    Array<SalesforcePardotEmailTemplateInput>
  >
  lightningPardotTemplateList?: InputMaybe<
    Array<SalesforcePardotEmailTemplateInput>
  >
}

/** Root track queries */
export type TrackQueriesTrackValidationResultsArgs = {
  codeIDList: Array<Scalars['String']>
}

/** Root track queries */
export type TrackQueriesTrackViewTableArgs = {
  codeIDList?: InputMaybe<Array<Scalars['String']>>
  dimensionFilter?: InputMaybe<DimensionFilter>
  limit?: InputMaybe<Scalars['Int']>
  noReturn?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<SortFilter>
  refreshCache?: InputMaybe<Scalars['Boolean']>
}

/** Root track queries */
export type TrackQueriesValidateShortLinkCandidateArgs = {
  customDomainID?: InputMaybe<Scalars['String']>
  deepLinkServiceID?: InputMaybe<Scalars['String']>
  testCandidate: Scalars['String']
}

/** Salesforce MC mutation operations */
export type TrackSalesforceMcMutations = {
  __typename?: 'TrackSalesforceMCMutations'
  /** Update an salesforce marketing cloud template */
  updateTemplate: MarketingCloudEmailTemplate
}

/** Salesforce MC mutation operations */
export type TrackSalesforceMcMutationsUpdateTemplateArgs = {
  mid: Scalars['String']
  templateID: Scalars['String']
  textContent?: InputMaybe<Scalars['String']>
  updatedHTMLMessage?: InputMaybe<Scalars['String']>
  updatedSlotMap?: InputMaybe<Array<McSlotMapInput>>
}

/** Validation result for a given campaign code */
export type TrackValidationResult = {
  __typename?: 'TrackValidationResult'
  /** URL is obviously bad */
  badUrl: Scalars['Boolean']
  /** Campaign Code ID */
  campaignCodeID: Scalars['String']
  /** No analytics tag found */
  noAnalyticsTag: Scalars['Boolean']
  /** Landing page redirects */
  redirectedLandingPage: Scalars['Boolean']
  /** Landing page is slow */
  slowLandingPage: Scalars['Boolean']
  /** Status code of the landing page */
  statusCode: Scalars['Int']
}

/** An update request is a grouping of option requests by a single user. */
export type UpdateRequest = {
  __typename?: 'UpdateRequest'
  /** List of option update requests, their status. */
  changeRequests?: Maybe<Array<ChangeRequest>>
  /** flag showing whether all requests in this update request have been addressed */
  completed: Scalars['Boolean']
  /** ISO formatted date of datetime the request was first sent. */
  requestDate: Scalars['String']
  /** A unique request id within uplifter */
  requestID: Scalars['String']
  /** Note sent to admin by requester on first request */
  requestNote: Scalars['String']
  /** email address of user that requested update */
  requestedBy: Scalars['String']
  /** Last note that was sent to requester in last approval/rejection */
  reviewNote?: Maybe<Scalars['String']>
  /** email of user that last approved/rejected an option in the request. */
  reviewedBy?: Maybe<Scalars['String']>
  /** Account the request was originally requested */
  updateRequestAccountID: Scalars['String']
  /** Name of the account the request was originally requested */
  updateRequestAccountName: Scalars['String']
}

/** Grouping of update requests, their status */
export type UpdateRequestList = {
  __typename?: 'UpdateRequestList'
  /** Update requests where all updates have been addressed */
  completedRequests: Array<UpdateRequest>
  /** Admin dismissed requests (no further action required) */
  dismissedRequests: Array<UpdateRequest>
  /** Update requests that have options to be addressed */
  pendingRequests: Array<UpdateRequest>
}

/** Uplifter admin mutations */
export type UplifterAdminMutations = {
  __typename?: 'UplifterAdminMutations'
  /** Add a new enterprise client to the Uplifter platform */
  createEnterpriseCompany: Company
  /** Update an existing enterprise client on the Uplifter platform */
  updateEnterpriseCompany: Company
}

/** Uplifter admin mutations */
export type UplifterAdminMutationsCreateEnterpriseCompanyArgs = {
  accountManagerList: Array<GenericEmailInput>
  auditAvailable: Scalars['Boolean']
  autoRenew: Scalars['Boolean']
  breakClauseDate?: InputMaybe<Scalars['String']>
  businessDivision: Scalars['String']
  companyName: Scalars['String']
  companyNotes: Scalars['String']
  contractEndDate: Scalars['String']
  contractStartDate: Scalars['String']
  customTandC: Scalars['Boolean']
  dataSourceType: Scalars['String']
  domainLimit: Scalars['Int']
  domains: Scalars['String']
  explainAvailable: Scalars['Boolean']
  legalEntityName: Scalars['String']
  reportAvailable: Scalars['Boolean']
  revenuePerMonth: Scalars['Float']
  revenuePerMonthPostBreakClause?: InputMaybe<Scalars['Float']>
  revenuePerMonthPreBreakClause?: InputMaybe<Scalars['Float']>
  sponsorList: Array<GenericEmailInput>
  totalContractValue: Scalars['Float']
  trackAvailable: Scalars['Boolean']
  userLimit: Scalars['Int']
  whitelabel?: InputMaybe<Scalars['String']>
}

/** Uplifter admin mutations */
export type UplifterAdminMutationsUpdateEnterpriseCompanyArgs = {
  accountManagerList?: InputMaybe<Array<GenericEmailInput>>
  auditAvailable?: InputMaybe<Scalars['Boolean']>
  autoRenew?: InputMaybe<Scalars['Boolean']>
  breakClauseDate?: InputMaybe<Scalars['String']>
  businessDivision?: InputMaybe<Scalars['String']>
  companyID: Scalars['String']
  companyName?: InputMaybe<Scalars['String']>
  companyNotes?: InputMaybe<Scalars['String']>
  contractEndDate?: InputMaybe<Scalars['String']>
  contractStartDate?: InputMaybe<Scalars['String']>
  customTandC?: InputMaybe<Scalars['Boolean']>
  dataSourceType?: InputMaybe<Scalars['String']>
  domainLimit?: InputMaybe<Scalars['Int']>
  domains?: InputMaybe<Scalars['String']>
  explainAvailable?: InputMaybe<Scalars['Boolean']>
  legalEntityName?: InputMaybe<Scalars['String']>
  reportAvailable?: InputMaybe<Scalars['Boolean']>
  revenuePerMonth?: InputMaybe<Scalars['Float']>
  revenuePerMonthPostBreakClause?: InputMaybe<Scalars['Float']>
  revenuePerMonthPreBreakClause?: InputMaybe<Scalars['Float']>
  sponsorList?: InputMaybe<Array<GenericEmailInput>>
  totalContractValue?: InputMaybe<Scalars['Float']>
  trackAvailable?: InputMaybe<Scalars['Boolean']>
  userLimit?: InputMaybe<Scalars['Int']>
}

/** Current campaign code Sequential total */
export type UplifterIdSequentialTotal = {
  __typename?: 'UplifterIDSequentialTotal'
  accountID: Scalars['String']
  /** first four characters of the account ID (or shared track account ID) */
  acctPrefix: Scalars['String']
  /** Current sequential total for the account */
  currentTotal: Scalars['String']
  /** etag for the current sequential total */
  etag: Scalars['String']
  /** Whether sequential total is enabled not returned on update (just the etag) */
  isEnabled?: Maybe<Scalars['Boolean']>
  /** parameter prefix default=(up_id=) not returned on update (just the etag) */
  prefix?: Maybe<Scalars['String']>
  /** Updated sequential total for the account */
  updatedTotal?: Maybe<Scalars['String']>
}

/** Represents a user within uplifter.  Provides less fields than CurrentUser for privacy */
export type User = {
  __typename?: 'User'
  /** ISO formatted date user was first added */
  created: Scalars['String']
  /** User's email address they use to sign in */
  email: Scalars['String']
  /** User's first name */
  firstName: Scalars['String']
  /** A concatenation of first and last name */
  fullName: Scalars['String']
  /** A flag determining whether user has completed registration */
  inviteOpen: Scalars['Boolean']
  /** User's last name */
  lastName: Scalars['String']
  /** URL slug to take the user to when they log in */
  preferredHomepage: Scalars['String']
  /** A link to blob storage to display an image of the user */
  profileImgLink?: Maybe<Scalars['String']>
  /** User's job description which is publically accessible (within uplifter) */
  publicJobTitle: Scalars['String']
  /** Users telephone number, if provided */
  telNum: Scalars['String']
  /** A flag determining whether user is an internal user */
  uplifterAdmin: Scalars['Boolean']
  /** List of user account profiles accessible to the user. */
  userAccountProfiles: Array<UserAccountProfile>
  /** Unique user ID within uplifter */
  userID: Scalars['String']
  /** Username to display to other users when they might be shared between accounts. */
  userName: Scalars['String']
  /** A flag of which whitelabel the admin user might be. null if not an admin */
  whitelabelAdmin?: Maybe<Scalars['String']>
}

/** A representation of a user's relationship to an account within uplifter */
export type UserAccountProfile = {
  __typename?: 'UserAccountProfile'
  /** Account ID of the user profile */
  accountID: Scalars['String']
  /** Account name of the accountID */
  accountName: Scalars['String']
  /** email address of user that created this users access to the account */
  addedByEmail: Scalars['String']
  /** User ID that added this profile */
  addedByID: Scalars['String']
  /** Flag determining whether the user receives audit emails for this account. */
  auditEmail: Scalars['Boolean']
  /** Company ID of the company that owns this account */
  companyID: Scalars['String']
  /** Name of the company that owns this account */
  companyName: Scalars['String']
  /** date user was added */
  created: Scalars['String']
  /** whether the users invite has expired */
  expired: Scalars['Boolean']
  /** Flag determining whether user receives explain emails for this account */
  explainEmail: Scalars['Boolean']
  /** homepage of account, used for favicon */
  homepage: Scalars['String']
  /** Whether the user has opened the invite email */
  inviteOpen: Scalars['Boolean']
  /** Date user last logged in or switched to this account */
  lastLogin: Scalars['String']
  /** Date last altered in ISO format */
  lastUpdated: Scalars['String']
  /** email address of user that last updated this user */
  lastUpdatedByEmail: Scalars['String']
  /** User ID of user that last updated this profile */
  lastUpdatedByID: Scalars['String']
  /** Flag determining user receives report emails for this account */
  reportEmail: Scalars['Boolean']
  /** Flag determining whether user receives track emails for this account */
  trackEmail: Scalars['Boolean']
  /** User's email address */
  userEmail: Scalars['String']
  /** Unique user ID within uplifter */
  userID: Scalars['String']
  /** One of admin|regular|support */
  userPermission: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettings = {
  __typename?: 'UserAccountSettings'
  /** Add a new account to the current company */
  addAccount: Account
  /** Add a new okta domain to the current company */
  addOktaDomain: OktaDomain
  /** Adds/invites a single user to many accounts */
  addUserToAccountBulk: User
  /** Mutations for Adobe Analytics integration */
  adobeAnalyticsMutations: AdobeAnalyticsMutations
  /** Associate a G Suite account with an uplifter user, so they can use SSO. */
  associateGsuiteAccount: CurrentUser
  /** Associate a Microsoft account with an uplifter user, so they can use SSO. */
  associateO365Account: CurrentUser
  /** Associate an Okta account with an uplifter user, so they can use SSO. */
  associateOktaAccount: CurrentUser
  /** Mutations for billing */
  billingMutations: BillingMutations
  /** Change current account */
  changeCurrentAccount: LoginToken
  /** Close company - deletes all the tables for an account */
  closeCompany: Scalars['Boolean']
  /** Connect a Adobe Analytics datasource to an account */
  connectAccountDatasourceAA: Account
  /** Connect a Google Analytics datasource to an account */
  connectAccountDatasourceGA: Account
  /** Connect a Google Analytics 4 datasource to an account */
  connectAccountDatasourceGA4: Account
  /** Create a new public API key for integrating with the apiat https://access.uplifter.tech */
  createPublicAPIKey: Company
  /** Delete an account from the current company */
  deleteAccount: RemoveAccountResult
  /** Delete an okta domain */
  deleteOktaDomain: OktaDomain
  /** Mutations for Google Analytics integration */
  googleAnalyticsMutations: GoogleAnalyticsMutations
  /** Make a user a whitelabel/uplifterAdmin */
  makeUserSupportUser: User
  /** Mutations for Monday.com integration */
  mondayMutations: MondayMutations
  /** Mutations for Oracle Eloqua integration */
  oracleEloquaMutations: OracleEloquaMutations
  /** Remove user from account */
  removeUser: Account
  /** Revoke registration - user won't be able to register to the invited account */
  revokeUserInvite: Scalars['Boolean']
  /** Mutations for Salesforce mc integration */
  salesforceMCMutations: SalesforceMcMutations
  /** Mutations for Salesforce Pardot integration */
  salesforcePardotMutations: SalesforcePardotMutations
  /** Save GCM credentials to the current account */
  saveGCMAuth: Account
  /** Sends user feedback by email to support@uplifter.ai */
  sendFeedback: CurrentUser
  /** Toggle email notifications */
  toggleEmailNotifications: CurrentUser
  /** Toggle module availability for an account */
  toggleModuleAvailability: CompanyAccount
  /** Update account name */
  updateAccountDescription: Account
  /** Update adobe FTP settings */
  updateAdobeFTPSettings: AdobeFtpAccount
  /** Update company internal contact */
  updateCompanyInternalContact: Company
  /** Update company name */
  updateCompanyName: Company
  /** Update preference for marketing */
  updateMarketingPermission: CurrentUser
  /** Update an okta domain */
  updateOktaDomain: OktaDomain
  /** Update uplifter admin settings */
  updateUplifterAdminSettings: UplifterAdminMutations
  /** Update email, name, last name and phone number */
  updateUser: CurrentUser
  /** Update user permissions for a given account */
  updateUserPermissionLevel: User
}

/** account settings mutations */
export type UserAccountSettingsAddAccountArgs = {
  accountName: Scalars['String']
  copyAccountLogoID?: InputMaybe<Scalars['String']>
  copyGeneratorAccountID?: InputMaybe<Scalars['String']>
  copyQRLogoAccountID?: InputMaybe<Scalars['String']>
  copyQRSettingsAccountID?: InputMaybe<Scalars['String']>
}

/** account settings mutations */
export type UserAccountSettingsAddOktaDomainArgs = {
  clientID: Scalars['String']
  clientSecret: Scalars['String']
  emailDomain: Scalars['String']
  tenantDomain: Scalars['String']
  tenantName: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettingsAddUserToAccountBulkArgs = {
  accountIDList: Array<Scalars['String']>
  email: Scalars['String']
  permissionLevelList: Array<Scalars['String']>
}

/** account settings mutations */
export type UserAccountSettingsAssociateGsuiteAccountArgs = {
  googleKey: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettingsAssociateO365AccountArgs = {
  microsoftKey: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettingsAssociateOktaAccountArgs = {
  oktaKey: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettingsChangeCurrentAccountArgs = {
  accountID: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettingsCloseCompanyArgs = {
  companyID: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettingsConnectAccountDatasourceAaArgs = {
  accountID: Scalars['String']
  creds: Scalars['String']
  dataSourceDesc: AaDescInput
}

/** account settings mutations */
export type UserAccountSettingsConnectAccountDatasourceGaArgs = {
  accountID: Scalars['String']
  creds: Scalars['String']
  dataSourceDesc: GaDescInput
}

/** account settings mutations */
export type UserAccountSettingsConnectAccountDatasourceGa4Args = {
  accountID: Scalars['String']
  creds: Scalars['String']
  dataSourceDesc: Ga4DescInput
}

/** account settings mutations */
export type UserAccountSettingsDeleteAccountArgs = {
  accountID: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettingsDeleteOktaDomainArgs = {
  clientID: Scalars['String']
  emailDomain: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettingsMakeUserSupportUserArgs = {
  email: Scalars['String']
  permissionLevel: Scalars['String']
  whitelabel?: InputMaybe<Scalars['String']>
}

/** account settings mutations */
export type UserAccountSettingsRemoveUserArgs = {
  accountID: Scalars['String']
  userID: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettingsRevokeUserInviteArgs = {
  userID: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettingsSaveGcmAuthArgs = {
  code: Scalars['String']
  state: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettingsSendFeedbackArgs = {
  feedbackContext: FeedbackContext
  message: Scalars['String']
  page: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettingsToggleEmailNotificationsArgs = {
  accountID: Scalars['String']
  audit?: InputMaybe<Scalars['Boolean']>
  explain?: InputMaybe<Scalars['Boolean']>
  report?: InputMaybe<Scalars['Boolean']>
  track?: InputMaybe<Scalars['Boolean']>
}

/** account settings mutations */
export type UserAccountSettingsToggleModuleAvailabilityArgs = {
  accountID?: InputMaybe<Scalars['String']>
  applyToCompany: Scalars['Boolean']
  auditModule?: InputMaybe<Scalars['Boolean']>
  companyID?: InputMaybe<Scalars['String']>
  explainModule?: InputMaybe<Scalars['Boolean']>
  reportModule?: InputMaybe<Scalars['Boolean']>
  trackModule?: InputMaybe<Scalars['Boolean']>
}

/** account settings mutations */
export type UserAccountSettingsUpdateAccountDescriptionArgs = {
  accountID: Scalars['String']
  trackLearnCopy?: InputMaybe<Scalars['String']>
  updatedAccountDomain?: InputMaybe<Scalars['String']>
  updatedAccountName?: InputMaybe<Scalars['String']>
}

/** account settings mutations */
export type UserAccountSettingsUpdateAdobeFtpSettingsArgs = {
  adobeFTPHost?: InputMaybe<Scalars['String']>
  adobeFTPLogin?: InputMaybe<Scalars['String']>
  adobeFTPPassword?: InputMaybe<Scalars['String']>
  relationID?: InputMaybe<Scalars['String']>
  reportID?: InputMaybe<Scalars['String']>
}

/** account settings mutations */
export type UserAccountSettingsUpdateCompanyInternalContactArgs = {
  newEmail?: InputMaybe<Scalars['String']>
  newName?: InputMaybe<Scalars['String']>
}

/** account settings mutations */
export type UserAccountSettingsUpdateCompanyNameArgs = {
  newName: Scalars['String']
}

/** account settings mutations */
export type UserAccountSettingsUpdateMarketingPermissionArgs = {
  allowMarketing: Scalars['Boolean']
}

/** account settings mutations */
export type UserAccountSettingsUpdateOktaDomainArgs = {
  clientID: Scalars['String']
  clientSecret?: InputMaybe<Scalars['String']>
  emailDomain: Scalars['String']
  tenantName?: InputMaybe<Scalars['String']>
}

/** account settings mutations */
export type UserAccountSettingsUpdateUserArgs = {
  firstName?: InputMaybe<Scalars['String']>
  jobTitle?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

/** account settings mutations */
export type UserAccountSettingsUpdateUserPermissionLevelArgs = {
  accountID: Scalars['String']
  permissionLevel: Scalars['String']
  userID: Scalars['String']
}

/** Onboarding steps specific to the user */
export type UserOnboarding = {
  __typename?: 'UserOnboarding'
  /** List of onboarding step progress */
  userOnboardingProgressList: Array<OnboardingStep>
}

/** Validation check to apply to a code before it is added. */
export type ValidationCheck = {
  __typename?: 'ValidationCheck'
  /** Whether to apply this validation check */
  enabled: Scalars['Boolean']
  /** Name of validation check to apply */
  name: Scalars['String']
  /** Where a value should be replaced, this is the value to replace with. */
  value?: Maybe<Scalars['String']>
}

/** specific track view table object */
export type ViewTable = {
  __typename?: 'ViewTable'
  /** List of rows for the campaign code table */
  rows: Array<ViewTableRow>
}

/** specific track view table object */
export type ViewTableRow = {
  __typename?: 'ViewTableRow'
  /** Full generated link */
  campaignLink: Scalars['String']
  /** Campaign Code ID */
  codeID: Scalars['String']
  /** Email address who created the link */
  createdBy: Scalars['String']
  /** Time the link was created */
  createdTime: Scalars['String']
  /** List of metric values */
  metricValues?: Maybe<Array<ViewTableRowMetricValue>>
  /** Shortened link */
  shortLink: Scalars['String']
  /** Status of the link Live, ended new unused etc */
  status: Scalars['String']
}

/** specific track view table object */
export type ViewTableRowMetricValue = {
  __typename?: 'ViewTableRowMetricValue'
  /** Name of the metric */
  metricName: Scalars['String']
  /** Value of the metric */
  metricValue: Scalars['String']
}

export type LogActionMutationVariables = Exact<{
  action: Scalars['String']
  functionName: Scalars['String']
  pagePath: Scalars['String']
  websiteSection: Scalars['String']
  extra?: InputMaybe<Scalars['String']>
  getParams?: InputMaybe<Scalars['String']>
  requestBody?: InputMaybe<Scalars['String']>
}>

export type LogActionMutation = {
  __typename?: 'Mutation'
  authenticatedLogAction: {
    __typename?: 'LogAction'
    acctID: string
    userID: string
    action: string
  }
}

export type SwitchAccountMutationVariables = Exact<{
  workspaceID: Scalars['String']
}>

export type SwitchAccountMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    changeCurrentAccount: { __typename?: 'LoginToken'; token: string }
  }
}

export type GetCompanyDetailsQueryVariables = Exact<{ [key: string]: never }>

export type GetCompanyDetailsQuery = {
  __typename?: 'Query'
  currentCompany: {
    __typename?: 'Company'
    accountCount: number
    companyID: string
    subscriptionLevel: string
    paddleSubscriptionStatus?: string | null
    internalContactName?: string | null
    internalContactEmail?: string | null
    microsoftSubscriptionId?: string | null
    paymentProcessor?: string | null
    userCount: number
    userLimit?: number | null
  }
}

export type GetPublicApiTokenQueryVariables = Exact<{ [key: string]: never }>

export type GetPublicApiTokenQuery = {
  __typename?: 'Query'
  currentCompany: {
    __typename?: 'Company'
    companyID: string
    publicApiKey?: string | null
  }
}

export type CreatePublicApiTokenMutationVariables = Exact<{
  [key: string]: never
}>

export type CreatePublicApiTokenMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    createPublicAPIKey: {
      __typename?: 'Company'
      companyID: string
      publicApiKey?: string | null
    }
  }
}

export type UpdateCompanyInternalContactMutationVariables = Exact<{
  newEmail?: InputMaybe<Scalars['String']>
  newName?: InputMaybe<Scalars['String']>
}>

export type UpdateCompanyInternalContactMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    updateCompanyInternalContact: {
      __typename?: 'Company'
      companyID: string
      internalContactName?: string | null
      internalContactEmail?: string | null
    }
  }
}

export type AddNewWorkspaceMutationVariables = Exact<{
  workspaceName: Scalars['String']
  copyGeneratorAccountID?: InputMaybe<Scalars['String']>
  copyQRSettingsAccountID?: InputMaybe<Scalars['String']>
  copyQRLogoAccountID?: InputMaybe<Scalars['String']>
  copyAccountLogoID?: InputMaybe<Scalars['String']>
}>

export type AddNewWorkspaceMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    addAccount: {
      __typename?: 'Account'
      accountID: string
      accountName: string
      companyID: string
      companyName: string
      created: string
      explainAvailable: boolean
      trackAvailable: boolean
      auditAvailable: boolean
      reportAvailable: boolean
      homepage: string
      isDemo: boolean
    }
  }
}

export type UpdateCompanyNameMutationVariables = Exact<{
  newName: Scalars['String']
}>

export type UpdateCompanyNameMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    updateCompanyName: {
      __typename?: 'Company'
      companyID: string
      companyName: string
    }
  }
}

export type UpdateAccountDescriptionMutationVariables = Exact<{
  accountID: Scalars['String']
  updatedAccountDomain?: InputMaybe<Scalars['String']>
  updatedAccountName?: InputMaybe<Scalars['String']>
  trackLearnCopy?: InputMaybe<Scalars['String']>
}>

export type UpdateAccountDescriptionMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    updateAccountDescription: {
      __typename?: 'Account'
      accountID: string
      homepage: string
      accountName: string
      trackLearnCopy?: string | null
    }
  }
}

export type GetCurrentAccountQrDetailsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetCurrentAccountQrDetailsQuery = {
  __typename?: 'Query'
  currentAccount: {
    __typename?: 'Account'
    accountID: string
    qrCodeLogoList: Array<string>
    logoLink: string
    qrSettings?: Array<{
      __typename?: 'GenericSetting'
      enabled?: boolean | null
      name: string
      value?: string | null
    }> | null
  }
}

export type GetQrLogoImageQueryVariables = Exact<{
  blobName: Scalars['String']
}>

export type GetQrLogoImageQuery = {
  __typename?: 'Query'
  currentAccount: {
    __typename?: 'Account'
    accountID: string
    qrCodeLogoSrc: string
  }
}

export type DeleteQrCodeImageMutationVariables = Exact<{
  blobName: Scalars['String']
}>

export type DeleteQrCodeImageMutation = {
  __typename?: 'Mutation'
  deleteQrCodeImage: {
    __typename?: 'Account'
    accountID: string
    qrCodeLogoList: Array<string>
  }
}

export type UpdateQrSettingsMutationVariables = Exact<{
  qrSettings: Array<GenericSettingInput> | GenericSettingInput
}>

export type UpdateQrSettingsMutation = {
  __typename?: 'Mutation'
  updateQrSettings: {
    __typename?: 'Account'
    accountID: string
    qrSettings?: Array<{
      __typename?: 'GenericSetting'
      enabled?: boolean | null
      value?: string | null
      name: string
    }> | null
  }
}

export type GetPaddleSubscriptionDetailsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetPaddleSubscriptionDetailsQuery = {
  __typename?: 'Query'
  currentCompany: {
    __typename?: 'Company'
    companyID: string
    paddleCancelUrl?: string | null
    paddleSubscriptionCheckoutId?: string | null
    paddleSubscriptionCurrency?: string | null
    paddleSubscriptionEmail?: string | null
    paddleSubscriptionId?: string | null
    paddleSubscriptionLastAlertName?: string | null
    paddleSubscriptionLastAlertId?: string | null
    paddleSubscriptionLastEventTime?: string | null
    paddleSubscriptionLastCustomData?: string | null
    paddleSubscriptionLinkedSubscriptions?: string | null
    paddleSubscriptionMarketingConsent?: string | null
    paddleSubscriptionNextBillDate?: string | null
    paddleSubscriptionPlanId?: string | null
    paddleSubscriptionQuantity?: string | null
    paddleSubscriptionSource?: string | null
    paddleSubscriptionStatus?: string | null
    paddleSubscriptionUnitPrice?: string | null
    paddleSubscriptionUserId?: string | null
    paddleUpdateUrl?: string | null
    subscriptionLevel: string
  }
}

export type UpdatePaddleSeatsQuantityMutationVariables = Exact<{
  companyID: Scalars['String']
  updatedQuantity: Scalars['Int']
  paddleEnv?: InputMaybe<Scalars['String']>
}>

export type UpdatePaddleSeatsQuantityMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    billingMutations: {
      __typename?: 'BillingMutations'
      updatePaddleQuantity: {
        __typename?: 'Company'
        companyID: string
        paddleSubscriptionCheckoutId?: string | null
        paddleSubscriptionCurrency?: string | null
        paddleSubscriptionEmail?: string | null
        paddleSubscriptionId?: string | null
        paddleSubscriptionLastAlertId?: string | null
        paddleSubscriptionLastAlertName?: string | null
        paddleSubscriptionLastCustomData?: string | null
        paddleSubscriptionLastEventTime?: string | null
        paddleSubscriptionNextBillDate?: string | null
        paddleSubscriptionLinkedSubscriptions?: string | null
        paddleSubscriptionMarketingConsent?: string | null
        paddleSubscriptionPlanId?: string | null
        paddleSubscriptionQuantity?: string | null
        paddleSubscriptionSource?: string | null
        paddleSubscriptionStatus?: string | null
        paddleSubscriptionUnitPrice?: string | null
        paddleSubscriptionUserId?: string | null
        paddleUpdateUrl?: string | null
      }
    }
  }
}

export type GetEnterpriseBillingDetailGqlQueryVariables = Exact<{
  [key: string]: never
}>

export type GetEnterpriseBillingDetailGqlQuery = {
  __typename?: 'Query'
  accountSettings: {
    __typename?: 'AccountSettingsQueries'
    enterpriseBillingDetail: {
      __typename?: 'EnterpriseBillingDetail'
      auditAvailable: boolean
      autoRenew: boolean
      billingUserList: string
      businessDivision: string
      contractEndDate: string
      companyName: string
      contractStartDate: string
      customTandC: boolean
      domains: string
      domainLimit: number
      explainAvailable: boolean
      legalEntityName: string
      origDataSource?: string | null
      reportAvailable: boolean
      totalContractValue: string
      trackAvailable: boolean
      userLimit: number
      sponsorList: Array<{
        __typename?: 'EnterpriseBillingUser'
        email: string
        name: string
      }>
      accountManagerList: Array<{
        __typename?: 'EnterpriseBillingUser'
        email: string
        name: string
      }>
    }
  }
}

export type DeleteCompanyMutationVariables = Exact<{
  companyID: Scalars['String']
}>

export type DeleteCompanyMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    closeCompany: boolean
  }
}

export type GetMicrosoftSubscriptionDataQueryVariables = Exact<{
  [key: string]: never
}>

export type GetMicrosoftSubscriptionDataQuery = {
  __typename?: 'Query'
  currentCompany: {
    __typename?: 'Company'
    companyID: string
    microsoftSubscriptionData?: {
      __typename?: 'MicrosoftSubscriptionData'
      subscriptionID: string
      currency: string
      planId: string
      planName: string
      periodStartDate: string
      periodEndDate: string
      price: string
      quantity: number
      status: string
      termDescription: string
      termUnit: string
    } | null
  }
}

export type ListAzureMarketplaceQueriesQueryVariables = Exact<{
  [key: string]: never
}>

export type ListAzureMarketplaceQueriesQuery = {
  __typename?: 'Query'
  accountSettings: {
    __typename?: 'AccountSettingsQueries'
    azureMarketplaceQueries: {
      __typename?: 'AzureMarketplaceQueries'
      listPlans: Array<{
        __typename?: 'AzureMarketplacePlan'
        description: string
        hasFreeTrials: boolean
        displayName: string
        isPricePerSeat: boolean
        maxQuantity?: number | null
        isStopSell: boolean
        minQuantity?: number | null
        planId: string
        billingTerms: Array<{
          __typename?: 'AzureMarketplaceBillingTerm'
          currency: string
          price: number
          termDescription: string
          termUnit: string
        }>
      }>
    }
  }
}

export type ChangeMicrosoftMarketplacePlanMutationVariables = Exact<{
  companyID: Scalars['String']
  newPlanID: Scalars['String']
}>

export type ChangeMicrosoftMarketplacePlanMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    billingMutations: {
      __typename?: 'BillingMutations'
      changeMicrosoftMarketplacePlan: {
        __typename?: 'Company'
        companyID: string
        paymentProcessor?: string | null
      }
    }
  }
}

export type UpdateMicrosoftMarketplaceSeatsMutationVariables = Exact<{
  companyID: Scalars['String']
  totalSeats: Scalars['Int']
}>

export type UpdateMicrosoftMarketplaceSeatsMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    billingMutations: {
      __typename?: 'BillingMutations'
      updateMicrosoftMarketplaceSeats: {
        __typename?: 'Company'
        companyID: string
        paymentProcessor?: string | null
      }
    }
  }
}

export type CancelMicrosoftMarketplaceSubscriptionMutationVariables = Exact<{
  companyID: Scalars['String']
}>

export type CancelMicrosoftMarketplaceSubscriptionMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    billingMutations: {
      __typename?: 'BillingMutations'
      cancelMicrosoftMarketplaceSubscription: {
        __typename?: 'Company'
        companyID: string
      }
    }
  }
}

export type GetCompanyAccountsAndUsersQueryVariables = Exact<{
  [key: string]: never
}>

export type GetCompanyAccountsAndUsersQuery = {
  __typename?: 'Query'
  currentCompany: {
    __typename?: 'Company'
    companyID: string
    accountList: Array<{
      __typename?: 'Account'
      accountName: string
      accountID: string
      userAccountProfiles: Array<{
        __typename?: 'UserAccountProfile'
        userEmail: string
        userID: string
      }>
    }>
  }
}

export type GetIntegrationsStatusQueryVariables = Exact<{
  [key: string]: never
}>

export type GetIntegrationsStatusQuery = {
  __typename?: 'Query'
  currentCompany: {
    __typename?: 'Company'
    companyID: string
    pardotIntegrationStatus: string
    mondayIntegrationStatus: string
    eloquaIntegrationStatus: string
    salesforceMcIntegrationStatus: string
    salesforceMcIntegrationList: Array<{
      __typename?: 'SalesforceMCAPIKey'
      mid: string
      businessUnitName: string
    }>
  }
}

export type GetOktaDomainsQueryVariables = Exact<{ [key: string]: never }>

export type GetOktaDomainsQuery = {
  __typename?: 'Query'
  currentCompany: {
    __typename?: 'Company'
    companyID: string
    oktaDomainList: {
      __typename?: 'OktaDomainList'
      oktaDomains: Array<{
        __typename?: 'OktaDomain'
        clientID: string
        dateCreated: string
        emailDomain: string
        loginLink: string
        tenantDomain: string
        tenantName: string
      }>
    }
  }
}

export type AddOktaDomainMutationVariables = Exact<{
  clientID: Scalars['String']
  clientSecret: Scalars['String']
  emailDomain: Scalars['String']
  tenantName: Scalars['String']
  tenantDomain: Scalars['String']
}>

export type AddOktaDomainMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    addOktaDomain: {
      __typename?: 'OktaDomain'
      clientID: string
      emailDomain: string
      loginLink: string
      tenantName: string
      dateCreated: string
      tenantDomain: string
    }
  }
}

export type UpdateOktaDomainMutationVariables = Exact<{
  clientID: Scalars['String']
  emailDomain: Scalars['String']
  tenantName?: InputMaybe<Scalars['String']>
  clientSecret?: InputMaybe<Scalars['String']>
}>

export type UpdateOktaDomainMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    updateOktaDomain: {
      __typename?: 'OktaDomain'
      clientID: string
      emailDomain: string
      loginLink: string
      tenantName: string
      dateCreated: string
      tenantDomain: string
    }
  }
}

export type DeleteOktaDomainMutationVariables = Exact<{
  clientID: Scalars['String']
  emailDomain: Scalars['String']
}>

export type DeleteOktaDomainMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    deleteOktaDomain: {
      __typename?: 'OktaDomain'
      clientID: string
      emailDomain: string
      loginLink: string
      tenantName: string
      dateCreated: string
      tenantDomain: string
    }
  }
}

export type GetMondayOauthLinkQueryVariables = Exact<{
  state: Scalars['String']
}>

export type GetMondayOauthLinkQuery = {
  __typename?: 'Query'
  accountSettings: {
    __typename?: 'AccountSettingsQueries'
    mondayQueries: {
      __typename?: 'MondayQueries'
      genMondayoauth2Link: { __typename?: 'Oauth2Link'; oauth2Link: string }
    }
  }
}

export type SaveMondayOAuthMutationVariables = Exact<{
  code: Scalars['String']
}>

export type SaveMondayOAuthMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    mondayMutations: {
      __typename?: 'MondayMutations'
      saveMondayAuth: {
        __typename?: 'MondayWorkspaces'
        mondayWorkspaces: Array<{
          __typename?: 'MondayWorkspace'
          mondayWorkspaceDescription?: string | null
          mondayWorkspaceId: string
          mondayWorkspaceName: string
        }>
      }
    }
  }
}

export type GetMondayWorkspacesQueryVariables = Exact<{ [key: string]: never }>

export type GetMondayWorkspacesQuery = {
  __typename?: 'Query'
  accountSettings: {
    __typename?: 'AccountSettingsQueries'
    mondayQueries: {
      __typename?: 'MondayQueries'
      getWorkspaces: {
        __typename?: 'MondayWorkspaces'
        mondayWorkspaces: Array<{
          __typename?: 'MondayWorkspace'
          mondayWorkspaceDescription?: string | null
          mondayWorkspaceId: string
          mondayWorkspaceName: string
        }>
      }
    }
  }
}

export type CreateMondayBoardMutationVariables = Exact<{
  workspaceID: Scalars['String']
  workspaceName: Scalars['String']
}>

export type CreateMondayBoardMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    mondayMutations: {
      __typename?: 'MondayMutations'
      mondayCreateBoard: {
        __typename?: 'MondayIDs'
        mondayUrlSlug: string
        mondayWorkspaceId: string
        mondayWorkspaceName: string
        mondayBoardId: string
        mondayBoardName: string
        mondayGroupId: string
        mondayGroupName: string
      }
    }
  }
}

export type GetMondayIDsQueryVariables = Exact<{ [key: string]: never }>

export type GetMondayIDsQuery = {
  __typename?: 'Query'
  accountSettings: {
    __typename?: 'AccountSettingsQueries'
    mondayQueries: {
      __typename?: 'MondayQueries'
      getMondayIds: {
        __typename?: 'MondayIDs'
        mondayUrlSlug: string
        mondayBoardId: string
        mondayBoardName: string
        mondayGroupId: string
        mondayGroupName: string
        mondayWorkspaceId: string
        mondayWorkspaceName: string
      }
    }
  }
}

export type RemoveMondayIntegrationMutationVariables = Exact<{
  [key: string]: never
}>

export type RemoveMondayIntegrationMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    mondayMutations: {
      __typename?: 'MondayMutations'
      mondayRemoveIntegration: boolean
    }
  }
}

export type GetSalesforceOauthLinkQueryVariables = Exact<{
  state: Scalars['String']
}>

export type GetSalesforceOauthLinkQuery = {
  __typename?: 'Query'
  accountSettings: {
    __typename?: 'AccountSettingsQueries'
    dataSourceQueries: {
      __typename?: 'DataSourceQueries'
      genSalesforceOauthLink: { __typename?: 'Oauth2Link'; oauth2Link: string }
    }
  }
}

export type HandleSalesforcePardotCallbackMutationVariables = Exact<{
  code: Scalars['String']
  encryptedState: Scalars['String']
}>

export type HandleSalesforcePardotCallbackMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    salesforcePardotMutations: {
      __typename?: 'SalesforcePardotMutations'
      handleSalesforcePardotCallback: {
        __typename?: 'SalesforcePardotAccountSummary'
        pardotList: Array<{
          __typename?: 'PardotAccount'
          pardotID: string
          pardotName: string
          pardotDisplayName: string
        }>
      }
    }
  }
}

export type GetAvailableSalesforcePardotAccountsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetAvailableSalesforcePardotAccountsQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    getAvailableSalesforcePardotAccounts: {
      __typename?: 'SalesforcePardotAccountSummary'
      pardotList: Array<{
        __typename?: 'PardotAccount'
        pardotDisplayName: string
        pardotID: string
        pardotName: string
      }>
    }
  }
}

export type GetSavedPardotAcctListQueryVariables = Exact<{
  [key: string]: never
}>

export type GetSavedPardotAcctListQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    getSavedPardotAcctList: {
      __typename?: 'SalesforcePardotAccountSummary'
      pardotList: Array<{
        __typename?: 'PardotAccount'
        pardotDisplayName: string
        pardotID: string
        pardotName: string
      }>
    }
  }
}

export type SetSavedPardotAccountsMutationVariables = Exact<{
  pardotAcctList: Array<PardotAccountInput> | PardotAccountInput
}>

export type SetSavedPardotAccountsMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    salesforcePardotMutations: {
      __typename?: 'SalesforcePardotMutations'
      savePardotAccountList: {
        __typename?: 'SalesforcePardotAccountSummary'
        pardotList: Array<{
          __typename?: 'PardotAccount'
          pardotDisplayName: string
          pardotID: string
          pardotName: string
        }>
      }
    }
  }
}

export type GetSalesforceEmailTemplatesQueryVariables = Exact<{
  pardotID?: InputMaybe<Scalars['String']>
  templateLocation: Scalars['String']
}>

export type GetSalesforceEmailTemplatesQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    availableSalesforcePardotEmailTemplates: {
      __typename?: 'SalesforcePardotEmailTemplates'
      classicBuilder: Array<{
        __typename?: 'SalesforcePardotEmailTemplateDef'
        uiType: string
        pardotName?: string | null
        pardotID?: string | null
        pardotCampaignID?: string | null
        isClassicListEmail: boolean
        emailSubject: string
        emailName: string
        emailLinks?: Array<string> | null
        emailID: string
        emailHTML?: string | null
        directLink: string
        dateUpdated: string
        dateAdded: string
      }>
      lightningBuilder: Array<{
        __typename?: 'SalesforcePardotEmailTemplateDef'
        dateAdded: string
        dateUpdated: string
        directLink: string
        emailHTML?: string | null
        emailID: string
        emailLinks?: Array<string> | null
        emailName: string
        isClassicListEmail: boolean
        emailSubject: string
        pardotCampaignID?: string | null
        pardotID?: string | null
        pardotName?: string | null
        uiType: string
      }>
    }
  }
}

export type GetSalesforceEmailHtmlQueryVariables = Exact<{
  classicPardotTemplateList?: InputMaybe<
    | Array<SalesforcePardotEmailTemplateInput>
    | SalesforcePardotEmailTemplateInput
  >
  lightningPardotTemplateList?: InputMaybe<
    | Array<SalesforcePardotEmailTemplateInput>
    | SalesforcePardotEmailTemplateInput
  >
}>

export type GetSalesforceEmailHtmlQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    salesforcePardotEmailHTML: {
      __typename?: 'SalesforcePardotEmailTemplates'
      classicBuilder: Array<{
        __typename?: 'SalesforcePardotEmailTemplateDef'
        dateAdded: string
        dateUpdated: string
        directLink: string
        emailHTML?: string | null
        emailID: string
        emailLinks?: Array<string> | null
        emailName: string
        emailSubject: string
        isClassicListEmail: boolean
        pardotCampaignID?: string | null
        pardotID?: string | null
        pardotName?: string | null
        uiType: string
      }>
      lightningBuilder: Array<{
        __typename?: 'SalesforcePardotEmailTemplateDef'
        uiType: string
        pardotName?: string | null
        pardotID?: string | null
        pardotCampaignID?: string | null
        isClassicListEmail: boolean
        emailSubject: string
        emailName: string
        emailLinks?: Array<string> | null
        emailID: string
        emailHTML?: string | null
        directLink: string
        dateUpdated: string
        dateAdded: string
      }>
    }
  }
}

export type UpdateSalesforceEmailHtmlByEmailIdMutationVariables = Exact<{
  emailID: Scalars['String']
  generatedPardotLinks: Array<PardotLinkInput> | PardotLinkInput
  pardotID: Scalars['String']
  pardotName: Scalars['String']
  uiType: Scalars['String']
  updatedHTMLMessage: Scalars['String']
}>

export type UpdateSalesforceEmailHtmlByEmailIdMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    sendPardotUpdate: {
      __typename?: 'SalesforcePardotEmailTemplateDef'
      emailHTML?: string | null
      emailID: string
      dateUpdated: string
      emailName: string
      pardotID?: string | null
      pardotName?: string | null
    }
  }
}

export type GetEloquaOrgListQueryVariables = Exact<{ [key: string]: never }>

export type GetEloquaOrgListQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    eloquaQueries: {
      __typename?: 'EloquaQueries'
      getConnectedEloquaOrgs: {
        __typename?: 'EloquaOrgList'
        eloquaOrgList: Array<{
          __typename?: 'EloquaOrg'
          eloquaOrgDisplayName: string
          eloquaOrgID: string
          eloquaOrgName: string
        }>
      }
    }
  }
}

export type UpdateEloquaOrgDisplayNameMutationVariables = Exact<{
  eloquaOrgID: Scalars['String']
  eloquaDisplayName: Scalars['String']
}>

export type UpdateEloquaOrgDisplayNameMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    oracleEloquaMutations: {
      __typename?: 'OracleEloquaMutations'
      updateEloquaOrgName: {
        __typename?: 'EloquaOrg'
        eloquaOrgDisplayName: string
        eloquaOrgID: string
        eloquaOrgName: string
      }
    }
  }
}

export type SaveEloquaBasicKeyMutationVariables = Exact<{
  eloquaPassword: Scalars['String']
  userName: Scalars['String']
  companyName: Scalars['String']
}>

export type SaveEloquaBasicKeyMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    oracleEloquaMutations: {
      __typename?: 'OracleEloquaMutations'
      saveEloquaBasicKey: {
        __typename?: 'Company'
        companyID: string
        eloquaIntegrationStatus: string
      }
    }
  }
}

export type GetAvailableEloquaEmailGroupsQueryVariables = Exact<{
  eloquaOrgID: Scalars['String']
}>

export type GetAvailableEloquaEmailGroupsQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    eloquaQueries: {
      __typename?: 'EloquaQueries'
      getAvailableEloquaEmailGroups: {
        __typename?: 'EloquaEmailGroupList'
        eloquaEmailGroupList: Array<{
          __typename?: 'EloquaEmailGroup'
          groupID: string
          name: string
        }>
      }
    }
  }
}

export type GetAvailableEloquaTemplatesQueryVariables = Exact<{
  eloquaOrgID: Scalars['String']
  groupID?: InputMaybe<Scalars['String']>
  search?: InputMaybe<Scalars['String']>
}>

export type GetAvailableEloquaTemplatesQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    eloquaQueries: {
      __typename?: 'EloquaQueries'
      getAvailableEloquaTemplateList: {
        __typename?: 'EloquaTemplateList'
        eloquaTemplateList: Array<{
          __typename?: 'EloquaTemplate'
          eloquaID: string
          name: string
        }>
      }
    }
  }
}

export type GetEloquaTemplateHtmlQueryVariables = Exact<{
  eloquaOrgID: Scalars['String']
  templateID: Scalars['String']
}>

export type GetEloquaTemplateHtmlQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    eloquaQueries: {
      __typename?: 'EloquaQueries'
      getEloquaEmailTemplateDetail: {
        __typename?: 'EloquaTemplate'
        eloquaID: string
        name: string
        emailHtml?: string | null
        eloquaLinkList?: Array<{
          __typename?: 'EloquaLink'
          linkType: string
          linkName: string
          linkURL: string
          linkID: string
        }> | null
      }
    }
  }
}

export type UpdateEloquaTemplateEmailHtmlMutationVariables = Exact<{
  eloquaOrgID: Scalars['String']
  templateID: Scalars['String']
  updatedHTMLMessage: Scalars['String']
}>

export type UpdateEloquaTemplateEmailHtmlMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    eloquaMutations: {
      __typename?: 'TrackOracleEloquaMutations'
      updateTemplate: {
        __typename?: 'EloquaTemplate'
        eloquaID: string
        emailHtml?: string | null
        name: string
        eloquaLinkList?: Array<{
          __typename?: 'EloquaLink'
          linkID: string
          linkName: string
          linkType: string
          linkURL: string
        }> | null
      }
    }
  }
}

export type FetchEloquaPicklistsQueryVariables = Exact<{
  eloquaOrgID: Scalars['String']
}>

export type FetchEloquaPicklistsQuery = {
  __typename?: 'Query'
  currentCompany: {
    __typename?: 'Company'
    companyID: string
    eloquaIntegrationData?: {
      __typename?: 'EloquaIntegrationData'
      parameterIDList: Array<string>
    } | null
  }
}

export type UpdateEloquaPicklistsMutationVariables = Exact<{
  eloquaOrgID: Scalars['String']
  parameterIDList: Array<Scalars['String']> | Scalars['String']
}>

export type UpdateEloquaPicklistsMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    eloquaMutations: {
      __typename?: 'TrackOracleEloquaMutations'
      syncEloquaTrack: {
        __typename?: 'Company'
        eloquaIntegrationData?: {
          __typename?: 'EloquaIntegrationData'
          parameterIDList: Array<string>
        } | null
      }
    }
  }
}

export type GenSalesforceMcOauthLinkQueryVariables = Exact<{
  state: Scalars['String']
}>

export type GenSalesforceMcOauthLinkQuery = {
  __typename?: 'Query'
  accountSettings: {
    __typename?: 'AccountSettingsQueries'
    dataSourceQueries: {
      __typename?: 'DataSourceQueries'
      genMCOauthLink: { __typename?: 'Oauth2Link'; oauth2Link: string }
    }
  }
}

export type HandleSalesforceMcCallbackMutationVariables = Exact<{
  businessUnitName: Scalars['String']
  code: Scalars['String']
  encryptedState: Scalars['String']
  mid: Scalars['String']
  tssd?: InputMaybe<Scalars['String']>
}>

export type HandleSalesforceMcCallbackMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    salesforceMCMutations: {
      __typename?: 'SalesforceMCMutations'
      handleSalesforceMCCallback: {
        __typename?: 'Company'
        salesforceMcIntegrationStatus: string
        salesforceMcIntegrationList: Array<{
          __typename?: 'SalesforceMCAPIKey'
          businessUnitName: string
          mid: string
        }>
      }
    }
  }
}

export type GetSalesforceMcTemplateListQueryVariables = Exact<{
  mid: Scalars['String']
}>

export type GetSalesforceMcTemplateListQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    salesforceMarketingCloudQueries: {
      __typename?: 'MarketingCloudQueries'
      getTemplateList: Array<{
        __typename?: 'MarketingCloudEmailTemplate'
        templateID: string
        templateName: string
        templateType: string
        dateLastUpdated: string
      }>
    }
  }
}

export type GetSalesforceMcEmailTemplateDetailQueryVariables = Exact<{
  mid: Scalars['String']
  templateID: Scalars['String']
}>

export type GetSalesforceMcEmailTemplateDetailQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    salesforceMarketingCloudQueries: {
      __typename?: 'MarketingCloudQueries'
      getTemplateDetail: {
        __typename?: 'MarketingCloudEmailTemplate'
        dateLastUpdated: string
        templateID: string
        templateName: string
        templateContent?: string | null
        templateType: string
        textContent: string
        slotMap?: Array<{
          __typename?: 'MCSlotMap'
          slotID: string
          content: string
          blocks: Array<{
            __typename?: 'MCBlock'
            blockID: string
            content: string
          }>
        }> | null
      }
    }
  }
}

export type UpdateSalesforceMcEmailTemplateMutationVariables = Exact<{
  mid: Scalars['String']
  templateID: Scalars['String']
  updatedHTMLMessage: Scalars['String']
  updatedSlotMap?: InputMaybe<Array<McSlotMapInput> | McSlotMapInput>
  updatedTextContent?: InputMaybe<Scalars['String']>
}>

export type UpdateSalesforceMcEmailTemplateMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    salesforceMCMutations: {
      __typename?: 'TrackSalesforceMCMutations'
      updateTemplate: {
        __typename?: 'MarketingCloudEmailTemplate'
        dateLastUpdated: string
        templateContent?: string | null
        templateID: string
        templateName: string
        templateType: string
        slotMap?: Array<{
          __typename?: 'MCSlotMap'
          slotID: string
          content: string
          blocks: Array<{
            __typename?: 'MCBlock'
            blockID: string
            content: string
          }>
        }> | null
      }
    }
  }
}

export type GetGaAuthLinkQueryVariables = Exact<{
  data: Scalars['String']
}>

export type GetGaAuthLinkQuery = {
  __typename?: 'Query'
  accountSettings: {
    __typename?: 'AccountSettingsQueries'
    dataSourceQueries: { __typename?: 'DataSourceQueries'; gaAuthLink: string }
  }
}

export type HandleGoogleResponseMutationVariables = Exact<{
  code: Scalars['String']
  state: Scalars['String']
}>

export type HandleGoogleResponseMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    googleAnalyticsMutations: {
      __typename?: 'GoogleAnalyticsMutations'
      handleGAAuthCallback: {
        __typename?: 'GAOauthCreds'
        creds: string
        state: string
      }
    }
  }
}

export type GetAvailableGaAccountsQueryVariables = Exact<{
  credentials: Scalars['String']
}>

export type GetAvailableGaAccountsQuery = {
  __typename?: 'Query'
  accountSettings: {
    __typename?: 'AccountSettingsQueries'
    dataSourceQueries: {
      __typename?: 'DataSourceQueries'
      availableGAAccounts: {
        __typename?: 'GAAccountSummary'
        ga4AccountList: Array<{
          __typename?: 'GAAccount'
          accountID: string
          accountName: string
          accountDisplayName: string
          properties: Array<{
            __typename?: 'GAProperty'
            propertyID: string
            propertyName: string
            propertyDisplayName: string
          }>
        }>
        uaAccountList: Array<{
          __typename?: 'GAAccount'
          accountID: string
          accountName: string
          accountDisplayName: string
          properties: Array<{
            __typename?: 'GAProperty'
            propertyID: string
            propertyName: string
            propertyDisplayName: string
            views?: Array<{
              __typename?: 'GAView'
              viewID: string
              viewName: string
              viewDisplayName: string
            }> | null
          }>
        }>
      }
    }
  }
}

export type ConnectGaAccountMutationVariables = Exact<{
  accountID: Scalars['String']
  creds: Scalars['String']
  dataSourceDesc: GaDescInput
}>

export type ConnectGaAccountMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    connectAccountDatasourceGA: {
      __typename?: 'Account'
      accountID: string
      accountName: string
      dataSource?: {
        __typename?: 'DataSource'
        kind: string
        connected: boolean
      } | null
    }
  }
}

export type ConnectGa4AccountMutationVariables = Exact<{
  accountID: Scalars['String']
  creds: Scalars['String']
  dataSourceDesc: Ga4DescInput
}>

export type ConnectGa4AccountMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    connectAccountDatasourceGA4: {
      __typename?: 'Account'
      accountID: string
      accountName: string
      dataSource?: {
        __typename?: 'DataSource'
        kind: string
        connected: boolean
      } | null
    }
  }
}

export type ReconnectGa4AccountMutationVariables = Exact<{
  accountID: Scalars['String']
  credentials: Scalars['String']
}>

export type ReconnectGa4AccountMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    googleAnalyticsMutations: {
      __typename?: 'GoogleAnalyticsMutations'
      reconnectGAAccount: boolean
    }
  }
}

export type GetAdobeAuthLinkQueryVariables = Exact<{
  data: Scalars['String']
}>

export type GetAdobeAuthLinkQuery = {
  __typename?: 'Query'
  accountSettings: {
    __typename?: 'AccountSettingsQueries'
    dataSourceQueries: {
      __typename?: 'DataSourceQueries'
      adobeAuthLink: string
    }
  }
}

export type HandleAdobeResponseMutationVariables = Exact<{
  code: Scalars['String']
  state: Scalars['String']
}>

export type HandleAdobeResponseMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    adobeAnalyticsMutations: {
      __typename?: 'AdobeAnalyticsMutations'
      handleAAAuthCallback: {
        __typename?: 'AAOauthCreds'
        creds: string
        state: string
      }
    }
  }
}

export type GetAvailableAaAccountsQueryVariables = Exact<{
  credentials: Scalars['String']
}>

export type GetAvailableAaAccountsQuery = {
  __typename?: 'Query'
  accountSettings: {
    __typename?: 'AccountSettingsQueries'
    dataSourceQueries: {
      __typename?: 'DataSourceQueries'
      availableAAAccounts: {
        __typename?: 'AAAccountSummary'
        aaAccountList: Array<{
          __typename?: 'AAAccount'
          adobeCompanyName: string
          adobeGlobalCompanyId: string
          adobeImsOrgId: string
          reportSuites: Array<{
            __typename?: 'AAReportSuite'
            adobeReportSuiteID: string
            adobeReportSuiteName: string
          }>
        }>
      }
    }
  }
}

export type ConnectAaAccountMutationVariables = Exact<{
  accountID: Scalars['String']
  creds: Scalars['String']
  dataSourceDesc: AaDescInput
}>

export type ConnectAaAccountMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    connectAccountDatasourceAA: { __typename?: 'Account'; accountID: string }
  }
}

export type ReconnectAaAccountMutationVariables = Exact<{
  accountID: Scalars['String']
  credentials: Scalars['String']
}>

export type ReconnectAaAccountMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    adobeAnalyticsMutations: {
      __typename?: 'AdobeAnalyticsMutations'
      reconnectAAAccount: boolean
    }
  }
}

export type ForceCacheRefreshMutationVariables = Exact<{
  accID?: InputMaybe<Scalars['String']>
  applyToAccount: Scalars['Boolean']
}>

export type ForceCacheRefreshMutation = {
  __typename?: 'Mutation'
  forceCacheRefresh: boolean
}

export type GetOnboardingSectionsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetOnboardingSectionsQuery = {
  __typename?: 'Query'
  userOnboardingProgress: {
    __typename?: 'UserOnboarding'
    userOnboardingProgressList: Array<{
      __typename?: 'OnboardingStep'
      onboardingSectionID: string
      sectionCompleted: boolean
      sectionSkipped: boolean
      sectionSkippedBefore: boolean
    }>
  }
  accountOnboardingProgress: {
    __typename?: 'AccountOnboarding'
    accountOnboardingProgressList: Array<{
      __typename?: 'OnboardingStep'
      onboardingSectionID: string
      sectionCompleted: boolean
      sectionSkipped: boolean
      sectionSkippedBefore: boolean
    }>
  }
}

export type SendFeedbackMutationVariables = Exact<{
  message: Scalars['String']
  page: Scalars['String']
  feedbackContext: FeedbackContext
}>

export type SendFeedbackMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    sendFeedback: {
      __typename?: 'CurrentUser'
      userID: string
      email: string
      fullName: string
      curAccountID: string
    }
  }
}

export type GetCampaignLinkDashboardMetaQueryVariables = Exact<{
  [key: string]: never
}>

export type GetCampaignLinkDashboardMetaQuery = {
  __typename?: 'Query'
  campaignLinkDashboardMeta: {
    __typename?: 'CampaignLinkDashboard'
    accountID: string
    dashboardTitle: string
    availableDimensions?: Array<{
      __typename?: 'AvailableDimension'
      dateFormat?: string | null
      dimensionParameterID: string
      dimensionName: string
      dimensionOptions?: Array<string> | null
      helpText?: string | null
      parameterType: string
    }> | null
    availableClickMetrics?: Array<{
      __typename?: 'AvailableMetric'
      calculation?: Array<string> | null
      description: string
      displayName: string
      increasePositive: boolean
      isStandard: boolean
      isUplifterClickMetric?: boolean | null
      metricID: string
      setupComplete: boolean
      units: string
    }> | null
    availableMetrics?: Array<{
      __typename?: 'AvailableMetric'
      isStandard: boolean
      increasePositive: boolean
      isUplifterClickMetric?: boolean | null
      calculation?: Array<string> | null
      description: string
      displayName: string
      metricID: string
      setupComplete: boolean
      units: string
    }> | null
    onloadDefaultOptions?: Array<{
      __typename?: 'GenericSetting'
      enabled?: boolean | null
      name: string
      value?: string | null
    }> | null
  }
}

export type GetDataSourceMetricListQueryVariables = Exact<{
  [key: string]: never
}>

export type GetDataSourceMetricListQuery = {
  __typename?: 'Query'
  dataSourceMetricList: {
    __typename?: 'DataSourceMetricList'
    dataSourceMetricList: Array<{
      __typename?: 'DataSourceMetric'
      description: string
      calculation?: Array<string> | null
      increasePositive?: boolean | null
      displayName: string
      metricID: string
      requiredMetrics?: Array<string> | null
      units: string
    }>
  }
}

export type SetDashboardSettingsMutationVariables = Exact<{
  additionalMetrics?: InputMaybe<Array<AdditionalMetric> | AdditionalMetric>
  onloadDefaultOptions?: InputMaybe<
    Array<GenericSettingInput> | GenericSettingInput
  >
}>

export type SetDashboardSettingsMutation = {
  __typename?: 'Mutation'
  setLinkPerformanceDashboardAccountSettings: {
    __typename?: 'CampaignLinkDashboard'
    accountID: string
    dashboardTitle: string
    availableMetrics?: Array<{
      __typename?: 'AvailableMetric'
      isStandard: boolean
      calculation?: Array<string> | null
      description: string
      displayName: string
      increasePositive: boolean
      metricID: string
      setupComplete: boolean
      units: string
    }> | null
    onloadDefaultOptions?: Array<{
      __typename?: 'GenericSetting'
      enabled?: boolean | null
      name: string
      value?: string | null
    }> | null
  }
}

export type GetAccountUsageDataQueryVariables = Exact<{
  activeUsers: Scalars['Boolean']
  totalUsers: Scalars['Boolean']
  badCodesBlocked: Scalars['Boolean']
  codesCreated: Scalars['Boolean']
  logins: Scalars['Boolean']
  invalidCodesBreakdown: Scalars['Boolean']
  startDate: Scalars['String']
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity?: InputMaybe<Scalars['String']>
}>

export type GetAccountUsageDataQuery = {
  __typename?: 'Query'
  report: {
    __typename?: 'ReportQueries'
    activeUsers?: {
      __typename?: 'AccountUsageGraphData'
      dateRange: Array<string>
      dateTotalRows: Array<number>
      queryStartDate: string
      queryEndDate: string
      total: number
      graphData: Array<{
        __typename?: 'AccountUsageGraphItem'
        dimensionName: string
        dimensionValues: Array<number>
      }>
    }
    badCodesBlocked?: {
      __typename?: 'AccountUsageGraphData'
      dateRange: Array<string>
      dateTotalRows: Array<number>
      queryStartDate: string
      queryEndDate: string
      total: number
      graphData: Array<{
        __typename?: 'AccountUsageGraphItem'
        dimensionName: string
        dimensionValues: Array<number>
      }>
    }
    codesCreated?: {
      __typename?: 'AccountUsageGraphData'
      dateRange: Array<string>
      dateTotalRows: Array<number>
      queryStartDate: string
      queryEndDate: string
      total: number
      graphData: Array<{
        __typename?: 'AccountUsageGraphItem'
        dimensionName: string
        dimensionValues: Array<number>
      }>
    }
    invalidCodesBreakdown?: {
      __typename?: 'AccountUsageGraphData'
      dateRange: Array<string>
      dateTotalRows: Array<number>
      queryStartDate: string
      queryEndDate: string
      total: number
      graphData: Array<{
        __typename?: 'AccountUsageGraphItem'
        dimensionName: string
        dimensionValues: Array<number>
      }>
    }
    logins?: {
      __typename?: 'AccountUsageGraphData'
      dateRange: Array<string>
      dateTotalRows: Array<number>
      queryStartDate: string
      queryEndDate: string
      total: number
      graphData: Array<{
        __typename?: 'AccountUsageGraphItem'
        dimensionName: string
        dimensionValues: Array<number>
      }>
    }
    totalUsers?: {
      __typename?: 'AccountUsageGraphData'
      dateRange: Array<string>
      dateTotalRows: Array<number>
      queryStartDate: string
      queryEndDate: string
      total: number
      graphData: Array<{
        __typename?: 'AccountUsageGraphItem'
        dimensionName: string
        dimensionValues: Array<number>
      }>
    }
  }
}

export type GetCampaignLinkDashboardMetricDataClickDataQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars['String']>
  campaignCodeID?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity: Scalars['String']
  matchType: Scalars['String']
  selectedMetric: Scalars['String']
  startDate: Scalars['String']
}>

export type GetCampaignLinkDashboardMetricDataClickDataQuery = {
  __typename?: 'Query'
  campaignLinkDashboardMetricDataClickData: {
    __typename?: 'CampaignLinkDashboardMetricData'
    increasePositive: boolean
    lastPeriodStartDate: string
    lastYearStartDate: string
    prevPeriodStartDate: string
    queryEndDate: string
    queryStartDate: string
    metricLastPeriod: {
      __typename?: 'ComparisonMetric'
      metricDisplay: string
      metricLastCompleteValue: number
      metricPrevValue?: number | null
      metricTitle: string
    }
    metricLastPeriodComparison: {
      __typename?: 'ComparisonMetric'
      metricDisplay: string
      metricLastCompleteValue: number
      metricPrevValue?: number | null
      metricTitle: string
      metricValue?: number | null
    }
    metricPeriodOnPeriodComparison: {
      __typename?: 'ComparisonMetric'
      metricDisplay: string
      metricLastCompleteValue: number
      metricPrevValue?: number | null
      metricTitle: string
      metricValue?: number | null
    }
    periodComparisonVsPeriodAverage: {
      __typename?: 'ComparisonMetric'
      metricDisplay: string
      metricLastCompleteValue: number
      metricPrevValue?: number | null
      metricTitle: string
      metricValue?: number | null
    }
    totalMetric: {
      __typename?: 'ComparisonMetric'
      metricDisplay: string
      metricLastCompleteValue: number
      metricPrevValue?: number | null
      metricTitle: string
      metricValue?: number | null
    }
  }
}

export type GetCampaignLinkDashboardMetricDataQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars['String']>
  campaignCodeID?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity: Scalars['String']
  matchType: Scalars['String']
  selectedMetric: Scalars['String']
  startDate: Scalars['String']
}>

export type GetCampaignLinkDashboardMetricDataQuery = {
  __typename?: 'Query'
  campaignLinkDashboardMetricData: {
    __typename?: 'CampaignLinkDashboardMetricData'
    increasePositive: boolean
    lastPeriodStartDate: string
    lastYearStartDate: string
    prevPeriodStartDate: string
    queryEndDate: string
    queryStartDate: string
    metricLastPeriod: {
      __typename?: 'ComparisonMetric'
      metricDisplay: string
      metricLastCompleteValue: number
      metricPrevValue?: number | null
      metricTitle: string
    }
    metricLastPeriodComparison: {
      __typename?: 'ComparisonMetric'
      metricDisplay: string
      metricLastCompleteValue: number
      metricPrevValue?: number | null
      metricTitle: string
      metricValue?: number | null
    }
    metricPeriodOnPeriodComparison: {
      __typename?: 'ComparisonMetric'
      metricDisplay: string
      metricLastCompleteValue: number
      metricPrevValue?: number | null
      metricTitle: string
      metricValue?: number | null
    }
    periodComparisonVsPeriodAverage: {
      __typename?: 'ComparisonMetric'
      metricDisplay: string
      metricLastCompleteValue: number
      metricPrevValue?: number | null
      metricTitle: string
      metricValue?: number | null
    }
    totalMetric: {
      __typename?: 'ComparisonMetric'
      metricDisplay: string
      metricLastCompleteValue: number
      metricPrevValue?: number | null
      metricTitle: string
      metricValue?: number | null
    }
  }
}

export type GetCampaignLinkDashboardGraphClickDataQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars['String']>
  campaignCodeID?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity: Scalars['String']
  matchType: Scalars['String']
  selectedMetric: Scalars['String']
  stackDimension?: InputMaybe<Scalars['String']>
  startDate: Scalars['String']
}>

export type GetCampaignLinkDashboardGraphClickDataQuery = {
  __typename?: 'Query'
  campaignLinkDashboardGraphClickData: {
    __typename?: 'CampaignLinkDashboardGraph'
    dateRange: Array<string>
    dateTotalRows: Array<number>
    graphType: string
    graphData: Array<{
      __typename?: 'CampaignLinkDashboardGraphItem'
      dimensionName: string
      dimensionValues: Array<number>
    }>
  }
}

export type GetCampaignLinkDashboardGraphDataQueryVariables = Exact<{
  campaignCodeID?: InputMaybe<Scalars['String']>
  startDate: Scalars['String']
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  granularity: Scalars['String']
  matchType: Scalars['String']
  selectedMetric: Scalars['String']
  stackDimension?: InputMaybe<Scalars['String']>
}>

export type GetCampaignLinkDashboardGraphDataQuery = {
  __typename?: 'Query'
  campaignLinkDashboardGraph: {
    __typename?: 'CampaignLinkDashboardGraph'
    dateRange: Array<string>
    dateTotalRows: Array<number>
    graphType: string
    graphData: Array<{
      __typename?: 'CampaignLinkDashboardGraphItem'
      dimensionName: string
      dimensionValues: Array<number>
    }>
  }
}

export type GetCampaignLinkDashboardTableQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars['String']>
  campaignCodeID?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  matchType: Scalars['String']
  selectedMetric: Scalars['String']
  startDate: Scalars['String']
}>

export type GetCampaignLinkDashboardTableQuery = {
  __typename?: 'Query'
  campaignLinkDashboardTable: {
    __typename?: 'CampaignLinkDashboardTable'
    campaignLinks: Array<string>
    tableData: Array<{
      __typename?: 'CampaignLinkDashboardTableItem'
      helpText: string
      metricID: string
      metricName: string
      metricValue: Array<number>
      units: string
    }>
  }
}

export type ListSavedLinkPerformanceReportsGqlQueryVariables = Exact<{
  [key: string]: never
}>

export type ListSavedLinkPerformanceReportsGqlQuery = {
  __typename?: 'Query'
  report: {
    __typename?: 'ReportQueries'
    listSavedLinkPerformanceReports: {
      __typename?: 'SavedLinkPerformanceReportList'
      hiddenReportList: Array<string>
      userPreferredOrder: Array<string>
      savedReports: Array<{
        __typename?: 'SavedLinkPerformanceReport'
        availableTo: string
        createdBy: string
        createdByID: string
        dateCreated: string
        endDate: string
        lastEditedBy: string
        lastEditedByID: string
        lastEditedDate: string
        matchType?: string | null
        reportName: string
        reportTitle: string
        savedReportID: string
        selectedMetric: string
        stackDimension?: string | null
        startDate: string
        granularity?: string | null
        updatedTableColumnIndexOrderList?: Array<number> | null
        renamedColumnNameList?: Array<string> | null
        hideColumnIndexList?: Array<number> | null
        applyFilters?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionParameterID: string
          dimensionName: string
          dimensionOptions: Array<string>
        }> | null
      }>
    }
  }
}

export type SaveLinkPerformanceReportMutationVariables = Exact<{
  reportName: Scalars['String']
  reportTitle: Scalars['String']
  availableTo: Scalars['String']
  matchType: Scalars['String']
  selectedMetric: Scalars['String']
  startDate: Scalars['String']
  endDate?: InputMaybe<Scalars['String']>
  stackDimension: Scalars['String']
  filterList?: InputMaybe<Array<DimensionFilter> | DimensionFilter>
  granularity?: InputMaybe<Scalars['String']>
}>

export type SaveLinkPerformanceReportMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    saveLinkPerformanceReport: {
      __typename?: 'SavedLinkPerformanceReport'
      availableTo: string
      createdBy: string
      createdByID: string
      dateCreated: string
      endDate: string
      lastEditedBy: string
      lastEditedByID: string
      lastEditedDate: string
      matchType?: string | null
      reportName: string
      reportTitle: string
      savedReportID: string
      selectedMetric: string
      stackDimension?: string | null
      startDate: string
      granularity?: string | null
      renamedColumnNameList?: Array<string> | null
      hideColumnIndexList?: Array<number> | null
      updatedTableColumnIndexOrderList?: Array<number> | null
      applyFilters?: Array<{
        __typename?: 'DimensionFilterOutput'
        dimensionParameterID: string
        dimensionName: string
        dimensionOptions: Array<string>
      }> | null
    }
  }
}

export type UpdateSavedLinkPerformanceReportMutationVariables = Exact<{
  reportName?: InputMaybe<Scalars['String']>
  reportTitle?: InputMaybe<Scalars['String']>
  availableTo?: InputMaybe<Scalars['String']>
  savedReportID: Scalars['String']
  selectedMetric?: InputMaybe<Scalars['String']>
  stackDimension?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  matchType?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<Array<DimensionFilter> | DimensionFilter>
  granularity?: InputMaybe<Scalars['String']>
  hideColumnIndexList?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>
  renamedColumnNameList?: InputMaybe<
    Array<Scalars['String']> | Scalars['String']
  >
  updatedTableColumnIndexOrderList?: InputMaybe<
    Array<Scalars['Int']> | Scalars['Int']
  >
}>

export type UpdateSavedLinkPerformanceReportMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    updateSavedLinkPerformanceReport: {
      __typename?: 'SavedLinkPerformanceReport'
      availableTo: string
      createdBy: string
      createdByID: string
      dateCreated: string
      endDate: string
      lastEditedBy: string
      lastEditedByID: string
      lastEditedDate: string
      matchType?: string | null
      reportName: string
      reportTitle: string
      savedReportID: string
      selectedMetric: string
      stackDimension?: string | null
      startDate: string
      granularity?: string | null
      updatedTableColumnIndexOrderList?: Array<number> | null
      renamedColumnNameList?: Array<string> | null
      hideColumnIndexList?: Array<number> | null
      applyFilters?: Array<{
        __typename?: 'DimensionFilterOutput'
        dimensionParameterID: string
        dimensionName: string
        dimensionOptions: Array<string>
      }> | null
    }
  }
}

export type RemoveSavedLinkPerformanceReportMutationVariables = Exact<{
  savedReportID: Scalars['String']
}>

export type RemoveSavedLinkPerformanceReportMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    removeSavedLinkPerformanceReport: {
      __typename?: 'SavedLinkPerformanceReportList'
      hiddenReportList: Array<string>
      userPreferredOrder: Array<string>
      savedReports: Array<{
        __typename?: 'SavedLinkPerformanceReport'
        availableTo: string
        createdBy: string
        createdByID: string
        dateCreated: string
        endDate: string
        lastEditedBy: string
        lastEditedByID: string
        lastEditedDate: string
        matchType?: string | null
        reportName: string
        reportTitle: string
        savedReportID: string
        selectedMetric: string
        stackDimension?: string | null
        startDate: string
        granularity?: string | null
        applyFilters?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionParameterID: string
          dimensionName: string
          dimensionOptions: Array<string>
        }> | null
      }>
    }
  }
}

export type UpdateLinkPerformanceUserPrefsMutationVariables = Exact<{
  newOrder?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type UpdateLinkPerformanceUserPrefsMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    updateLinkPerformanceUserPrefs: {
      __typename?: 'SavedLinkPerformanceReportList'
      hiddenReportList: Array<string>
      userPreferredOrder: Array<string>
      savedReports: Array<{
        __typename?: 'SavedLinkPerformanceReport'
        availableTo: string
        createdBy: string
        createdByID: string
        dateCreated: string
        endDate: string
        lastEditedBy: string
        lastEditedByID: string
        lastEditedDate: string
        matchType?: string | null
        reportName: string
        reportTitle: string
        savedReportID: string
        selectedMetric: string
        stackDimension?: string | null
        startDate: string
        granularity?: string | null
        applyFilters?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionParameterID: string
          dimensionName: string
          dimensionOptions: Array<string>
        }> | null
      }>
    }
  }
}

export type GetLostLinksGraphDataQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  selectedMetric?: InputMaybe<Scalars['String']>
  granularity?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<DimensionFilterList>
  searchFilter?: InputMaybe<SearchFilter>
}>

export type GetLostLinksGraphDataQuery = {
  __typename?: 'Query'
  report: {
    __typename?: 'ReportQueries'
    linkAudit: {
      __typename?: 'LinkAuditQueries'
      linkAuditGraph: {
        __typename?: 'AccountUsageGraphData'
        dateRange: Array<string>
        dateTotalRows: Array<number>
        queryEndDate: string
        queryStartDate: string
        total: number
        graphData: Array<{
          __typename?: 'AccountUsageGraphItem'
          dimensionName: string
          dimensionValues: Array<number>
        }>
      }
    }
  }
}

export type GetLostLinksTableDataQueryVariables = Exact<{
  endDate?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  filterList?: InputMaybe<DimensionFilterList>
  searchFilter?: InputMaybe<SearchFilter>
  selectedMetric?: InputMaybe<Scalars['String']>
  sortDirection?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  tempTable?: InputMaybe<Scalars['String']>
  groupByLandingPage?: InputMaybe<Scalars['Boolean']>
}>

export type GetLostLinksTableDataQuery = {
  __typename?: 'Query'
  report: {
    __typename?: 'ReportQueries'
    linkAudit: {
      __typename?: 'LinkAuditQueries'
      accountID: string
      linkAuditTable: {
        __typename?: 'LinkAuditTable'
        firstObserved: Array<string>
        landingPageList: Array<string>
        metricFormat: Array<string>
        metricHeadings: Array<string>
        metricValues: Array<Array<number>>
        parameterHeadings: Array<string>
        parameterValues: Array<Array<string>>
        pageData?: {
          __typename?: 'PagingData'
          destination: string
          limit: number
          offset: number
          total: number
          totalPages: number
        } | null
      }
    }
  }
}

export type GetLostLinksFilterOptionsQueryVariables = Exact<{
  tempTable?: InputMaybe<Scalars['String']>
}>

export type GetLostLinksFilterOptionsQuery = {
  __typename?: 'Query'
  report: {
    __typename?: 'ReportQueries'
    linkAudit: {
      __typename?: 'LinkAuditQueries'
      accountID: string
      linkAuditFilters: {
        __typename?: 'LinkAuditFilters'
        parameterFilters: Array<{
          __typename?: 'LinkAuditFilter'
          parameterID: string
          parameterName: string
          parameterOptions: Array<string>
        }>
      }
    }
  }
}

export type ListSavedLostLinksReportsQueryVariables = Exact<{
  [key: string]: never
}>

export type ListSavedLostLinksReportsQuery = {
  __typename?: 'Query'
  report: {
    __typename?: 'ReportQueries'
    listSavedLinkAuditReports: {
      __typename?: 'SavedLinkPerformanceReportList'
      hiddenReportList: Array<string>
      userPreferredOrder: Array<string>
      savedReports: Array<{
        __typename?: 'SavedLinkPerformanceReport'
        availableTo: string
        createdBy: string
        createdByID: string
        dateCreated: string
        endDate: string
        granularity?: string | null
        groupByLandingPage?: boolean | null
        hideColumnIndexList?: Array<number> | null
        lastEditedBy: string
        lastEditedByID: string
        lastEditedDate: string
        matchType?: string | null
        renamedColumnNameList?: Array<string> | null
        reportName: string
        reportTitle: string
        savedReportID: string
        selectedMetric: string
        stackDimension?: string | null
        startDate: string
        updatedTableColumnIndexOrderList?: Array<number> | null
        applyFilters?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
      }>
    }
  }
}

export type SaveNewLostLinkReportMutationVariables = Exact<{
  reportName: Scalars['String']
  reportTitle: Scalars['String']
  selectedMetric: Scalars['String']
  startDate: Scalars['String']
  availableTo: Scalars['String']
  endDate?: InputMaybe<Scalars['String']>
  granularity: Scalars['String']
  filterList?: InputMaybe<Array<DimensionFilter> | DimensionFilter>
}>

export type SaveNewLostLinkReportMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    saveLinkAuditReport: {
      __typename?: 'SavedLinkPerformanceReport'
      availableTo: string
      dateCreated: string
      createdByID: string
      endDate: string
      lastEditedByID: string
      lastEditedDate: string
      matchType?: string | null
      reportTitle: string
      selectedMetric: string
      savedReportID: string
      startDate: string
      renamedColumnNameList?: Array<string> | null
      hideColumnIndexList?: Array<number> | null
      groupByLandingPage?: boolean | null
      updatedTableColumnIndexOrderList?: Array<number> | null
      applyFilters?: Array<{
        __typename?: 'DimensionFilterOutput'
        dimensionName: string
        dimensionOptions: Array<string>
      }> | null
    }
  }
}

export type UpdateSavedLostLinkReportMutationVariables = Exact<{
  savedReportID: Scalars['String']
  reportName?: InputMaybe<Scalars['String']>
  reportTitle?: InputMaybe<Scalars['String']>
  availableTo?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  granularity?: InputMaybe<Scalars['String']>
  selectedMetric?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<Array<DimensionFilter> | DimensionFilter>
  groupByLandingPage?: InputMaybe<Scalars['Boolean']>
  hideColumnIndexList?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>
  renamedColumnNameList?: InputMaybe<
    Array<Scalars['String']> | Scalars['String']
  >
  updatedTableColumnIndexOrderList?: InputMaybe<
    Array<Scalars['Int']> | Scalars['Int']
  >
}>

export type UpdateSavedLostLinkReportMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    updateSavedLinkAuditReport: {
      __typename?: 'SavedLinkPerformanceReport'
      availableTo: string
      createdBy: string
      createdByID: string
      dateCreated: string
      endDate: string
      granularity?: string | null
      lastEditedBy: string
      lastEditedByID: string
      lastEditedDate: string
      matchType?: string | null
      reportName: string
      reportTitle: string
      savedReportID: string
      selectedMetric: string
      stackDimension?: string | null
      startDate: string
      renamedColumnNameList?: Array<string> | null
      hideColumnIndexList?: Array<number> | null
      groupByLandingPage?: boolean | null
      updatedTableColumnIndexOrderList?: Array<number> | null
      applyFilters?: Array<{
        __typename?: 'DimensionFilterOutput'
        dimensionName: string
        dimensionOptions: Array<string>
        dimensionParameterID: string
      }> | null
    }
  }
}

export type RemoveSavedLostLinkReportMutationVariables = Exact<{
  savedReportID: Scalars['String']
}>

export type RemoveSavedLostLinkReportMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    removeSavedLinkAuditReport: {
      __typename?: 'SavedLinkPerformanceReportList'
      hiddenReportList: Array<string>
      userPreferredOrder: Array<string>
      savedReports: Array<{
        __typename?: 'SavedLinkPerformanceReport'
        availableTo: string
        createdBy: string
        createdByID: string
        dateCreated: string
        endDate: string
        granularity?: string | null
        lastEditedBy: string
        lastEditedByID: string
        lastEditedDate: string
        matchType?: string | null
        reportName: string
        reportTitle: string
        savedReportID: string
        selectedMetric: string
        stackDimension?: string | null
        startDate: string
        applyFilters?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
      }>
    }
  }
}

export type UpdateSavedLostLinkReportPrefsMutationVariables = Exact<{
  hideReports?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  newOrder?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type UpdateSavedLostLinkReportPrefsMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    updateLinkAuditUserPrefs: {
      __typename?: 'SavedLinkPerformanceReportList'
      hiddenReportList: Array<string>
      userPreferredOrder: Array<string>
      savedReports: Array<{
        __typename?: 'SavedLinkPerformanceReport'
        availableTo: string
        createdBy: string
        createdByID: string
        dateCreated: string
        endDate: string
        granularity?: string | null
        lastEditedBy: string
        lastEditedByID: string
        lastEditedDate: string
        matchType?: string | null
        reportName: string
        reportTitle: string
        savedReportID: string
        selectedMetric: string
        stackDimension?: string | null
        startDate: string
        applyFilters?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
      }>
    }
  }
}

export type ListRemoteDashboardsQueryVariables = Exact<{ [key: string]: never }>

export type ListRemoteDashboardsQuery = {
  __typename?: 'Query'
  report: {
    __typename?: 'ReportQueries'
    listRemoteDashboards: {
      __typename?: 'RemoteDashboardList'
      remoteDashboards: Array<{
        __typename?: 'RemoteDashboard'
        dashboardDescription: string
        dashboardID: string
        dashboardName: string
        dashboardSource: string
        dashboardThumbnail: string
        dateAdded: string
        embedLink: string
        lastAccessed: string
        userAdded: string
      }>
    }
  }
}

export type AddRemoteDashboardMutationVariables = Exact<{
  dashboardName: Scalars['String']
  embedLink: Scalars['String']
}>

export type AddRemoteDashboardMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    addCustomEmbeddedDashboard: {
      __typename?: 'RemoteDashboard'
      dashboardDescription: string
      dashboardID: string
      dashboardName: string
      dashboardSource: string
      dashboardThumbnail: string
      dateAdded: string
      embedLink: string
      lastAccessed: string
      userAdded: string
    }
  }
}

export type DeleteRemoteDashboardMutationVariables = Exact<{
  dashboardID: Scalars['String']
}>

export type DeleteRemoteDashboardMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    deleteCustomEmbeddedDashboard: {
      __typename?: 'RemoteDashboard'
      dashboardDescription: string
      dashboardID: string
      dashboardName: string
      dashboardSource: string
      dashboardThumbnail: string
      dateAdded: string
      embedLink: string
      lastAccessed: string
      userAdded: string
    }
  }
}

export type UpdateRemoteDashboardMutationVariables = Exact<{
  dashboardID: Scalars['String']
  dashboardName?: InputMaybe<Scalars['String']>
  dashboardDescription?: InputMaybe<Scalars['String']>
  embedLink?: InputMaybe<Scalars['String']>
}>

export type UpdateRemoteDashboardMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    updateCustomEmbeddedDashboard: {
      __typename?: 'RemoteDashboard'
      dashboardDescription: string
      dashboardID: string
      dashboardName: string
      dashboardSource: string
      dashboardThumbnail: string
      dateAdded: string
      embedLink: string
      lastAccessed: string
      userAdded: string
    }
  }
}

export type GetMarketingFunnelReportQueryVariables = Exact<{
  awarenessFilter?: InputMaybe<Scalars['String']>
  boardID?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<Array<DimensionFilter> | DimensionFilter>
  mainFilter?: InputMaybe<Array<DimensionFilter> | DimensionFilter>
  startDate?: InputMaybe<Scalars['String']>
}>

export type GetMarketingFunnelReportQuery = {
  __typename?: 'Query'
  report: {
    __typename?: 'ReportQueries'
    marketingFunnel: {
      __typename?: 'MarketingFunnelQueries'
      getMarketingFunnelReport: {
        __typename?: 'MarketingFunnelReport'
        boardID?: string | null
        boardTitle: string
        boardURL?: string | null
        boardDefinition: {
          __typename?: 'MarketingFunnelBoardDefinition'
          awarenessFilter?: string | null
          boardID: string
          boardTitle: string
          endDate?: string | null
          startDate: string
          filterList?: Array<{
            __typename?: 'DimensionFilterOutput'
            dimensionName: string
            dimensionOptions: Array<string>
            dimensionParameterID: string
          }> | null
          mainFilter?: Array<{
            __typename?: 'DimensionFilterOutput'
            dimensionOptions: Array<string>
            dimensionName: string
            dimensionParameterID: string
          }> | null
        }
        laneList: Array<{
          __typename?: 'MarketingFunnelLane'
          laneID: string
          laneOrder: number
          laneTitle: string
          hasBeenReordered?: boolean | null
          secondaryColour?: string | null
          primaryColour?: string | null
          selectedMetricList: Array<string>
          laneMetrics: Array<{
            __typename?: 'FunnelMetric'
            metricName: string
            metricValue?: number | null
          }>
          cardList: Array<{
            __typename?: 'MarketingFunnelCard'
            additionalImages?: Array<string> | null
            cardID: string
            cardOrder: number
            cardTitle: string
            cardType: string
            hideCard?: boolean | null
            lastEditedBy?: string | null
            lastEditedDate?: string | null
            updatedTitle?: string | null
            cardAnnotation?: string | null
            cardMetrics: Array<{
              __typename?: 'FunnelMetric'
              metricName: string
              metricValue?: number | null
            }>
            cardConnections?: Array<{
              __typename?: 'MarketingFunnelCardConnection'
              connectedFrom: string
              connectedTo: string
              connectionMetricName: string
              connectionMetricValue: number
              connectionType: string
              hideConnection?: boolean | null
              updatedConnectionMetricName?: string | null
              updatedConnectionMetricValue?: number | null
            }> | null
            additionalConnections?: Array<{
              __typename?: 'MarketingFunnelCardConnection'
              connectedFrom: string
              connectedTo: string
              connectionMetricName: string
              connectionMetricValue: number
              connectionType: string
              hideConnection?: boolean | null
              updatedConnectionMetricName?: string | null
              updatedConnectionMetricValue?: number | null
            }> | null
            additionalMetrics?: Array<{
              __typename?: 'FunnelMetric'
              metricName: string
              metricValue?: number | null
            }> | null
          }>
        }>
      }
    }
  }
}

export type GetMarketingFunnelBoardListQueryVariables = Exact<{
  [key: string]: never
}>

export type GetMarketingFunnelBoardListQuery = {
  __typename?: 'Query'
  report: {
    __typename?: 'ReportQueries'
    marketingFunnel: {
      __typename?: 'MarketingFunnelQueries'
      getMarketingFunnelBoardList: Array<{
        __typename?: 'MarketingFunnelBoardDescription'
        boardDescription: string
        boardID?: string | null
        boardTitle: string
        boardURL?: string | null
        boardHidden: boolean
        availableTo: string
      }>
    }
  }
}

export type SaveMarketingFunnelBoardInitialMutationVariables = Exact<{
  boardID: Scalars['String']
  boardTitle: Scalars['String']
  boardDescription?: InputMaybe<Scalars['String']>
  availableTo: Scalars['String']
  awarenessFilter?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  filterList?: InputMaybe<Array<DimensionFilter> | DimensionFilter>
  mainFilter?: InputMaybe<Array<DimensionFilter> | DimensionFilter>
}>

export type SaveMarketingFunnelBoardInitialMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    saveMarketingFunnelBoardInitial: {
      __typename?: 'MarketingFunnelReport'
      boardID?: string | null
      boardTitle: string
      boardURL?: string | null
      boardDefinition: {
        __typename?: 'MarketingFunnelBoardDefinition'
        awarenessFilter?: string | null
        boardID: string
        boardTitle: string
        endDate?: string | null
        startDate: string
        filterList?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
        mainFilter?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
      }
      laneList: Array<{
        __typename?: 'MarketingFunnelLane'
        secondaryColour?: string | null
        primaryColour?: string | null
        selectedMetricList: Array<string>
        laneID: string
        laneOrder: number
        laneTitle: string
        hasBeenReordered?: boolean | null
        laneMetrics: Array<{
          __typename?: 'FunnelMetric'
          metricName: string
          metricValue?: number | null
        }>
        cardList: Array<{
          __typename?: 'MarketingFunnelCard'
          additionalImages?: Array<string> | null
          cardAnnotation?: string | null
          cardID: string
          cardOrder: number
          cardTitle: string
          cardType: string
          hideCard?: boolean | null
          lastEditedBy?: string | null
          lastEditedDate?: string | null
          updatedTitle?: string | null
          additionalConnections?: Array<{
            __typename?: 'MarketingFunnelCardConnection'
            connectedFrom: string
            connectionMetricName: string
            connectedTo: string
            connectionMetricValue: number
            connectionType: string
            updatedConnectionMetricName?: string | null
            hideConnection?: boolean | null
            updatedConnectionMetricValue?: number | null
          }> | null
          additionalMetrics?: Array<{
            __typename?: 'FunnelMetric'
            metricName: string
            metricValue?: number | null
          }> | null
          cardConnections?: Array<{
            __typename?: 'MarketingFunnelCardConnection'
            connectedFrom: string
            connectedTo: string
            connectionMetricName: string
            connectionMetricValue: number
            connectionType: string
            hideConnection?: boolean | null
            updatedConnectionMetricName?: string | null
            updatedConnectionMetricValue?: number | null
          }> | null
          cardMetrics: Array<{
            __typename?: 'FunnelMetric'
            metricName: string
            metricValue?: number | null
          }>
        }>
      }>
    }
  }
}

export type UpdateMarketingFunnelBoardMutationVariables = Exact<{
  boardID: Scalars['String']
  boardTitle: Scalars['String']
  boardDescription?: InputMaybe<Scalars['String']>
  availableTo?: InputMaybe<Scalars['String']>
  awarenessFilter?: InputMaybe<Scalars['String']>
  startDate?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
  mainFilter?: InputMaybe<Array<DimensionFilter> | DimensionFilter>
  filterList?: InputMaybe<Array<DimensionFilter> | DimensionFilter>
}>

export type UpdateMarketingFunnelBoardMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    updateMarketingFunnelBoard: {
      __typename?: 'MarketingFunnelReport'
      boardID?: string | null
      boardTitle: string
      boardURL?: string | null
      boardDefinition: {
        __typename?: 'MarketingFunnelBoardDefinition'
        awarenessFilter?: string | null
        boardID: string
        boardTitle: string
        endDate?: string | null
        startDate: string
        filterList?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
        mainFilter?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
      }
      laneList: Array<{
        __typename?: 'MarketingFunnelLane'
        laneID: string
        laneOrder: number
        laneTitle: string
        hasBeenReordered?: boolean | null
        cardList: Array<{
          __typename?: 'MarketingFunnelCard'
          additionalImages?: Array<string> | null
          cardAnnotation?: string | null
          cardID: string
          cardOrder: number
          cardTitle: string
          cardType: string
          hideCard?: boolean | null
          lastEditedBy?: string | null
          lastEditedDate?: string | null
          updatedTitle?: string | null
          additionalConnections?: Array<{
            __typename?: 'MarketingFunnelCardConnection'
            connectedFrom: string
            connectedTo: string
            connectionMetricName: string
            connectionMetricValue: number
            connectionType: string
            hideConnection?: boolean | null
            updatedConnectionMetricName?: string | null
            updatedConnectionMetricValue?: number | null
          }> | null
          additionalMetrics?: Array<{
            __typename?: 'FunnelMetric'
            metricName: string
            metricValue?: number | null
          }> | null
          cardConnections?: Array<{
            __typename?: 'MarketingFunnelCardConnection'
            connectedFrom: string
            connectedTo: string
            connectionMetricName: string
            connectionMetricValue: number
            connectionType: string
            hideConnection?: boolean | null
            updatedConnectionMetricName?: string | null
            updatedConnectionMetricValue?: number | null
          }> | null
          cardMetrics: Array<{
            __typename?: 'FunnelMetric'
            metricName: string
            metricValue?: number | null
          }>
        }>
        laneMetrics: Array<{
          __typename?: 'FunnelMetric'
          metricName: string
          metricValue?: number | null
        }>
      }>
    }
  }
}

export type UpdateMarketingFunnelBoardOrderMutationVariables = Exact<{
  newOrder: Array<Scalars['String']> | Scalars['String']
  hideReports?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type UpdateMarketingFunnelBoardOrderMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    updateMarketingFunnelBoardOrder: Array<{
      __typename?: 'MarketingFunnelBoardDescription'
      boardDescription: string
      boardID?: string | null
      boardTitle: string
      boardURL?: string | null
      boardHidden: boolean
      availableTo: string
    }>
  }
}

export type DeleteMarketingFunnelBoardMutationVariables = Exact<{
  boardID: Scalars['String']
}>

export type DeleteMarketingFunnelBoardMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    deleteMarketingFunnelBoard: Array<{
      __typename?: 'MarketingFunnelBoardDescription'
      boardDescription: string
      boardID?: string | null
      boardTitle: string
      boardURL?: string | null
      boardHidden: boolean
      availableTo: string
    }>
  }
}

export type UpdateMarketingBoardLaneMutationVariables = Exact<{
  boardID: Scalars['String']
  laneID: Scalars['String']
  cardOrder?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  laneTitle?: InputMaybe<Scalars['String']>
  primaryColour?: InputMaybe<Scalars['String']>
  secondaryColour?: InputMaybe<Scalars['String']>
  selectedMetricList?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type UpdateMarketingBoardLaneMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    updateMarketingFunnelLane: {
      __typename?: 'MarketingFunnelReport'
      boardID?: string | null
      boardTitle: string
      boardURL?: string | null
      boardDefinition: {
        __typename?: 'MarketingFunnelBoardDefinition'
        awarenessFilter?: string | null
        boardID: string
        boardTitle: string
        endDate?: string | null
        startDate: string
        filterList?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
        mainFilter?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
      }
      laneList: Array<{
        __typename?: 'MarketingFunnelLane'
        laneID: string
        laneOrder: number
        laneTitle: string
        hasBeenReordered?: boolean | null
        secondaryColour?: string | null
        primaryColour?: string | null
        selectedMetricList: Array<string>
        laneMetrics: Array<{
          __typename?: 'FunnelMetric'
          metricName: string
          metricValue?: number | null
        }>
        cardList: Array<{
          __typename?: 'MarketingFunnelCard'
          additionalImages?: Array<string> | null
          cardID: string
          cardOrder: number
          cardTitle: string
          cardType: string
          hideCard?: boolean | null
          lastEditedBy?: string | null
          lastEditedDate?: string | null
          updatedTitle?: string | null
          cardAnnotation?: string | null
          cardMetrics: Array<{
            __typename?: 'FunnelMetric'
            metricName: string
            metricValue?: number | null
          }>
          cardConnections?: Array<{
            __typename?: 'MarketingFunnelCardConnection'
            connectedFrom: string
            connectionMetricName: string
            connectedTo: string
            connectionMetricValue: number
            connectionType: string
            hideConnection?: boolean | null
            updatedConnectionMetricName?: string | null
            updatedConnectionMetricValue?: number | null
          }> | null
          additionalConnections?: Array<{
            __typename?: 'MarketingFunnelCardConnection'
            connectedFrom: string
            connectedTo: string
            connectionMetricName: string
            connectionMetricValue: number
            connectionType: string
            hideConnection?: boolean | null
            updatedConnectionMetricName?: string | null
            updatedConnectionMetricValue?: number | null
          }> | null
          additionalMetrics?: Array<{
            __typename?: 'FunnelMetric'
            metricName: string
            metricValue?: number | null
          }> | null
        }>
      }>
    }
  }
}

export type UpdateMarketingBoardCardMutationVariables = Exact<{
  boardID: Scalars['String']
  cardID: Scalars['String']
  laneID: Scalars['String']
  additionalConnections?: InputMaybe<
    | Array<MarketingFunnelCardConnectionInput>
    | MarketingFunnelCardConnectionInput
  >
  additionalImages?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  additionalMetrics?: InputMaybe<Array<FunnelMetricInput> | FunnelMetricInput>
  cardTitle?: InputMaybe<Scalars['String']>
  hideCard?: InputMaybe<Scalars['Boolean']>
}>

export type UpdateMarketingBoardCardMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    updateMarketingFunnelCard: {
      __typename?: 'MarketingFunnelReport'
      boardID?: string | null
      boardTitle: string
      boardURL?: string | null
      boardDefinition: {
        __typename?: 'MarketingFunnelBoardDefinition'
        awarenessFilter?: string | null
        boardID: string
        boardTitle: string
        endDate?: string | null
        startDate: string
        filterList?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
        mainFilter?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
      }
      laneList: Array<{
        __typename?: 'MarketingFunnelLane'
        laneTitle: string
        laneOrder: number
        hasBeenReordered?: boolean | null
        laneID: string
        secondaryColour?: string | null
        primaryColour?: string | null
        selectedMetricList: Array<string>
        cardList: Array<{
          __typename?: 'MarketingFunnelCard'
          additionalImages?: Array<string> | null
          updatedTitle?: string | null
          lastEditedBy?: string | null
          lastEditedDate?: string | null
          hideCard?: boolean | null
          cardType: string
          cardTitle: string
          cardOrder: number
          cardID: string
          cardAnnotation?: string | null
          cardMetrics: Array<{
            __typename?: 'FunnelMetric'
            metricName: string
            metricValue?: number | null
          }>
          cardConnections?: Array<{
            __typename?: 'MarketingFunnelCardConnection'
            connectedFrom: string
            connectedTo: string
            connectionMetricName: string
            connectionMetricValue: number
            connectionType: string
            hideConnection?: boolean | null
            updatedConnectionMetricName?: string | null
            updatedConnectionMetricValue?: number | null
          }> | null
          additionalMetrics?: Array<{
            __typename?: 'FunnelMetric'
            metricName: string
            metricValue?: number | null
          }> | null
          additionalConnections?: Array<{
            __typename?: 'MarketingFunnelCardConnection'
            connectedFrom: string
            connectedTo: string
            connectionMetricName: string
            connectionMetricValue: number
            hideConnection?: boolean | null
            connectionType: string
            updatedConnectionMetricName?: string | null
            updatedConnectionMetricValue?: number | null
          }> | null
        }>
        laneMetrics: Array<{
          __typename?: 'FunnelMetric'
          metricValue?: number | null
          metricName: string
        }>
      }>
    }
  }
}

export type AddMarketingBoardCardMutationVariables = Exact<{
  boardID: Scalars['String']
  laneID: Scalars['String']
  cardTitle: Scalars['String']
  curCardOrder: Array<Scalars['String']> | Scalars['String']
}>

export type AddMarketingBoardCardMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    addMarketingFunnelCard: {
      __typename?: 'MarketingFunnelReport'
      boardID?: string | null
      boardTitle: string
      boardURL?: string | null
      boardDefinition: {
        __typename?: 'MarketingFunnelBoardDefinition'
        awarenessFilter?: string | null
        boardID: string
        boardTitle: string
        endDate?: string | null
        startDate: string
        filterList?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
        mainFilter?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
      }
      laneList: Array<{
        __typename?: 'MarketingFunnelLane'
        laneTitle: string
        laneOrder: number
        hasBeenReordered?: boolean | null
        laneID: string
        cardList: Array<{
          __typename?: 'MarketingFunnelCard'
          additionalImages?: Array<string> | null
          updatedTitle?: string | null
          lastEditedDate?: string | null
          lastEditedBy?: string | null
          cardType: string
          hideCard?: boolean | null
          cardTitle: string
          cardOrder: number
          cardID: string
          cardAnnotation?: string | null
          cardMetrics: Array<{
            __typename?: 'FunnelMetric'
            metricName: string
            metricValue?: number | null
          }>
          cardConnections?: Array<{
            __typename?: 'MarketingFunnelCardConnection'
            connectedFrom: string
            connectedTo: string
            connectionMetricName: string
            connectionMetricValue: number
            connectionType: string
            hideConnection?: boolean | null
            updatedConnectionMetricName?: string | null
            updatedConnectionMetricValue?: number | null
          }> | null
          additionalMetrics?: Array<{
            __typename?: 'FunnelMetric'
            metricName: string
            metricValue?: number | null
          }> | null
          additionalConnections?: Array<{
            __typename?: 'MarketingFunnelCardConnection'
            connectedFrom: string
            connectionMetricName: string
            connectedTo: string
            connectionMetricValue: number
            connectionType: string
            hideConnection?: boolean | null
            updatedConnectionMetricName?: string | null
            updatedConnectionMetricValue?: number | null
          }> | null
        }>
        laneMetrics: Array<{
          __typename?: 'FunnelMetric'
          metricName: string
          metricValue?: number | null
        }>
      }>
    }
  }
}

export type DeleteMarketingBoardCardMutationVariables = Exact<{
  boardID: Scalars['String']
  cardID: Scalars['String']
  laneID: Scalars['String']
}>

export type DeleteMarketingBoardCardMutation = {
  __typename?: 'Mutation'
  report: {
    __typename?: 'ReportMutations'
    deleteMarketingFunnelCard: {
      __typename?: 'MarketingFunnelReport'
      boardID?: string | null
      boardTitle: string
      boardURL?: string | null
      boardDefinition: {
        __typename?: 'MarketingFunnelBoardDefinition'
        awarenessFilter?: string | null
        boardID: string
        boardTitle: string
        endDate?: string | null
        startDate: string
        filterList?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
        mainFilter?: Array<{
          __typename?: 'DimensionFilterOutput'
          dimensionName: string
          dimensionOptions: Array<string>
          dimensionParameterID: string
        }> | null
      }
      laneList: Array<{
        __typename?: 'MarketingFunnelLane'
        laneID: string
        laneOrder: number
        laneTitle: string
        hasBeenReordered?: boolean | null
        secondaryColour?: string | null
        primaryColour?: string | null
        selectedMetricList: Array<string>
        cardList: Array<{
          __typename?: 'MarketingFunnelCard'
          additionalImages?: Array<string> | null
          cardAnnotation?: string | null
          cardID: string
          cardOrder: number
          cardTitle: string
          cardType: string
          hideCard?: boolean | null
          lastEditedBy?: string | null
          lastEditedDate?: string | null
          updatedTitle?: string | null
          additionalConnections?: Array<{
            __typename?: 'MarketingFunnelCardConnection'
            connectedFrom: string
            connectedTo: string
            connectionMetricName: string
            connectionMetricValue: number
            connectionType: string
            hideConnection?: boolean | null
            updatedConnectionMetricName?: string | null
            updatedConnectionMetricValue?: number | null
          }> | null
          additionalMetrics?: Array<{
            __typename?: 'FunnelMetric'
            metricName: string
            metricValue?: number | null
          }> | null
          cardConnections?: Array<{
            __typename?: 'MarketingFunnelCardConnection'
            connectedFrom: string
            connectedTo: string
            connectionMetricName: string
            connectionMetricValue: number
            connectionType: string
            hideConnection?: boolean | null
            updatedConnectionMetricName?: string | null
            updatedConnectionMetricValue?: number | null
          }> | null
          cardMetrics: Array<{
            __typename?: 'FunnelMetric'
            metricName: string
            metricValue?: number | null
          }>
        }>
        laneMetrics: Array<{
          __typename?: 'FunnelMetric'
          metricName: string
          metricValue?: number | null
        }>
      }>
    }
  }
}

export type FetchPageScreenshotsQueryVariables = Exact<{
  urlList: Array<Scalars['String']> | Scalars['String']
}>

export type FetchPageScreenshotsQuery = {
  __typename?: 'Query'
  report: {
    __typename?: 'ReportQueries'
    pagePreview: Array<{
      __typename?: 'PageScreenshot'
      landingPage: string
      screenshotBase64?: string | null
      screenshotLocation?: string | null
      screenshotType: string
    }>
  }
}

export type GetTrackValidationResultsByLandingPageQueryVariables = Exact<{
  urlList: Array<Scalars['String']> | Scalars['String']
  includeQueryPars?: InputMaybe<Scalars['Boolean']>
}>

export type GetTrackValidationResultsByLandingPageQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    getTrackValidationResultsByLandingPage: Array<{
      __typename?: 'TrackValidationResult'
      badUrl: boolean
      campaignCodeID: string
      noAnalyticsTag: boolean
      redirectedLandingPage: boolean
      slowLandingPage: boolean
      statusCode: number
    } | null>
  }
}

export type SendFeatureRequestMutationVariables = Exact<{
  message: Scalars['String']
  page: Scalars['String']
}>

export type SendFeatureRequestMutation = {
  __typename?: 'Mutation'
  sendFeatureRequest: boolean
}

export type CreateEnterpriseCompanyMutationVariables = Exact<{
  accountManagerList: Array<GenericEmailInput> | GenericEmailInput
  auditAvailable: Scalars['Boolean']
  autoRenew: Scalars['Boolean']
  breakClauseDate?: InputMaybe<Scalars['String']>
  businessDivision: Scalars['String']
  companyName: Scalars['String']
  companyNotes: Scalars['String']
  contractEndDate: Scalars['String']
  contractStartDate: Scalars['String']
  customTandC: Scalars['Boolean']
  dataSourceType: Scalars['String']
  domainLimit: Scalars['Int']
  domains: Scalars['String']
  explainAvailable: Scalars['Boolean']
  legalEntityName: Scalars['String']
  reportAvailable: Scalars['Boolean']
  revenuePerMonth: Scalars['Float']
  revenuePerMonthPostBreakClause?: InputMaybe<Scalars['Float']>
  revenuePerMonthPreBreakClause?: InputMaybe<Scalars['Float']>
  sponsorList: Array<GenericEmailInput> | GenericEmailInput
  totalContractValue: Scalars['Float']
  trackAvailable: Scalars['Boolean']
  userLimit: Scalars['Int']
  whitelabel?: InputMaybe<Scalars['String']>
}>

export type CreateEnterpriseCompanyMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    updateUplifterAdminSettings: {
      __typename?: 'UplifterAdminMutations'
      createEnterpriseCompany: {
        __typename?: 'Company'
        accountCount: number
        accountManagerList?: string | null
        activeState: string
        auditAvailable: boolean
        autoRenew?: boolean | null
        billingUserList?: string | null
        billingNotes?: string | null
        breakClause?: string | null
        breakClauseDate?: string | null
        breakClauseExists?: boolean | null
        businessDivision?: string | null
        companyID: string
        contractEndDate?: string | null
        companyNotes?: string | null
        contractLink?: string | null
        contractStartDate?: string | null
        created: string
        customTandC?: boolean | null
        mezzoAccountManager?: string | null
        revenuePerMonth?: number | null
        reportAvailable: boolean
        totalContractValue?: number | null
        userCount: number
        userLimit?: number | null
      }
    }
  }
}

export type UpdateEnterpriseCompanyMutationVariables = Exact<{
  companyID: Scalars['String']
  accountManagerList?: InputMaybe<Array<GenericEmailInput> | GenericEmailInput>
  auditAvailable?: InputMaybe<Scalars['Boolean']>
  autoRenew?: InputMaybe<Scalars['Boolean']>
  breakClauseDate?: InputMaybe<Scalars['String']>
  businessDivision?: InputMaybe<Scalars['String']>
  companyName?: InputMaybe<Scalars['String']>
  companyNotes?: InputMaybe<Scalars['String']>
  contractEndDate?: InputMaybe<Scalars['String']>
  contractStartDate?: InputMaybe<Scalars['String']>
  customTandC?: InputMaybe<Scalars['Boolean']>
  dataSourceType?: InputMaybe<Scalars['String']>
  domainLimit?: InputMaybe<Scalars['Int']>
  domains?: InputMaybe<Scalars['String']>
  explainAvailable?: InputMaybe<Scalars['Boolean']>
  legalEntityName?: InputMaybe<Scalars['String']>
  reportAvailable?: InputMaybe<Scalars['Boolean']>
  revenuePerMonth?: InputMaybe<Scalars['Float']>
  revenuePerMonthPostBreakClause?: InputMaybe<Scalars['Float']>
  revenuePerMonthPreBreakClause?: InputMaybe<Scalars['Float']>
  sponsorList?: InputMaybe<Array<GenericEmailInput> | GenericEmailInput>
  totalContractValue?: InputMaybe<Scalars['Float']>
  trackAvailable?: InputMaybe<Scalars['Boolean']>
  userLimit?: InputMaybe<Scalars['Int']>
}>

export type UpdateEnterpriseCompanyMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    updateUplifterAdminSettings: {
      __typename?: 'UplifterAdminMutations'
      updateEnterpriseCompany: {
        __typename?: 'Company'
        accountCount: number
        accountManagerList?: string | null
        activeState: string
        auditAvailable: boolean
        autoRenew?: boolean | null
        billingUserList?: string | null
        billingNotes?: string | null
        breakClause?: string | null
        breakClauseDate?: string | null
        breakClauseExists?: boolean | null
        businessDivision?: string | null
        companyID: string
        contractEndDate?: string | null
        companyNotes?: string | null
        contractLink?: string | null
        contractStartDate?: string | null
        created: string
        customTandC?: boolean | null
        mezzoAccountManager?: string | null
        origDataSource?: string | null
        revenuePerMonth?: number | null
        reportAvailable: boolean
        totalContractValue?: number | null
        userCount: number
        userLimit?: number | null
      }
    }
  }
}

export type AddSupportUserMutationVariables = Exact<{
  email: Scalars['String']
  whitelabel?: InputMaybe<Scalars['String']>
  permissionLevel: Scalars['String']
}>

export type AddSupportUserMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    makeUserSupportUser: {
      __typename?: 'User'
      userID: string
      email: string
      inviteOpen: boolean
      userAccountProfiles: Array<{
        __typename?: 'UserAccountProfile'
        userEmail: string
        accountID: string
        accountName: string
        userPermission: string
        companyID: string
        inviteOpen: boolean
      }>
    }
  }
}

export type ShareCodesMutationVariables = Exact<{
  codeList: Array<Scalars['String']> | Scalars['String']
  emailList: Array<Scalars['String']> | Scalars['String']
  note: Scalars['String']
  subject: Scalars['String']
}>

export type ShareCodesMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    shareCodes: {
      __typename?: 'TrackDirectDownloadShare'
      account: string
      cid: string
      codeShareData: string
      requester: string
      sent: string
      shortID: string
    }
  }
}

export type DeleteBatchCodesMutationVariables = Exact<{
  codeIDList: Array<Scalars['String']> | Scalars['String']
  customDomainID?: InputMaybe<Scalars['String']>
  deepLinkServiceID?: InputMaybe<Scalars['String']>
}>

export type DeleteBatchCodesMutation = {
  __typename?: 'Mutation'
  track: { __typename?: 'TrackMutations'; deleteCodes: Array<string> }
}

export type GetCampaignCodeGeneratorFieldTypesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetCampaignCodeGeneratorFieldTypesQuery = {
  __typename?: 'Query'
  campaignCodeGenerator: {
    __typename?: 'CampaignCodeGenerator'
    accountID: string
    paramDefs: Array<{
      __typename?: 'ParamDef'
      fieldID: string
      fieldType: string
    }>
  }
}

export type GetCampaignCodeGeneratorQueryVariables = Exact<{
  [key: string]: never
}>

export type GetCampaignCodeGeneratorQuery = {
  __typename?: 'Query'
  campaignCodeGenerator: {
    __typename?: 'CampaignCodeGenerator'
    accountID: string
    defaultPardotBusinessUnit?: string | null
    existingParametersAddedToStart: boolean
    isLocked: boolean
    masterPrefix: string
    paramSeparator: string
    paramDefs: Array<{
      __typename?: 'ParamDef'
      dateFormat?: string | null
      fieldAvailable: boolean
      fieldID: string
      fieldName: string
      fieldType: string
      fixedValue?: string | null
      forceLowerCase?: boolean | null
      helpText: string
      lengthLimit?: number | null
      isCampaignField?: boolean | null
      isCreativeField?: boolean | null
      metaParameter?: boolean | null
      prefix: string
      required: boolean
      copyFromField?: Array<{
        __typename?: 'CopyFromField'
        copyFromID: string
      }> | null
      emailDefault?: {
        __typename?: 'DefaultEmailParameter'
        optionID?: string | null
        optionName?: string | null
        optionValue?: string | null
        parameterID: string
      } | null
      parameterDependsOn?: {
        __typename?: 'OptionFilter'
        parentFieldID: string
        parentOptionIDs: Array<string>
      } | null
      selectFields?: Array<{
        __typename?: 'SelectField'
        hide: boolean
        optionID: string
        optionName: string
        optionValue: string
        optionFilter?: Array<{
          __typename?: 'OptionFilter'
          parentFieldID: string
          parentOptionIDs: Array<string>
        }> | null
      }> | null
    }>
    validationChecks: Array<{
      __typename?: 'ValidationCheck'
      enabled: boolean
      name: string
      value?: string | null
    }>
  }
}

export type GetShortLinkCustomDomainsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetShortLinkCustomDomainsQuery = {
  __typename?: 'Query'
  currentCompany: {
    __typename?: 'Company'
    companyID: string
    availableShortLinkCustomDomains: Array<{
      __typename?: 'ShortLinkCustomDomain'
      customDomainID: string
      customDomainName: string
    }>
  }
}

export type GetShortLinkCandidatesQueryVariables = Exact<{
  nLinks: Scalars['Int']
  customDomainID?: InputMaybe<Scalars['String']>
  deepLinkServiceID?: InputMaybe<Scalars['String']>
}>

export type GetShortLinkCandidatesQuery = {
  __typename?: 'Query'
  shortLinkCandidates:
    | {
        __typename: 'ShortLinkBatchCandidates'
        bulkStart: string
        nLinks: number
      }
    | { __typename: 'ShortLinkCandidates'; availableLinkIDs: Array<string> }
}

export type ValidateShortLinkCandidateQueryVariables = Exact<{
  testCandidate: Scalars['String']
  customDomainID?: InputMaybe<Scalars['String']>
  deepLinkServiceID?: InputMaybe<Scalars['String']>
}>

export type ValidateShortLinkCandidateQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    validateShortLinkCandidate: {
      __typename?: 'ShortLinkAvailable'
      availableUntil?: string | null
      isAvailable: boolean
      shortLinkID: string
    }
  }
}

export type CreateNewLinkMutationVariables = Exact<{
  customDomainID?: InputMaybe<Scalars['String']>
  codeList: Array<AddCode> | AddCode
  bulkStart?: InputMaybe<Scalars['String']>
  deepLinkConfig?: InputMaybe<DeepLinkInput>
}>

export type CreateNewLinkMutation = {
  __typename?: 'Mutation'
  addCodes: {
    __typename?: 'MinimalCodeList'
    accountID: string
    author: Array<string>
    codeDef: Array<Array<string>>
    codeID: Array<string>
    createdTime: Array<string>
    fullLink: Array<string>
    shortLink?: Array<string> | null
    totalCodes: number
    versionNumber: Array<number>
    minGenDef: Array<{
      __typename?: 'MinParamDef'
      paramID: string
      paramName: string
    }>
  }
}

export type SendUpdateRequestMutationVariables = Exact<{
  changeRequests: Array<ChangeRequestInput> | ChangeRequestInput
  requestNote: Scalars['String']
}>

export type SendUpdateRequestMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    sendUpdateRequest: { __typename?: 'UpdateRequest'; requestID: string }
  }
}

export type GetUplifterIdCurrentTotalQueryVariables = Exact<{
  [key: string]: never
}>

export type GetUplifterIdCurrentTotalQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    currentSequentialCodeID: {
      __typename?: 'UplifterIDSequentialTotal'
      accountID: string
      isEnabled?: boolean | null
      currentTotal: string
      etag: string
      prefix?: string | null
      acctPrefix: string
    }
  }
}

export type UpdateCurrentSequentialCodeIdMutationVariables = Exact<{
  currentTotal: Scalars['String']
  etag: Scalars['String']
  newTotal: Scalars['String']
}>

export type UpdateCurrentSequentialCodeIdMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateCurrentSequentialCodeID: {
      __typename?: 'UplifterIDSequentialTotal'
      currentTotal: string
      etag: string
      acctPrefix: string
      updatedTotal?: string | null
    }
  }
}

export type GetCreativeListQueryVariables = Exact<{
  parameterID: Scalars['String']
}>

export type GetCreativeListQuery = {
  __typename?: 'Query'
  report: {
    __typename?: 'ReportQueries'
    getCreativeList: Array<{
      __typename?: 'CreativeLibraryItem'
      accountID: string
      blobURL?: string | null
      createdBy: string
      createdTime: string
      creativeID: string
      creativeLink?: string | null
      creativeType: string
      optionID?: string | null
      optionName?: string | null
      parameterID: string
    }>
  }
}

export type DeleteCreativeLinkMutationVariables = Exact<{
  creativeID: Scalars['String']
}>

export type DeleteCreativeLinkMutation = {
  __typename?: 'Mutation'
  track: { __typename?: 'TrackMutations'; deleteCreativeLink: boolean }
}

export type GetUpdateRequestListQueryVariables = Exact<{ [key: string]: never }>

export type GetUpdateRequestListQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    updateRequestList: {
      __typename?: 'UpdateRequestList'
      pendingRequests: Array<{
        __typename?: 'UpdateRequest'
        completed: boolean
        requestDate: string
        requestID: string
        requestNote: string
        requestedBy: string
        reviewNote?: string | null
        reviewedBy?: string | null
        updateRequestAccountName: string
        updateRequestAccountID: string
        changeRequests?: Array<{
          __typename?: 'ChangeRequest'
          fieldID: string
          newOptions?: Array<{
            __typename?: 'ChangeRequestNewOption'
            approved?: boolean | null
            message?: string | null
            optionName: string
            optionRequestID?: string | null
            optionValue: string
            rejected?: boolean | null
          }> | null
        }> | null
      }>
    }
  }
}

export type CampaignCodeGeneratorParamDefsFragment = {
  __typename?: 'CampaignCodeGenerator'
  paramDefs: Array<{
    __typename?: 'ParamDef'
    fieldID: string
    fieldName: string
    fieldAvailable: boolean
    dateFormat?: string | null
    fieldType: string
    fixedValue?: string | null
    forceLowerCase?: boolean | null
    helpText: string
    isCreativeField?: boolean | null
    metaParameter?: boolean | null
    prefix: string
    required: boolean
    copyFromField?: Array<{
      __typename?: 'CopyFromField'
      copyFromID: string
    }> | null
    parameterDependsOn?: {
      __typename?: 'OptionFilter'
      parentFieldID: string
      parentOptionIDs: Array<string>
    } | null
    selectFields?: Array<{
      __typename?: 'SelectField'
      hide: boolean
      optionID: string
      optionName: string
      optionValue: string
      optionFilter?: Array<{
        __typename?: 'OptionFilter'
        parentFieldID: string
        parentOptionIDs: Array<string>
      }> | null
    }> | null
  }>
} & { ' $fragmentName'?: 'CampaignCodeGeneratorParamDefsFragment' }

export type UpdateGeneratorParameterOrderMutationVariables = Exact<{
  newOrder: Array<Scalars['String']> | Scalars['String']
}>

export type UpdateGeneratorParameterOrderMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateGeneratorParameterOrder: {
      __typename?: 'CampaignCodeGenerator'
      accountID: string
    } & {
      ' $fragmentRefs'?: {
        CampaignCodeGeneratorParamDefsFragment: CampaignCodeGeneratorParamDefsFragment
      }
    }
  }
}

export type AddNewGeneratorParameterMutationVariables = Exact<{
  fieldName: Scalars['String']
  fieldAvailable: Scalars['Boolean']
  fieldType: Scalars['String']
  fixedValue?: InputMaybe<Scalars['String']>
  dateFormat?: InputMaybe<Scalars['String']>
  required: Scalars['Boolean']
  prefix: Scalars['String']
  metaParameter?: InputMaybe<Scalars['Boolean']>
  helpText: Scalars['String']
  forceLowerCase?: InputMaybe<Scalars['Boolean']>
  isCreativeField?: InputMaybe<Scalars['Boolean']>
}>

export type AddNewGeneratorParameterMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    addGeneratorParameter: {
      __typename?: 'ParamDef'
      fieldID: string
      fieldName: string
      fieldAvailable: boolean
      fixedValue?: string | null
      dateFormat?: string | null
      fieldType: string
      forceLowerCase?: boolean | null
      helpText: string
      isCreativeField?: boolean | null
      metaParameter?: boolean | null
      prefix: string
      required: boolean
      copyFromField?: Array<{
        __typename?: 'CopyFromField'
        copyFromID: string
      }> | null
      parameterDependsOn?: {
        __typename?: 'OptionFilter'
        parentFieldID: string
        parentOptionIDs: Array<string>
      } | null
      selectFields?: Array<{
        __typename?: 'SelectField'
        hide: boolean
        optionID: string
        optionName: string
        optionValue: string
        optionFilter?: Array<{
          __typename?: 'OptionFilter'
          parentFieldID: string
          parentOptionIDs: Array<string>
        }> | null
      }> | null
    }
  }
}

export type UpdateGeneratorParameterByIdMutationVariables = Exact<{
  updateFieldID: Scalars['String']
  copyFromField?: InputMaybe<Array<CopyFromFieldInput> | CopyFromFieldInput>
  emailDefault?: InputMaybe<DefaultEmailParameterInput>
  fieldName?: InputMaybe<Scalars['String']>
  fieldAvailable?: InputMaybe<Scalars['Boolean']>
  fieldType?: InputMaybe<Scalars['String']>
  fixedValue?: InputMaybe<Scalars['String']>
  dateFormat?: InputMaybe<Scalars['String']>
  required?: InputMaybe<Scalars['Boolean']>
  prefix?: InputMaybe<Scalars['String']>
  metaParameter?: InputMaybe<Scalars['Boolean']>
  helpText?: InputMaybe<Scalars['String']>
  forceLowerCase?: InputMaybe<Scalars['Boolean']>
  parameterDependsOn?: InputMaybe<OptionFilterInput>
  isCampaignField?: InputMaybe<Scalars['Boolean']>
  isCreativeField?: InputMaybe<Scalars['Boolean']>
  lengthLimit?: InputMaybe<Scalars['Int']>
}>

export type UpdateGeneratorParameterByIdMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateGeneratorParameter: {
      __typename?: 'ParamDef'
      fieldID: string
      fieldName: string
      fieldAvailable: boolean
      dateFormat?: string | null
      fieldType: string
      fixedValue?: string | null
      forceLowerCase?: boolean | null
      isCampaignField?: boolean | null
      helpText: string
      isCreativeField?: boolean | null
      lengthLimit?: number | null
      metaParameter?: boolean | null
      prefix: string
      required: boolean
      copyFromField?: Array<{
        __typename?: 'CopyFromField'
        copyFromID: string
      }> | null
      parameterDependsOn?: {
        __typename?: 'OptionFilter'
        parentFieldID: string
        parentOptionIDs: Array<string>
      } | null
      selectFields?: Array<{
        __typename?: 'SelectField'
        hide: boolean
        optionID: string
        optionName: string
        optionValue: string
        optionFilter?: Array<{
          __typename?: 'OptionFilter'
          parentFieldID: string
          parentOptionIDs: Array<string>
        }> | null
      }> | null
    }
  }
}

export type DeleteGeneratorParameterByIdMutationVariables = Exact<{
  removeFieldID: Scalars['String']
}>

export type DeleteGeneratorParameterByIdMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    deleteGeneratorParameter: {
      __typename?: 'CampaignCodeGenerator'
      accountID: string
    } & {
      ' $fragmentRefs'?: {
        CampaignCodeGeneratorParamDefsFragment: CampaignCodeGeneratorParamDefsFragment
      }
    }
  }
}

export type UpdateParametersMasterRulesMutationVariables = Exact<{
  validationChecks?: InputMaybe<
    Array<GenericSettingInput> | GenericSettingInput
  >
}>

export type UpdateParametersMasterRulesMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateGeneratorMasterSettings: {
      __typename?: 'CampaignCodeGenerator'
      accountID: string
      validationChecks: Array<{
        __typename?: 'ValidationCheck'
        enabled: boolean
        name: string
        value?: string | null
      }>
    }
  }
}

export type UpdateMasterPrefixAndSeparatorMutationVariables = Exact<{
  masterPrefix?: InputMaybe<Scalars['String']>
  paramSeparator?: InputMaybe<Scalars['String']>
}>

export type UpdateMasterPrefixAndSeparatorMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateGeneratorMasterSettings: {
      __typename?: 'CampaignCodeGenerator'
      accountID: string
      masterPrefix: string
      paramSeparator: string
    }
  }
}

export type UpdateParameterSelectUpdateOptionMutationVariables = Exact<{
  fieldID: Scalars['String']
  optionID: Scalars['String']
  hide?: InputMaybe<Scalars['Boolean']>
  optionName?: InputMaybe<Scalars['String']>
  optionValue?: InputMaybe<Scalars['String']>
  optionFilter?: InputMaybe<Array<OptionFilterInput> | OptionFilterInput>
}>

export type UpdateParameterSelectUpdateOptionMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateGeneratorParameterSelectUpdateOption: {
      __typename?: 'CampaignCodeGenerator'
      accountID: string
    } & {
      ' $fragmentRefs'?: {
        CampaignCodeGeneratorParamDefsFragment: CampaignCodeGeneratorParamDefsFragment
      }
    }
  }
}

export type UpdateParameterSelectDeleteOptionMutationVariables = Exact<{
  fieldID: Scalars['String']
  optionID: Scalars['String']
}>

export type UpdateParameterSelectDeleteOptionMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateGeneratorParameterSelectDeleteOption: {
      __typename?: 'CampaignCodeGenerator'
      accountID: string
    } & {
      ' $fragmentRefs'?: {
        CampaignCodeGeneratorParamDefsFragment: CampaignCodeGeneratorParamDefsFragment
      }
    }
  }
}

export type UpdateParameterSelectResetParentMutationVariables = Exact<{
  fieldID: Scalars['String']
}>

export type UpdateParameterSelectResetParentMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateGeneratorParameterSelectDropParentChild: {
      __typename?: 'CampaignCodeGenerator'
      accountID: string
    } & {
      ' $fragmentRefs'?: {
        CampaignCodeGeneratorParamDefsFragment: CampaignCodeGeneratorParamDefsFragment
      }
    }
  }
}

export type UpdateParameterSelectAddOptionMutationVariables = Exact<{
  fieldID: Scalars['String']
  hide: Scalars['Boolean']
  optionName: Scalars['String']
  optionValue: Scalars['String']
}>

export type UpdateParameterSelectAddOptionMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateGeneratorParameterSelectAddOption: {
      __typename?: 'CampaignCodeGenerator'
      accountID: string
    } & {
      ' $fragmentRefs'?: {
        CampaignCodeGeneratorParamDefsFragment: CampaignCodeGeneratorParamDefsFragment
      }
    }
  }
}

export type UpdateParameterSelectAddMultipleOptionsMutationVariables = Exact<{
  fieldID: Scalars['String']
  optionList: Array<SelectOptionInput> | SelectOptionInput
}>

export type UpdateParameterSelectAddMultipleOptionsMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateGeneratorParameterSelectAddMultipleOptions: {
      __typename?: 'CampaignCodeGenerator'
      accountID: string
    } & {
      ' $fragmentRefs'?: {
        CampaignCodeGeneratorParamDefsFragment: CampaignCodeGeneratorParamDefsFragment
      }
    }
  }
}

export type GetCampaignCodeGeneratorByAccountIdQueryVariables = Exact<{
  accountID: Scalars['String']
}>

export type GetCampaignCodeGeneratorByAccountIdQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    campaignCodeGeneratorAccount: {
      __typename?: 'CampaignCodeGenerator'
      accountID: string
      masterPrefix: string
      paramSeparator: string
      paramDefs: Array<{
        __typename?: 'ParamDef'
        fieldID: string
        fieldName: string
        fieldAvailable: boolean
        fixedValue?: string | null
        dateFormat?: string | null
        fieldType: string
        forceLowerCase?: boolean | null
        helpText: string
        isCreativeField?: boolean | null
        metaParameter?: boolean | null
        prefix: string
        required: boolean
        copyFromField?: Array<{
          __typename?: 'CopyFromField'
          copyFromID: string
        }> | null
        parameterDependsOn?: {
          __typename?: 'OptionFilter'
          parentFieldID: string
          parentOptionIDs: Array<string>
        } | null
        selectFields?: Array<{
          __typename?: 'SelectField'
          hide: boolean
          optionID: string
          optionName: string
          optionValue: string
          optionFilter?: Array<{
            __typename?: 'OptionFilter'
            parentFieldID: string
            parentOptionIDs: Array<string>
          }> | null
        }> | null
      }>
      validationChecks: Array<{
        __typename?: 'ValidationCheck'
        enabled: boolean
        name: string
        value?: string | null
      }>
    }
  }
}

export type RejectUpdateRequestMutationVariables = Exact<{
  fieldID: Scalars['String']
  fieldName: Scalars['String']
  message?: InputMaybe<Scalars['String']>
  optionRequestID?: InputMaybe<Scalars['String']>
  optionRequestIndex?: InputMaybe<Scalars['Int']>
  requestID: Scalars['String']
}>

export type RejectUpdateRequestMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    rejectUpdateRequest: {
      __typename?: 'UpdateRequest'
      completed: boolean
      requestDate: string
      requestID: string
      requestNote: string
      requestedBy: string
      reviewNote?: string | null
      reviewedBy?: string | null
      updateRequestAccountName: string
      updateRequestAccountID: string
      changeRequests?: Array<{
        __typename?: 'ChangeRequest'
        fieldID: string
        newOptions?: Array<{
          __typename?: 'ChangeRequestNewOption'
          approved?: boolean | null
          message?: string | null
          optionName: string
          optionRequestID?: string | null
          optionValue: string
          rejected?: boolean | null
        }> | null
      }> | null
    }
  }
}

export type ApproveUpdateRequestMutationVariables = Exact<{
  accountIDList?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  fieldID: Scalars['String']
  fieldName: Scalars['String']
  message?: InputMaybe<Scalars['String']>
  newName: Scalars['String']
  newValue: Scalars['String']
  optionRequestID?: InputMaybe<Scalars['String']>
  optionRequestIndex?: InputMaybe<Scalars['Int']>
  requestID: Scalars['String']
}>

export type ApproveUpdateRequestMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    approveUpdateRequest: {
      __typename?: 'UpdateRequest'
      completed: boolean
      requestDate: string
      requestID: string
      requestNote: string
      requestedBy: string
      reviewNote?: string | null
      reviewedBy?: string | null
      updateRequestAccountName: string
      updateRequestAccountID: string
      changeRequests?: Array<{
        __typename?: 'ChangeRequest'
        fieldID: string
        newOptions?: Array<{
          __typename?: 'ChangeRequestNewOption'
          approved?: boolean | null
          message?: string | null
          optionName: string
          optionRequestID?: string | null
          optionValue: string
          rejected?: boolean | null
        }> | null
      }> | null
    }
  }
}

export type LockUnlockGeneratorMutationVariables = Exact<{
  isLocked?: InputMaybe<Scalars['Boolean']>
}>

export type LockUnlockGeneratorMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateGeneratorMasterSettings: {
      __typename?: 'CampaignCodeGenerator'
      accountID: string
      isLocked: boolean
    }
  }
}

export type UpdateExistingParamsAddedToStartMutationVariables = Exact<{
  existingParametersAddedToStart?: InputMaybe<Scalars['Boolean']>
}>

export type UpdateExistingParamsAddedToStartMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateGeneratorMasterSettings: {
      __typename?: 'CampaignCodeGenerator'
      accountID: string
      existingParametersAddedToStart: boolean
    }
  }
}

export type UpdateDefaultPardotBusinessUnitMutationVariables = Exact<{
  defaultPardotBusinessUnit: Scalars['String']
}>

export type UpdateDefaultPardotBusinessUnitMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateGeneratorMasterSettings: {
      __typename?: 'CampaignCodeGenerator'
      accountID: string
      defaultPardotBusinessUnit?: string | null
    }
  }
}

export type GetUplifterIdParamStateQueryVariables = Exact<{
  [key: string]: never
}>

export type GetUplifterIdParamStateQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    currentSequentialCodeID: {
      __typename?: 'UplifterIDSequentialTotal'
      accountID: string
      isEnabled?: boolean | null
    }
  }
}

export type UpdateUplifterIdEnabledStateMutationVariables = Exact<{
  isEnabled?: InputMaybe<Scalars['Boolean']>
}>

export type UpdateUplifterIdEnabledStateMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    updateCurrentSequentialCodeIDObject: {
      __typename?: 'UplifterIDSequentialTotal'
      accountID: string
      isEnabled?: boolean | null
    }
  }
}

export type GetAaftpDetailsQueryVariables = Exact<{ [key: string]: never }>

export type GetAaftpDetailsQuery = {
  __typename?: 'Query'
  accountSettings: {
    __typename?: 'AccountSettingsQueries'
    adobeFTPDetails: {
      __typename?: 'AdobeFTPAccount'
      adobeFTPHost?: string | null
      adobeFTPLogin?: string | null
      relationID?: string | null
      reportID?: string | null
    }
  }
}

export type UpdateAaftpDetailsMutationVariables = Exact<{
  reportID?: InputMaybe<Scalars['String']>
  relationID?: InputMaybe<Scalars['String']>
  adobeFTPPassword?: InputMaybe<Scalars['String']>
  adobeFTPLogin?: InputMaybe<Scalars['String']>
  adobeFTPHost?: InputMaybe<Scalars['String']>
}>

export type UpdateAaftpDetailsMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    updateAdobeFTPSettings: {
      __typename?: 'AdobeFTPAccount'
      reportID?: string | null
      relationID?: string | null
      adobeFTPLogin?: string | null
      adobeFTPHost?: string | null
    }
  }
}

export type ListDeepLinkServicesQueryVariables = Exact<{ [key: string]: never }>

export type ListDeepLinkServicesQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    deepLinkQueries: {
      __typename?: 'DeepLinkQueries'
      listDeepLinkServices: Array<{
        __typename?: 'DeepLinkService'
        companyID: string
        deepLinkServiceID: string
        deepLinkServiceName: string
        deepLinkServiceSubdomain: string
      }>
    }
  }
}

export type ListAppGroupsQueryVariables = Exact<{ [key: string]: never }>

export type ListAppGroupsQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    deepLinkQueries: {
      __typename?: 'DeepLinkQueries'
      listAppGroups: Array<{
        __typename?: 'DeepLinkAppGroup'
        accountID: string
        appGroupDescription?: string | null
        appGroupID: string
        appGroupName: string
        appGroupPath?: string | null
        companyID: string
        dateAdded: string
        deepLinkServiceID: string
        isUAT: boolean
        redirectWeb: string
        appList: Array<
          | {
              __typename?: 'DeepLinkAndroidApp'
              androidAppName?: string | null
              androidPackage?: string | null
              redirectAndroidUri?: string | null
              redirectAndroidPreferred?: string | null
              fallBackURL?: string | null
              companyID: string
              appID: string
              appGroupID: string
              androidPlayStoreLink?: string | null
              assetLinksAndroid?: string | null
              androidUrl?: string | null
              deepLinkServiceID: string
            }
          | {
              __typename?: 'DeepLinkIosApp'
              iosAppName?: string | null
              appGroupID: string
              appID: string
              companyID: string
              fallBackURL?: string | null
              iosAppStoreID?: string | null
              iosBundleID?: string | null
              iosTeamID?: string | null
              redirectIosDownload?: string | null
              redirectIosPreferred?: string | null
              redirectIosUri?: string | null
              deepLinkServiceID: string
            }
        >
      }>
    }
  }
}

export type CreateAppGroupMutationVariables = Exact<{
  deepLinkServiceID: Scalars['String']
  appGroupName: Scalars['String']
  redirectWeb: Scalars['String']
  appGroupDescription?: InputMaybe<Scalars['String']>
  appGroupPath?: InputMaybe<Scalars['String']>
  isUAT?: InputMaybe<Scalars['Boolean']>
}>

export type CreateAppGroupMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    trackDeeplinkingMutations: {
      __typename?: 'TrackDeeplinkingMutations'
      createAppGroup: {
        __typename?: 'DeepLinkAppGroup'
        accountID: string
        appGroupDescription?: string | null
        appGroupID: string
        appGroupName: string
        appGroupPath?: string | null
        redirectWeb: string
        isUAT: boolean
        dateAdded: string
        companyID: string
        appList: Array<
          | {
              __typename?: 'DeepLinkAndroidApp'
              androidAppName?: string | null
              androidPackage?: string | null
              redirectAndroidUri?: string | null
              redirectAndroidPreferred?: string | null
              fallBackURL?: string | null
              companyID: string
              appID: string
              appGroupID: string
              androidPlayStoreLink?: string | null
              assetLinksAndroid?: string | null
              androidUrl?: string | null
              deepLinkServiceID: string
            }
          | {
              __typename?: 'DeepLinkIosApp'
              iosAppName?: string | null
              appGroupID: string
              appID: string
              companyID: string
              fallBackURL?: string | null
              iosAppStoreID?: string | null
              iosBundleID?: string | null
              iosTeamID?: string | null
              redirectIosDownload?: string | null
              redirectIosPreferred?: string | null
              redirectIosUri?: string | null
              deepLinkServiceID: string
            }
        >
      }
    }
  }
}

export type UpdateAppGroupMutationVariables = Exact<{
  appGroupID: Scalars['String']
  appGroupDescription?: InputMaybe<Scalars['String']>
  appGroupName?: InputMaybe<Scalars['String']>
  appGroupPath?: InputMaybe<Scalars['String']>
  redirectWeb?: InputMaybe<Scalars['String']>
  isUAT?: InputMaybe<Scalars['Boolean']>
}>

export type UpdateAppGroupMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    trackDeeplinkingMutations: {
      __typename?: 'TrackDeeplinkingMutations'
      updateAppGroup: {
        __typename?: 'DeepLinkAppGroup'
        accountID: string
        appGroupDescription?: string | null
        appGroupID: string
        appGroupName: string
        appGroupPath?: string | null
        companyID: string
        dateAdded: string
        deepLinkServiceID: string
        isUAT: boolean
        redirectWeb: string
      }
    }
  }
}

export type DeleteAppGroupMutationVariables = Exact<{
  appGroupID: Scalars['String']
}>

export type DeleteAppGroupMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    trackDeeplinkingMutations: {
      __typename?: 'TrackDeeplinkingMutations'
      deleteAppGroup: boolean
    }
  }
}

export type RegisterAppMutationVariables = Exact<{
  appGroupID: Scalars['String']
  deepLinkServiceID: Scalars['String']
  device: Scalars['String']
  redirectMethod: Scalars['String']
  fallBackURL?: InputMaybe<Scalars['String']>
  iosAppStoreID?: InputMaybe<Scalars['String']>
  iosBundleID?: InputMaybe<Scalars['String']>
  iosTeamID?: InputMaybe<Scalars['String']>
  androidPackage?: InputMaybe<Scalars['String']>
  androidPlayStoreLink?: InputMaybe<Scalars['String']>
  androidURI?: InputMaybe<Scalars['String']>
  assetLinksAndroid?: InputMaybe<Scalars['String']>
  androidAppName?: InputMaybe<Scalars['String']>
  iosAppName?: InputMaybe<Scalars['String']>
}>

export type RegisterAppMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    trackDeeplinkingMutations: {
      __typename?: 'TrackDeeplinkingMutations'
      registerApp:
        | {
            __typename?: 'DeepLinkAndroidApp'
            androidAppName?: string | null
            androidPackage?: string | null
            redirectAndroidUri?: string | null
            redirectAndroidPreferred?: string | null
            fallBackURL?: string | null
            companyID: string
            appID: string
            appGroupID: string
            androidPlayStoreLink?: string | null
            assetLinksAndroid?: string | null
            androidUrl?: string | null
            deepLinkServiceID: string
          }
        | {
            __typename?: 'DeepLinkIosApp'
            iosAppName?: string | null
            appGroupID: string
            appID: string
            companyID: string
            fallBackURL?: string | null
            iosAppStoreID?: string | null
            iosBundleID?: string | null
            iosTeamID?: string | null
            redirectIosDownload?: string | null
            redirectIosPreferred?: string | null
            redirectIosUri?: string | null
            deepLinkServiceID: string
          }
    }
  }
}

export type UpdateRegisteredAppMutationVariables = Exact<{
  appID: Scalars['String']
  deepLinkServiceID: Scalars['String']
  device: Scalars['String']
  androidPackage?: InputMaybe<Scalars['String']>
  androidPlayStoreLink?: InputMaybe<Scalars['String']>
  androidURI?: InputMaybe<Scalars['String']>
  appGroupID?: InputMaybe<Scalars['String']>
  androidAppName?: InputMaybe<Scalars['String']>
  iosAppName?: InputMaybe<Scalars['String']>
  assetLinksAndroid?: InputMaybe<Scalars['String']>
  iosAppStoreID?: InputMaybe<Scalars['String']>
  iosBundleID?: InputMaybe<Scalars['String']>
  iosTeamID?: InputMaybe<Scalars['String']>
  iosURI?: InputMaybe<Scalars['String']>
}>

export type UpdateRegisteredAppMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    trackDeeplinkingMutations: {
      __typename?: 'TrackDeeplinkingMutations'
      updateApp:
        | {
            __typename?: 'DeepLinkAndroidApp'
            androidAppName?: string | null
            androidPackage?: string | null
            redirectAndroidUri?: string | null
            redirectAndroidPreferred?: string | null
            fallBackURL?: string | null
            companyID: string
            appID: string
            appGroupID: string
            androidPlayStoreLink?: string | null
            assetLinksAndroid?: string | null
            androidUrl?: string | null
            deepLinkServiceID: string
          }
        | {
            __typename?: 'DeepLinkIosApp'
            iosAppName?: string | null
            appGroupID: string
            appID: string
            companyID: string
            fallBackURL?: string | null
            iosAppStoreID?: string | null
            iosBundleID?: string | null
            iosTeamID?: string | null
            redirectIosDownload?: string | null
            redirectIosPreferred?: string | null
            redirectIosUri?: string | null
            deepLinkServiceID: string
          }
    }
  }
}

export type ListAppContextOptionsQueryVariables = Exact<{
  appGroupID: Scalars['String']
}>

export type ListAppContextOptionsQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    deepLinkQueries: {
      __typename?: 'DeepLinkQueries'
      listAppContextOptions: Array<{
        __typename?: 'AppContext'
        accountID: string
        addedBy: string
        companyID: string
        dateAdded: string
        dateLastUpdated: string
        lastUpdatedBy: string
        optionDisplayName: string
        optionID: string
        optionKey: string
        optionValue: string
        order: number
      }>
    }
  }
}

export type BulkAddAppContextMutationVariables = Exact<{
  appGroupID: Scalars['String']
  insertContextList: Array<AppContextInsertInput> | AppContextInsertInput
}>

export type BulkAddAppContextMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    trackDeeplinkingMutations: {
      __typename?: 'TrackDeeplinkingMutations'
      bulkAddAppContext: Array<{
        __typename?: 'AppContext'
        dateAdded: string
        addedBy: string
        lastUpdatedBy: string
        dateLastUpdated: string
        optionDisplayName: string
        optionID: string
        optionKey: string
        optionValue: string
        order: number
      }>
    }
  }
}

export type UpdateAppContextListMutationVariables = Exact<{
  appGroupID: Scalars['String']
  updateContextList: Array<AppContextUpdateInput> | AppContextUpdateInput
}>

export type UpdateAppContextListMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    trackDeeplinkingMutations: {
      __typename?: 'TrackDeeplinkingMutations'
      bulkUpdateAppContext: Array<{
        __typename?: 'AppContext'
        addedBy: string
        dateAdded: string
        dateLastUpdated: string
        optionDisplayName: string
        lastUpdatedBy: string
        optionKey: string
        optionID: string
        optionValue: string
        order: number
      }>
    }
  }
}

export type DeleteAppContextListOptionsMutationVariables = Exact<{
  appGroupID: Scalars['String']
  deleteContextList: Array<Scalars['String']> | Scalars['String']
}>

export type DeleteAppContextListOptionsMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    trackDeeplinkingMutations: {
      __typename?: 'TrackDeeplinkingMutations'
      bulkDeleteAppContext: Array<{
        __typename?: 'AppContext'
        dateAdded: string
        addedBy: string
        dateLastUpdated: string
        lastUpdatedBy: string
        optionDisplayName: string
        optionID: string
        optionKey: string
        order: number
        optionValue: string
      }>
    }
  }
}

export type GetMinCodesByAccountQueryVariables = Exact<{
  codeIDList?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  dimensionFilter?: InputMaybe<DimensionFilter>
  filterByCurrentUser?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  noReturn?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<SortFilter>
  refreshCache?: InputMaybe<Scalars['Boolean']>
  startDate?: InputMaybe<Scalars['String']>
  endDate?: InputMaybe<Scalars['String']>
}>

export type GetMinCodesByAccountQuery = {
  __typename?: 'Query'
  minCodesByAccount: {
    __typename?: 'MinimalCodeList'
    accountID: string
    author: Array<string>
    codeDef: Array<Array<string>>
    codeID: Array<string>
    createdTime: Array<string>
    fullLink: Array<string>
    shortLink?: Array<string> | null
    totalCodes: number
    versionNumber: Array<number>
    minGenDef: Array<{
      __typename?: 'MinParamDef'
      paramID: string
      paramName: string
    }>
  }
}

export type GetUrlValidationStatusQueryVariables = Exact<{
  codeIDList: Array<Scalars['String']> | Scalars['String']
}>

export type GetUrlValidationStatusQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    accountID: string
    trackValidationResults: Array<{
      __typename?: 'TrackValidationResult'
      statusCode: number
      badUrl: boolean
      campaignCodeID: string
      noAnalyticsTag: boolean
      redirectedLandingPage: boolean
      slowLandingPage: boolean
    } | null>
  }
}

export type GetStoredCodesStatsQueryVariables = Exact<{
  codeIDList?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  dimensionFilter?: InputMaybe<DimensionFilter>
  filterByCurrentUser?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  noReturn?: InputMaybe<Scalars['Boolean']>
  offset?: InputMaybe<Scalars['Int']>
  orderBy?: InputMaybe<SortFilter>
  refreshCache?: InputMaybe<Scalars['Boolean']>
}>

export type GetStoredCodesStatsQuery = {
  __typename?: 'Query'
  storedCodeStats: {
    __typename?: 'CodeMetricData'
    accountID: string
    codeIDs: Array<string>
    earliestClickDate?: string | null
    earliestMetricDate?: string | null
    earliestDeepLinkDate?: string | null
    status?: Array<string> | null
    totalCodes: number
    metricValues: Array<{
      __typename?: 'CodeMetricDataValues'
      displayName: string
      helpText: string
      metricID: string
      totalMetricValues: Array<number>
      units: string
    }>
  }
}

export type QuickUrlValidationQueryVariables = Exact<{
  urlList: Array<Scalars['String']> | Scalars['String']
}>

export type QuickUrlValidationQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    quickUrlValidation: {
      __typename?: 'QuickUrlValidationResults'
      validationResults: Array<{
        __typename?: 'QuickUrlValidation'
        statusCode: number
        testUrl: string
      }>
    }
  }
}

export type IntensiveUrlValidationQueryVariables = Exact<{
  urlList: Array<Scalars['String']> | Scalars['String']
}>

export type IntensiveUrlValidationQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    intensiveUrlValidation: {
      __typename?: 'IntensiveUrlValidationResults'
      validationResults: Array<{
        __typename?: 'IntensiveUrlValidation'
        badUrl: boolean
        clickedCookieConsent: boolean
        noAnalyticsTag: boolean
        redirectedLandingPage: boolean
        slowLandingPage: boolean
        statusCode: number
        testUrl: string
        validatorReturned: boolean
      }>
    }
  }
}

export type AddShortLinkExistingCodeMutationVariables = Exact<{
  codeID: Scalars['String']
  customDomainID?: InputMaybe<Scalars['String']>
  newShortLinkID: Scalars['String']
}>

export type AddShortLinkExistingCodeMutation = {
  __typename?: 'Mutation'
  track: {
    __typename?: 'TrackMutations'
    addShortLinkExistingCode: {
      __typename?: 'CampaignCode'
      c: string
      cID: string
      e: string
      edB?: string | null
      edT?: string | null
      sL: string
      t: string
      vN?: number | null
      cDef: Array<{
        __typename?: 'CodeDef'
        fID: string
        n?: string | null
        v?: string | null
      }>
    }
  }
}

export type UpdateCodeMutationVariables = Exact<{
  codeID: Scalars['String']
  fullCode?: InputMaybe<Scalars['String']>
  paramDefs?: InputMaybe<Array<ParamDefInput> | ParamDefInput>
  customDomainID?: InputMaybe<Scalars['String']>
  deepLinkConfig?: InputMaybe<DeepLinkInput>
}>

export type UpdateCodeMutation = {
  __typename?: 'Mutation'
  updateCode: {
    __typename?: 'CampaignCode'
    c: string
    cID: string
    e: string
    edB?: string | null
    edT?: string | null
    sL: string
    t: string
    vN?: number | null
    cDef: Array<{
      __typename?: 'CodeDef'
      fID: string
      n?: string | null
      v?: string | null
    }>
  }
}

export type FetchLinkEditHistoryQueryVariables = Exact<{
  codeID: Scalars['String']
}>

export type FetchLinkEditHistoryQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    codeVersionHistory: {
      __typename?: 'CodeVersionHistory'
      changeMap: Array<Array<boolean>>
      editedBy: Array<string>
      editedTime: Array<string>
      codeID: string
      createdTime: string
      author: string
      versionHistory: {
        __typename?: 'MinimalCodeList'
        linkID?: string | null
        codeDef: Array<Array<string>>
        fullLink: Array<string>
        totalCodes: number
        shortLink?: Array<string> | null
        versionNumber: Array<number>
        minGenDef: Array<{
          __typename?: 'MinParamDef'
          paramID: string
          paramName: string
        }>
      }
    }
  }
}

export type GetDeeplinkDetailsQueryVariables = Exact<{
  codeID: Scalars['String']
  deepLinkServiceID: Scalars['String']
}>

export type GetDeeplinkDetailsQuery = {
  __typename?: 'Query'
  track: {
    __typename?: 'TrackQueries'
    deepLinkQueries: {
      __typename?: 'DeepLinkQueries'
      getDeeplinkDetails: {
        __typename?: 'DeepLink'
        accountID: string
        analyticsContext?: string | null
        appGroupID: string
        campaignCodeID: string
        companyID: string
        deepLinkServiceID: string
        deepLinkShortLink?: string | null
        fallBackURL?: string | null
        ogDescription?: string | null
        ogImage?: string | null
        ogImageAlt?: string | null
        ogImageHeight?: string | null
        ogImageWidth?: string | null
        ogTitle?: string | null
        redirectContext?: string | null
      }
    }
  }
}

export type GetPreferredHomepageQueryVariables = Exact<{ [key: string]: never }>

export type GetPreferredHomepageQuery = {
  __typename?: 'Query'
  currentAccount: {
    __typename?: 'Account'
    accountID: string
    trackAvailable: boolean
    reportAvailable: boolean
    auditAvailable: boolean
    explainAvailable: boolean
  }
  currentUser: {
    __typename?: 'CurrentUser'
    userID: string
    preferredHomepage: string
  }
}

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetUserInfoQuery = {
  __typename?: 'Query'
  currentUser: {
    __typename?: 'CurrentUser'
    curAccountID: string
    currentAccountPermission: string
    email: string
    firstName: string
    lastName: string
    profileImgLink: string
    userID: string
    userName: string
    whitelabelAdmin?: string | null
  }
  currentAccount: {
    __typename?: 'Account'
    accountID: string
    accountName: string
    homepage: string
    companyID: string
    companyName: string
    created: string
    isDemo: boolean
    trackAvailable: boolean
    reportAvailable: boolean
    explainAvailable: boolean
  }
}

export type GetProfileInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetProfileInfoQuery = {
  __typename?: 'Query'
  currentUser: {
    __typename?: 'CurrentUser'
    email: string
    telNum: string
    firstName: string
    lastName: string
    userName: string
    userID: string
    publicJobTitle: string
    profileImgLink: string
    acceptedMarketing: string
  }
}

export type GetUserAccountsQueryVariables = Exact<{ [key: string]: never }>

export type GetUserAccountsQuery = {
  __typename?: 'Query'
  currentUser: {
    __typename?: 'CurrentUser'
    userID: string
    userAccountProfiles: Array<{
      __typename?: 'UserAccountProfile'
      accountID: string
      accountName: string
      companyID: string
      companyName: string
      userPermission: string
      homepage: string
    }>
  }
}

export type UpdateUserProfileMutationVariables = Exact<{
  username?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  jobTitle?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
}>

export type UpdateUserProfileMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    updateUser: {
      __typename?: 'CurrentUser'
      userID: string
      firstName: string
      lastName: string
      userName: string
      publicJobTitle: string
      email: string
      telNum: string
    }
  }
}

export type UpdateUserHomepageMutationVariables = Exact<{
  newPage: Scalars['String']
}>

export type UpdateUserHomepageMutation = {
  __typename?: 'Mutation'
  updateUserHomepage: {
    __typename?: 'CurrentUser'
    userID: string
    preferredHomepage: string
  }
}

export type UpdateMarketingPermissionMutationVariables = Exact<{
  allowMarketing: Scalars['Boolean']
}>

export type UpdateMarketingPermissionMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    updateMarketingPermission: {
      __typename?: 'CurrentUser'
      userID: string
      acceptedMarketing: string
    }
  }
}

export type GetEmailNotificationsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetEmailNotificationsQuery = {
  __typename?: 'Query'
  currentUser: {
    __typename?: 'CurrentUser'
    userID: string
    userAccountProfiles: Array<{
      __typename?: 'UserAccountProfile'
      accountID: string
      explainEmail: boolean
      reportEmail: boolean
      trackEmail: boolean
    }>
  }
}

export type ToggleEmailNotificationsMutationVariables = Exact<{
  accountID: Scalars['String']
  explain?: InputMaybe<Scalars['Boolean']>
  report?: InputMaybe<Scalars['Boolean']>
  track?: InputMaybe<Scalars['Boolean']>
}>

export type ToggleEmailNotificationsMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    toggleEmailNotifications: {
      __typename?: 'CurrentUser'
      userID: string
      userAccountProfiles: Array<{
        __typename?: 'UserAccountProfile'
        accountID: string
        trackEmail: boolean
        reportEmail: boolean
        explainEmail: boolean
      }>
    }
  }
}

export type GetAccountDataSourceQueryVariables = Exact<{ [key: string]: never }>

export type GetAccountDataSourceQuery = {
  __typename?: 'Query'
  currentAccount: {
    __typename?: 'Account'
    accountID: string
    blankAccount: boolean
    trackLearnCopy?: string | null
    dataSource?: {
      __typename?: 'DataSource'
      adobeReportSuiteName?: string | null
      adobeSegmentName?: string | null
      connected: boolean
      gaAccountName?: string | null
      gaPropertyName?: string | null
      gaViewName?: string | null
      ga4AccountName?: string | null
      ga4PropertyName?: string | null
      kind: string
    } | null
  }
}

export type GetCompanyWorkspacesDataSourcesQueryVariables = Exact<{
  [key: string]: never
}>

export type GetCompanyWorkspacesDataSourcesQuery = {
  __typename?: 'Query'
  currentCompany: {
    __typename?: 'Company'
    companyID: string
    accountList: Array<{
      __typename?: 'Account'
      accountID: string
      accountName: string
      homepage: string
      blankAccount: boolean
      dataSource?: {
        __typename?: 'DataSource'
        gaPropertyID?: string | null
        ga4PropertyID?: string | null
        gaAccountName?: string | null
        gaAccountID?: string | null
        ga4PropertyName?: string | null
        ga4AccountName?: string | null
        connected: boolean
        adobeSegmentName?: string | null
        adobeReportSuiteName?: string | null
        adobeReportID?: string | null
        ga4AccountID?: string | null
        gaPropertyName?: string | null
        gaViewID?: string | null
        gaViewName?: string | null
        kind: string
      } | null
    }>
  }
}

export type GetAccountUsersQueryVariables = Exact<{
  accountID: Scalars['String']
}>

export type GetAccountUsersQuery = {
  __typename?: 'Query'
  account: {
    __typename?: 'Account'
    accountID: string
    userAccountProfiles: Array<{
      __typename?: 'UserAccountProfile'
      accountID: string
      accountName: string
      created: string
      expired: boolean
      inviteOpen: boolean
      lastLogin: string
      userEmail: string
      userID: string
      userPermission: string
    }>
  }
}

export type UpdateUserPermissionsMutationVariables = Exact<{
  accountID: Scalars['String']
  userID: Scalars['String']
  permissionLevel: Scalars['String']
}>

export type UpdateUserPermissionsMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    updateUserPermissionLevel: {
      __typename?: 'User'
      email: string
      userID: string
    }
  }
}

export type RemoveUserMutationVariables = Exact<{
  accountID: Scalars['String']
  userID: Scalars['String']
}>

export type RemoveUserMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    removeUser: {
      __typename?: 'Account'
      accountID: string
      userAccountProfiles: Array<{
        __typename?: 'UserAccountProfile'
        accountID: string
        accountName: string
        created: string
        expired: boolean
        inviteOpen: boolean
        lastLogin: string
        userEmail: string
        userID: string
        userPermission: string
      }>
    }
  }
}

export type RevokeUserInviteMutationVariables = Exact<{
  userID: Scalars['String']
}>

export type RevokeUserInviteMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    revokeUserInvite: boolean
  }
}

export type ToggleModuleAvailabilityMutationVariables = Exact<{
  accountID: Scalars['String']
  companyID: Scalars['String']
  explainModule?: InputMaybe<Scalars['Boolean']>
  reportModule?: InputMaybe<Scalars['Boolean']>
  trackModule?: InputMaybe<Scalars['Boolean']>
}>

export type ToggleModuleAvailabilityMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    toggleModuleAvailability:
      | {
          __typename: 'Account'
          accountID: string
          explainAvailable: boolean
          reportAvailable: boolean
          trackAvailable: boolean
        }
      | { __typename?: 'Company' }
  }
}

export type DeleteWorkspaceMutationVariables = Exact<{
  workspaceID: Scalars['String']
}>

export type DeleteWorkspaceMutation = {
  __typename?: 'Mutation'
  userAccountSettings: {
    __typename?: 'UserAccountSettings'
    deleteAccount: {
      __typename?: 'RemoveAccountResult'
      newToken?: string | null
      success: boolean
      wasUserCurrentAccount: boolean
    }
  }
}

export const CampaignCodeGeneratorParamDefsFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CampaignCodeGeneratorParamDefs' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CampaignCodeGenerator' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paramDefs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'copyFromField' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'copyFromID' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fieldID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fieldName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fieldAvailable' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dateFormat' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fieldType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fixedValue' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'forceLowerCase' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'helpText' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCreativeField' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metaParameter' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parameterDependsOn' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentFieldID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parentOptionIDs' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'prefix' } },
                { kind: 'Field', name: { kind: 'Name', value: 'required' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'selectFields' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'hide' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optionFilter' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentFieldID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentOptionIDs' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optionID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optionName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optionValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<CampaignCodeGeneratorParamDefsFragment, unknown>
export const LogActionDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LogAction' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'action' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'functionName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagePath' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'websiteSection' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'extra' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          defaultValue: { kind: 'StringValue', value: '', block: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'getParams' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          defaultValue: { kind: 'StringValue', value: '', block: false },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'requestBody' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          defaultValue: { kind: 'StringValue', value: '', block: false },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authenticatedLogAction' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'action' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'action' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'functionName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'functionName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagePath' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pagePath' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'websiteSection' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'websiteSection' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'extra' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'extra' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'getParams' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'getParams' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requestBody' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'requestBody' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'acctID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'action' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<LogActionMutation, LogActionMutationVariables>
export const SwitchAccountDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'changeCurrentAccount' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'accountID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'workspaceID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  SwitchAccountMutation,
  SwitchAccountMutationVariables
>
export const GetCompanyDetailsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompanyDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountCount' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'companyID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptionLevel' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paddleSubscriptionStatus' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalContactName' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'internalContactEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'microsoftSubscriptionId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paymentProcessor' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userLimit' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCompanyDetailsQuery,
  GetCompanyDetailsQueryVariables
>
export const GetPublicApiTokenDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPublicAPIToken' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publicApiKey' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetPublicApiTokenQuery,
  GetPublicApiTokenQueryVariables
>
export const CreatePublicApiTokenDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePublicAPIToken' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createPublicAPIKey' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publicApiKey' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreatePublicApiTokenMutation,
  CreatePublicApiTokenMutationVariables
>
export const UpdateCompanyInternalContactDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanyInternalContact' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newEmail' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateCompanyInternalContact' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'newEmail' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newEmail' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'newName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newName' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalContactName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'internalContactEmail' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateCompanyInternalContactMutation,
  UpdateCompanyInternalContactMutationVariables
>
export const AddNewWorkspaceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddNewWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'copyGeneratorAccountID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'copyQRSettingsAccountID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'copyQRLogoAccountID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'copyAccountLogoID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addAccount' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'accountName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'workspaceName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'copyGeneratorAccountID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'copyGeneratorAccountID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'copyQRSettingsAccountID' },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'copyQRSettingsAccountID',
                        },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'copyQRLogoAccountID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'copyQRLogoAccountID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'copyAccountLogoID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'copyAccountLogoID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'explainAvailable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trackAvailable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'auditAvailable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportAvailable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'homepage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isDemo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  AddNewWorkspaceMutation,
  AddNewWorkspaceMutationVariables
>
export const UpdateCompanyNameDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanyName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateCompanyName' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'newName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newName' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateCompanyNameMutation,
  UpdateCompanyNameMutationVariables
>
export const UpdateAccountDescriptionDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAccountDescription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatedAccountDomain' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatedAccountName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'trackLearnCopy' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateAccountDescription' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'accountID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'updatedAccountDomain' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'updatedAccountDomain' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'updatedAccountName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'updatedAccountName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'trackLearnCopy' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackLearnCopy' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'homepage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trackLearnCopy' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateAccountDescriptionMutation,
  UpdateAccountDescriptionMutationVariables
>
export const GetCurrentAccountQrDetailsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentAccountQRDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qrSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qrCodeLogoList' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'logoLink' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCurrentAccountQrDetailsQuery,
  GetCurrentAccountQrDetailsQueryVariables
>
export const GetQrLogoImageDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetQrLogoImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'blobName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qrCodeLogoSrc' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'blobName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'blobName' },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetQrLogoImageQuery, GetQrLogoImageQueryVariables>
export const DeleteQrCodeImageDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteQrCodeImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'blobName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteQrCodeImage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'blobName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'blobName' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qrCodeLogoList' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteQrCodeImageMutation,
  DeleteQrCodeImageMutationVariables
>
export const UpdateQrSettingsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateQrSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'qrSettings' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'GenericSettingInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateQrSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'qrSettings' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'qrSettings' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'qrSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateQrSettingsMutation,
  UpdateQrSettingsMutationVariables
>
export const GetPaddleSubscriptionDetailsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPaddleSubscriptionDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paddleCancelUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paddleSubscriptionCheckoutId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paddleSubscriptionCurrency' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paddleSubscriptionEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paddleSubscriptionId' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'paddleSubscriptionLastAlertName',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'paddleSubscriptionLastAlertId',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'paddleSubscriptionLastEventTime',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'paddleSubscriptionLastCustomData',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'paddleSubscriptionLinkedSubscriptions',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'paddleSubscriptionMarketingConsent',
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'paddleSubscriptionNextBillDate',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paddleSubscriptionPlanId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paddleSubscriptionQuantity' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paddleSubscriptionSource' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paddleSubscriptionStatus' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paddleSubscriptionUnitPrice' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paddleSubscriptionUserId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paddleUpdateUrl' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscriptionLevel' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetPaddleSubscriptionDetailsQuery,
  GetPaddleSubscriptionDetailsQueryVariables
>
export const UpdatePaddleSeatsQuantityDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePaddleSeatsQuantity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatedQuantity' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paddleEnv' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatePaddleQuantity' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'companyID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'updatedQuantity' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'updatedQuantity' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'paddleEnv' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'paddleEnv' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionCheckoutId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionCurrency',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionEmail',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionLastAlertId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionLastAlertName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionLastCustomData',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionLastEventTime',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionNextBillDate',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionLinkedSubscriptions',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionMarketingConsent',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionPlanId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionQuantity',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionSource',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionStatus',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionUnitPrice',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'paddleSubscriptionUserId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paddleUpdateUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdatePaddleSeatsQuantityMutation,
  UpdatePaddleSeatsQuantityMutationVariables
>
export const GetEnterpriseBillingDetailGqlDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEnterpriseBillingDetailGQL' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enterpriseBillingDetail' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'auditAvailable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'autoRenew' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'billingUserList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessDivision' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractEndDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contractStartDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customTandC' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'domains' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'domainLimit' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'explainAvailable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'legalEntityName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'origDataSource' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportAvailable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sponsorList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalContractValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trackAvailable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userLimit' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountManagerList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetEnterpriseBillingDetailGqlQuery,
  GetEnterpriseBillingDetailGqlQueryVariables
>
export const DeleteCompanyDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'closeCompany' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'companyID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyID' },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables
>
export const GetMicrosoftSubscriptionDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMicrosoftSubscriptionData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'microsoftSubscriptionData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subscriptionID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'planId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'planName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodStartDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'periodEndDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quantity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termUnit' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetMicrosoftSubscriptionDataQuery,
  GetMicrosoftSubscriptionDataQueryVariables
>
export const ListAzureMarketplaceQueriesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAzureMarketplaceQueries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'azureMarketplaceQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listPlans' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingTerms' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'currency' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'price' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'termDescription',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'termUnit' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasFreeTrials' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'displayName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isPricePerSeat' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'maxQuantity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isStopSell' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minQuantity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'planId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ListAzureMarketplaceQueriesQuery,
  ListAzureMarketplaceQueriesQueryVariables
>
export const ChangeMicrosoftMarketplacePlanDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeMicrosoftMarketplacePlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newPlanID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'changeMicrosoftMarketplacePlan',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'companyID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'newPlanID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'newPlanID' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paymentProcessor' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ChangeMicrosoftMarketplacePlanMutation,
  ChangeMicrosoftMarketplacePlanMutationVariables
>
export const UpdateMicrosoftMarketplaceSeatsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMicrosoftMarketplaceSeats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'totalSeats' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'updateMicrosoftMarketplaceSeats',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'companyID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'totalSeats' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'totalSeats' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'paymentProcessor' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateMicrosoftMarketplaceSeatsMutation,
  UpdateMicrosoftMarketplaceSeatsMutationVariables
>
export const CancelMicrosoftMarketplaceSubscriptionDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelMicrosoftMarketplaceSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'billingMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'cancelMicrosoftMarketplaceSubscription',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'companyID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CancelMicrosoftMarketplaceSubscriptionMutation,
  CancelMicrosoftMarketplaceSubscriptionMutationVariables
>
export const GetCompanyAccountsAndUsersDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompanyAccountsAndUsers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userAccountProfiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userEmail' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCompanyAccountsAndUsersQuery,
  GetCompanyAccountsAndUsersQueryVariables
>
export const GetIntegrationsStatusDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetIntegrationsStatus' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pardotIntegrationStatus' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mondayIntegrationStatus' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eloquaIntegrationStatus' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'salesforceMcIntegrationStatus',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salesforceMcIntegrationList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'mid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'businessUnitName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetIntegrationsStatusQuery,
  GetIntegrationsStatusQueryVariables
>
export const GetOktaDomainsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOktaDomains' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oktaDomainList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'oktaDomains' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'clientID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateCreated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailDomain' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'loginLink' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tenantDomain' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'tenantName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetOktaDomainsQuery, GetOktaDomainsQueryVariables>
export const AddOktaDomainDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddOktaDomain' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientSecret' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emailDomain' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tenantName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tenantDomain' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addOktaDomain' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'clientID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clientID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clientSecret' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'emailDomain' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'emailDomain' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'tenantName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tenantName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'tenantDomain' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tenantDomain' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clientID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailDomain' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loginLink' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenantName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateCreated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenantDomain' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  AddOktaDomainMutation,
  AddOktaDomainMutationVariables
>
export const UpdateOktaDomainDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOktaDomain' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emailDomain' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tenantName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientSecret' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateOktaDomain' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'clientID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clientID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'clientSecret' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clientSecret' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'emailDomain' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'emailDomain' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'tenantName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'tenantName' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clientID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailDomain' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loginLink' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenantName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateCreated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenantDomain' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateOktaDomainMutation,
  UpdateOktaDomainMutationVariables
>
export const DeleteOktaDomainDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteOktaDomain' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emailDomain' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleteOktaDomain' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'clientID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'clientID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'emailDomain' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'emailDomain' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clientID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailDomain' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loginLink' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenantName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateCreated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tenantDomain' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteOktaDomainMutation,
  DeleteOktaDomainMutationVariables
>
export const GetMondayOauthLinkDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMondayOauthLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mondayQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'genMondayoauth2Link' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'state' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'state' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'oauth2Link' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetMondayOauthLinkQuery,
  GetMondayOauthLinkQueryVariables
>
export const SaveMondayOAuthDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveMondayOAuth' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mondayMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saveMondayAuth' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'code' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'code' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mondayWorkspaces' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mondayWorkspaceDescription',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mondayWorkspaceId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mondayWorkspaceName',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  SaveMondayOAuthMutation,
  SaveMondayOAuthMutationVariables
>
export const GetMondayWorkspacesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMondayWorkspaces' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mondayQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getWorkspaces' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mondayWorkspaces' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mondayWorkspaceDescription',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mondayWorkspaceId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'mondayWorkspaceName',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetMondayWorkspacesQuery,
  GetMondayWorkspacesQueryVariables
>
export const CreateMondayBoardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateMondayBoard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mondayMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mondayCreateBoard' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'workspaceID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'workspaceID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'workspaceName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'workspaceName' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mondayUrlSlug' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'mondayWorkspaceId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'mondayWorkspaceName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mondayBoardId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mondayBoardName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mondayGroupId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mondayGroupName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateMondayBoardMutation,
  CreateMondayBoardMutationVariables
>
export const GetMondayIDsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMondayIDs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mondayQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getMondayIds' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mondayUrlSlug' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mondayBoardId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mondayBoardName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mondayGroupId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mondayGroupName' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'mondayWorkspaceId',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'mondayWorkspaceName',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetMondayIDsQuery, GetMondayIDsQueryVariables>
export const RemoveMondayIntegrationDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveMondayIntegration' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mondayMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'mondayRemoveIntegration',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  RemoveMondayIntegrationMutation,
  RemoveMondayIntegrationMutationVariables
>
export const GetSalesforceOauthLinkDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSalesforceOauthLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSourceQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'genSalesforceOauthLink' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'state' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'state' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'oauth2Link' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetSalesforceOauthLinkQuery,
  GetSalesforceOauthLinkQueryVariables
>
export const HandleSalesforcePardotCallbackDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'HandleSalesforcePardotCallback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'encryptedState' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salesforcePardotMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'handleSalesforcePardotCallback',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'code' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'code' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'encryptedState' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'encryptedState' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pardotID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pardotName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'pardotDisplayName',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  HandleSalesforcePardotCallbackMutation,
  HandleSalesforcePardotCallbackMutationVariables
>
export const GetAvailableSalesforcePardotAccountsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailableSalesforcePardotAccounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'getAvailableSalesforcePardotAccounts',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pardotList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'pardotDisplayName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAvailableSalesforcePardotAccountsQuery,
  GetAvailableSalesforcePardotAccountsQueryVariables
>
export const GetSavedPardotAcctListDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSavedPardotAcctList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getSavedPardotAcctList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pardotList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'pardotDisplayName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetSavedPardotAcctListQuery,
  GetSavedPardotAcctListQueryVariables
>
export const SetSavedPardotAccountsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetSavedPardotAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pardotAcctList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PardotAccountInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salesforcePardotMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savePardotAccountList' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'pardotAcctList' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'pardotAcctList' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'pardotDisplayName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pardotID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pardotName' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  SetSavedPardotAccountsMutation,
  SetSavedPardotAccountsMutationVariables
>
export const GetSalesforceEmailTemplatesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSalesforceEmailTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pardotID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templateLocation' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'availableSalesforcePardotEmailTemplates',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pardotID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pardotID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'templateLocation' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'templateLocation' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'classicBuilder' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uiType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotCampaignID' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'isClassicListEmail',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailSubject' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailLinks' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailHTML' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'directLink' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateUpdated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateAdded' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lightningBuilder' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateAdded' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateUpdated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'directLink' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailHTML' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailLinks' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailName' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'isClassicListEmail',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailSubject' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotCampaignID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uiType' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetSalesforceEmailTemplatesQuery,
  GetSalesforceEmailTemplatesQueryVariables
>
export const GetSalesforceEmailHtmlDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSalesforceEmailHTML' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'classicPardotTemplateList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'SalesforcePardotEmailTemplateInput',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lightningPardotTemplateList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'SalesforcePardotEmailTemplateInput',
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salesforcePardotEmailHTML' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {
                        kind: 'Name',
                        value: 'classicPardotTemplateList',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'classicPardotTemplateList',
                        },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: {
                        kind: 'Name',
                        value: 'lightningPardotTemplateList',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'lightningPardotTemplateList',
                        },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'classicBuilder' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateAdded' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateUpdated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'directLink' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailHTML' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailLinks' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailSubject' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'isClassicListEmail',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotCampaignID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uiType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lightningBuilder' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uiType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pardotCampaignID' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'isClassicListEmail',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailSubject' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailLinks' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailHTML' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'directLink' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateUpdated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateAdded' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetSalesforceEmailHtmlQuery,
  GetSalesforceEmailHtmlQueryVariables
>
export const UpdateSalesforceEmailHtmlByEmailIdDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSalesforceEmailHTMLByEmailID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emailID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'generatedPardotLinks' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PardotLinkInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pardotID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pardotName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'uiType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatedHTMLMessage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendPardotUpdate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'emailID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'emailID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'generatedPardotLinks' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'generatedPardotLinks' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pardotID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pardotID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'pardotName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pardotName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'uiType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'uiType' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'updatedHTMLMessage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'updatedHTMLMessage' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailHTML' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateUpdated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pardotID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pardotName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateSalesforceEmailHtmlByEmailIdMutation,
  UpdateSalesforceEmailHtmlByEmailIdMutationVariables
>
export const GetEloquaOrgListDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEloquaOrgList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eloquaQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getConnectedEloquaOrgs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eloquaOrgList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'eloquaOrgDisplayName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'eloquaOrgID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'eloquaOrgName',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetEloquaOrgListQuery,
  GetEloquaOrgListQueryVariables
>
export const UpdateEloquaOrgDisplayNameDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEloquaOrgDisplayName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eloquaOrgID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eloquaDisplayName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oracleEloquaMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateEloquaOrgName' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'eloquaOrgID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'eloquaOrgID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'eloquaDisplayName' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'eloquaDisplayName',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'eloquaOrgDisplayName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eloquaOrgID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eloquaOrgName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateEloquaOrgDisplayNameMutation,
  UpdateEloquaOrgDisplayNameMutationVariables
>
export const SaveEloquaBasicKeyDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveEloquaBasicKey' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eloquaPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'oracleEloquaMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saveEloquaBasicKey' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'eloquaPassword' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'eloquaPassword' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'userName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userName' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'companyName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyName' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'eloquaIntegrationStatus',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  SaveEloquaBasicKeyMutation,
  SaveEloquaBasicKeyMutationVariables
>
export const GetAvailableEloquaEmailGroupsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailableEloquaEmailGroups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eloquaOrgID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eloquaQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'getAvailableEloquaEmailGroups',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'eloquaOrgID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'eloquaOrgID' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'eloquaEmailGroupList',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'groupID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAvailableEloquaEmailGroupsQuery,
  GetAvailableEloquaEmailGroupsQueryVariables
>
export const GetAvailableEloquaTemplatesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailableEloquaTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eloquaOrgID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'search' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eloquaQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'getAvailableEloquaTemplateList',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'eloquaOrgID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'eloquaOrgID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'groupID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'groupID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'search' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'search' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'eloquaTemplateList',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'eloquaID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAvailableEloquaTemplatesQuery,
  GetAvailableEloquaTemplatesQueryVariables
>
export const GetEloquaTemplateHtmlDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEloquaTemplateHTML' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eloquaOrgID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templateID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eloquaQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'getEloquaEmailTemplateDetail',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'eloquaOrgID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'eloquaOrgID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'templateID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'templateID' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eloquaID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailHtml' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eloquaLinkList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'linkType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'linkName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'linkURL' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'linkID' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetEloquaTemplateHtmlQuery,
  GetEloquaTemplateHtmlQueryVariables
>
export const UpdateEloquaTemplateEmailHtmlDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEloquaTemplateEmailHTML' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eloquaOrgID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templateID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatedHTMLMessage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eloquaMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateTemplate' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'eloquaOrgID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'eloquaOrgID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'templateID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'templateID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'updatedHTMLMessage' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'updatedHTMLMessage',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eloquaID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'eloquaLinkList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'linkID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'linkName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'linkType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'linkURL' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'emailHtml' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateEloquaTemplateEmailHtmlMutation,
  UpdateEloquaTemplateEmailHtmlMutationVariables
>
export const FetchEloquaPicklistsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchEloquaPicklists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eloquaOrgID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eloquaIntegrationData' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'eloquaOrgID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'eloquaOrgID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameterIDList' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  FetchEloquaPicklistsQuery,
  FetchEloquaPicklistsQueryVariables
>
export const UpdateEloquaPicklistsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEloquaPicklists' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'eloquaOrgID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parameterIDList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eloquaMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'syncEloquaTrack' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'eloquaOrgID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'eloquaOrgID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'parameterIDList' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'parameterIDList' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'eloquaIntegrationData',
                              },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'eloquaOrgID' },
                                  value: {
                                    kind: 'Variable',
                                    name: {
                                      kind: 'Name',
                                      value: 'eloquaOrgID',
                                    },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'parameterIDList',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateEloquaPicklistsMutation,
  UpdateEloquaPicklistsMutationVariables
>
export const GenSalesforceMcOauthLinkDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GenSalesforceMCOauthLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSourceQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'genMCOauthLink' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'state' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'state' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'oauth2Link' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GenSalesforceMcOauthLinkQuery,
  GenSalesforceMcOauthLinkQueryVariables
>
export const HandleSalesforceMcCallbackDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'HandleSalesforceMCCallback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessUnitName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'encryptedState' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mid' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tssd' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salesforceMCMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'handleSalesforceMCCallback',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'businessUnitName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'businessUnitName' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'code' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'code' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'encryptedState' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'encryptedState' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'mid' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'mid' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'tssd' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'tssd' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'salesforceMcIntegrationList',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'businessUnitName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mid' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'salesforceMcIntegrationStatus',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  HandleSalesforceMcCallbackMutation,
  HandleSalesforceMcCallbackMutationVariables
>
export const GetSalesforceMcTemplateListDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSalesforceMCTemplateList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mid' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'salesforceMarketingCloudQueries',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getTemplateList' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'mid' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'mid' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'templateID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'templateName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'templateType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateLastUpdated' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetSalesforceMcTemplateListQuery,
  GetSalesforceMcTemplateListQueryVariables
>
export const GetSalesforceMcEmailTemplateDetailDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSalesforceMCEmailTemplateDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mid' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templateID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'salesforceMarketingCloudQueries',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getTemplateDetail' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'mid' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'mid' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'templateID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'templateID' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateLastUpdated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'templateID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'templateName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'templateContent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'templateType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slotMap' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slotID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'blocks' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'blockID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'content',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'textContent' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetSalesforceMcEmailTemplateDetailQuery,
  GetSalesforceMcEmailTemplateDetailQueryVariables
>
export const UpdateSalesforceMcEmailTemplateDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSalesforceMCEmailTemplate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mid' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'templateID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatedHTMLMessage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatedSlotMap' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'MCSlotMapInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatedTextContent' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'salesforceMCMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateTemplate' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'mid' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'mid' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'templateID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'templateID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'updatedHTMLMessage' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'updatedHTMLMessage',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'updatedSlotMap' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'updatedSlotMap' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'textContent' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'updatedTextContent',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateLastUpdated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'templateContent' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'templateID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'templateName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'templateType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slotMap' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'slotID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'blocks' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'blockID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'content',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateSalesforceMcEmailTemplateMutation,
  UpdateSalesforceMcEmailTemplateMutationVariables
>
export const GetGaAuthLinkDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetGAAuthLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSourceQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gaAuthLink' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'state' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'data' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetGaAuthLinkQuery, GetGaAuthLinkQueryVariables>
export const HandleGoogleResponseDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'HandleGoogleResponse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'googleAnalyticsMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handleGAAuthCallback' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'code' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'code' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'state' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'state' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'creds' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  HandleGoogleResponseMutation,
  HandleGoogleResponseMutationVariables
>
export const GetAvailableGaAccountsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailableGAAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'credentials' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSourceQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableGAAccounts' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'creds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'credentials' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ga4AccountList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accountID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'accountName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'accountDisplayName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'properties' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'propertyID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'propertyName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'propertyDisplayName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uaAccountList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'accountID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'accountName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'accountDisplayName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'properties' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'propertyID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'propertyName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'propertyDisplayName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'views',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'viewID',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'viewName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'viewDisplayName',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAvailableGaAccountsQuery,
  GetAvailableGaAccountsQueryVariables
>
export const ConnectGaAccountDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConnectGAAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dataSourceDesc' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GADescInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectAccountDatasourceGA' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'accountID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'creds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'creds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dataSourceDesc' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dataSourceDesc' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataSource' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kind' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'connected' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ConnectGaAccountMutation,
  ConnectGaAccountMutationVariables
>
export const ConnectGa4AccountDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConnectGA4Account' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dataSourceDesc' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GA4DescInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectAccountDatasourceGA4' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'accountID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'creds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'creds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dataSourceDesc' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dataSourceDesc' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataSource' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kind' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'connected' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ConnectGa4AccountMutation,
  ConnectGa4AccountMutationVariables
>
export const ReconnectGa4AccountDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReconnectGA4Account' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'credentials' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'googleAnalyticsMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reconnectGAAccount' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'credentials' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'credentials' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'accountID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'accountID' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ReconnectGa4AccountMutation,
  ReconnectGa4AccountMutationVariables
>
export const GetAdobeAuthLinkDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAdobeAuthLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSourceQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adobeAuthLink' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'state' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'data' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAdobeAuthLinkQuery,
  GetAdobeAuthLinkQueryVariables
>
export const HandleAdobeResponseDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'HandleAdobeResponse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'state' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'adobeAnalyticsMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'handleAAAuthCallback' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'code' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'code' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'state' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'state' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'creds' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  HandleAdobeResponseMutation,
  HandleAdobeResponseMutationVariables
>
export const GetAvailableAaAccountsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailableAAAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'credentials' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSourceQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableAAAccounts' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'creds' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'credentials' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'aaAccountList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'adobeCompanyName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'adobeGlobalCompanyId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'adobeImsOrgId',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'reportSuites',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'adobeReportSuiteID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'adobeReportSuiteName',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAvailableAaAccountsQuery,
  GetAvailableAaAccountsQueryVariables
>
export const ConnectAaAccountDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConnectAAAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dataSourceDesc' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AADescInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'connectAccountDatasourceAA' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'accountID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'creds' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'creds' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dataSourceDesc' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dataSourceDesc' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ConnectAaAccountMutation,
  ConnectAaAccountMutationVariables
>
export const ReconnectAaAccountDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReconnectAAAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'credentials' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'adobeAnalyticsMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reconnectAAAccount' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'credentials' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'credentials' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'accountID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'accountID' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ReconnectAaAccountMutation,
  ReconnectAaAccountMutationVariables
>
export const ForceCacheRefreshDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ForceCacheRefresh' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'applyToAccount' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'forceCacheRefresh' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'task' },
                value: {
                  kind: 'StringValue',
                  value: 'refresh_onboarding_cache',
                  block: false,
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'applyToAccount' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'applyToAccount' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accID' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ForceCacheRefreshMutation,
  ForceCacheRefreshMutationVariables
>
export const GetOnboardingSectionsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOnboardingSections' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userOnboardingProgress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userOnboardingProgressList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onboardingSectionID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sectionCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sectionSkipped' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sectionSkippedBefore' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountOnboardingProgress' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'accountOnboardingProgressList',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onboardingSectionID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sectionCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sectionSkipped' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sectionSkippedBefore' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetOnboardingSectionsQuery,
  GetOnboardingSectionsQueryVariables
>
export const SendFeedbackDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'feedbackContext' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FeedbackContext' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendFeedback' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'message' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'message' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'page' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'page' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'feedbackContext' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'feedbackContext' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userID' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'curAccountID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  SendFeedbackMutation,
  SendFeedbackMutationVariables
>
export const GetCampaignLinkDashboardMetaDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignLinkDashboardMeta' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignLinkDashboardMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountID' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'client' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableDimensions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateFormat' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dimensionParameterID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dimensionName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dimensionOptions' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'helpText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameterType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableClickMetrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calculation' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'increasePositive' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isStandard' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isUplifterClickMetric' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setupComplete' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'units' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableMetrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isStandard' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'increasePositive' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isUplifterClickMetric' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calculation' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setupComplete' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'units' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dashboardTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onloadDefaultOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCampaignLinkDashboardMetaQuery,
  GetCampaignLinkDashboardMetaQueryVariables
>
export const GetDataSourceMetricListDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDataSourceMetricList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dataSourceMetricList' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSourceMetricList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calculation' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'increasePositive' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requiredMetrics' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'units' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetDataSourceMetricListQuery,
  GetDataSourceMetricListQueryVariables
>
export const SetDashboardSettingsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SetDashboardSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'additionalMetrics' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AdditionalMetric' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'onloadDefaultOptions' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'GenericSettingInput' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'setLinkPerformanceDashboardAccountSettings',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'additionalMetrics' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'additionalMetrics' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'onloadDefaultOptions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'onloadDefaultOptions' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountID' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'client' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dashboardTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availableMetrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isStandard' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'calculation' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'increasePositive' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'setupComplete' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'units' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'onloadDefaultOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  SetDashboardSettingsMutation,
  SetDashboardSettingsMutationVariables
>
export const GetAccountUsageDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountUsageData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activeUsers' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'totalUsers' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'badCodesBlocked' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codesCreated' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'logins' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'invalidCodesBreakdown' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DimensionFilterList' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'granularity' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeUsers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filterList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'granularity' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'granularity' },
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'activeUsers' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionValues' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateRange' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTotalRows' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionValues' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'queryStartDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'queryEndDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'badCodesBlocked' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filterList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'granularity' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'granularity' },
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'badCodesBlocked' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionValues' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateRange' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTotalRows' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionValues' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'queryStartDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'queryEndDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'codesCreated' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filterList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'granularity' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'granularity' },
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'codesCreated' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionValues' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateRange' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTotalRows' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionValues' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'queryStartDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'queryEndDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invalidCodesBreakdown' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filterList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'granularity' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'granularity' },
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: {
                              kind: 'Name',
                              value: 'invalidCodesBreakdown',
                            },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionValues' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateRange' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTotalRows' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionValues' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'queryStartDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'queryEndDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logins' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filterList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'granularity' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'granularity' },
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'logins' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionValues' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateRange' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTotalRows' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionValues' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'queryStartDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'queryEndDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalUsers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filterList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'granularity' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'granularity' },
                      },
                    },
                  ],
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'include' },
                      arguments: [
                        {
                          kind: 'Argument',
                          name: { kind: 'Name', value: 'if' },
                          value: {
                            kind: 'Variable',
                            name: { kind: 'Name', value: 'totalUsers' },
                          },
                        },
                      ],
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionValues' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateRange' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateTotalRows' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'graphData' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionValues' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'queryStartDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'queryEndDate' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAccountUsageDataQuery,
  GetAccountUsageDataQueryVariables
>
export const GetCampaignLinkDashboardMetricDataClickDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'GetCampaignLinkDashboardMetricDataClickData',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignCodeID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DimensionFilterList' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'granularity' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matchType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedMetric' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'campaignLinkDashboardMetricDataClickData',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignCodeID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignCodeID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterList' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterList' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'granularity' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'granularity' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'matchType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'matchType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedMetric' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'selectedMetric' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'increasePositive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastPeriodStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastYearStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metricLastPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricDisplay' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'metricLastCompleteValue',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricPrevValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricTitle' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metricLastPeriodComparison' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricDisplay' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'metricLastCompleteValue',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricPrevValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricValue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'metricPeriodOnPeriodComparison',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricDisplay' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'metricLastCompleteValue',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricPrevValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricValue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'periodComparisonVsPeriodAverage',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricDisplay' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'metricLastCompleteValue',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricPrevValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricValue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prevPeriodStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'queryEndDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'queryStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalMetric' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricDisplay' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'metricLastCompleteValue',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricPrevValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCampaignLinkDashboardMetricDataClickDataQuery,
  GetCampaignLinkDashboardMetricDataClickDataQueryVariables
>
export const GetCampaignLinkDashboardMetricDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignLinkDashboardMetricData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignCodeID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DimensionFilterList' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'granularity' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matchType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedMetric' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignLinkDashboardMetricData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignCodeID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignCodeID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterList' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterList' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'granularity' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'granularity' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'matchType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'matchType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedMetric' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'selectedMetric' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'increasePositive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastPeriodStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lastYearStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metricLastPeriod' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricDisplay' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'metricLastCompleteValue',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricPrevValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricTitle' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metricLastPeriodComparison' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricDisplay' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'metricLastCompleteValue',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricPrevValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricValue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'metricPeriodOnPeriodComparison',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricDisplay' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'metricLastCompleteValue',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricPrevValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricValue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'periodComparisonVsPeriodAverage',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricDisplay' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'metricLastCompleteValue',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricPrevValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricValue' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prevPeriodStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'queryEndDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'queryStartDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalMetric' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricDisplay' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'metricLastCompleteValue',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricPrevValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricValue' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCampaignLinkDashboardMetricDataQuery,
  GetCampaignLinkDashboardMetricDataQueryVariables
>
export const GetCampaignLinkDashboardGraphClickDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignLinkDashboardGraphClickData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignCodeID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DimensionFilterList' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'granularity' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matchType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedMetric' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'stackDimension' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'campaignLinkDashboardGraphClickData',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignCodeID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignCodeID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterList' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterList' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'granularity' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'granularity' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'matchType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'matchType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedMetric' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'selectedMetric' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stackDimension' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'stackDimension' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dateRange' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dateTotalRows' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'graphType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'graphData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dimensionName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dimensionValues' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCampaignLinkDashboardGraphClickDataQuery,
  GetCampaignLinkDashboardGraphClickDataQueryVariables
>
export const GetCampaignLinkDashboardGraphDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignLinkDashboardGraphData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignCodeID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DimensionFilterList' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'granularity' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matchType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedMetric' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'stackDimension' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignLinkDashboardGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignCodeID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignCodeID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterList' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterList' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'granularity' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'granularity' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'matchType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'matchType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedMetric' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'selectedMetric' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stackDimension' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'stackDimension' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'dateRange' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dateTotalRows' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'graphType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'graphData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dimensionName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dimensionValues' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCampaignLinkDashboardGraphDataQuery,
  GetCampaignLinkDashboardGraphDataQueryVariables
>
export const GetCampaignLinkDashboardTableDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignLinkDashboardTable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'campaignCodeID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DimensionFilterList' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matchType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedMetric' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignLinkDashboardTable' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'campaignCodeID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'campaignCodeID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterList' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterList' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'selectedMetric' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'selectedMetric' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'matchType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'matchType' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignLinks' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tableData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'helpText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricValue' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'units' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCampaignLinkDashboardTableQuery,
  GetCampaignLinkDashboardTableQueryVariables
>
export const ListSavedLinkPerformanceReportsGqlDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListSavedLinkPerformanceReportsGQL' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'listSavedLinkPerformanceReports',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiddenReportList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savedReports' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applyFilters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'availableTo' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdByID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateCreated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedByID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'matchType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'savedReportID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'selectedMetric' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stackDimension' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'granularity' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'updatedTableColumnIndexOrderList',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'renamedColumnNameList',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'hideColumnIndexList',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userPreferredOrder' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ListSavedLinkPerformanceReportsGqlQuery,
  ListSavedLinkPerformanceReportsGqlQueryVariables
>
export const SaveLinkPerformanceReportDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveLinkPerformanceReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportTitle' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'availableTo' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matchType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedMetric' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'stackDimension' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DimensionFilter' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'granularity' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saveLinkPerformanceReport' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reportName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reportName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reportTitle' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reportTitle' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'availableTo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'matchType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matchType' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'selectedMetric' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'selectedMetric' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'stackDimension' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'stackDimension' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filterList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'granularity' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'granularity' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applyFilters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dimensionParameterID',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionOptions' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdByID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateCreated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastEditedBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastEditedByID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastEditedDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savedReportID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selectedMetric' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stackDimension' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'granularity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'renamedColumnNameList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hideColumnIndexList' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'updatedTableColumnIndexOrderList',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  SaveLinkPerformanceReportMutation,
  SaveLinkPerformanceReportMutationVariables
>
export const UpdateSavedLinkPerformanceReportDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSavedLinkPerformanceReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportTitle' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'availableTo' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'savedReportID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedMetric' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'stackDimension' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'matchType' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DimensionFilter' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'granularity' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hideColumnIndexList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'renamedColumnNameList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatedTableColumnIndexOrderList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateSavedLinkPerformanceReport',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reportName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reportName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reportTitle' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reportTitle' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'availableTo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'savedReportID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'savedReportID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'selectedMetric' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'selectedMetric' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'stackDimension' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'stackDimension' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'matchType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'matchType' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filterList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'granularity' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'granularity' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'hideColumnIndexList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hideColumnIndexList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'renamedColumnNameList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'renamedColumnNameList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: {
                        kind: 'Name',
                        value: 'updatedTableColumnIndexOrderList',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'updatedTableColumnIndexOrderList',
                        },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applyFilters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dimensionParameterID',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionOptions' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdByID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateCreated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastEditedBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastEditedByID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastEditedDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savedReportID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selectedMetric' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stackDimension' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'granularity' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'updatedTableColumnIndexOrderList',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'renamedColumnNameList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hideColumnIndexList' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateSavedLinkPerformanceReportMutation,
  UpdateSavedLinkPerformanceReportMutationVariables
>
export const RemoveSavedLinkPerformanceReportDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveSavedLinkPerformanceReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'savedReportID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'removeSavedLinkPerformanceReport',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'savedReportID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'savedReportID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiddenReportList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savedReports' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applyFilters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'availableTo' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdByID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateCreated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedByID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'matchType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'savedReportID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'selectedMetric' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stackDimension' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'granularity' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userPreferredOrder' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  RemoveSavedLinkPerformanceReportMutation,
  RemoveSavedLinkPerformanceReportMutationVariables
>
export const UpdateLinkPerformanceUserPrefsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateLinkPerformanceUserPrefs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newOrder' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateLinkPerformanceUserPrefs',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'newOrder' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newOrder' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiddenReportList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savedReports' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applyFilters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'availableTo' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdByID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateCreated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedByID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'matchType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'savedReportID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'selectedMetric' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stackDimension' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'granularity' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userPreferredOrder' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateLinkPerformanceUserPrefsMutation,
  UpdateLinkPerformanceUserPrefsMutationVariables
>
export const GetLostLinksGraphDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLostLinksGraphData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedMetric' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'granularity' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DimensionFilterList' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchFilter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SearchFilter' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkAudit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkAuditGraph' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'startDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'endDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'selectedMetric' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'selectedMetric' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'granularity' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'granularity' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filterList' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filterList' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'searchFilter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchFilter' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateRange' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateTotalRows' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'graphData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionValues',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'queryEndDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'queryStartDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'total' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetLostLinksGraphDataQuery,
  GetLostLinksGraphDataQueryVariables
>
export const GetLostLinksTableDataDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLostLinksTableData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DimensionFilterList' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'searchFilter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SearchFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedMetric' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortDirection' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tempTable' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupByLandingPage' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkAudit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkAuditTable' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'endDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'limit' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'offset' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'offset' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filterList' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filterList' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'searchFilter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'searchFilter' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'selectedMetric' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'selectedMetric' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'sortDirection' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'sortDirection' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'startDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'tempTable' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'tempTable' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'groupByLandingPage' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'groupByLandingPage',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstObserved' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'landingPageList' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metricFormat' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metricHeadings' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metricValues' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageData' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'destination',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'limit' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'offset' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'totalPages' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'parameterHeadings',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parameterValues' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetLostLinksTableDataQuery,
  GetLostLinksTableDataQueryVariables
>
export const GetLostLinksFilterOptionsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetLostLinksFilterOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tempTable' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'linkAudit' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'linkAuditFilters' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'tempTable' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'tempTable' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parameterFilters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'parameterID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'parameterName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'parameterOptions',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetLostLinksFilterOptionsQuery,
  GetLostLinksFilterOptionsQueryVariables
>
export const ListSavedLostLinksReportsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListSavedLostLinksReports' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listSavedLinkAuditReports' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiddenReportList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savedReports' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applyFilters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'availableTo' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdByID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateCreated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'granularity' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'groupByLandingPage',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'hideColumnIndexList',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedByID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'matchType' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'renamedColumnNameList',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'savedReportID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'selectedMetric' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stackDimension' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'updatedTableColumnIndexOrderList',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userPreferredOrder' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ListSavedLostLinksReportsQuery,
  ListSavedLostLinksReportsQueryVariables
>
export const SaveNewLostLinkReportDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveNewLostLinkReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportTitle' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedMetric' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'availableTo' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'granularity' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DimensionFilter' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saveLinkAuditReport' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reportName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reportName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reportTitle' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reportTitle' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'selectedMetric' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'selectedMetric' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'availableTo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'granularity' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'granularity' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filterList' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applyFilters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionOptions' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateCreated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdByID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastEditedByID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastEditedDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selectedMetric' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savedReportID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'renamedColumnNameList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hideColumnIndexList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupByLandingPage' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'updatedTableColumnIndexOrderList',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  SaveNewLostLinkReportMutation,
  SaveNewLostLinkReportMutationVariables
>
export const UpdateSavedLostLinkReportDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSavedLostLinkReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'savedReportID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportTitle' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'availableTo' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'granularity' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedMetric' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DimensionFilter' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupByLandingPage' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hideColumnIndexList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'renamedColumnNameList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updatedTableColumnIndexOrderList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateSavedLinkAuditReport' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'savedReportID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'savedReportID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reportName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reportName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reportTitle' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reportTitle' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'availableTo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'granularity' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'granularity' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'selectedMetric' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'selectedMetric' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filterList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'groupByLandingPage' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'groupByLandingPage' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'hideColumnIndexList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hideColumnIndexList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'renamedColumnNameList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'renamedColumnNameList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: {
                        kind: 'Name',
                        value: 'updatedTableColumnIndexOrderList',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'updatedTableColumnIndexOrderList',
                        },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'applyFilters' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dimensionOptions' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dimensionParameterID',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdByID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateCreated' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'granularity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastEditedBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastEditedByID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastEditedDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'matchType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savedReportID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selectedMetric' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stackDimension' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'renamedColumnNameList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hideColumnIndexList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupByLandingPage' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'updatedTableColumnIndexOrderList',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateSavedLostLinkReportMutation,
  UpdateSavedLostLinkReportMutationVariables
>
export const RemoveSavedLostLinkReportDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveSavedLostLinkReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'savedReportID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeSavedLinkAuditReport' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'savedReportID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'savedReportID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiddenReportList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savedReports' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applyFilters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'availableTo' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdByID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateCreated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'granularity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedByID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'matchType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'savedReportID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'selectedMetric' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stackDimension' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userPreferredOrder' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  RemoveSavedLostLinkReportMutation,
  RemoveSavedLostLinkReportMutationVariables
>
export const UpdateSavedLostLinkReportPrefsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSavedLostLinkReportPrefs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hideReports' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newOrder' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateLinkAuditUserPrefs' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'hideReports' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hideReports' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'newOrder' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newOrder' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hiddenReportList' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'savedReports' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'applyFilters' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'availableTo' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdByID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateCreated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'granularity' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedByID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastEditedDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'matchType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'savedReportID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'selectedMetric' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'stackDimension' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userPreferredOrder' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateSavedLostLinkReportPrefsMutation,
  UpdateSavedLostLinkReportPrefsMutationVariables
>
export const ListRemoteDashboardsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListRemoteDashboards' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'listRemoteDashboards' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'remoteDashboards' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dashboardDescription',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dashboardID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dashboardName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dashboardSource' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'dashboardThumbnail',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateAdded' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'embedLink' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastAccessed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userAdded' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ListRemoteDashboardsQuery,
  ListRemoteDashboardsQueryVariables
>
export const AddRemoteDashboardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddRemoteDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dashboardName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'embedLink' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addCustomEmbeddedDashboard' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dashboardName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dashboardName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'embedLink' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'embedLink' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardSource' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardThumbnail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateAdded' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'embedLink' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastAccessed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userAdded' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  AddRemoteDashboardMutation,
  AddRemoteDashboardMutationVariables
>
export const DeleteRemoteDashboardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteRemoteDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dashboardID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'deleteCustomEmbeddedDashboard',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dashboardID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dashboardID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardSource' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardThumbnail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateAdded' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'embedLink' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastAccessed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userAdded' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteRemoteDashboardMutation,
  DeleteRemoteDashboardMutationVariables
>
export const UpdateRemoteDashboardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRemoteDashboard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dashboardID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dashboardName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dashboardDescription' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'embedLink' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateCustomEmbeddedDashboard',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dashboardID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dashboardID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dashboardName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dashboardName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dashboardDescription' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dashboardDescription' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'embedLink' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'embedLink' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardSource' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dashboardThumbnail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateAdded' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'embedLink' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastAccessed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userAdded' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateRemoteDashboardMutation,
  UpdateRemoteDashboardMutationVariables
>
export const GetMarketingFunnelReportDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMarketingFunnelReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'awarenessFilter' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'boardID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DimensionFilter' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mainFilter' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DimensionFilter' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingFunnel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'getMarketingFunnelReport',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'awarenessFilter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'awarenessFilter' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'boardID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'boardID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'endDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filterList' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'filterList' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'mainFilter' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'mainFilter' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'startDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardDefinition' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'awarenessFilter',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'boardID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'boardTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'endDate' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'filterList' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'dimensionName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'dimensionOptions',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'dimensionParameterID',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'mainFilter' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'dimensionOptions',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'dimensionName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'dimensionParameterID',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'startDate' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'laneID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'laneOrder' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'laneTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'hasBeenReordered',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'laneMetrics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'secondaryColour',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'primaryColour',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'selectedMetricList',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardList' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'additionalImages',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'cardID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'cardMetrics',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'metricName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'metricValue',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'cardOrder',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'cardTitle',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'cardType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hideCard',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastEditedBy',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'lastEditedDate',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'updatedTitle',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'cardConnections',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'connectedFrom',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'connectedTo',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'connectionMetricName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'connectionMetricValue',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'connectionType',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'hideConnection',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'updatedConnectionMetricName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'updatedConnectionMetricValue',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'additionalConnections',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'connectedFrom',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'connectedTo',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'connectionMetricName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'connectionMetricValue',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'connectionType',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'hideConnection',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'updatedConnectionMetricName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'updatedConnectionMetricValue',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'additionalMetrics',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'metricName',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'metricValue',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'cardAnnotation',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetMarketingFunnelReportQuery,
  GetMarketingFunnelReportQueryVariables
>
export const GetMarketingFunnelBoardListDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMarketingFunnelBoardList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'marketingFunnel' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'getMarketingFunnelBoardList',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardDescription' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardHidden' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'availableTo' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetMarketingFunnelBoardListQuery,
  GetMarketingFunnelBoardListQueryVariables
>
export const SaveMarketingFunnelBoardInitialDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveMarketingFunnelBoardInitial' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'boardID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'boardTitle' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'boardDescription' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'availableTo' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'awarenessFilter' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DimensionFilter' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mainFilter' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DimensionFilter' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'saveMarketingFunnelBoardInitial',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'boardID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'boardTitle' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'boardTitle' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'boardDescription' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'boardDescription' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'availableTo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'awarenessFilter' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'awarenessFilter' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filterList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'mainFilter' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'mainFilter' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardDefinition' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'awarenessFilter' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainFilter' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardURL' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'laneList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneMetrics' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metricName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'metricValue',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'secondaryColour' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'primaryColour' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'selectedMetricList',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cardList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalConnections',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedFrom',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedTo',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hideConnection',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalImages',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalMetrics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardAnnotation',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardConnections',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedFrom',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedTo',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hideConnection',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardMetrics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardOrder' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hideCard' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastEditedBy',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastEditedDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'updatedTitle',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneOrder' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasBeenReordered' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  SaveMarketingFunnelBoardInitialMutation,
  SaveMarketingFunnelBoardInitialMutationVariables
>
export const UpdateMarketingFunnelBoardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMarketingFunnelBoard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'boardID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'boardTitle' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'boardDescription' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'availableTo' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'awarenessFilter' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'mainFilter' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DimensionFilter' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'DimensionFilter' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateMarketingFunnelBoard' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'boardID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'boardTitle' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'boardTitle' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'boardDescription' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'boardDescription' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'availableTo' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'awarenessFilter' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'awarenessFilter' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'startDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'startDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'endDate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'endDate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'mainFilter' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'mainFilter' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filterList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filterList' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardDefinition' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'awarenessFilter' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainFilter' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardURL' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'laneList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cardList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalConnections',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedFrom',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedTo',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hideConnection',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalImages',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardAnnotation',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalMetrics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardConnections',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedFrom',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedTo',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hideConnection',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardMetrics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardOrder' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hideCard' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastEditedBy',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastEditedDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'updatedTitle',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneMetrics' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metricName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'metricValue',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneOrder' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasBeenReordered' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateMarketingFunnelBoardMutation,
  UpdateMarketingFunnelBoardMutationVariables
>
export const UpdateMarketingFunnelBoardOrderDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMarketingFunnelBoardOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newOrder' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hideReports' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateMarketingFunnelBoardOrder',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'newOrder' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newOrder' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'hideReports' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hideReports' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardURL' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardHidden' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateMarketingFunnelBoardOrderMutation,
  UpdateMarketingFunnelBoardOrderMutationVariables
>
export const DeleteMarketingFunnelBoardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMarketingFunnelBoard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'boardID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleteMarketingFunnelBoard' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'boardID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardURL' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardHidden' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableTo' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteMarketingFunnelBoardMutation,
  DeleteMarketingFunnelBoardMutationVariables
>
export const UpdateMarketingBoardLaneDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMarketingBoardLane' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'boardID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'laneID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cardOrder' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'laneTitle' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'primaryColour' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'secondaryColour' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'selectedMetricList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateMarketingFunnelLane' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'boardID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'laneID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'laneID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'cardOrder' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cardOrder' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'laneTitle' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'laneTitle' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'primaryColour' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'primaryColour' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'secondaryColour' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'secondaryColour' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'selectedMetricList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'selectedMetricList' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardURL' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardDefinition' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'awarenessFilter' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainFilter' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'laneList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneOrder' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneMetrics' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metricName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'metricValue',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasBeenReordered' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'secondaryColour' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'primaryColour' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'selectedMetricList',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cardList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalImages',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardMetrics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardOrder' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hideCard' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastEditedBy',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastEditedDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'updatedTitle',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardConnections',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedFrom',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedTo',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hideConnection',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalConnections',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedFrom',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedTo',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hideConnection',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalMetrics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardAnnotation',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateMarketingBoardLaneMutation,
  UpdateMarketingBoardLaneMutationVariables
>
export const UpdateMarketingBoardCardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMarketingBoardCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'boardID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cardID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'laneID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'additionalConnections' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: {
                  kind: 'Name',
                  value: 'MarketingFunnelCardConnectionInput',
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'additionalImages' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'additionalMetrics' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'FunnelMetricInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cardTitle' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'hideCard' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateMarketingFunnelCard' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'boardID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'cardID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cardID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'laneID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'laneID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'additionalConnections' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'additionalConnections' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'additionalImages' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'additionalImages' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'additionalMetrics' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'additionalMetrics' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'cardTitle' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cardTitle' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'hideCard' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hideCard' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardDefinition' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'awarenessFilter' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainFilter' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardURL' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'laneList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cardList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalImages',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'updatedTitle',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastEditedBy',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastEditedDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hideCard' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardOrder' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardMetrics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardConnections',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedFrom',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedTo',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hideConnection',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardAnnotation',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalMetrics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalConnections',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedFrom',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedTo',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hideConnection',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneOrder' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasBeenReordered' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneMetrics' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'metricValue',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metricName' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'secondaryColour' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'primaryColour' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'selectedMetricList',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateMarketingBoardCardMutation,
  UpdateMarketingBoardCardMutationVariables
>
export const AddMarketingBoardCardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddMarketingBoardCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'boardID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'laneID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cardTitle' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'curCardOrder' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addMarketingFunnelCard' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'boardID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'laneID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'laneID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'cardTitle' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cardTitle' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'curCardOrder' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'curCardOrder' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardDefinition' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'awarenessFilter' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainFilter' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardURL' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'laneList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cardList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalImages',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'updatedTitle',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastEditedDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastEditedBy',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hideCard' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardOrder' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardMetrics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardConnections',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedFrom',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedTo',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hideConnection',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardAnnotation',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalMetrics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalConnections',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedFrom',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedTo',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hideConnection',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneOrder' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasBeenReordered' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneMetrics' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metricName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'metricValue',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  AddMarketingBoardCardMutation,
  AddMarketingBoardCardMutationVariables
>
export const DeleteMarketingBoardCardDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteMarketingBoardCard' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'boardID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cardID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'laneID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleteMarketingFunnelCard' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'boardID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'cardID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'cardID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'laneID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'laneID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardDefinition' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'awarenessFilter' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'boardTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'endDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'filterList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mainFilter' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionOptions',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'dimensionParameterID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startDate' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardTitle' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'boardURL' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'laneList' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'cardList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalConnections',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedFrom',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedTo',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hideConnection',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalImages',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'additionalMetrics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardAnnotation',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardConnections',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedFrom',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectedTo',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionMetricValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'connectionType',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hideConnection',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value:
                                              'updatedConnectionMetricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'cardMetrics',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'metricValue',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardOrder' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardTitle' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'cardType' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hideCard' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastEditedBy',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'lastEditedDate',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'updatedTitle',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneMetrics' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metricName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'metricValue',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneOrder' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'laneTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hasBeenReordered' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'secondaryColour' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'primaryColour' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'selectedMetricList',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteMarketingBoardCardMutation,
  DeleteMarketingBoardCardMutationVariables
>
export const FetchPageScreenshotsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchPageScreenshots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'urlList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagePreview' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'urlList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'urlList' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'landingPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'screenshotBase64' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'screenshotLocation' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'screenshotType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  FetchPageScreenshotsQuery,
  FetchPageScreenshotsQueryVariables
>
export const GetTrackValidationResultsByLandingPageDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTrackValidationResultsByLandingPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'urlList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'includeQueryPars' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'getTrackValidationResultsByLandingPage',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'urlList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'urlList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'includeQueryPars' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'includeQueryPars' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'badUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaignCodeID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noAnalyticsTag' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'redirectedLandingPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slowLandingPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetTrackValidationResultsByLandingPageQuery,
  GetTrackValidationResultsByLandingPageQueryVariables
>
export const SendFeatureRequestDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendFeatureRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendFeatureRequest' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'message' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'message' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'page' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  SendFeatureRequestMutation,
  SendFeatureRequestMutationVariables
>
export const CreateEnterpriseCompanyDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateEnterpriseCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountManagerList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'GenericEmailInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'auditAvailable' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'autoRenew' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'breakClauseDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessDivision' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyNotes' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contractEndDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contractStartDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customTandC' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dataSourceType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'domainLimit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'domains' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'explainAvailable' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'legalEntityName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportAvailable' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'revenuePerMonth' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'revenuePerMonthPostBreakClause' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'revenuePerMonthPreBreakClause' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sponsorList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'GenericEmailInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'totalContractValue' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'trackAvailable' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userLimit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'whitelabel' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateUplifterAdminSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'createEnterpriseCompany',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'accountManagerList' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'accountManagerList',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'auditAvailable' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'auditAvailable' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'autoRenew' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'autoRenew' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'businessDivision' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'businessDivision' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'companyName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyName' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'companyNotes' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyNotes' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'contractEndDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'contractEndDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'contractStartDate' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'contractStartDate',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'customTandC' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'customTandC' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'dataSourceType' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dataSourceType' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'domainLimit' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'domainLimit' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'domains' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'domains' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'explainAvailable' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'explainAvailable' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'legalEntityName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'legalEntityName' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'reportAvailable' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'reportAvailable' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'revenuePerMonth' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'revenuePerMonth' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'sponsorList' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'sponsorList' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'totalContractValue' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'totalContractValue',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'trackAvailable' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'trackAvailable' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'userLimit' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userLimit' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'breakClauseDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'breakClauseDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'revenuePerMonthPostBreakClause',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'revenuePerMonthPostBreakClause',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'revenuePerMonthPreBreakClause',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'revenuePerMonthPreBreakClause',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'whitelabel' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'whitelabel' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'accountManagerList',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activeState' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'auditAvailable' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'autoRenew' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingUserList' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingNotes' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'breakClause' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'breakClauseDate' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'breakClauseExists',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'businessDivision' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contractEndDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyNotes' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contractLink' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'contractStartDate',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customTandC' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'mezzoAccountManager',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'revenuePerMonth' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportAvailable' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'totalContractValue',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userLimit' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateEnterpriseCompanyMutation,
  CreateEnterpriseCompanyMutationVariables
>
export const UpdateEnterpriseCompanyDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateEnterpriseCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountManagerList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'GenericEmailInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'auditAvailable' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'autoRenew' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'breakClauseDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'businessDivision' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyNotes' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contractEndDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'contractStartDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customTandC' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dataSourceType' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'domainLimit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'domains' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'explainAvailable' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'legalEntityName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportAvailable' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'revenuePerMonth' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'revenuePerMonthPostBreakClause' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'revenuePerMonthPreBreakClause' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sponsorList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'GenericEmailInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'totalContractValue' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'trackAvailable' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userLimit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateUplifterAdminSettings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'updateEnterpriseCompany',
                        },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'companyID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'accountManagerList' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'accountManagerList',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'auditAvailable' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'auditAvailable' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'autoRenew' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'autoRenew' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'businessDivision' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'businessDivision' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'companyName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyName' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'companyNotes' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'companyNotes' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'contractEndDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'contractEndDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'contractStartDate' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'contractStartDate',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'customTandC' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'customTandC' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'dataSourceType' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dataSourceType' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'domainLimit' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'domainLimit' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'domains' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'domains' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'explainAvailable' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'explainAvailable' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'legalEntityName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'legalEntityName' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'reportAvailable' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'reportAvailable' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'revenuePerMonth' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'revenuePerMonth' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'sponsorList' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'sponsorList' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'totalContractValue' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'totalContractValue',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'trackAvailable' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'trackAvailable' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'userLimit' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userLimit' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'breakClauseDate' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'breakClauseDate' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'revenuePerMonthPostBreakClause',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'revenuePerMonthPostBreakClause',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'revenuePerMonthPreBreakClause',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'revenuePerMonthPreBreakClause',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountCount' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'accountManagerList',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'activeState' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'auditAvailable' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'autoRenew' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingUserList' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'billingNotes' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'breakClause' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'breakClauseDate' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'breakClauseExists',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'businessDivision' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contractEndDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyNotes' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'contractLink' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'contractStartDate',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customTandC' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'mezzoAccountManager',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'origDataSource' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'revenuePerMonth' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportAvailable' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'totalContractValue',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userCount' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userLimit' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateEnterpriseCompanyMutation,
  UpdateEnterpriseCompanyMutationVariables
>
export const AddSupportUserDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddSupportUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'whitelabel' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'permissionLevel' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'makeUserSupportUser' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'email' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'email' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'whitelabel' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'whitelabel' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'permissionLevel' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'permissionLevel' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userID' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inviteOpen' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userAccountProfiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userEmail' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userPermission' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inviteOpen' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  AddSupportUserMutation,
  AddSupportUserMutationVariables
>
export const ShareCodesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ShareCodes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emailList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'note' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subject' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareCodes' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'codeList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'codeList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'emailList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'emailList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'note' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'note' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'subject' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'subject' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'account' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'cid' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codeShareData' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requester' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'sent' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ShareCodesMutation, ShareCodesMutationVariables>
export const DeleteBatchCodesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteBatchCodes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeIDList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customDomainID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deepLinkServiceID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleteCodes' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'codeIDList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'codeIDList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'customDomainID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'customDomainID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'deepLinkServiceID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deepLinkServiceID' },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteBatchCodesMutation,
  DeleteBatchCodesMutationVariables
>
export const GetCampaignCodeGeneratorFieldTypesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignCodeGeneratorFieldTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignCodeGenerator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountID' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'client' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paramDefs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldType' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCampaignCodeGeneratorFieldTypesQuery,
  GetCampaignCodeGeneratorFieldTypesQueryVariables
>
export const GetCampaignCodeGeneratorDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignCodeGenerator' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'campaignCodeGenerator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountID' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'client' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultPardotBusinessUnit' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'existingParametersAddedToStart',
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'isLocked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'masterPrefix' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paramSeparator' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'paramDefs' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'copyFromField' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'copyFromID' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateFormat' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailDefault' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parameterID' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldAvailable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fixedValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'forceLowerCase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'helpText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lengthLimit' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isCampaignField' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isCreativeField' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metaParameter' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameterDependsOn' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentFieldID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentOptionIDs' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'prefix' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'required' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selectFields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hide' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionFilter' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'parentFieldID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'parentOptionIDs',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionValue' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validationChecks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCampaignCodeGeneratorQuery,
  GetCampaignCodeGeneratorQueryVariables
>
export const GetShortLinkCustomDomainsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetShortLinkCustomDomains' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyID' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'availableShortLinkCustomDomains',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customDomainID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customDomainName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetShortLinkCustomDomainsQuery,
  GetShortLinkCustomDomainsQueryVariables
>
export const GetShortLinkCandidatesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetShortLinkCandidates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'nLinks' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customDomainID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deepLinkServiceID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortLinkCandidates' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nLinks' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'nLinks' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customDomainID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customDomainID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deepLinkServiceID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deepLinkServiceID' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ShortLinkCandidates' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableLinkIDs' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ShortLinkBatchCandidates' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bulkStart' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nLinks' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetShortLinkCandidatesQuery,
  GetShortLinkCandidatesQueryVariables
>
export const ValidateShortLinkCandidateDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ValidateShortLinkCandidate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'testCandidate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customDomainID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deepLinkServiceID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'validateShortLinkCandidate' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'testCandidate' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'testCandidate' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'customDomainID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'customDomainID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'deepLinkServiceID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'deepLinkServiceID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'availableUntil' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isAvailable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortLinkID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ValidateShortLinkCandidateQuery,
  ValidateShortLinkCandidateQueryVariables
>
export const CreateNewLinkDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNewLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customDomainID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'AddCode' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'bulkStart' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deepLinkConfig' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DeepLinkInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addCodes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'codeList' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'codeList' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customDomainID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customDomainID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'bulkStart' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'bulkStart' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deepLinkConfig' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deepLinkConfig' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountID' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'client' },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'author' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codeDef' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codeID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdTime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minGenDef' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paramID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paramName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fullLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCodes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'versionNumber' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateNewLinkMutation,
  CreateNewLinkMutationVariables
>
export const SendUpdateRequestDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendUpdateRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'changeRequests' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ChangeRequestInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'requestNote' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendUpdateRequest' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'requestNote' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'requestNote' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'updateRequestList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'changeRequests' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  SendUpdateRequestMutation,
  SendUpdateRequestMutationVariables
>
export const GetUplifterIdCurrentTotalDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUplifterIDCurrentTotal' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentSequentialCodeID' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isEnabled' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentTotal' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'etag' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'prefix' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acctPrefix' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetUplifterIdCurrentTotalQuery,
  GetUplifterIdCurrentTotalQueryVariables
>
export const UpdateCurrentSequentialCodeIdDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCurrentSequentialCodeID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currentTotal' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'etag' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newTotal' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateCurrentSequentialCodeID',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'currentTotal' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'currentTotal' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'etag' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'etag' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'newTotal' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newTotal' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentTotal' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'etag' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acctPrefix' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedTotal' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateCurrentSequentialCodeIdMutation,
  UpdateCurrentSequentialCodeIdMutationVariables
>
export const GetCreativeListDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCreativeList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parameterID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'report' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getCreativeList' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'parameterID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'parameterID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blobURL' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creativeID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creativeLink' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'creativeType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optionID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optionName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameterID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCreativeListQuery,
  GetCreativeListQueryVariables
>
export const DeleteCreativeLinkDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCreativeLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'creativeID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleteCreativeLink' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'creativeID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'creativeID' },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteCreativeLinkMutation,
  DeleteCreativeLinkMutationVariables
>
export const GetUpdateRequestListDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUpdateRequestList' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateRequestList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pendingRequests' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'changeRequests' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fieldID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'newOptions' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'approved',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'message',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'optionName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'optionRequestID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'optionValue',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'rejected',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'completed' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'requestDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'requestID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'requestNote' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'requestedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reviewNote' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reviewedBy' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'updateRequestAccountName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'updateRequestAccountID',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetUpdateRequestListQuery,
  GetUpdateRequestListQueryVariables
>
export const UpdateGeneratorParameterOrderDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGeneratorParameterOrder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newOrder' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateGeneratorParameterOrder',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'newOrder' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newOrder' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CampaignCodeGeneratorParamDefs',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CampaignCodeGeneratorParamDefsFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  UpdateGeneratorParameterOrderMutation,
  UpdateGeneratorParameterOrderMutationVariables
>
export const AddNewGeneratorParameterDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddNewGeneratorParameter' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldAvailable' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fixedValue' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateFormat' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'required' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'prefix' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'metaParameter' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'helpText' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'forceLowerCase' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isCreativeField' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addGeneratorParameter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldAvailable' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldAvailable' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldType' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fixedValue' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fixedValue' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dateFormat' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateFormat' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'required' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'required' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'prefix' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'prefix' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'metaParameter' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'metaParameter' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'helpText' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'helpText' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'forceLowerCase' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'forceLowerCase' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'isCreativeField' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isCreativeField' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'copyFromField' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'copyFromID' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldAvailable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fixedValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateFormat' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'forceLowerCase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'helpText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isCreativeField' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metaParameter' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameterDependsOn' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentFieldID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentOptionIDs' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'prefix' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'required' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selectFields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hide' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionFilter' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'parentFieldID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'parentOptionIDs',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionValue' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  AddNewGeneratorParameterMutation,
  AddNewGeneratorParameterMutationVariables
>
export const UpdateGeneratorParameterByIdDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGeneratorParameterByID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateFieldID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'copyFromField' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'CopyFromFieldInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emailDefault' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DefaultEmailParameterInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldAvailable' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldType' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fixedValue' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateFormat' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'required' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'prefix' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'metaParameter' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'helpText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'forceLowerCase' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'parameterDependsOn' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'OptionFilterInput' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isCampaignField' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isCreativeField' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lengthLimit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateGeneratorParameter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'updateFieldID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'updateFieldID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'copyFromField' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'copyFromField' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'emailDefault' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'emailDefault' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'dateFormat' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'dateFormat' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldAvailable' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldAvailable' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldType' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fixedValue' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fixedValue' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'forceLowerCase' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'forceLowerCase' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'helpText' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'helpText' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'metaParameter' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'metaParameter' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'prefix' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'prefix' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'required' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'required' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'parameterDependsOn' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'parameterDependsOn' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'isCampaignField' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isCampaignField' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'isCreativeField' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isCreativeField' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'lengthLimit' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lengthLimit' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'copyFromField' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'copyFromID' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldAvailable' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dateFormat' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fieldType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fixedValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'forceLowerCase' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isCampaignField' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'helpText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isCreativeField' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lengthLimit' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metaParameter' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'parameterDependsOn' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentFieldID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'parentOptionIDs' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'prefix' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'required' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'selectFields' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'hide' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionFilter' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'parentFieldID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'parentOptionIDs',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionValue' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateGeneratorParameterByIdMutation,
  UpdateGeneratorParameterByIdMutationVariables
>
export const DeleteGeneratorParameterByIdDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteGeneratorParameterByID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'removeFieldID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleteGeneratorParameter' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'removeFieldID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'removeFieldID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CampaignCodeGeneratorParamDefs',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CampaignCodeGeneratorParamDefsFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  DeleteGeneratorParameterByIdMutation,
  DeleteGeneratorParameterByIdMutationVariables
>
export const UpdateParametersMasterRulesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateParametersMasterRules' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'validationChecks' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'GenericSettingInput' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateGeneratorMasterSettings',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'validationChecks' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'validationChecks' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'validationChecks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'enabled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateParametersMasterRulesMutation,
  UpdateParametersMasterRulesMutationVariables
>
export const UpdateMasterPrefixAndSeparatorDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMasterPrefixAndSeparator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'masterPrefix' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paramSeparator' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateGeneratorMasterSettings',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'masterPrefix' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'masterPrefix' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'paramSeparator' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'paramSeparator' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'masterPrefix' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paramSeparator' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateMasterPrefixAndSeparatorMutation,
  UpdateMasterPrefixAndSeparatorMutationVariables
>
export const UpdateParameterSelectUpdateOptionDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateParameterSelectUpdateOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hide' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionValue' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionFilter' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'OptionFilterInput' },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateGeneratorParameterSelectUpdateOption',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'optionID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'hide' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hide' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'optionName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'optionValue' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionValue' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'optionFilter' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionFilter' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CampaignCodeGeneratorParamDefs',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CampaignCodeGeneratorParamDefsFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  UpdateParameterSelectUpdateOptionMutation,
  UpdateParameterSelectUpdateOptionMutationVariables
>
export const UpdateParameterSelectDeleteOptionDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateParameterSelectDeleteOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateGeneratorParameterSelectDeleteOption',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'optionID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CampaignCodeGeneratorParamDefs',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CampaignCodeGeneratorParamDefsFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  UpdateParameterSelectDeleteOptionMutation,
  UpdateParameterSelectDeleteOptionMutationVariables
>
export const UpdateParameterSelectResetParentDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateParameterSelectResetParent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateGeneratorParameterSelectDropParentChild',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CampaignCodeGeneratorParamDefs',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CampaignCodeGeneratorParamDefsFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  UpdateParameterSelectResetParentMutation,
  UpdateParameterSelectResetParentMutationVariables
>
export const UpdateParameterSelectAddOptionDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateParameterSelectAddOption' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'hide' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionValue' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateGeneratorParameterSelectAddOption',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'hide' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'hide' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'optionName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'optionValue' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionValue' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CampaignCodeGeneratorParamDefs',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CampaignCodeGeneratorParamDefsFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  UpdateParameterSelectAddOptionMutation,
  UpdateParameterSelectAddOptionMutationVariables
>
export const UpdateParameterSelectAddMultipleOptionsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateParameterSelectAddMultipleOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'SelectOptionInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateGeneratorParameterSelectAddMultipleOptions',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'optionList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionList' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'CampaignCodeGeneratorParamDefs',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CampaignCodeGeneratorParamDefsFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode<
  UpdateParameterSelectAddMultipleOptionsMutation,
  UpdateParameterSelectAddMultipleOptionsMutationVariables
>
export const GetCampaignCodeGeneratorByAccountIdDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCampaignCodeGeneratorByAccountID' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'campaignCodeGeneratorAccount' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'accountID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'masterPrefix' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paramSeparator' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paramDefs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'copyFromField' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'copyFromID' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fieldID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fieldName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fieldAvailable' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fixedValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateFormat' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fieldType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'forceLowerCase' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'helpText' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isCreativeField' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'metaParameter' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'parameterDependsOn',
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'parentFieldID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'parentOptionIDs',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'prefix' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'required' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'selectFields' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hide' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'optionFilter',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'parentFieldID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'parentOptionIDs',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'optionID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'optionName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'optionValue',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'validationChecks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'enabled' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCampaignCodeGeneratorByAccountIdQuery,
  GetCampaignCodeGeneratorByAccountIdQueryVariables
>
export const RejectUpdateRequestDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RejectUpdateRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionRequestID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionRequestIndex' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'requestID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rejectUpdateRequest' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'message' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'message' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'requestID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'requestID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'optionRequestID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionRequestID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'optionRequestIndex' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionRequestIndex' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'changeRequests' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fieldID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'newOptions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'approved' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'message' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'optionName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'optionRequestID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'optionValue',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rejected' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestNote' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestedBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewNote' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewedBy' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'updateRequestAccountName',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateRequestAccountID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  RejectUpdateRequestMutation,
  RejectUpdateRequestMutationVariables
>
export const ApproveUpdateRequestDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApproveUpdateRequest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountIDList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fieldName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'message' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newValue' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionRequestID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optionRequestIndex' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'requestID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'approveUpdateRequest' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'accountIDList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountIDList' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'fieldName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'fieldName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'message' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'message' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'newName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'newValue' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newValue' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'requestID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'requestID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'optionRequestID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionRequestID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'optionRequestIndex' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'optionRequestIndex' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'changeRequests' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fieldID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'newOptions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'approved' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'message' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'optionName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'optionRequestID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'optionValue',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'rejected' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completed' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestNote' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestedBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewNote' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewedBy' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'updateRequestAccountName',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateRequestAccountID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ApproveUpdateRequestMutation,
  ApproveUpdateRequestMutationVariables
>
export const LockUnlockGeneratorDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LockUnlockGenerator' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isLocked' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateGeneratorMasterSettings',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'isLocked' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isLocked' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isLocked' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  LockUnlockGeneratorMutation,
  LockUnlockGeneratorMutationVariables
>
export const UpdateExistingParamsAddedToStartDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateExistingParamsAddedToStart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'existingParametersAddedToStart' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateGeneratorMasterSettings',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {
                        kind: 'Name',
                        value: 'existingParametersAddedToStart',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'existingParametersAddedToStart',
                        },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'existingParametersAddedToStart',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateExistingParamsAddedToStartMutation,
  UpdateExistingParamsAddedToStartMutationVariables
>
export const UpdateDefaultPardotBusinessUnitDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDefaultPardotBusinessUnit' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'defaultPardotBusinessUnit' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateGeneratorMasterSettings',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: {
                        kind: 'Name',
                        value: 'defaultPardotBusinessUnit',
                      },
                      value: {
                        kind: 'Variable',
                        name: {
                          kind: 'Name',
                          value: 'defaultPardotBusinessUnit',
                        },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'defaultPardotBusinessUnit',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateDefaultPardotBusinessUnitMutation,
  UpdateDefaultPardotBusinessUnitMutationVariables
>
export const GetUplifterIdParamStateDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUplifterIDParamState' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentSequentialCodeID' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isEnabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetUplifterIdParamStateQuery,
  GetUplifterIdParamStateQueryVariables
>
export const UpdateUplifterIdEnabledStateDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUplifterIDEnabledState' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isEnabled' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateCurrentSequentialCodeIDObject',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'isEnabled' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'isEnabled' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                        directives: [
                          {
                            kind: 'Directive',
                            name: { kind: 'Name', value: 'client' },
                          },
                        ],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isEnabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateUplifterIdEnabledStateMutation,
  UpdateUplifterIdEnabledStateMutationVariables
>
export const GetAaftpDetailsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAAFTPDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'accountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'adobeFTPDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adobeFTPHost' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adobeFTPLogin' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relationID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAaftpDetailsQuery,
  GetAaftpDetailsQueryVariables
>
export const UpdateAaftpDetailsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAAFTPDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'relationID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adobeFTPPassword' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adobeFTPLogin' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'adobeFTPHost' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateAdobeFTPSettings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reportID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reportID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'relationID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'relationID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'adobeFTPPassword' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'adobeFTPPassword' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'adobeFTPLogin' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'adobeFTPLogin' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'adobeFTPHost' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'adobeFTPHost' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'relationID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adobeFTPLogin' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adobeFTPHost' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateAaftpDetailsMutation,
  UpdateAaftpDetailsMutationVariables
>
export const ListDeepLinkServicesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListDeepLinkServices' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deepLinkQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listDeepLinkServices' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'deepLinkServiceID',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'deepLinkServiceName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'deepLinkServiceSubdomain',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ListDeepLinkServicesQuery,
  ListDeepLinkServicesQueryVariables
>
export const ListAppGroupsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAppGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deepLinkQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listAppGroups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountID' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'appGroupDescription',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appGroupID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appGroupName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appGroupPath' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'DeepLinkAndroidApp',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'androidAppName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'androidPackage',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'redirectAndroidUri',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'redirectAndroidPreferred',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'fallBackURL',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'companyID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'appID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'appGroupID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'androidPlayStoreLink',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'assetLinksAndroid',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'androidUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'deepLinkServiceID',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'DeepLinkIosApp',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'iosAppName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'appGroupID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'appID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'companyID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'fallBackURL',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'iosAppStoreID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'iosBundleID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'iosTeamID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'redirectIosDownload',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'redirectIosPreferred',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'redirectIosUri',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'deepLinkServiceID',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateAdded' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'deepLinkServiceID',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isUAT' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'redirectWeb' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<ListAppGroupsQuery, ListAppGroupsQueryVariables>
export const CreateAppGroupDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAppGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deepLinkServiceID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'redirectWeb' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupDescription' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupPath' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isUAT' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackDeeplinkingMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createAppGroup' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'deepLinkServiceID' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'deepLinkServiceID',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'appGroupName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appGroupName' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'redirectWeb' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'redirectWeb' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'appGroupDescription',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'appGroupDescription',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'appGroupPath' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appGroupPath' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'isUAT' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isUAT' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountID' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'appGroupDescription',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appGroupID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appGroupName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appGroupPath' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appList' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'DeepLinkAndroidApp',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'androidAppName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'androidPackage',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'redirectAndroidUri',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'redirectAndroidPreferred',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'fallBackURL',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'companyID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'appID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'appGroupID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'androidPlayStoreLink',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'assetLinksAndroid',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'androidUrl',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'deepLinkServiceID',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: {
                                        kind: 'Name',
                                        value: 'DeepLinkIosApp',
                                      },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'iosAppName',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'appGroupID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'appID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'companyID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'fallBackURL',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'iosAppStoreID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'iosBundleID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'iosTeamID',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'redirectIosDownload',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'redirectIosPreferred',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'redirectIosUri',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'deepLinkServiceID',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'redirectWeb' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isUAT' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateAdded' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  CreateAppGroupMutation,
  CreateAppGroupMutationVariables
>
export const UpdateAppGroupDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAppGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupDescription' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupPath' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'redirectWeb' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'isUAT' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackDeeplinkingMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateAppGroup' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'appGroupID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appGroupID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'appGroupDescription',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'appGroupDescription',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'appGroupName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appGroupName' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'appGroupPath' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appGroupPath' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'redirectWeb' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'redirectWeb' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'isUAT' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'isUAT' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountID' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'appGroupDescription',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appGroupID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appGroupName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appGroupPath' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateAdded' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'deepLinkServiceID',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isUAT' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'redirectWeb' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateAppGroupMutation,
  UpdateAppGroupMutationVariables
>
export const DeleteAppGroupDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAppGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackDeeplinkingMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'deleteAppGroup' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'appGroupID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appGroupID' },
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteAppGroupMutation,
  DeleteAppGroupMutationVariables
>
export const RegisterAppDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegisterApp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deepLinkServiceID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'device' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'redirectMethod' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fallBackURL' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'iosAppStoreID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'iosBundleID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'iosTeamID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'androidPackage' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'androidPlayStoreLink' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'androidURI' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assetLinksAndroid' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'androidAppName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'iosAppName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackDeeplinkingMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'registerApp' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'appGroupID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appGroupID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'deepLinkServiceID' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'deepLinkServiceID',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'device' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'device' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'redirectMethod' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'redirectMethod' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'fallBackURL' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'fallBackURL' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'iosAppStoreID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'iosAppStoreID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'iosBundleID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'iosBundleID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'iosTeamID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'iosTeamID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'androidPackage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'androidPackage' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'androidPlayStoreLink',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'androidPlayStoreLink',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'androidURI' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'androidURI' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'assetLinksAndroid' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'assetLinksAndroid',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'androidAppName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'androidAppName' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'iosAppName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'iosAppName' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'DeepLinkAndroidApp',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'androidAppName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'androidPackage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'redirectAndroidUri',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'redirectAndroidPreferred',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'fallBackURL',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appGroupID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'androidPlayStoreLink',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assetLinksAndroid',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'androidUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'deepLinkServiceID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'DeepLinkIosApp' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'iosAppName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appGroupID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'fallBackURL',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'iosAppStoreID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'iosBundleID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'iosTeamID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'redirectIosDownload',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'redirectIosPreferred',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'redirectIosUri',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'deepLinkServiceID',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<RegisterAppMutation, RegisterAppMutationVariables>
export const UpdateRegisteredAppDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateRegisteredApp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deepLinkServiceID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'device' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'androidPackage' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'androidPlayStoreLink' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'androidURI' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'androidAppName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'iosAppName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assetLinksAndroid' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'iosAppStoreID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'iosBundleID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'iosTeamID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'iosURI' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackDeeplinkingMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateApp' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'appID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'deepLinkServiceID' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'deepLinkServiceID',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'device' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'device' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'androidPackage' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'androidPackage' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: {
                              kind: 'Name',
                              value: 'androidPlayStoreLink',
                            },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'androidPlayStoreLink',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'androidURI' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'androidURI' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'appGroupID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appGroupID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'androidAppName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'androidAppName' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'iosAppName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'iosAppName' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'assetLinksAndroid' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'assetLinksAndroid',
                              },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'iosAppStoreID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'iosAppStoreID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'iosBundleID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'iosBundleID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'iosTeamID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'iosTeamID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'iosURI' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'iosURI' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: {
                                  kind: 'Name',
                                  value: 'DeepLinkAndroidApp',
                                },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'androidAppName',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'androidPackage',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'redirectAndroidUri',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'redirectAndroidPreferred',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'fallBackURL',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appGroupID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'androidPlayStoreLink',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assetLinksAndroid',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'androidUrl' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'deepLinkServiceID',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'DeepLinkIosApp' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'iosAppName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appGroupID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'appID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'companyID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'fallBackURL',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'iosAppStoreID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'iosBundleID',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'iosTeamID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'redirectIosDownload',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'redirectIosPreferred',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'redirectIosUri',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'deepLinkServiceID',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateRegisteredAppMutation,
  UpdateRegisteredAppMutationVariables
>
export const ListAppContextOptionsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ListAppContextOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deepLinkQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listAppContextOptions' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'appGroupID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appGroupID' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateAdded' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateLastUpdated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastUpdatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'optionDisplayName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionKey' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ListAppContextOptionsQuery,
  ListAppContextOptionsQueryVariables
>
export const BulkAddAppContextDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BulkAddAppContext' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'insertContextList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'AppContextInsertInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackDeeplinkingMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bulkAddAppContext' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'appGroupID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appGroupID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'insertContextList' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'insertContextList',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateAdded' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastUpdatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateLastUpdated' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'optionDisplayName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionKey' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  BulkAddAppContextMutation,
  BulkAddAppContextMutationVariables
>
export const UpdateAppContextListDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateAppContextList' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'updateContextList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'AppContextUpdateInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackDeeplinkingMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bulkUpdateAppContext' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'appGroupID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appGroupID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'updateContextList' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'updateContextList',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateAdded' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateLastUpdated' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'optionDisplayName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastUpdatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionKey' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateAppContextListMutation,
  UpdateAppContextListMutationVariables
>
export const DeleteAppContextListOptionsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAppContextListOptions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'appGroupID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deleteContextList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackDeeplinkingMutations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bulkDeleteAppContext' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'appGroupID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'appGroupID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'deleteContextList' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'deleteContextList',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateAdded' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'addedBy' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dateLastUpdated' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastUpdatedBy' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'optionDisplayName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionKey' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'order' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'optionValue' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteAppContextListOptionsMutation,
  DeleteAppContextListOptionsMutationVariables
>
export const GetMinCodesByAccountDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMinCodesByAccount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeIDList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dimensionFilter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DimensionFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterByCurrentUser' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'noReturn' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SortFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'refreshCache' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minCodesByAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'codeIDList' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'codeIDList' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dimensionFilter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dimensionFilter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterByCurrentUser' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterByCurrentUser' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'noReturn' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'noReturn' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'refreshCache' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'refreshCache' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountID' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'client' },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'author' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codeDef' } },
                { kind: 'Field', name: { kind: 'Name', value: 'codeID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdTime' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minGenDef' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paramID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'paramName' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'fullLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shortLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCodes' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'versionNumber' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetMinCodesByAccountQuery,
  GetMinCodesByAccountQueryVariables
>
export const GetUrlValidationStatusDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUrlValidationStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeIDList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountID' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'client' },
                    },
                  ],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackValidationResults' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'codeIDList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'codeIDList' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'statusCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'badUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'campaignCodeID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'noAnalyticsTag' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'redirectedLandingPage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slowLandingPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetUrlValidationStatusQuery,
  GetUrlValidationStatusQueryVariables
>
export const GetStoredCodesStatsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetStoredCodesStats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeIDList' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'String' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dimensionFilter' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DimensionFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filterByCurrentUser' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'noReturn' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'offset' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderBy' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'SortFilter' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'refreshCache' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'storedCodeStats' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'codeIDList' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'codeIDList' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dimensionFilter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dimensionFilter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filterByCurrentUser' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filterByCurrentUser' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'noReturn' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'noReturn' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'offset' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderBy' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'refreshCache' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'refreshCache' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountID' },
                  directives: [
                    {
                      kind: 'Directive',
                      name: { kind: 'Name', value: 'client' },
                    },
                  ],
                },
                { kind: 'Field', name: { kind: 'Name', value: 'codeIDs' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'earliestClickDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'earliestMetricDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'earliestDeepLinkDate' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metricValues' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'displayName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'helpText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'metricID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'totalMetricValues' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'units' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCodes' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetStoredCodesStatsQuery,
  GetStoredCodesStatsQueryVariables
>
export const QuickUrlValidationDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'QuickUrlValidation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'urlList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'quickUrlValidation' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'urlList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'urlList' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'validationResults' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'testUrl' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  QuickUrlValidationQuery,
  QuickUrlValidationQueryVariables
>
export const IntensiveUrlValidationDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IntensiveUrlValidation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'urlList' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intensiveUrlValidation' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'urlList' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'urlList' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'validationResults' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'badUrl' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'clickedCookieConsent',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'noAnalyticsTag' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'redirectedLandingPage',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slowLandingPage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statusCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'testUrl' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'validatorReturned',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  IntensiveUrlValidationQuery,
  IntensiveUrlValidationQueryVariables
>
export const AddShortLinkExistingCodeDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddShortLinkExistingCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customDomainID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newShortLinkID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'addShortLinkExistingCode' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'codeID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'codeID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'customDomainID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'customDomainID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'newShortLinkID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'newShortLinkID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'c' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cID' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cDef' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'n' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'v' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'e' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'edB' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'edT' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sL' } },
                      { kind: 'Field', name: { kind: 'Name', value: 't' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'vN' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  AddShortLinkExistingCodeMutation,
  AddShortLinkExistingCodeMutationVariables
>
export const UpdateCodeDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fullCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'paramDefs' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'ParamDefInput' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'customDomainID' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deepLinkConfig' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'DeepLinkInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'codeID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'codeID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fullCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fullCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'paramDefs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'paramDefs' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'customDomainID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'customDomainID' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deepLinkConfig' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'deepLinkConfig' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cDef' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'fID' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'n' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'v' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'c' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'e' } },
                { kind: 'Field', name: { kind: 'Name', value: 'edB' } },
                { kind: 'Field', name: { kind: 'Name', value: 'edT' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sL' } },
                { kind: 'Field', name: { kind: 'Name', value: 't' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vN' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<UpdateCodeMutation, UpdateCodeMutationVariables>
export const FetchLinkEditHistoryDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchLinkEditHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'codeVersionHistory' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'codeID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'codeID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'changeMap' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'editedBy' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'editedTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versionHistory' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'linkID' },
                              directives: [
                                {
                                  kind: 'Directive',
                                  name: { kind: 'Name', value: 'client' },
                                },
                              ],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'codeDef' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullLink' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'minGenDef' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'paramID' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'paramName' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCodes' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortLink' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'versionNumber' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'codeID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'author' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  FetchLinkEditHistoryQuery,
  FetchLinkEditHistoryQueryVariables
>
export const GetDeeplinkDetailsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDeeplinkDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'codeID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'deepLinkServiceID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'track' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deepLinkQueries' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getDeeplinkDetails' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'codeID' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'codeID' },
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'deepLinkServiceID' },
                            value: {
                              kind: 'Variable',
                              name: {
                                kind: 'Name',
                                value: 'deepLinkServiceID',
                              },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'analyticsContext' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'appGroupID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'campaignCodeID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'companyID' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'deepLinkServiceID',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'deepLinkShortLink',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fallBackURL' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ogDescription' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ogImage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ogImageAlt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ogImageHeight' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ogImageWidth' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ogTitle' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'redirectContext' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetDeeplinkDetailsQuery,
  GetDeeplinkDetailsQueryVariables
>
export const GetPreferredHomepageDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPreferredHomepage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackAvailable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportAvailable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'auditAvailable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'explainAvailable' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'preferredHomepage' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetPreferredHomepageQuery,
  GetPreferredHomepageQueryVariables
>
export const GetUserInfoDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curAccountID' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentAccountPermission' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImgLink' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'whitelabelAdmin' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'accountName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'homepage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyID' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isDemo' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackAvailable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reportAvailable' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'explainAvailable' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetUserInfoQuery, GetUserInfoQueryVariables>
export const GetProfileInfoDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfileInfo' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'telNum' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'publicJobTitle' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'profileImgLink' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acceptedMarketing' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<GetProfileInfoQuery, GetProfileInfoQueryVariables>
export const GetUserAccountsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserAccounts' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userAccountProfiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userPermission' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'homepage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetUserAccountsQuery,
  GetUserAccountsQueryVariables
>
export const UpdateUserProfileDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'username' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'phone' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobTitle' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateUser' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'username' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'username' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'phone' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'phone' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'lastName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'jobTitle' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'jobTitle' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'firstName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'publicJobTitle' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'telNum' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>
export const UpdateUserHomepageDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserHomepage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newPage' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserHomepage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'preferredHomepage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newPage' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'preferredHomepage' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateUserHomepageMutation,
  UpdateUserHomepageMutationVariables
>
export const UpdateMarketingPermissionDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateMarketingPermission' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'allowMarketing' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateMarketingPermission' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'allowMarketing' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'allowMarketing' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'acceptedMarketing' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateMarketingPermissionMutation,
  UpdateMarketingPermissionMutationVariables
>
export const GetEmailNotificationsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEmailNotifications' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userAccountProfiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'explainEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reportEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'trackEmail' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'userID' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetEmailNotificationsQuery,
  GetEmailNotificationsQueryVariables
>
export const ToggleEmailNotificationsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ToggleEmailNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'explain' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'report' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'track' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'toggleEmailNotifications' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'accountID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'report' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'report' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'track' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'track' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'explain' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'explain' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userAccountProfiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'trackEmail' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportEmail' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'explainEmail' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ToggleEmailNotificationsMutation,
  ToggleEmailNotificationsMutationVariables
>
export const GetAccountDataSourceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountDataSource' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blankAccount' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataSource' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adobeReportSuiteName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'adobeSegmentName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'connected' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gaAccountName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gaPropertyName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'gaViewName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ga4AccountName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'ga4PropertyName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'trackLearnCopy' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAccountDataSourceQuery,
  GetAccountDataSourceQueryVariables
>
export const GetCompanyWorkspacesDataSourcesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompanyWorkspacesDataSources' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentCompany' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountList' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'homepage' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blankAccount' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataSource' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gaPropertyID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ga4PropertyID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gaAccountName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gaAccountID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ga4PropertyName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ga4AccountName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'connected' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'adobeSegmentName' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'adobeReportSuiteName',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'adobeReportID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'ga4AccountID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gaPropertyName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gaViewID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'gaViewName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'kind' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetCompanyWorkspacesDataSourcesQuery,
  GetCompanyWorkspacesDataSourcesQueryVariables
>
export const GetAccountUsersDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAccountUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'account' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'accountID' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'accountID' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accountID' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userAccountProfiles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'created' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'expired' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inviteOpen' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastLogin' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userPermission' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  GetAccountUsersQuery,
  GetAccountUsersQueryVariables
>
export const UpdateUserPermissionsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserPermissions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'permissionLevel' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateUserPermissionLevel' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'accountID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'permissionLevel' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'permissionLevel' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'userID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userID' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  UpdateUserPermissionsMutation,
  UpdateUserPermissionsMutationVariables
>
export const RemoveUserDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'removeUser' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'accountID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'userID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userAccountProfiles' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'created' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'expired' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'inviteOpen' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastLogin' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userEmail' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'userPermission' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<RemoveUserMutation, RemoveUserMutationVariables>
export const RevokeUserInviteDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RevokeUserInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'revokeUserInvite' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'userID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'userID' },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  RevokeUserInviteMutation,
  RevokeUserInviteMutationVariables
>
export const ToggleModuleAvailabilityDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ToggleModuleAvailability' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'accountID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'explainModule' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reportModule' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'trackModule' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'toggleModuleAvailability' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'applyToCompany' },
                      value: { kind: 'BooleanValue', value: false },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'accountID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'accountID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'companyID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'companyID' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'explainModule' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'explainModule' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'reportModule' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'reportModule' },
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'trackModule' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'trackModule' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Account' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'accountID' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'explainAvailable' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'reportAvailable' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'trackAvailable' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  ToggleModuleAvailabilityMutation,
  ToggleModuleAvailabilityMutationVariables
>
export const DeleteWorkspaceDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteWorkspace' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workspaceID' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAccountSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deleteAccount' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'accountID' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'workspaceID' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'newToken' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'success' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'wasUserCurrentAccount' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown) as DocumentNode<
  DeleteWorkspaceMutation,
  DeleteWorkspaceMutationVariables
>
