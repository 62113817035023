import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { useReactiveVar } from '@apollo/client'
import { nanoid } from 'nanoid'

import Input from './input'
import OrderArrow from './order-arrow'
import Pagination from './pagination'
import { CustomLinkAlias } from './custom-link-fields'
import Table from './table'
import TopScrollbar from './top-scrollbar'
import { customLinkAliasesByDomain } from '../api/apollo/variables'
import { CampaignCodeGeneratorStructure, GeneratorFields } from '../api/types'
import { paginateData, sortData } from '../helpers'
import { GeneratedCode } from '../helpers/track-create'
import {
  getAnchorFromString,
  minBatchShortLinks,
} from '../helpers/track-module'
import useCustomLinks from '../hooks/useCustomLinks'
import styles from '../styles/campaign-code-generator.module.scss'

interface CampaignCodesPreviewTableProps {
  form: CampaignCodeGeneratorStructure | null
  codes: GeneratedCode[]
  setShowPreviewCodes: Dispatch<SetStateAction<GeneratedCode[]>>
  url?: string[]
  linkType: string
  previewTableShortLinks: { [code: string]: string }
  setPreviewTableShortLinks: Dispatch<
    SetStateAction<{ [code: string]: string }>
  >
  setShortLinkStatus?: Dispatch<SetStateAction<UrlStatus>>
}

export default function CampaignCodeCreatePreviewTable({
  form,
  codes,
  setShowPreviewCodes,
  url = [],
  linkType,
  previewTableShortLinks,
  setPreviewTableShortLinks,
  setShortLinkStatus,
}: CampaignCodesPreviewTableProps) {
  const {
    selectedDomain,
    fetchNewAliases,
    fetchingAliases,
    replaceAlias,
    replaceBatchAlias,
  } = useCustomLinks()

  const aliasesByDomain = useReactiveVar(customLinkAliasesByDomain)

  const [useBatch, setUseBatch] = useState(false)

  // Fetch required number of new aliases
  useEffect(() => {
    if (
      !(linkType === 'short' || linkType === 'combined') ||
      !aliasesByDomain[selectedDomain]
    )
      return

    const newPreviewSLs = {}

    if (codes.length >= minBatchShortLinks) {
      setUseBatch(true)
      // Batch alias already exists - use that
      if (aliasesByDomain[selectedDomain].batch) {
        codes.forEach(({ code }) => {
          newPreviewSLs[code] = aliasesByDomain[selectedDomain].batch
        })

        // Set all privew SLs to be the same - they won't be shown
        setPreviewTableShortLinks(newPreviewSLs)
        return
      }

      // Will update the batch alias only
      fetchNewAliases(codes.length)

      return
    }

    setUseBatch(false)

    codes.forEach(({ code }, index) => {
      newPreviewSLs[code] =
        aliasesByDomain[selectedDomain].individual[index] || ''
    })
    setPreviewTableShortLinks(newPreviewSLs)

    const existingAliasesCount =
      aliasesByDomain[selectedDomain].individual.length

    if (existingAliasesCount >= codes.length) return

    fetchNewAliases(codes.length - existingAliasesCount)
  }, [aliasesByDomain])

  const [activePage, setActivePage] = useState(1)
  const [pages, setPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [allChecked, setAllChecked] = useState(true)
  const [sortKey, setSortKey] = useState('url')
  const [orderAsc, setOrderAsc] = useState(false)

  const scrollRef = useRef<HTMLDivElement>(null)
  const ref = useRef(null)

  const setSortOrder = (key: string): void => {
    if (key === sortKey) {
      setOrderAsc(!orderAsc)
    } else {
      setSortKey(key)
      setOrderAsc(true)
    }
    // Go to the start of pagination
    setActivePage(1)
  }

  const orderedData = useMemo(() => {
    let fn

    if (sortKey.search(/[0-9]{1,9}-/gi) === 0) {
      const index = sortKey.split('-')[0]
      fn = (row) => {
        return row.pDfs[index][1] || ''
      }
    }

    const filtered = sortData([...codes], sortKey, orderAsc, fn)
    setPages(Math.ceil(filtered.length / rowsPerPage))

    return paginateData(filtered, rowsPerPage)
  }, [sortKey, orderAsc, codes, rowsPerPage])

  if (
    !form ||
    codes.length === 0 ||
    (linkType === 'short' && !aliasesByDomain[selectedDomain])
  )
    return null

  return (
    <>
      {useBatch && (
        <div className={styles.batchShortLinkRow}>
          <span>Short links will start with:</span>
          <div>
            <CustomLinkAlias
              domainID={selectedDomain}
              fetchingAliases={fetchingAliases}
              alias={aliasesByDomain[selectedDomain].batch || ''}
              nLinks={codes.length}
              replaceBatchAlias={replaceBatchAlias}
              canUseCustom={false}
              setCustomLinkStatus={setShortLinkStatus}
            />
          </div>
        </div>
      )}
      <div className={styles.tableContainer}>
        <TopScrollbar ref={scrollRef}>
          <Table ref={ref} className={styles.table}>
            <thead>
              <tr>
                <th className={styles.checkboxCell}>
                  <Input
                    type="checkbox"
                    id="allNone"
                    name="allNone"
                    checked={allChecked}
                    className={styles.selectItem}
                    label=" "
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const { checked } = e.target as HTMLInputElement

                      setAllChecked(checked)

                      const setCodes = codes.map((item) => {
                        return {
                          ...item,
                          selected: checked,
                        }
                      })

                      setShowPreviewCodes(setCodes)
                    }}
                  />
                </th>
                {linkType === 'short' && !useBatch && (
                  <th className={styles.shortLinkCell}>Short link</th>
                )}
                {url.length > 1 && (
                  <th
                    key={nanoid()}
                    onClick={() => setSortOrder('url')}
                    className={styles.otherColumn}
                  >
                    Landing page URL
                    <OrderArrow
                      className={styles.orderArrow}
                      currentKey="url"
                      sortKey={sortKey}
                      orderAsc={orderAsc}
                    />
                  </th>
                )}
                {form &&
                  form.paramDefs.map(
                    (
                      field: GeneratorFields,
                      index: number,
                    ): React.ReactElement | null => {
                      const showCol = !codes.every((code) => {
                        return code.pDfs[index][0] === ''
                      })

                      if (!showCol) {
                        return null
                      }

                      return (
                        <th
                          key={nanoid()}
                          onClick={() =>
                            setSortOrder(`${index}-${field.fieldName}`)
                          }
                          className={styles.otherColumn}
                        >
                          {field.fieldName}
                          {field.metaParameter ? (
                            <b className={styles.optional}> (meta)</b>
                          ) : (
                            ''
                          )}
                          <OrderArrow
                            className={styles.orderArrow}
                            currentKey={`${index}-${field.fieldName}`}
                            sortKey={sortKey}
                            orderAsc={orderAsc}
                          />
                        </th>
                      )
                    },
                  )}
              </tr>
            </thead>
            <tbody>
              {orderedData.length > 0 &&
                orderedData[activePage - 1] &&
                orderedData[activePage - 1].map((field: GeneratedCode) => {
                  const useAnchor = getAnchorFromString(field.tC)

                  return (
                    <tr
                      key={JSON.stringify(field)}
                      className={styles.otherColumn}
                    >
                      <td className={styles.checkboxCell}>
                        <Input
                          type="checkbox"
                          id={nanoid()}
                          name={nanoid()}
                          checked={field.selected}
                          className={styles.selectItem}
                          label=" "
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            const { checked } = e.target as HTMLInputElement

                            const setCodes = codes.map((item) => {
                              if (field.code === item.code) {
                                return {
                                  ...item,
                                  selected: checked,
                                }
                              }
                              return item
                            })

                            setAllChecked(
                              setCodes.every((code) => code.selected),
                            )

                            setShowPreviewCodes(setCodes)
                          }}
                        />
                      </td>
                      {linkType === 'short' && !useBatch && (
                        <td className={styles.shortLinkCell}>
                          <CustomLinkAlias
                            domainID={selectedDomain}
                            fetchingAliases={fetchingAliases}
                            alias={previewTableShortLinks[field.code] || ''}
                            index={aliasesByDomain[
                              selectedDomain
                            ].individual.indexOf(
                              previewTableShortLinks[field.code],
                            )}
                            replaceAlias={replaceAlias}
                            canUseCustom={false}
                            setCustomLinkStatus={setShortLinkStatus}
                          />
                        </td>
                      )}
                      {url.length > 1 && (
                        <td>
                          {field.url}
                          {useAnchor}
                        </td>
                      )}
                      {field.pDfs.map((item, paramIndex) => {
                        const showCol = !codes.every((code) => {
                          return code.pDfs[paramIndex][0] === ''
                        })

                        if (!showCol) return null

                        return <td key={nanoid()}>{item[0]}</td>
                      })}
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </TopScrollbar>
      </div>
      {pages > 1 && (
        <Pagination
          pages={pages}
          activePage={activePage}
          onChange={(index) => setActivePage(index)}
          rowsPerPageData={{
            rowsPerPage,
            maxRowsPerPage: 50,
            totalRows: codes.length,
            onChange: (newRowsPerPage) => {
              setRowsPerPage(newRowsPerPage)
              setActivePage(1)
            },
          }}
        />
      )}
    </>
  )
}
